<template>
    <div class="app-container pt0Im pl0Im">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>教辅维护</a></el-breadcrumb-item>
                <el-breadcrumb-item>教辅详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div style="height: calc(100vh - 150px);overflow: auto;">
            <el-card>
                <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                    <el-descriptions :column="3" class="detailFF" title="基本信息">
                        <el-descriptions-item label="文件名称">
                            {{ form.fileName }}
                        </el-descriptions-item>
                        <el-descriptions-item label="铺设状态">
                            <span v-if="form.printStatus == 0">未铺设</span>
                            <span v-if="form.printStatus == 1">正在铺设</span>
                            <span v-if="form.printStatus == 2">铺设成功</span>
                            <span v-if="form.printStatus == 3">铺设失败</span>
                            <span v-if="form.printStatus == 4">无需铺设</span>
                        </el-descriptions-item>
                        <el-descriptions-item label="归属客户">
                            {{ form.customName }}
                        </el-descriptions-item>
                        <el-descriptions-item label="学校">
                            {{ form.schoolName }}
                        </el-descriptions-item>
                        <el-descriptions-item label="年级">
                            {{ form.grade }}
                        </el-descriptions-item>
                        <el-descriptions-item label="教师">
                            {{ form.teacherTxt }}
                        </el-descriptions-item>
                        <el-descriptions-item label="页数">
                            {{ form.pages }}
                        </el-descriptions-item>
                        <el-descriptions-item label="创建时间">
                            {{ timestampToTime(form.createTime) }}
                        </el-descriptions-item>
                    </el-descriptions>
                </el-form>
                <div class="demo-image__preview mt15" style="background: #f3f3f3;display: flex;flex-wrap: wrap;">
                    <el-image v-for="(item, index) in srcList"
                        style="width: 30%; height: auto;margin-top: 15px;margin-bottom: 15px;margin-left: 1.5%;margin-right: 1.5%;"
                        :src="item" :zoom-rate="1.2" :preview-src-list="srcList" :initial-index="index" :infinite="false"
                        fit="cover" />
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
import { bookList } from "@/api/adminservice/public"
import { queryCustoms, queryTreeCustomOrg, createCustoms, custominfo, configCustom, customOrgBook } from "@/api/adminservice/custom"
import { qunQueryUser, qunCreate, qunUpdate, queryCustomQun } from "@/api/adminservice/qun"
import { formCreate, formInfo, updateForm } from "@/api/adminservice/form"
import { queryScene } from "@/api/adminservice/scene"
import { timestampToTime } from "@/utils/date";
import { eduFileInfo } from "@/api/adminservice/readOver"

export default {
    name: "demoAdd",
    components: {},
    data() {
        return {
            fileList: [],
            formId: '',
            groupData: {},
            timestampToTime,
            id: '',
            readBook: false,
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            srcList: [],
            srcurl: '',
            // 表单参数
            form: {
                adminName: '',
                adminUserId: '',
                orgId: '',
                file: [],
                isDesign: false,
                teacherTxt: ''
            },
            tableData: [{
                adminAccount: '',
                orgName: ''
            }],
            // 表单校验
            rules: {
            },
            bookList: [],
            configObj: {
                bookIds: [],
                orgId: ''
            },
            customList: [],
            orgList: [],
            oldBookIds: [],
            sceneList: [],
        };
    },
    created() {

    },
    mounted() {
        if (this.$route.query.fileId) {
            this.fileId = this.$route.query.fileId;
            this.form.fileId = this.$route.query.fileId;
            eduFileInfo({ fileId: this.fileId }).then(res => {
                Object.assign(this.form, res.data.data)
                var teacherTxtList = []
                this.form.teacherList.forEach(e => {
                    teacherTxtList.push(e.teacherName)
                })
                this.form.teacherTxt = teacherTxtList.toString('、')
                for (var i = 1; i <= res.data.data.pages; i++) {
                    this.srcList.push(res.data.data.imgFileUrl + '' + i + '.png_nostyle')
                }
                this.srcurl = res.data.data.imgFileUrl + '1.png_nostyle'
                console.log(this.srcList)
            })

        } else {

        }
        this.getList();
    },
    computed: {
    },
    methods: {
        handleChange(file, fileList) {
            console.log(fileList)
            console.log(fileList.length)

            this.form.file = []
            if (fileList[0]) {
                this.form.file.push(fileList[fileList.length - 1])
                this.form.fileName = fileList[fileList.length - 1].name
                this.form.formName = fileList[fileList.length - 1].name
            }
            // console.log(this.form.file)
        },
        changeOrg() {
            console.log(this.$refs['tsd'])
            console.log(this.oldBookIds);
            this.form.bookIds = [];
            customOrgBook({ orgId: this.form.orgId }).then(res => {
                this.bookList = res.data.data;
                res.data.data.forEach(e => {
                    this.oldBookIds.forEach(j => {
                        if (e.id == j) {
                            this.form.bookIds.push(e.id)
                        }
                    })
                })
            })
        },
        validateUsername(rule, value, callback) {
            qunQueryUser({ mobile: value }).then(res => {
                const result = res.data.data
                if (!result) {
                    callback(new Error("该账号用户不存在"))
                } else {
                    this.form.adminName = res.data.data.nickName;
                    this.form.adminUserId = res.data.data.userId;

                    callback()
                }
            })
        },
        changeCustom() {
            this.orgList = [];
            this.form.orgId = '';
            queryTreeCustomOrg({ customId: this.form.customId }).then(res => {
                this.orgList = res.data.data;
                res.data.data.forEach((e, i) => {
                    e.label = e.orgName
                    e.value = e.orgId
                    e.children = e.childrenList;
                    e.disabled = true
                    this.ergodicList(e.childrenList)
                })
                console.log(res.data.data)
                this.orgList = res.data.data;
            })
        },
        submitConfigForm() {
            console.log(this.configObj)
            configCustom(this.configObj).then(res => {
                if (res.data.code == 1) {
                    this.tableData.forEach(e => {
                        if (e.orgId) {
                            if (e.orgId == this.configObj.orgId) {
                                e.bookIds = this.configObj.bookIds
                            }
                        }
                    })
                    this.$message.success("配置成功");
                    this.addPeopleOpen = false;
                    this.configCancel();
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        configCancel() {
            this.readBook = false;
            this.configObj.bookIds = [];
            this.configObj.orgId = '';
        },
        configurationRow(index, row) {
            if (row.adminAccount == '') {
                this.$message.error("请填写管理员账号")
                return;
            }
            if (row.orgName == '') {
                this.$message.error("请填写组织名称")
                return;
            }
            this.readBook = true;
            this.configObj.bookIds = row.bookIds;
            this.configObj.orgId = row.orgId;
            console.log(index, row)
        },
        deleteRow(index) {
            console.log(index)
            if (this.tableData.length == 1) {
                this.$message.error("不能全部删除")
            } else {
                this.tableData.splice(index, 1)
            }
        },
        onAddItem() {
            console.log(this.tableData)
            this.tableData.push({
                adminAccount: '',
                orgName: ''
            })
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginDate === '' || !this.form.beginDate) {
                callback(new Error('请选择开始时间'))
            } else if (this.form.endDate === '' || !this.form.endDate) {
                callback(new Error('请选择结束时间'))
            } else {
                callback()
            }
        },
        startDateConfirm(e) {
            if (e > this.form.endDate) {
                this.form.endDate = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e < this.form.beginDate) {
                this.form.beginDate = '';
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date())
        },
        enddisabledDate(current) {
            if (this.form.beginDate) {
                return current && current < this.form.beginDate
            } else {
                return current && current < Number(new Date())
            }
        },
        /** 查询基础：基数数据列表 */
        getList() {
            this.loading = false;

            // bookList().then(res => {
            //     this.bookList = res.data.data;
            // });
            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                this.customList = res.data.data;
            });
            queryScene({ customId: this.$storage.get('customId') }).then(res => {
                this.sceneList = res.data.data;
            })
        },


        /** 提交按钮 */
        submitForm() {
            console.log(this.form)
            this.$refs["form"].validate(valid => {
                if (valid) {
                    console.log(this.form)
                    if (this.formId) {
                        if (this.form.isDesign == false) {
                            this.form.isDesign = 0
                        } else {
                            this.form.isDesign = 1
                        }
                        updateForm(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                this.replace('/formMain')
                            } else {
                                this.$message.error("操作失败")
                            }
                        });
                    } else {
                        let formdata = new FormData();
                        this.form.file.map((item) => {
                            console.log(item)
                            formdata.append('file', item.raw)
                        })
                        formdata.append('customId', this.form.customId)
                        formdata.append('fileName', this.form.fileName)
                        formdata.append('formName', this.form.formName)
                        formdata.append('formType', this.form.formType)
                        formdata.append('isDesign', this.form.isDesign == true ? 1 : 0)
                        formdata.append('sceneId', this.form.sceneId)

                        formCreate(formdata).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("新增成功");
                                this.replace('/formMain')
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            this.replace('/teachAssistantMain')
            // this.$emit('isTg', true)
        },
        ergodicList(childrenList) {
            if (childrenList.length != 0) {

                childrenList.forEach(e => {
                    e.label = e.orgName
                    e.value = e.orgId
                    console.log(this.findParentNode(e.orgId))
                    if (this.findParentNode(e.orgId).indexOf(2) == -1) {
                        e.disabled = true
                    }
                    if (e.orgType == 5) {
                        // debugger
                        e.disabled = true
                        console.log(e)
                    }
                    if (e.childrenList.length != 0) {
                        e.children = e.childrenList

                        this.ergodicList(e.childrenList)
                    }
                })
            }
        },
        // id 指的是当前点击的节点id;
        findParentNode(id) {
            // debugger
            // 初始化所需数据
            var firstParentObj = {}; // 记录直系父级的名称和id即接口要传的数据
            var parentIds = []; // 记录所有的父级ids
            var parentIdsQueue = []; // 记录排队的

            // 将树放到排队系列
            var parentIdsQueue = JSON.parse(JSON.stringify(this.orgList));

            // 开始遍历排队的树
            while (parentIdsQueue.length) {
                //抽取第一个排队的数据 
                let item = parentIdsQueue.shift();

                let { childrenList } = item;
                if (item.orgId === id) {
                    // 第一层就找到了
                    if (!item.parentArr) {
                        firstParentObj = {
                            id: item.orgId,
                            name: item.orgType
                        };
                        parentIds = [item.orgType];
                    } else {
                        // 获取当前节点的parentArr
                        let len = item.parentArr.length;
                        firstParentObj = item.parentArr[len - 1];
                        item.parentArr.forEach(a => {
                            parentIds.push(a.name);
                        });
                        parentIds.push(item.orgType);
                    }

                    // 结束遍历
                    parentIdsQueue = [];

                    break;
                } else if (childrenList && childrenList.length) {
                    let len = childrenList.length;
                    for (let i = len - 1; i >= 0; i--) {
                        // 新建一个数组用于记录它的父亲节点
                        childrenList[i].parentArr = [];

                        // 把它的历史父亲节点们先放入
                        if (item.parentArr) {
                            childrenList[i].parentArr = childrenList[i].parentArr.concat(
                                item.parentArr
                            );
                        }

                        // 再放入当前的父亲节点
                        childrenList[i].parentArr.push({
                            id: item.orgId,
                            name: item.orgType
                        });

                        // 加入到排队序列中
                        parentIdsQueue.unshift(childrenList[i]);
                    }

                }
            }
            return parentIds

        },
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}
</style>