<template>
    <div class="app-container pt0Im pl0Im">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>备课管理</a></el-breadcrumb-item>
                <el-breadcrumb-item>{{ lessonId ? '编辑备课' : '新增备课' }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 180px);overflow: auto;">
            <el-form ref="form" :model="form" :rules="rules" label-width="100px">

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="授课教师" prop="teacherId">
                            <el-select style="width: 100%;" clearable @change="changeLeadTeacher" v-model="form.teacherId"
                                placeholder="请选择授课教师">
                                <el-option v-for="item in teacherList" :key="item.teacherId"
                                    :label="item.teacherName + '-' + item.schoolName" :value="item.teacherId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="学科" prop="subject">
                            <el-select @change="changeSubject" style="width: 100%;" v-model="form.subject"
                                placeholder="请选择学科">
                                <el-option v-for="item in sksubjectList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="课程名称" prop="lessonName">
                            <el-input v-model="form.lessonName" placeholder="最多64个字" maxlength="64" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="是否开放" prop="isOpen">
                            <el-switch v-model="form.isOpen"></el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="lesscustom mb0">
                    <el-col :span="12">
                        <el-card class="mr5">
                            <template #header>
                                <div class="card-header">
                                    板书教案
                                </div>
                            </template>
                            <el-col :span="24">
                                <el-form-item label-width="70px" label="点阵文件" prop="fileUrls">
                                    <el-upload v-model:file-list="dzFileList" class="upload-demo" :on-remove="handleRemove"
                                        :show-file-list="false"
                                        action="https://zbh.zbform.com/adminservice/zhkt/lesson/uploadFile"
                                        :on-change="handleJAChange" :headers="{ token }"
                                        :data="{ fileType: 1, fileBelowType: 1, fileName: fileName }" accept=".pdf"
                                        :before-upload="beforePdfUpload">
                                        <div style="display: flex;width: 100%;">
                                            <el-button type="primary">上传</el-button>
                                        </div>
                                    </el-upload>
                                </el-form-item>
                                <el-table border :data="dzFileList" class="mt5">
                                    <el-table-column align="center" width="60" label="序号" type="index"></el-table-column>
                                    <el-table-column align="center" label="文件名称" prop="name">
                                        <template #default="scope">
                                            <div :title="scope.row.name" class="text-one">
                                                {{ scope.row.name }}
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" fixed="right" width="130" label="操作"
                                        class-name="small-padding fixed-width">
                                        <template #default="scope">
                                            <el-button @click="xz(scope.row)" size="mini" type="text">
                                                下载文件
                                            </el-button>
                                            <el-button @click="handleRemove(scope.row)" size="mini" type="text">
                                                删除
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-col>
                            <el-col :span="24" class="mt15">
                                <el-form-item label-width="75px" label="普通文件" prop="fileUrls">
                                    <el-upload v-model:file-list="ptFileList" class="upload-demo" :on-remove="handleRemove"
                                        :show-file-list="false"
                                        action="https://zbh.zbform.com/adminservice/zhkt/lesson/uploadFile"
                                        :on-change="handleJAChange" :headers="{ token }"
                                        :data="{ fileType: 1, fileBelowType: 2, fileName: fileName }"
                                        accept=".pdf,.ppt,.pptx" :before-upload="beforeJAUpload">
                                        <div style="display: flex;width: 100%;">
                                            <el-button type="primary">上传</el-button>
                                        </div>
                                    </el-upload>

                                </el-form-item>
                                <el-table border :data="ptFileList" class="mt5">
                                    <el-table-column align="center" width="60" label="序号" type="index"></el-table-column>
                                    <el-table-column align="center" label="文件名称" prop="name">
                                        <template #default="scope">
                                            <div :title="scope.row.name" class="text-one">
                                                {{ scope.row.name }}
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" fixed="right" width="130" label="操作"
                                        class-name="small-padding fixed-width">
                                        <template #default="scope">
                                            <el-button @click="xz(scope.row)" size="mini" type="text">
                                                下载文件
                                            </el-button>
                                            <el-button @click="handleRemove(scope.row)" size="mini" type="text">
                                                删除
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-col>
                            <el-col :span="24" class="mt15">
                                <el-form-item label-width="45px" label="PPT" prop="fileUrls">
                                    <el-upload v-model:file-list="PPTFileList" class="upload-demo" :on-remove="handleRemove"
                                        :show-file-list="false"
                                        action="https://zbh.zbform.com/adminservice/zhkt/lesson/uploadFile"
                                        :on-change="handleJAChange" :headers="{ token }"
                                        :data="{ fileType: 1, fileBelowType: 3, fileName: fileName }" accept=".ppt,.pptx"
                                        :before-upload="beforePPTUpload">
                                        <div style="display: flex;width: 100%;">
                                            <el-button type="primary">上传</el-button>
                                        </div>
                                    </el-upload>
                                </el-form-item>
                                <el-table border :data="PPTFileList" class="mt5">
                                    <el-table-column align="center" width="60" label="序号" type="index"></el-table-column>
                                    <el-table-column align="center" label="文件名称" prop="name">
                                        <template #default="scope">
                                            <div :title="scope.row.name" class="text-one">
                                                {{ scope.row.name }}
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" fixed="right" width="130" label="操作"
                                        class-name="small-padding fixed-width">
                                        <template #default="scope">
                                            <el-button @click="xz(scope.row)" size="mini" type="text">
                                                下载文件
                                            </el-button>
                                            <el-button @click="handleRemove(scope.row)" size="mini" type="text">
                                                删除
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-col>
                        </el-card>
                    </el-col>
                    <el-col :span="12">
                        <el-card class="ml5">
                            <template #header>
                                <div class="card-header">
                                    互动学案
                                </div>
                            </template>
                            <el-col :span="24">
                                <el-form-item label-width="75px" label="学生学案" prop="fileUrls">
                                    <el-upload v-model:file-list="xsxaFileList" class="upload-demo"
                                        :on-remove="handleRemove" :show-file-list="false"
                                        action="https://zbh.zbform.com/adminservice/zhkt/lesson/uploadFile"
                                        :on-change="handleJAChange" :headers="{ token }"
                                        :data="{ fileType: 2, fileBelowType: 4, fileName: fileName }" accept=".pdf"
                                        :before-upload="beforePdfUpload">
                                        <div style="display: flex;width: 100%;">
                                            <el-button type="primary">上传</el-button>
                                        </div>
                                    </el-upload>
                                </el-form-item>
                                <el-table border :data="xsxaFileList" class="mt5">
                                    <el-table-column align="center" width="60" label="序号" type="index"></el-table-column>
                                    <el-table-column align="center" label="文件名称" prop="name">
                                        <template #default="scope">
                                            <div :title="scope.row.name" class="text-one">
                                                {{ scope.row.name }}
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" fixed="right" width="130" label="操作"
                                        class-name="small-padding fixed-width">
                                        <template #default="scope">
                                            <el-button @click="xz(scope.row)" size="mini" type="text">
                                                下载文件
                                            </el-button>
                                            <el-button @click="handleRemove(scope.row)" size="mini" type="text">
                                                删除
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-col>
                            <el-col :span="24" class="mt15">
                                <el-form-item label-width="75px" label="小组学案" prop="fileUrls">
                                    <el-upload v-model:file-list="xzxaFileList" class="upload-demo"
                                        :on-remove="handleRemove" :show-file-list="false"
                                        action="https://zbh.zbform.com/adminservice/zhkt/lesson/uploadFile"
                                        :on-change="handleJAChange" :headers="{ token }"
                                        :data="{ fileType: 2, fileBelowType: 5, fileName: fileName }" accept=".pdf"
                                        :before-upload="beforePdfUpload">
                                        <div style="display: flex;width: 100%;">
                                            <el-button type="primary">上传</el-button>
                                        </div>
                                    </el-upload>
                                </el-form-item>
                                <el-table border :data="xzxaFileList" class="mt5">
                                    <el-table-column align="center" width="60" label="序号" type="index"></el-table-column>
                                    <el-table-column align="center" label="文件名称" prop="name">
                                        <template #default="scope">
                                            <div :title="scope.row.name" class="text-one">
                                                {{ scope.row.name }}
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" fixed="right" width="130" label="操作"
                                        class-name="small-padding fixed-width">
                                        <template #default="scope">
                                            <el-button @click="xz(scope.row)" size="mini" type="text">
                                                下载文件
                                            </el-button>
                                            <el-button @click="handleRemove(scope.row)" size="mini" type="text">
                                                删除
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-col>
                            <el-col :span="24" class="mt15">
                                <el-form-item label-width="50px" label="试卷" prop="fileUrls">
                                    <el-upload v-model:file-list="sjFileList" class="upload-demo" :on-remove="handleRemove"
                                        :show-file-list="false"
                                        action="https://zbh.zbform.com/adminservice/zhkt/lesson/uploadFile"
                                        :on-change="handleJAChange" :headers="{ token }"
                                        :data="{ fileType: 2, fileBelowType: 6, fileName: fileName }" accept=".pdf"
                                        :before-upload="beforePdfUpload">
                                        <div style="display: flex;width: 100%;">
                                            <el-button type="primary">上传</el-button>
                                        </div>
                                    </el-upload>
                                </el-form-item>
                                <el-table border :data="sjFileList" class="mt5">
                                    <el-table-column align="center" width="60" label="序号" type="index"></el-table-column>
                                    <el-table-column align="center" label="文件名称" prop="name">
                                        <template #default="scope">
                                            <div :title="scope.row.name" class="text-one">
                                                {{ scope.row.name }}
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" fixed="right" width="130" label="操作"
                                        class-name="small-padding fixed-width">
                                        <template #default="scope">
                                            <el-button @click="xz(scope.row)" size="mini" type="text">
                                                下载文件
                                            </el-button>
                                            <el-button @click="handleRemove(scope.row)" size="mini" type="text">
                                                删除
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-col>
                        </el-card>
                    </el-col>
                </el-row>
                <el-row class="mt15">
                    <el-col :span="24" class="custom">
                        <el-card class="mr5">
                            <template #header>
                                <div class="card-header">
                                    互动本
                                </div>
                            </template>
                            <div>
                                <div class="fl-warp" style="display: flex;max-height: 300px;overflow-y: auto;">
                                    <div v-for="item in lessPreparatList" class="csbbb mr10"
                                        :style="form.bookIdsList.indexOf(item.id) != -1 ? '' : 'display:none'">
                                        <el-icon @click="delBookId(item.id)" class="eicon"
                                            style="cursor: pointer;color: red;">
                                            <Delete />
                                        </el-icon>
                                        <img style="width: 90px;margin-top: 10px;"
                                            :src="this.$store.state.zdy.imgUrl+'/book/' + item.id + '/img/cover.png_original'"
                                            alt="" class="image">
                                        <div style="width: 90px;text-align: center;font-size:14px;" class="text-one mb15">{{
                                            item.bookName }}</div>
                                    </div>
                                    <el-icon @click="openBook"
                                        style="width: 90px;height:130px;border:1px solid #e3e3e3;margin-top:10px;"
                                        class="avatar-uploader-icon cursor">
                                        <Plus />
                                    </el-icon>
                                </div>
                            </div>
                            <!-- <el-checkbox-group v-model="form.bookIdsList">
                                <el-checkbox :label="item.id" v-for="item in lessPreparatList">
                                    <img :title="item.bookName" style="width: 100px;margin-top: 10px;height: 140px;"
                                        :src="this.$store.state.zdy.imgUrl+'/book/' + item.id + '/img/cover.png_original'"
                                        alt="" class="image">
                                    <div :title="item.bookName" style="width: 100px;" class="text-one mb10">{{
                                        item.bookName }}</div>
                                </el-checkbox>
                            </el-checkbox-group> -->
                        </el-card>
                    </el-col>
                </el-row>
                <el-row class="mt15">
                    <el-col :span="24">
                        <el-card class="mr5">
                            <template #header>
                                <div class="card-header">
                                    客观测验
                                </div>
                            </template>
                            <el-form-item label-width="0" label="" prop="answerCardIds">
                                <div class="mr15 csbbb" v-for="(item, index) in form.answerCardList">
                                    <el-icon @click="editAnswerCard(item)" :size="20" class="editeicon"
                                        style="cursor: pointer;z-index: 99;">
                                        <Edit />
                                    </el-icon>
                                    <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                        icon-color="#626AEF" title="确定删除该答题卡" @confirm="delcardId(item.cardId, index)">
                                        <template #reference>
                                            <el-icon :size="20" class="eicon"
                                                style="cursor: pointer;z-index: 99;color: red;">
                                                <Delete />
                                            </el-icon>
                                        </template>
                                    </el-popconfirm>
                                    <img style="width: 180px;height:130px;" src="../../assets/login-background.jpg"
                                        class="image">
                                    <div class="text-one">{{ item.cardName }}</div>
                                </div>
                                <div style="height:163px">
                                    <el-icon @click="openIsaddAnswer"
                                        style="width: 180px;height:130px;border:1px solid #e3e3e3;"
                                        class="avatar-uploader-icon cursor">
                                        <Plus />
                                    </el-icon>
                                </div>
                            </el-form-item>
                        </el-card>
                    </el-col>
                </el-row>
                <el-row class="mt15 mb20">
                    <el-col :span="24">
                        <el-card class="mr5">
                            <template #header>
                                <div class="card-header">
                                    投票反馈题
                                </div>
                            </template>
                            <el-form-item label-width="0" label="" prop="backCardIds">
                                <div class="mr15 csbbb" v-for="(item, index) in form.backCardList">
                                    <el-icon @click="editCard(item)" :size="20" class="editeicon"
                                        style="cursor: pointer;z-index: 99;">
                                        <Edit />
                                    </el-icon>
                                    <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                        icon-color="#626AEF" title="确定删除该答题卡" @confirm="delbackCardId(item.cardId, index)">
                                        <template #reference>
                                            <el-icon :size="20" class="eicon"
                                                style="cursor: pointer;z-index: 99;color: red;">
                                                <Delete />
                                            </el-icon>
                                        </template>
                                    </el-popconfirm>
                                    <img style="width: 180px;height:130px;" src="../../assets/login-background.jpg"
                                        class="image">
                                    <div class="text-one">{{ item.cardName }}</div>
                                </div>
                                <div style="height:163px">
                                    <el-icon @click="openIsaddCard"
                                        style="width: 180px;height:130px;border:1px solid #e3e3e3;"
                                        class="avatar-uploader-icon cursor">
                                        <Plus />
                                    </el-icon>
                                </div>
                            </el-form-item>
                        </el-card>

                    </el-col>
                </el-row>
            </el-form>

        </el-card>
        <!-- <div style="width: 390px;height:844px" v-html="form.content">

        </div> -->
        <el-dialog :title="answerCardForm.cardId ? '编辑答题卡' : '新增答题卡'" v-model="addAnswer" width="60%">
            <el-form ref="answerCardForm" :model="answerCardForm" :rules="addAnswerrules" label-width="120px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="标题" prop="cardName">
                            <el-input v-model="answerCardForm.cardName" placeholder="最多64个字" maxlength="64" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="考试用时" prop="examTime">
                            <el-input-number step-strictly v-model="answerCardForm.examTime" :min="0" :max="100000" />
                            <span class="ml15" style="color:red">精确到分钟</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="强制交卷时间" prop="submitTime">
                            <el-input-number step-strictly v-model="answerCardForm.submitTime" :min="0" :max="100000" />
                            <span class="ml15" style="color:red">精确到分钟</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="分数段设置" prop="sectionList" required>
                            <div v-for="(item, index) in answerCardForm.sectionList"
                                style="display: flex;align-items: center;width:500px;">
                                <el-rate v-model="item.starNum" disabled show-score text-color="#ff9900"
                                    score-template="{value} 星" />
                                <el-input-number class="ml15" size="small" style="width: 90px;" step-strictly disabled
                                    v-model="item.minScore" :min="0" :max="10000" />
                                <span class="ml5 mr5">~</span>
                                <el-input-number @change="changeMaxNum(index)" size="small" style="width: 90px;"
                                    step-strictly v-model="item.maxScore" :disabled="index == 4" :min="0" :max="10000" />
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="题目数" prop="questionNum">
                            <el-input-number @change="changeQuestionNum" step-strictly v-model="answerCardForm.questionNum"
                                :min="1" :max="25" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="测试总分" prop="totalScore">
                            <el-input-number @change="changeIsNum" step-strictly v-model="answerCardForm.totalScore"
                                :min="0" :max="10000" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="平均分数" prop="isAverageBol">
                            <el-switch @change="changeIsNum" v-model="answerCardForm.isAverageBol" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label-width="0" label="" prop="answerList">
                            <el-table ref="answerList" border :data="answerCardForm.answerList" style="width: 100%"
                                max-height="30vh">
                                <el-table-column align="center" prop="answerNo" label="序号" width="60" />
                                <el-table-column align="center" prop="itemName" label="选项内容">
                                    <template #default="scope">
                                        <div>
                                            <el-checkbox-group v-model="scope.row.answerContentList" size="small">
                                                <el-checkbox label="A" size="small" border />
                                                <el-checkbox label="B" size="small" border />
                                                <el-checkbox label="C" size="small" border />
                                                <el-checkbox label="D" size="small" border />
                                                <el-checkbox label="E" size="small" border />
                                            </el-checkbox-group>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" prop="answerScore" label="分值" width="120">
                                    <template #default="scope">
                                        <el-input-number @change="changeAnswer" :disabled="answerCardForm.isAverageBol"
                                            size="small" style="width: 90px;" v-model="scope.row.answerScore" :min="0"
                                            :max="100" />
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="addAnswerSumbit">确 定</el-button>
                    <el-button @click="addAnswer = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="addCardForm.cardId ? '编辑反馈卡' : '新增反馈卡'" v-model="addCard" width="60%">
            <el-form ref="addCardForm" :model="addCardForm" :rules="addCardrules" label-width="120px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="类型" prop="cardType">
                            <el-radio-group @change="changeCardType" v-model="addCardForm.cardType">
                                <el-radio :label="1">单选</el-radio>
                                <el-radio :label="2">多选</el-radio>
                                <el-radio :label="3">判断</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="标题" prop="cardName">
                            <el-input v-model="addCardForm.cardName" placeholder="最多64个字" maxlength="64" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="内容描述" prop="cardDescription">
                            <el-input type="textarea" v-model="addCardForm.cardDescription" placeholder="最多1000个字"
                                maxlength="1000" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="图片内容" prop="imgList">
                            <div v-for="(item, index) in addCardForm.imgList" class="csbbb">
                                <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                    icon-color="#626AEF" title="确定删除该图片" @confirm="delImage(index)">
                                    <template #reference>
                                        <el-icon :size="20" class="eicon" style="cursor: pointer;z-index: 99;color: red;">
                                            <Delete />
                                        </el-icon>
                                    </template>
                                </el-popconfirm>
                                <el-image fit="cover" :initial-index="index" :preview-src-list="addCardForm.imgList"
                                    style="width: 180px;height:130px;margin-bottom: 10px;object-fit: cover;" :src="item"
                                    alt="" class="image mr15" />
                            </div>
                            <div class="csbbb" v-if="addCardForm.imgList.length <= 5" style="height:140px;">

                                <el-upload v-model:file-list="filesList" class="avatar-uploadertxx"
                                    action="https://zbh.zbform.com/adminservice/zhkt/backCard/upload"
                                    :show-file-list="false" :on-change="handleSCChange" :before-upload="beforeAvatarUpload"
                                    :headers="{ token }">
                                    <el-icon style="width: 180px;height:130px;" class="avatar-uploader-icon">
                                        <Plus />
                                    </el-icon>
                                </el-upload>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>


                <el-row>
                    <el-col>
                        <el-form-item label-width="0" label="" prop="optionList">
                            <el-table ref="optionList" border :data="addCardForm.optionList" style="width: 100%"
                                max-height="300px">
                                <el-table-column align="center" prop="optionAnswer" label="投票选项" width="100" />
                                <el-table-column align="center" prop="optionContent" label="选项内容">
                                    <template #default="scope">
                                        <div v-if="addCardForm.cardType != 3">
                                            <el-input v-model="scope.row.optionContent" placeholder="最多1000个字"
                                                maxlength="1000" />
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" prop="isAnswer" label="正确答案" width="120">
                                    <template #default="scope">
                                        <el-radio-group v-model="scope.row.isAnswer" size="small">
                                            <el-radio-button :label="true">Y</el-radio-button>
                                            <el-radio-button :label="false">N</el-radio-button>
                                        </el-radio-group>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="addCardSumbit">确 定</el-button>
                    <el-button @click="addCard = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <div>
            <div class="disflex-row-center cusbtbtn" :style="$store.state.zdy.isActive ? 'left:64px;' : 'left:201px;'">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submitForm">保 存</el-button>
            </div>
        </div>
        <el-dialog :title="'配置互动本'" v-model="chooseBook" width="670px">
            <el-col class="custom ylsb" style="">
                <div v-for="item in lessPreparatList"
                    :style="form.bookIdsList.indexOf(item.id) != -1 ? 'display:none' : ''">
                    <div class="ylqs" @click="setChooseBook(item.id)"
                        :style="chooseBookIds.indexOf(item.id) != -1 ? 'background: #a3d6ff' : ''">
                        <img style="width: 100px;margin-top: 10px;"
                            :src="this.$store.state.zdy.imgUrl+'/book/' + item.id + '/img/cover.png_original'"
                            alt="" class="image">
                        <div style="width: 100px;" class="text-one mb15">{{ item.bookName }}</div>
                    </div>
                </div>
            </el-col>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitChooseBookForm">确 定</el-button>
                    <el-button @click="chooseBook = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { zhktQueryDict } from "@/api/adminservice/classOrganizaMain"
import { lessonListBook, lessonCreate, lessonUpdate, userTeachers, lessonDeleteFile, lessonInfo, answerCardCreate, answerCardUpdate, answerCardDelete, backCardDelete, backCardCreate, backCardUpdate } from "@/api/adminservice/lesson";
import { download } from "@/api/adminservice/public";

export default {
    name: "demoAdd",
    components: {
    },
    computed: {
    },
    data() {
        return {
            chooseBook: false,
            lessPreparatList: [],
            chooseBookIds: [],
            addAnswer: false,
            addCard: false,
            addCardForm: {
                cardType: 1,
                answer: '',
                cardDescription: '',
                cardName: '',
                imgList: [],
                optionList: [
                    {
                        optionAnswer: 'A',
                        optionContent: '',
                        optionNo: 1,
                        isAnswer: false,
                    }, {
                        optionAnswer: 'B',
                        optionContent: '',
                        optionNo: 2,
                        isAnswer: false,
                    }, {
                        optionAnswer: 'C',
                        optionContent: '',
                        optionNo: 3,
                        isAnswer: false,
                    }, {
                        optionAnswer: 'D',
                        optionContent: '',
                        optionNo: 4,
                        isAnswer: false,
                    }, {
                        optionAnswer: 'E',
                        optionContent: '',
                        optionNo: 5,
                        isAnswer: false,
                    }
                ]
            },
            answerCardForm: {
                cardName: '',
                examTime: 0,
                submitTime: 0,
                questionNum: 1,
                totalScore: 0,
                isAverageBol: true,
                sectionList: [
                    { maxScore: 0, minScore: 0, starNum: 1 },
                    { maxScore: 0, minScore: 0, starNum: 2 },
                    { maxScore: 0, minScore: 0, starNum: 3 },
                    { maxScore: 0, minScore: 0, starNum: 4 },
                    { maxScore: 0, minScore: 0, starNum: 5 },
                ],
                answerList: [
                    {
                        answerNo: 1,
                        answerContentList: [],
                        answerContent: '',
                        answerScore: ''
                    }
                ],
            },
            addAnswerrules: {
                cardName: [
                    { required: true, message: "请输入标题", trigger: "blur" },
                ],
                examTime: [
                    { required: true, message: "请输入考试用时", trigger: "blur" },
                ],
                submitTime: [
                    { required: true, message: "请输入强制交卷时间", trigger: "blur" },
                    { validator: this.validateSubmitTime, trigger: "blur" },
                ],
                questionNum: [
                    { required: true, message: "请输入题目数", trigger: "blur" },
                ],
                sectionList: [
                    { validator: this.validateSectionList, trigger: "blur" },
                ],
                totalScore: [
                    { required: true, message: "请输入测试总数", trigger: "blur" },
                ],
            },
            addCardrules: {
                cardName: [
                    { required: true, message: "请输入反馈卡名称", trigger: "blur" },
                ],
                cardType: [
                    { required: true, message: "请新增反馈卡类型", trigger: "change" },
                ]
            },
            fileName: '',
            lessonId: '',
            natureList: [],
            dzFileList: [],
            ptFileList: [],
            PPTFileList: [],
            xsxaFileList: [],
            xzxaFileList: [],
            sjFileList: [],
            periodList: [],
            gradeList: [],
            sksubjectList: [],
            subjectList: [],
            lessonLevelList: [],
            userList: [],
            teacherList: [],
            groupList: [],
            token: this.$storage.get("token"),
            delAnswerList: [],
            delCardList: [],
            // 遮罩层
            loading: true,
            // 非单个禁用
            single: true,
            // 表单参数
            form: {
                bookIds: '',
                bookIdsList: [],
                answerCardIds: '',
                backCardIds: '',
                fileUrls: '',
                subject: '',
                teacherId: this.$storage.get("userId"),
                answerCardList: [],
                backCardList: [],
                isOpen: false,
                coverUrl: '',
                groupId: '',
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                lessonLevelKey: '',
                title: '',
                leadUser: '',
                teamUsers: '',
                teamuseList: [],
                guideUsers: '',
                guideUsersList: [],
                type: 0,
                lessonNature: '1'
            },
            // 表单校验
            rules: {
                isOpen: [
                    { required: true, message: "请选择开放", trigger: "change" },
                ],
                lessonName: [
                    { required: true, message: "请输入课程名称", trigger: "blur" },
                ],
                teacherId: [
                    { required: true, message: "请选择授课教师", trigger: "change" },
                ],
                subject: [
                    { required: true, message: "请选择学科", trigger: "change" },
                ],
            },
            delFileList: [],
            isMyLess: 0,
            isDirectory: false,
            directoryList: [],
            tableData: [],
            isCourse: false,
        };
    },
    created() {
        this.getZhktQueryDict()
    },
    mounted() {
        if (this.$route.query.lessonId) {
            this.lessonId = this.$route.query.lessonId
            console.log(this.form)
            userTeachers({ username: JSON.parse(this.$storage.get("userInfo")).username }).then(res => {
                this.teacherList = res.data.data;
                this.getlessonInfo()
            })

        } else {
            this.form.bookIdsList = []
            lessonListBook().then(res => {
                this.lessPreparatList = res.data.data;
                res.data.data.forEach(e => {
                    this.form.bookIdsList.push(e.id)
                })
            })
            zhktQueryDict({ code: 'subject' }).then(response => {
                this.subjectList = response.data.data;
                this.getUserTeachers()

            })
            var userId = JSON.parse(this.$storage.get("userInfo")).userId
            this.form.leadUser = userId
        }
        if (this.$route.query.isMyLess) {
            this.isMyLess = this.$route.query.isMyLess
            console.log(this.isMyLess);
            if (this.isMyLess == 1) {
                this.form.lessonNature = 1
            }
        }
    },
    unmounted() {
        if (this.lessonId) {
            try {
                this.dzFileList.forEach(e => {
                    if (e.response.code) {
                        lessonDeleteFile({ originalFileUrl: e.response.data });
                    }
                })
                this.ptFileList.forEach(e => {
                    if (e.response.code) {
                        lessonDeleteFile({ originalFileUrl: e.response.data });
                    }
                })
                this.PPTFileList.forEach(e => {
                    if (e.response.code) {
                        lessonDeleteFile({ originalFileUrl: e.response.data });
                    }
                })
                this.xsxaFileList.forEach(e => {
                    if (e.response.code) {
                        lessonDeleteFile({ originalFileUrl: e.response.data });
                    }
                })
                this.xzxaFileList.forEach(e => {
                    if (e.response.code) {
                        lessonDeleteFile({ originalFileUrl: e.response.data });
                    }
                })
                this.sjFileList.forEach(e => {
                    if (e.response.code) {
                        lessonDeleteFile({ originalFileUrl: e.response.data });
                    }
                })
            } catch (e) {
                console.log('e', e)
            }
        } else {
            try {
                this.dzFileList.forEach(e => {
                    lessonDeleteFile({ originalFileUrl: e.response.data });
                })
                this.ptFileList.forEach(e => {
                    lessonDeleteFile({ originalFileUrl: e.response.data });
                })
                this.PPTFileList.forEach(e => {
                    lessonDeleteFile({ originalFileUrl: e.response.data });
                })
                this.xsxaFileList.forEach(e => {
                    lessonDeleteFile({ originalFileUrl: e.response.data });
                })
                this.xzxaFileList.forEach(e => {
                    lessonDeleteFile({ originalFileUrl: e.response.data });
                })
                this.sjFileList.forEach(e => {
                    lessonDeleteFile({ originalFileUrl: e.response.data });
                })
            } catch (e) {
                console.log('e', e)
            }
        }
    },
    computed: {
    },
    methods: {
        delBookId(id) {
            this.form.bookIdsList.splice(this.form.bookIdsList.indexOf(id), 1)
        },
        setChooseBook(id) {
            if (this.chooseBookIds.indexOf(id) === -1) {
                this.chooseBookIds.push(id)
            } else {
                this.chooseBookIds.splice(this.chooseBookIds.indexOf(id), 1)
            }
        },
        submitChooseBookForm() {
            this.form.bookIdsList = this.form.bookIdsList.concat(this.chooseBookIds)
            this.chooseBook = false;
        },
        openBook() {
            this.chooseBookIds = [];
            this.chooseBook = true;
        },
        validateSectionList(rule, value, callback) {
            var isTg = true;
            var istgList = [];
            for (var i = 0; i < this.answerCardForm.sectionList.length; i++) {
                var e = this.answerCardForm.sectionList[i]
                if (e.maxScore <= e.minScore) {
                    callback(new Error("同一级设置的最大分数大于最小分数"))
                    isTg = false;
                    break;
                }
                if (i != 0) {
                    if (e.minScore <= this.answerCardForm.sectionList[i - 1].maxScore) {
                        callback(new Error("设置的最小分数必须大于上一个最大分数"))
                        isTg = false;
                        break;
                    }
                }
            }
            if (!isTg) {

            } else {
                callback();
            }
        },
        validateSubmitTime(rule, value, callback) {
            if (this.answerCardForm.submitTime < this.answerCardForm.examTime) {
                callback(new Error("强制交卷时间不能小于考试时间"))
            } else {
                callback();
            }
        },
        changeSubject() {
            this.form.lessonName = '';
            this.teacherList.forEach(e => {
                if (e.teacherId == this.form.teacherId) {
                    this.sksubjectList.forEach(m => {
                        if (m.dictKey == this.form.subject) {
                            this.form.lessonName = e.teacherName + '-' + m.dictValue + '-' + '互动课堂'
                        }
                    })
                }
            })
        },
        changeLeadTeacher(item) {
            this.form.lessonName = '';
            this.form.subject = '';
            console.log(item);
            this.sksubjectList = [];
            this.teacherList.forEach(e => {
                if (e.teacherId == this.form.teacherId) {
                    console.log(e);
                    var subjectList = e.subject.split(',')
                    this.subjectList.forEach(m => {
                        subjectList.forEach(j => {
                            if (m.dictKey == j) {
                                this.sksubjectList.push(m)
                            }
                        })
                    })
                    if (this.sksubjectList.length != 0) {
                        this.form.subject = this.sksubjectList[0].dictKey
                        this.form.lessonName = e.teacherName + '-' + this.sksubjectList[0].dictValue + '-' + '互动课堂'
                    } else {
                        this.form.lessonName = e.teacherName + '-' + '互动课堂'
                    }
                }
            })
        },
        xz(item) {
            console.log(item);
            if (item.url) {
                download(item.url + '?_=' + Date.now(), item.name)
            } else {
                download(item.response.data + '?_=' + Date.now(), item.name)
            }

        },
        changeAnswer(e) {
            console.log(e);
        },
        changeMaxNum(index) {
            console.log(this.answerCardForm.sectionList[index]);
            this.answerCardForm.sectionList[index + 1].minScore = this.answerCardForm.sectionList[index].maxScore + 1;
        },
        changeCardType() {
            console.log(this.addCardForm.cardType);
            if (this.addCardForm.cardType == 3) {
                this.addCardForm.optionList = [
                    {
                        optionAnswer: '是',
                        optionContent: '',
                        optionNo: 1,
                        isAnswer: false,
                    }, {
                        optionAnswer: '否',
                        optionContent: '',
                        optionNo: 2,
                        isAnswer: false,
                    }, {
                        optionAnswer: '未知',
                        optionContent: '',
                        optionNo: 3,
                        isAnswer: false,
                    }
                ]
            } else {
                this.addCardForm.optionList = [
                    {
                        optionAnswer: 'A',
                        optionContent: '',
                        optionNo: 1,
                        isAnswer: false,
                    }, {
                        optionAnswer: 'B',
                        optionContent: '',
                        optionNo: 2,
                        isAnswer: false,
                    }, {
                        optionAnswer: 'C',
                        optionContent: '',
                        optionNo: 3,
                        isAnswer: false,
                    }, {
                        optionAnswer: 'D',
                        optionContent: '',
                        optionNo: 4,
                        isAnswer: false,
                    }, {
                        optionAnswer: 'E',
                        optionContent: '',
                        optionNo: 5,
                        isAnswer: false,
                    }
                ]
            }
        },
        handleSCChange(e, type) {
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    this.$message.success('上传成功')
                    this.addCardForm.imgList.push(e.response.data)
                } else {
                    if (e.response.msg) {
                        this.$message.error(e.response.msg)
                    } else {
                        this.$message.error('上传失败')
                    }
                }
            }
            if (e.status == 'fail') {
                this.$message.error('上传失败')
            }
        },
        editAnswerCard(item) {
            console.log(item);
            this.answerCardForm = item;
            this.answerCardForm.sectionList.sort(function (a, b) {
                if (a.starNum > b.starNum) { return 1; }
                if (a.starNum < b.starNum) { return -1; }
                return 0;
            })
            if (this.answerCardForm.isAverage) {
                this.answerCardForm.isAverageBol = true;
            } else {
                this.answerCardForm.isAverageBol = false;
            }
            this.answerCardForm.answerList.forEach(e => {
                e.answerContentList = e.answerContent.split(',')
            })
            this.addAnswer = true;
        },
        editCard(item) {
            console.log(item);
            this.addCardForm = item;
            var answerList = item.answer.split(',')
            this.addCardForm.optionList.forEach(e => {
                e.isAnswer = false;
                answerList.forEach(j => {
                    if (e.optionAnswer == j) {
                        e.isAnswer = true;
                    }
                })
            })
            this.addCard = true;

        },
        delcardId(cardId, index) {
            this.delAnswerList.push(cardId);
            this.form.answerCardList.splice(index, 1);
        },
        delbackCardId(cardId, index) {
            this.delCardList.push(cardId);
            this.form.backCardList.splice(index, 1);
        },
        delImage(index) {
            this.addCardForm.imgList.splice(index, 1);
        },
        openIsaddAnswer() {
            this.answerCardForm = {
                cardName: '',
                examTime: 0,
                submitTime: 0,
                questionNum: 0,
                totalScore: 0,
                isAverageBol: true,
                sectionList: [
                    { maxScore: 0, minScore: 0, starNum: 1 },
                    { maxScore: 0, minScore: 0, starNum: 2 },
                    { maxScore: 0, minScore: 0, starNum: 3 },
                    { maxScore: 0, minScore: 0, starNum: 4 },
                    { maxScore: 0, minScore: 0, starNum: 5 },
                ],
                answerList: [{
                    answerNo: 1,
                    answerContentList: [],
                    answerContent: '',
                    answerScore: ''
                }],
            };
            this.addAnswer = true;
        },
        openIsaddCard() {
            this.addCardForm = {
                cardType: 1,
                answer: '',
                cardDescription: '',
                cardName: '',
                imgList: [],
                optionList: [
                    {
                        optionAnswer: 'A',
                        optionContent: '',
                        optionNo: 1,
                        isAnswer: false,
                    }, {
                        optionAnswer: 'B',
                        optionContent: '',
                        optionNo: 2,
                        isAnswer: false,
                    }, {
                        optionAnswer: 'C',
                        optionContent: '',
                        optionNo: 3,
                        isAnswer: false,
                    }, {
                        optionAnswer: 'D',
                        optionContent: '',
                        optionNo: 4,
                        isAnswer: false,
                    }, {
                        optionAnswer: 'E',
                        optionContent: '',
                        optionNo: 5,
                        isAnswer: false,
                    }
                ]
            }
            this.addCard = true;
        },
        addAnswerSumbit() {
            console.log(this.answerCardForm);
            this.$refs["answerCardForm"].validate(valid => {
                if (valid) {
                    this.answerCardForm.answerList.forEach(e => {
                        e.answerContent = e.answerContentList.toString();
                    })
                    if (this.answerCardForm.isAverageBol) {
                        this.answerCardForm.isAverage = 1;
                    } else {
                        this.answerCardForm.isAverage = 0;
                    }
                    if (this.answerCardForm.cardId) {
                        answerCardUpdate(this.answerCardForm).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                this.addAnswer = false;
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    } else {
                        answerCardCreate(this.answerCardForm).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("创建成功");
                                this.form.answerCardList.push(res.data.data)
                                this.addAnswer = false;
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    }

                }
            });
        },
        addCardSumbit() {
            this.$refs["addCardForm"].validate(valid => {
                if (valid) {
                    if (this.addCardForm.cardType == 1) {
                        var zqNum = 0;
                        this.addCardForm.optionList.forEach(e => {
                            console.log(e);
                            if (e.isAnswer) {
                                zqNum++;
                            }
                        })
                        if (zqNum != 1) {
                            this.$message.error('单选正确答案必须只有一个')
                            return;
                        }
                    }

                    var answer = []
                    this.addCardForm.optionList.forEach(e => {
                        if (e.isAnswer == true) {
                            answer.push(e.optionAnswer)
                        }
                    })
                    this.addCardForm.answer = answer.toString()
                    if (this.addCardForm.cardId) {
                        backCardUpdate(this.addCardForm).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                this.addCard = false;
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    } else {
                        backCardCreate(this.addCardForm).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("创建成功");
                                this.form.backCardList.push(res.data.data)
                                this.addCard = false;
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    }

                }
            });
        },
        changeQuestionNum() {
            console.log(this.answerCardForm.questionNum);
            if (!this.answerCardForm.questionNum) {
                this.answerCardForm.questionNum = 1;
                this.$forceUpdate()
            }
            if (this.answerCardForm.questionNum >= this.answerCardForm.answerList.length) {
                for (var i = 0; i < this.answerCardForm.questionNum; i++) {
                    if ((i + 1) > this.answerCardForm.answerList.length) {
                        console.log(i);
                        this.answerCardForm.answerList.push({
                            answerNo: i + 1,
                            answerContentList: [],
                            answerContent: '',
                            answerScore: ''
                        })
                    }
                }
            } else {
                for (var i = this.answerCardForm.answerList.length; i > 0; i--) {
                    if ((i) > this.answerCardForm.questionNum) {
                        console.log(i);
                        this.answerCardForm.answerList.splice(i - 1, 1)
                    }
                }
            }
            this.changeIsNum()
        },
        changeIsNum() {
            if (this.answerCardForm.isAverageBol) {
                this.answerCardForm.answerList.forEach(e => {
                    e.answerScore = (this.answerCardForm.totalScore / this.answerCardForm.questionNum).toFixed(2)
                    console.log((this.answerCardForm.totalScore / this.answerCardForm.questionNum).toFixed(2));
                })
            }
            this.answerCardForm.sectionList[4].maxScore = this.answerCardForm.totalScore;
        },
        getUserTeachers() {
            userTeachers({ username: JSON.parse(this.$storage.get("userInfo")).username }).then(res => {
                this.teacherList = res.data.data;
                if (!this.$route.query.lessonId && this.teacherList.length != 0) {
                    this.form.teacherId = this.teacherList[0].teacherId
                    this.changeLeadTeacher()
                }
            })
        },
        getZhktQueryDict() {
            lessonListBook().then(res => {
                this.lessPreparatList = res.data.data;
            })
        },
        changeTitle(row) {
            console.log(row);
            this.form.title = row.className + '­—' + row.ptit + (row.title ? '­—' + row.title : '')
            this.isDirectory = false;
            this.isCourse = false;
        },
        objectSpanMethod(e) {
            // console.log(e);
            if (e.columnIndex === 0) {
                if (e.row.col != 0) {
                    return {
                        rowspan: e.row.col,
                        colspan: 1,
                    }
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0,
                    }
                }
            }
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            console.log(this.dzFileList);
            if (file.response.data) {
                this.delFileList.push(file.response.data)
            }
            this.dzFileList.forEach((e, i) => {
                if (e.response.data == file.response.data) {
                    this.dzFileList.splice(i, 1)
                }
            })
            this.ptFileList.forEach((e, i) => {
                if (e.response.data == file.response.data) {
                    this.ptFileList.splice(i, 1)
                }
            })
            this.PPTFileList.forEach((e, i) => {
                if (e.response.data == file.response.data) {
                    this.PPTFileList.splice(i, 1)
                }
            })
            this.xsxaFileList.forEach((e, i) => {
                if (e.response.data == file.response.data) {
                    this.xsxaFileList.splice(i, 1)
                }
            })
            this.xzxaFileList.forEach((e, i) => {
                if (e.response.data == file.response.data) {
                    this.xzxaFileList.splice(i, 1)
                }
            })
            this.sjFileList.forEach((e, i) => {
                if (e.response.data == file.response.data) {
                    this.sjFileList.splice(i, 1)
                }
            })
        },
        getlessonInfo() {
            zhktQueryDict({ code: 'subject' }).then(response => {
                this.subjectList = response.data.data;
                lessonInfo({ lessonId: this.lessonId }).then(res => {
                    this.form = res.data.data;
                    if (this.teacherList.findIndex((item) => item.teacherId == this.form.teacherId) == -1) {
                        this.teacherList.push({ teacherName: this.form.teacherName, teacherId: this.form.teacherId, schoolName: this.form.schoolName, subject: this.form.subject })
                    }
                    if (this.form.isOpen == 1) {
                        this.form.isOpen = true
                    } else {
                        this.form.isOpen = false
                    }
                    this.form.bookIdsList = [];
                    this.sksubjectList = []
                    var subjectList = this.form.subject.split(',')
                    this.subjectList.forEach(m => {
                        subjectList.forEach(j => {
                            if (m.dictKey == j) {
                                this.sksubjectList.push(m)
                            }
                        })
                    })
                    if (this.form.bookIds) {
                        this.form.bookIdsList = this.form.bookIds.split(',')
                    }
                    this.form.fileList.forEach(e => {
                        if (e.fileType == 1) {
                            e.typeList.forEach(j => {
                                if (j.fileBelowType == 1) {
                                    j.fileList.forEach(m => {
                                        this.dzFileList.push({
                                            name: m.fileName,
                                            fileId: m.fileId,
                                            url: m.originalFileUrl,
                                            response: {
                                                data: m.originalFileUrl
                                            }
                                        })
                                    })
                                }
                                if (j.fileBelowType == 2) {
                                    j.fileList.forEach(m => {
                                        this.ptFileList.push({
                                            name: m.fileName,
                                            fileId: m.fileId,
                                            url: m.originalFileUrl,
                                            response: {
                                                data: m.originalFileUrl
                                            }
                                        })
                                    })
                                }
                                if (j.fileBelowType == 3) {
                                    j.fileList.forEach(m => {
                                        this.PPTFileList.push({
                                            name: m.fileName,
                                            fileId: m.fileId,
                                            url: m.originalFileUrl,
                                            response: {
                                                data: m.originalFileUrl
                                            }
                                        })
                                    })
                                }
                            })
                        }
                        if (e.fileType == 2) {
                            e.typeList.forEach(j => {
                                if (j.fileBelowType == 4) {
                                    j.fileList.forEach(m => {
                                        this.xsxaFileList.push({
                                            name: m.fileName,
                                            fileId: m.fileId,
                                            url: m.originalFileUrl,
                                            response: {
                                                data: m.originalFileUrl
                                            }
                                        })
                                    })
                                }
                                if (j.fileBelowType == 5) {
                                    j.fileList.forEach(m => {
                                        this.xzxaFileList.push({
                                            name: m.fileName,
                                            fileId: m.fileId,
                                            url: m.originalFileUrl,
                                            response: {
                                                data: m.originalFileUrl
                                            }
                                        })
                                    })
                                }
                                if (j.fileBelowType == 6) {
                                    j.fileList.forEach(m => {
                                        this.sjFileList.push({
                                            name: m.fileName,
                                            fileId: m.fileId,
                                            url: m.originalFileUrl,
                                            response: {
                                                data: m.originalFileUrl
                                            }
                                        })
                                    })
                                }
                            })

                        }
                    })
                    // this.form.gajaFiles.forEach(e => {
                    //     this.jAFileList.push({
                    //         name: e.fileName,
                    //         fileId: e.fileId,
                    //         url: e.fileUrl,
                    //         response: {
                    //             data: e.fileUrl
                    //         }
                    //     })
                    // })

                })
            })
        },
        handleXTChange(file) {
            console.log(file)
        },
        handleJAChange(file) {
            console.log(file)
        },
        handleXAChange(file) {
            console.log(file);
        },
        handleKjChange(file) {
            console.log(file)

        },
        beforePdfUpload(rawFile) {
            console.log(rawFile)
            this.fileName = rawFile.name;
            if (!(rawFile.type == 'application/pdf')) {
                this.$message.error('文件必须是.pdf格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            this.fileName = rawFile.name;
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        beforePPTUpload(rawFile) {
            console.log(rawFile)
            this.fileName = rawFile.name;
            if (!(rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
                this.$message.error('文件必须是.ppt,.pptx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        startDateConfirm(e) {
            if (e > this.form.endTime) {
                this.form.endTime = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e < this.form.beginTime) {
                this.form.beginTime = '';
            }
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginTime === '' || !this.form.beginTime) {
                callback(new Error('请选择开始日期'))
            } else if (this.form.endTime === '' || !this.form.endTime) {
                callback(new Error('请选择结束日期'))
            } else {
                callback()
            }
        },
        checkteamuseList(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.$refs.teamuseList.checkList.length == 0) {
                callback(new Error('请选择协备人员'))
            } else {
                callback()
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date()) - 86400000
        },
        enddisabledDate(current) {
            if (this.form.beginTime) {
                return current && current < this.form.beginTime
            } else {
                return current && current < Number(new Date()) - 86400000
            }
        },
        changePeriod() {
            this.form.gradeKey = '';
            this.form.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.form.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.form.coverUrl = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        /** 提交按钮 */
        submitForm() {
            // this.form.teamuseList = [];
            // this.form.guideUsersList = [];
            // if (this.isMyLess != 1) {
            //     this.$refs.teamuseList.checkList.forEach(e => {
            //         this.form.teamuseList.push(e.userId)
            //     })
            //     this.$refs.guideUsersList.checkList.forEach(e => {
            //         this.form.guideUsersList.push(e.userId)
            //     })
            //     if (this.$refs.leadUser.checkList.length != 0) {
            //         this.form.leadUser = this.$refs.leadUser.checkList[0].userId;
            //     } else {
            //         this.form.leadUser = '';
            //     }
            // }


            // console.log(this.form)
            // console.log(this.jAFileList)
            // console.log(this.kJFileList)
            // console.log(this.xTFileList)
            console.log(this.dzFileList);
            var fileList = [];
            this.form.fileUrls = ''
            this.dzFileList.forEach((e) => {
                if (e.response.data) {
                    fileList.push(e.response.data);
                }
            })
            this.ptFileList.forEach((e) => {
                if (e.response.data) {
                    fileList.push(e.response.data);
                }
            })
            this.PPTFileList.forEach((e) => {
                if (e.response.data) {
                    fileList.push(e.response.data);
                }
            })
            this.xsxaFileList.forEach((e) => {
                if (e.response.data) {
                    fileList.push(e.response.data);
                }
            })
            this.xzxaFileList.forEach((e) => {
                if (e.response.data) {
                    fileList.push(e.response.data);
                }
            })
            this.sjFileList.forEach((e) => {
                if (e.response.data) {
                    fileList.push(e.response.data);
                }
            })

            // this.form.teamUsers = this.form.teamuseList.toString()
            // this.form.guideUsers = this.form.guideUsersList.toString()
            this.$refs["form"].validate(valid => {
                if (valid) {
                    if (this.form.isOpen == false) {
                        this.form.isOpen = 0
                    } else {
                        this.form.isOpen = 1
                    }
                    var answerCardIds = [];
                    this.form.answerCardList.forEach(e => {
                        answerCardIds.push(e.cardId)
                    })
                    var backCardIds = [];
                    this.form.backCardList.forEach(e => {
                        backCardIds.push(e.cardId)
                    })
                    this.form.backCardIds = backCardIds.toString();
                    this.form.answerCardIds = answerCardIds.toString();
                    console.log(fileList)
                    //         var obj = JSON.parse(JSON.stringify(this.form));
                    this.form.fileUrls = fileList.toString()

                    this.form.bookIds = this.form.bookIdsList.toString();

                    if (this.lessonId) {
                        lessonUpdate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.dzFileList = []
                                this.ptFileList = []
                                this.PPTFileList = []
                                this.xsxaFileList = []
                                this.xzxaFileList = []
                                this.sjFileList = []
                                this.delFileList.forEach(e => {
                                    lessonDeleteFile({ originalFileUrl: e });
                                })
                                this.delAnswerList.forEach(e => {
                                    answerCardDelete({ cardId: e })
                                })
                                this.delCardList.forEach(e => {
                                    backCardDelete({ cardId: e })
                                })
                                this.$message.success("编辑成功");
                                this.replace('/lessPreparatManage', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    } else {
                        lessonCreate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.dzFileList = []
                                this.ptFileList = []
                                this.PPTFileList = []
                                this.xsxaFileList = []
                                this.xzxaFileList = []
                                this.sjFileList = []
                                this.delFileList.forEach(e => {
                                    lessonDeleteFile({ originalFileUrl: e });
                                })
                                this.delAnswerList.forEach(e => {
                                    answerCardDelete({ cardId: e })
                                })
                                this.delCardList.forEach(e => {
                                    backCardDelete({ cardId: e })
                                })
                                this.$message.success("新增成功");
                                this.replace('/lessPreparatManage', { isSx: 1 })

                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }
                        });
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            this.replace('/lessPreparatManage')
        },
    }
};
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 270px;
    height: 152px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    width: 270px;
    height: 152px;
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 270px;
    height: 152px;
    text-align: center;
}


/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    /* height: calc(100vh - 500px);
    overflow-y: auto; */
}

.custom /deep/ .el-radio {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-radio-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-radio__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* padding: 0 15px; */
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.editeicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 40px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .editeicon {
    display: block;
}

.csbbb:hover .eicon {
    display: block;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #21ac74;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar-uploadertxx /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploadertxx .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.lesscustom /deep/ .el-form-item {
    justify-content: space-between;
}

.lesscustom /deep/ .el-form-item__content {
    flex: none;
}

.lesscustom /deep/ .el-form-item__label {
    font-weight: bold;
    color: #21ac74;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}
</style>