<template>
    <div class="app-container">
        <TEditor ref="editor" v-model="content" />
        {{ content }}
    </div>
</template>

<script>
import TEditor from '@/components/TEditor.vue';

export default {
    name: "About",
    components: {
        TEditor,
    },
    data() {
        return {
            content: '',
        };
    },
    created() {
    },
    methods: {
    }
};
</script>