<template>
    <div class="app-container" @click="visible = false;">
        <el-card class="mb15">
            <el-row>
                <el-col :span="20" class="mb0">
                    <el-button type="primary" @click="handleAdd">新增更新内容</el-button>
                </el-col>
                <el-col :span="4" class="mr0 mb0">
                    <!-- <div class="pull-right">
                        <el-popover :visible="visible" popper-style="width: 320px !important;" placement="bottom"
                            :min-width="'auto'">
                            <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true"
                                label-width="68px" @submit.native.prevent="getList">
                                <el-row class="mt15">
                                    <el-col :span="24" class="mr0">
                                        <el-form-item label="客户状态" class="w-100">
                                            <el-select clearable filterable class="w-100" v-model="queryParams.status"
                                                placeholder="请选择">
                                                <el-option :key="0" :label="'正式客户'" :value="0" />
                                                <el-option :key="1" :label="'演示客户'" :value="1" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="mr0">
                                        <el-form-item label="客户类型" class="w-100">
                                            <el-select clearable class="w-100" v-model="queryParams.customType"
                                                placeholder="请选择">
                                                <el-option :key="1" :label="'表单客户'" :value="1" />
                                                <el-option :key="2" :label="'群笔记客户'" :value="2" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="mr0">
                                        <el-form-item label="停用状态" class="w-100">
                                            <el-select clearable class="w-100" v-model="queryParams.isStop"
                                                placeholder="请选择">
                                                <el-option :key="0" :label="'正常'" :value="0" />
                                                <el-option :key="1" :label="'停用'" :value="1" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="mr0">
                                        <el-form-item label="客户名称" class="w-100">
                                            <el-input clearable class="w-100" v-model="queryParams.customName" placeholder="请输入客户名称">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <div style="margin:0 auto;">
                                        <el-button @click="getList" type="primary">查找</el-button>
                                    </div>
                                </el-row>
                            </el-form>
                            <template #reference>
                                <el-button type="primary" @click.stop="visible = true;">筛选条件</el-button>
                            </template>
                        </el-popover>
                    </div> -->
                </el-col>
            </el-row>
        </el-card>
        <el-card>
            <div class="bre9e" style="position: relative;">
                <img @click="isSxShow = true;" v-if="!isSxShow" style="transform: translateY(-36px);" class="sxShowcss"
                    src="../../assets/img/arrow-down.png" alt="">
                <el-table header-cell-class-name="bgf2" highlight-current-row class="sssstt"
                    :height="'calc(100vh - ' + (isSxShow ? '270' : '270') + 'px)'" v-loading="loading" :data="dataList">
                    <el-table-column align="center" width="" label="更新内容" prop="editContent">
                        <template #default="scope">
                            <div :title="scope.row.editContent" class="text-one">
                                {{ scope.row.editContent }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="150px" label="创建时间">
                        <template #default="scope">
                            <div>{{ scope.row.createTime }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" fixed="right" width="80" label="编辑"
                        class-name="small-padding fixed-width">
                        <template #default="scope">
                            <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                icon-color="#626AEF" title="确定删除该更新内容" @confirm="handleDelete(scope.row)">
                                <template #reference>
                                    <el-button link icon="Delete" type="danger">删除
                                    </el-button>
                                </template>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <!-- <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
                :limit.sync="queryParams.pageSize" @pagination="getList" /> -->
        </el-card>
        <el-dialog :title="adminForm.id ? '修改更新内容' : '新增更新内容'" v-model="isAddAdmin" width="60%">
            <el-form v-if="isAddAdmin" ref="adminForm" :model="adminForm" :rules="adminFormRules" label-width="80px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="更新内容" prop="editContent">
                            <el-input type="textarea" :rows="6" v-model="adminForm.editContent" placeholder="请输更新内容"
                                maxlength="800" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="adminSubmitForm">
                        确定
                    </el-button>
                    <el-button @click="isAddAdmin = false">
                        取消
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { bookList } from "@/api/adminservice/public"
const router = useRouter();
import {
    queryAllGroup,
    getData,
    delData,
    addData,
    updateData,
    exportData
} from "@/api/demo/data";
import { timestampToTime } from "@/utils/date";
import { editList, editCreate, editDelete } from "@/api/adminservice/custom";
let validatePhone = (rule, value, callback) => {
    if (!value) {
        return callback(new Error("手机号不能为空"))
    } else {
        const reg = /^1[3456789]\d{9}$/
        if (reg.test(value)) {
            callback();
        } else {
            return callback(new Error('请输入正确的手机号'));
        }
    }
}
export default {
    name: "editManage",
    components: {
    },
    data() {
        return {
            isAddAdmin: false,
            adminForm: {},
            adminFormRules: {
                editContent: [
                    { required: true, message: "请输入更新内容", trigger: "blur" },
                ]
            },
            visible: false,
            isSxShow: true,
            isSuperAuth: this.$storage.get('isSuperAuth'),
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            readBook: false,
            readBookList: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                status: '',
                customName: '',
                isStop: '',
                customType: ''
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            }
        };
    },
    created() {
        this.getList();
        this.getBookList();
    },
    activated() {
        // console.log('fdssfd',this.$route.query.isSx)
        if (this.$route.query.isSx == 1) {
            this.getList();
        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        adminSubmitForm() {
            this.$refs["adminForm"].validate(valid => {
                if (valid) {
                    editCreate(this.adminForm).then(res => {
                        if (res.data.code == 1) {
                            this.$message.success("操作成功");
                            this.isAddAdmin = false;
                            this.getList();
                        } else {
                            this.$message.error("操作失败");
                        }
                    })
                }
            }).catch(e => {
                console.log(e);
            })
        },
        readbookIds(row) {
            this.readBookList = [];
            this.bookList.forEach(e => {
                row.bookIds.forEach(j => {
                    if (e.id == j) {
                        this.readBookList.push(e)
                    }
                })
            })
            this.readBook = true;
        },
        getBookList() {
            bookList().then(res => {
                this.bookList = res.data.data;
            });
        },
        tgMethod(e) {
            this.queryParams.customName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        dateStringToTimestamp(str) {
            return new Date(str).getTime();
        },
        /** 查询基础：基数数据列表 */
        getList() {
            this.loading = false;
            editList().then(res => {
                res.data.data.forEach((e, i) => {
                    e.createTime = timestampToTime(this.dateStringToTimestamp(e.createTime))
                })
                this.dataList = res.data.data;
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            editDelete({ editId: row.editId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getList();
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.did);
            this.single = selection.length !== 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.adminForm = {
                editContent: '',
            };
            this.isAddAdmin = true;
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            console.log(row);
            var obj = JSON.parse(JSON.stringify(row))
            this.adminForm = obj;
            this.adminForm.roleType = Number(obj.roleType);
            this.isAddAdmin = true;
        }
    }
};
</script>
<style scoped>
.sssstt /deep/ .el-scrollbar__view {
    display: block !important;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}
</style>