import request from "@/utils/request";

// 查询所有表单记录
export const queryAllRecords =(data) =>{
    return request({
        url: "/adminservice/formRecord/queryAllRecords",
        method:"post",
        data: data
    })
}

// 查询表单关联记录及已保存识别结果
export const queryFormResult =(query) =>{
    return request({
        url: "/adminservice/formRecord/queryFormResult",
        method:"get",
        params: query
    })
}

// 查询笔迹
export const strokeQuery =(data) =>{
    return request({
        url: "/adminservice/mobile/stroke/query",
        method:"post",
        data:data
    })
}

// 查询识别项表格信息
export const tableInfo =(data) =>{
    return request({
        url: "/adminservice/mobile/identify/tableInfo",
        method:"post",
        data:data
    })
}

// 保存表单记录识别结果
export const saveResult =(data) =>{
    return request({
        url: "/adminservice/mobile/identify/saveResult",
        method:"post",
        data:data
    })
}

// 查询表单记录已保存识别结果
export const identifyResult =(query) =>{
    return request({
        url: "/adminservice/identify/result",
        method:"get",
        params: query
    })
}

// 查询上次识别信息
export const queryLastIdentify =(query) =>{
    return request({
        url: "/adminservice/mobile/identify/queryLastIdentify",
        method:"get",
        params: query
    })
}
// 查询上次识别信息(无图片信息)
export const queryLastIdentifyNoImg =(query) =>{
    return request({
        url: "/adminservice/formRecord/identify/queryLastIdentifyNoImg",
        method:"get",
        params: query
    })
}
// 查询识别项图片信息
export const identifyQueryImg =(query) =>{
    return request({
        url: "/adminservice/formRecord/identify/queryImg",
        method:"get",
        params: query
    })
}
// 保存选择或输入值
export const formRecordResult =(data) =>{
    return request({
        url: "/adminservice/formRecord/saveSelectResult",
        method:"post",
        data:data
    })
}
// 查询表单记录识别结果
export const queryResult =(query) =>{
    return request({
        url: "/adminservice/mobile/identify/queryResult",
        method:"get",
        params: query
    })
}
// 单页识别
export const identifySinglePage =(data) =>{
    return request({
        url: "/adminservice/mobile/identify/singlePage",
        method:"post",
        data:data
    })
}

// 取消确认表单记录
export const recordCancelConfirm =(query) =>{
    return request({
        url: "/adminservice/formRecord/cancelConfirm",
        method:"get",
        params: query
    })
}

// 取消删除表单记录
export const recordCancelDelete =(query) =>{
    return request({
        url: "/adminservice/formRecord/cancelDelete",
        method:"get",
        params: query
    })
}

// 批量确认表单记录
export const recordConfirm =(query) =>{
    return request({
        url: "/adminservice/formRecord/confirm",
        method:"get",
        params: query
    })
}
// 删除表单记录
export const recordDelete =(query) =>{
    return request({
        url: "/adminservice/formRecord/delete",
        method:"get",
        params: query
    })
}
// 批量删除表单记录
export const recordBatchDelete =(query) =>{
    return request({
        url: "/adminservice/formRecord/batchDelete",
        method:"get",
        params: query
    })
}
// 表单记录详情
export const formRecordInfo =(query) =>{
    return request({
        url: "/adminservice/formRecord/info",
        method:"get",
        params: query
    })
}