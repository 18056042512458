import request from "@/utils/request";
// 查询客户及其资源
export const queryCustoms =(query) =>{
    return request({
        url: "/adminservice/custom/queryCustoms",
        method:"get",
        params: query
    })
}
// 配置客户组织资源库
export const configCustom =(data) =>{
    return request({
        url: "/adminservice/custom/config",
        method:"post",
        data: data
    })
}
// 新增客户
export const createCustoms =(data) =>{
    return request({
        url: "/adminservice/custom/create",
        method:"post",
        data: data
    })
}
// 修改客户信息
export const updateCustoms =(data) =>{
    return request({
        url: "/adminservice/custom/update",
        method:"post",
        data: data
    })
}
// 删除客户
export const delCustoms =(query) =>{
    return request({
        url: "/adminservice/custom/delete",
        method:"get",
        params: query
    })
}
// 停用客户
export const stopCustoms =(query) =>{
    return request({
        url: "/adminservice/custom/stop",
        method:"get",
        params: query
    })
}

// 查询客户信息
export const custominfo =(query) =>{
    return request({
        url: "/adminservice/custom/info",
        method:"get",
        params: query
    })
}

// 创建下级组织
export const customOrgCreate =(data) =>{
    return request({
        url: "/adminservice/customOrg/create",
        method:"post",
        data: data
    })
}

// 创建客户组织
export const customOrgCreateOrg =(data) =>{
    return request({
        url: "/adminservice/customOrg/createOrg",
        method:"post",
        data: data
    })
}


// 查询组织及其下级组织（树形）
export const customOrgQueryTreeOrg =(query) =>{
    return request({
        url: "/adminservice/customOrg/queryTreeOrg",
        method:"get",
        params: query
    })
}

// 查询客户组织及其下级组织（树形）
export const queryTreeCustomOrg =(query) =>{
    return request({
        url: "/adminservice/customOrg/queryTreeCustomOrg",
        method:"get",
        params: query
    })
}

// 修改下级组织
export const customOrgUpdate =(data) =>{
    return request({
        url: "/adminservice/customOrg/update",
        method:"post",
        data: data
    })
}

// 删除组织
export const customOrgDel =(query) =>{
    return request({
        url: "/adminservice/customOrg/delete",
        method:"get",
        params: query
    })
}

// 查询组织可配置云本
export const customOrgBook =(query) =>{
    return request({
        url: "/adminservice/customOrg/queryOrgBook",
        method:"get",
        params: query
    })
}

// 转移客户下级组织
export const moveOrg =(data) =>{
    return request({
        url: "/adminservice/customOrg/moveOrg",
        method:"post",
        data: data
    })
}

// 上传新闻资讯文件
export const customOrgUpload =(data) =>{
    return request({
        url: "/adminservice/customOrg/uploadFile",
        method:"post",
        ContentType:'multipart/form-data',
        data: data
    })
}

// 新增或修改管理员
export const createOrUpdate = (data) =>{
    return request({
        url: "/adminservice/adminuser/createOrUpdate",
        method:"post",
        data: data
    })
}

// 删除管理员
export const adminuserDelete =(query) =>{
    return request({
        url: "/adminservice/adminuser/delete",
        method:"get",
        params: query
    })
}

// 查询管理员列表
export const adminuserQuery =(query) =>{
    return request({
        url: "/adminservice/adminuser/queryAdmin",
        method:"get",
        params: query
    })
}

// 查询业务模块列表
export const moduleList =(query) =>{
    return request({
        url: "/adminservice/module/list",
        method:"get",
        params: query
    })
}

// 更新业务模块
export const moduleUpdate = (data) =>{
    return request({
        url: "/adminservice/module/update",
        method:"post",
        data: data
    })
}

// 添加更新内容
export const editCreate =(query) =>{
    return request({
        url: "/adminservice/edit/create",
        method:"get",
        params: query
    })
}

// 删除更新内容
export const editDelete =(query) =>{
    return request({
        url: "/adminservice/edit/delete",
        method:"get",
        params: query
    })
}

// 查询更新内容列表
export const editList =(query) =>{
    return request({
        url: "/adminservice/edit/list",
        method:"get",
        params: query
    })
}
// 查询更新内容
export const editQuery =(query) =>{
    return request({
        url: "/adminservice/edit/query",
        method:"get",
        params: query
    })
}
// 修改超级验证码
export const editSuperCode =(query) =>{
    return request({
        url: "/adminservice/user/editSuperCode",
        method:"get",
        params: query
    })
}
// 查询超级验证码
export const querySuperCode =(query) =>{
    return request({
        url: "/adminservice/user/querySuperCode",
        method:"get",
        params: query
    })
}