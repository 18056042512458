<template>
    <div class="app-container" @click="visible = false;">
        <el-card>
            <el-row>
                <el-col :span="24" class="mr0 mb0">
                    <div class="pull-right">
                        <el-popover :visible="visible" popper-style="width: 320px !important;" placement="bottom"
                            :min-width="'auto'">
                            <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true"
                                label-width="68px">
                                <el-row class="">
                                    <el-col :span="24" class="mr0">
                                        <el-form-item label="学校" prop="schoolId" class="w-100">
                                            <el-select style="width: 100%;" clearable @change="changeSchool"
                                                v-model="queryParams.schoolId" placeholder="请选择学校">
                                                <el-option v-for="item in schoolList" :key="item.schoolId"
                                                    :label="item.schoolName" :value="item.schoolId" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="">
                                        <el-form-item label="教辅名称" prop="termId" class="w-100">
                                            <el-select clearable class="w-100" @change="getChapterList()" v-model="queryParams.fileId"
                                                placeholder="请选择教辅">
                                                <el-option :label="item.fileName" :value="item.fileId"
                                                    v-for="item in eduFileList" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="">
                                        <el-form-item label="章节名称" prop="chapterId" class="w-100">
                                            <el-select style="width: 100%;" clearable v-model="queryParams.chapterId"
                                                placeholder="请选择章节">
                                                <el-option v-for="item in chapterList" :key="item.chapterId"
                                                    :label="item.chapterName" :value="item.chapterId" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <div style="margin:0 auto;">
                                        <el-button @click="getList()" type="primary">查找</el-button>
                                    </div>
                                </el-row>
                            </el-form>
                            <template #reference>
                                <el-button type="primary" @click.stop="visible = true;">筛选条件</el-button>
                            </template>
                        </el-popover>
                    </div>
                </el-col>
            </el-row>
            <!-- <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
                <el-row class="">
                    <el-col :span="6" class="mr0">
                        <el-form-item label="学校" prop="schoolId">
                            <el-select style="width: 100%;" clearable @change="changeSchool" v-model="queryParams.schoolId"
                                placeholder="请选择学校">
                                <el-option v-for="item in schoolList" :key="item.schoolId" :label="item.schoolName"
                                    :value="item.schoolId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="">
                        <el-form-item label="教辅名称" prop="termId">
                            <el-select clearable @change="getChapterList()" v-model="queryParams.fileId"
                                placeholder="请选择教辅">
                                <el-option :label="item.fileName" :value="item.fileId" v-for="item in eduFileList" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="">
                        <el-form-item label="章节名称" prop="chapterId">
                            <el-select style="width: 100%;" clearable v-model="queryParams.chapterId" placeholder="请选择章节">
                                <el-option v-for="item in chapterList" :key="item.chapterId" :label="item.chapterName"
                                    :value="item.chapterId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="">
                        <el-button style="width:100px" class="pull-right" type="primary" @click="getList()">查找</el-button>
                    </el-col>
                </el-row>
            </el-form>
            <el-divider style="position: relative;" v-if="isSxShow">
                <img @click="isSxShow = false;" style="transform: translateY(-100%);" class="sxShowcss"
                    src="../../assets/img/arrow-up.png" alt="">
            </el-divider> -->
            <div class="bre9e mt15" style="position: relative;">
                <!-- <img @click="isSxShow = true;" v-if="!isSxShow" style="transform: translateY(-36px);" class="sxShowcss"
                    src="../../assets/img/arrow-down.png" alt=""> -->

                <el-table header-cell-class-name="bgf2" highlight-current-row class="sssstt"
                    :height="'calc(100vh - ' + (isSxShow ? '213' : '213') + 'px)'" v-loading="loading" :data="dataList">
                    <el-table-column fixed="left" align="center" type="index" width="60" prop="studentNo" label="序号">
                    </el-table-column>
                    <el-table-column fixed="left" width="400" align="center" label="题目" prop="name">
                        <template #default="scope">
                            <div @click="getQuingo(scope.row)" :title="scope.row.name" style="color: #409efe;"
                                class="text-one cursor">
                                {{ scope.row.name }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="left" width="80" align="center" label="答错人数" prop="errorCount">
                        <template #default="scope">
                            <div @click="openCwrs(scope.row)" :title="scope.row.errorCount" style="color: #409efe;"
                                class="text-one cursor">
                                {{ scope.row.errorCount }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="left" width="80" align="center" label="答错率" prop="errorRate">
                        <template #default="scope">
                            <div class="text-one cursor">
                                {{ scope.row.errorRate }}%
                            </div>
                        </template>
                    </el-table-column>
                    <template v-for="item in classDataList">
                        <el-table-column align="center" width="170" :label="item.className + '错误人数'" prop="name">
                            <template #default="scope">
                                <span v-if="scope.row[item.classId]" @click="openClassCwrs(scope.row[item.classId])"
                                    class="cursor" style="color: #409efe;">
                                    {{ scope.row[item.classId].errorCount }}
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" width="170" :label="item.className + '错误率'" prop="name">
                            <template #default="scope">
                                <span v-if="scope.row[item.classId]">
                                    {{ scope.row[item.classId].errorRate }}%
                                </span>
                            </template>
                        </el-table-column>
                    </template>
                </el-table>
            </div>
        </el-card>
        <el-dialog :title="'答错人数'" v-model="scShow" width="600px">
            <img class="mb10" :src="item.image" style="width: 560px;height:auto" v-for="item in cwrsObj.questionList">
            <div class="bre9e">
                <el-table header-cell-class-name="bgf2" highlight-current-row class="sssstt" :height="`calc(100vh - 400px)`"
                    v-loading="loading" :data="cwrsObj">
                    <el-table-column align="center" type="index" width="60" prop="studentNo" label="序号">
                    </el-table-column>
                    <el-table-column align="center" label="姓名" prop="studentName">
                        <template #default="scope">
                            {{ scope.row.studentName }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="错题数" prop="errorCount">
                        <template #default="scope">
                            {{ scope.row.errorCount }}
                        </template>
                    </el-table-column>

                </el-table>
            </div>
        </el-dialog>
        <el-image-viewer hide-on-click-modal v-if="isOpen" :url-list="activeimgs" @close="isOpen = false" />

    </div>
</template>

<script>
import { timestampToTime } from "@/utils/date";
import { download } from "@/api/adminservice/public";
import { readClassReadStat, readClassStuReport, readClassErrorStat, fileAreaInfos, fileAreaQuestion } from "@/api/adminservice/readOver";
import { schoolUserAuthCondition, schoolListAuth, classList, zhktQueryDict, schoolList, schoolDelete, teacherList, teacherDelete } from "@/api/adminservice/classOrganizaMain";
import { eduFileList, chapterList } from "@/api/adminservice/readOver"
import {
    Document, ImageRun, Packer, Paragraph, HeadingLevel, TextRun, SymbolRun, AlignmentType, WidthType, BorderStyle, Table, TableRow, TableCell, convertInchesToTwip, VerticalAlign, TableLayoutType
} from 'docx';
import JSZip from "jszip";
import { commSetting } from "@/utils/date"
const createHeading = (text1, text2) => {
    return new Paragraph({
        alignment: AlignmentType.CENTER,
        heading: HeadingLevel.HEADING_1,
        children: [
            new TextRun({
                text: text1,
            }),
        ],
    });
};
const createZw = (text1) => {
    return new Paragraph({
        alignment: AlignmentType.LEFT,
        heading: HeadingLevel.HEADING_4,
        children: [
            new TextRun({
                text: text1,
            }),
        ],
    });
};
// 生成tablecell图片
const createImg = (photo, width, height, type = 0) => {
    return new Paragraph({
        children: [
            new ImageRun({
                data: Buffer.from(photo, 'base64'),
                transformation: {
                    width,
                    height,
                },
            }),
        ],
    });
}

export default {
    name: "classIncorrectAnswers",
    components: {
    },
    data() {
        return {
            visible: false,
            activeimgs: [],
            isOpen: false,
            isSxShow: true,
            showTit: '',
            schoolList: [],
            eduFileList: [],
            chapterList: [],
            cwrsObj: [],
            token: this.$storage.get("token"),
            // 遮罩层
            loading: false,
            // 非单个禁用
            single: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                schoolId: '',
                classId: '',
                fileId: '',
                chapterId: '',
                sortType: 0
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            schoolId: '',
            scShow: false,
            classList: [],
            classDataList: [],
            role: '',
        };
    },
    created() {
        this.role = this.$storage.get("role")
        this.getZhktQueryDict();
        // if (this.role.includes(0) || this.role.includes(1)) {
        //     this.getList();
        // }
    },
    activated() {
        // console.log('fdssfd',this.$route.query.isSx)
        if (this.$route.query.isSx == 1) {
            this.getList();
        }
    },
    mounted() {
    },
    methods: {
        getQuingo(row) {
            this.activeimgs = []
            if (row.questionList) {
                row.questionList.forEach(e => {
                    this.activeimgs.push(e.image)
                })
                this.isOpen = true;
            } else {
                fileAreaQuestion({ areaId: row.areaId }).then(respon => {
                    row.questionList = respon.data.data;
                    row.questionList.forEach(e => {
                        this.activeimgs.push(e.image)
                    })
                    this.isOpen = true;
                })
            }
        },
        generateStuSet(row) {
            console.log(row);
            var wtxt = row.errorQuestionList[0].chapterName + '-' + row.className + '错题本';
            var children = []
            children.push(createHeading(wtxt))
            row.errorQuestionList.forEach(e => {
                children.push(createZw(e.name))
                e.questionList.forEach(j => {
                    // 600
                    if (j.image) {
                        var xj = j.image.split(',')
                        // console.log(xj[1]);
                        children.push(createImg(xj[1], j.width * (600 / e.fileWidth), j.height * (600 / e.fileWidth), 1))
                    }
                })
            })
            var documents = new Document({
                ...commSetting,
                sections: [
                    {
                        children: children,
                    },
                ],
            });

            Packer.toBlob(documents).then((blob) => {
                const x = new XMLHttpRequest()
                x.open('GET', URL.createObjectURL(blob), true)
                x.responseType = 'blob'
                x.onload = function () {
                    const url = window.URL.createObjectURL(x.response)
                    const a = document.createElement('a')
                    a.href = url
                    a.download = wtxt + '.docx'
                    a.click()
                }
                x.send()
            });
        },
        openCwrs(row) {
            this.cwrsObj = []
            console.log(row);
            this.showTit = '答错数'
            row.classList.forEach(e => {
                e.errorStuList.forEach(j => {
                    this.cwrsObj.push(j)
                })
            })
            this.scShow = true;
        },
        openClassCwrs(row) {
            this.cwrsObj = []
            console.log(row);
            this.showTit = '答错数'

            this.cwrsObj = row.errorStuList

            this.scShow = true;
        },
        openQds(row) {
            console.log(row);
            this.showTit = '全对数'
            this.cwrsObj = row.rightStuList;
            this.scShow = true;
        },
        getChapterList() {
            this.dataList = [];
            this.queryParams.chapterId = '';
            this.chapterList = [];
            if (this.queryParams.fileId) {
                chapterList({ fileId: this.queryParams.fileId }).then(res => {
                    this.chapterList = res.data.data;
                })
            }

        },
        changeSchool() {
            this.dataList = [];
            this.classList = [];
            this.eduFileList = [];
            this.chapterList = [];
            this.queryParams.classId = '';
            this.queryParams.fileId = '';
            this.queryParams.chapterId = '';

            if (this.queryParams.schoolId) {
                // classList({ customId: this.$storage.get('customId'), schoolId: this.queryParams.schoolId }).then(res => {
                //     this.classList = res.data.data;
                //     // this.getList();
                // });
                eduFileList(this.queryParams).then(res => {
                    this.eduFileList = res.data.data;
                })
            }
        },
        changeClass() {
            this.dataList = [];
            this.eduFileList = [];
            this.chapterList = [];
            this.queryParams.fileId = '';
            this.queryParams.chapterId = '';
            console.log(this.classList);
            this.eduFileList = [];
            if (this.queryParams.classId) {
                this.classList.forEach(e => {
                    if (e.classId == this.queryParams.classId) {
                        if (e.schoolId) {
                            this.queryParams.schoolId = e.schoolId;
                        }
                        eduFileList(this.queryParams).then(res => {
                            this.eduFileList = res.data.data;
                        })
                    }
                })

            }
        },
        uniqueBy(arr, key) {
            const seen = new Set();
            return arr.filter((item) => {
                const seenValue = seen.has(item[key]);
                seen.add(item[key]);
                return !seenValue;
            });
        },
        getZhktQueryDict() {
            schoolListAuth({ customId: this.$storage.get('customId'), adminUserId: ((this.role.includes(3) || this.role.includes(2)) && !this.role.includes(1)) ? JSON.parse(this.$storage.get('userInfo')).userId : '' }).then(resa => {
                this.schoolList = resa.data.data;
                // this.getList();
                schoolUserAuthCondition().then(res => {
                    if (!this.role.includes(0) && !this.role.includes(1) && !this.role.includes(2) && !this.role.includes(3)) {
                        this.schoolList = [];
                        res.data.data.classList.forEach(e => {
                            console.log(e);
                            this.schoolList.push(e)
                        })
                        this.schoolList = this.uniqueBy(this.schoolList, 'schoolId')
                    }
                    if (this.schoolList && this.schoolList.length != 0) {
                        this.queryParams.schoolId = this.schoolList[0].schoolId
                        this.changeSchool()
                    }
                })
            });
            // classList({ customId: this.$storage.get('customId'), schoolId: this.queryParams.schoolId }).then(res => {
            //     this.classList = res.data.data;
            // });
        },
        /** 查询基础：基数数据列表 */
        getList() {
            // this.queryParams = {
            //     chapterId: "1784422498310426624",
            //     classId: "",
            //     fileId: "1784407361583190016",
            //     schoolId: "1747519773521948673",
            //     sortType: 0
            // }
            this.dataList = [];
            if (!this.queryParams.schoolId) {
                this.$message.warning("请选择学校")
                return;
            }
            if (!this.queryParams.fileId) {
                this.$message.warning("请选择教辅名称")
                return;
            }
            if (!this.queryParams.chapterId) {
                this.$message.warning("请选择章节名称")
                return;
            }

            if (!this.role.includes(0)) {
                this.queryParams.customId = this.$storage.get('customId');
            }
            this.loading = true;
            this.classDataList = [];
            readClassErrorStat(this.queryParams).then(res => {
                this.dataList = res.data.data;
                // this.dataList.forEach(e => {
                //     fileAreaQuestion({ areaId: e.areaId }).then(respon => {
                //         e.questionList = respon.data.data;
                //     })
                // })
                res.data.data.forEach(e => {
                    e.classList.forEach(j => {
                        e[j.classId] = j;
                        this.classDataList.push({
                            classId: j.classId,
                            className: j.className
                        })
                    })
                })
                this.classDataList = this.uniqueBy(this.classDataList, 'classId')
                this.loading = false;
                console.log(this.dataList);
            })
        },

    }
};
</script>
<style scoped>
.disnone /deep/ .el-upload-list {
    display: none;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.sssstt /deep/ .el-scrollbar__view {
    display: block !important;
}</style>