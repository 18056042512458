<template>
    <div class="" style="grid-template-columns:1fr auto 1fr;grid-template-rows:1fr auto 1fr;overflow: auto;">
        <div style="margin: auto;">
            <div class="grid" :style="`justify-content: center;grid-template-columns:${
                getSpace(table.region.columDefinitions,table.region.rectangle.Width)
                .aggregate(String(),this.mergeString)};grid-template-rows:${
                getSpace(table.region.rowDefinitions,table.region.rectangle.Height)
                .aggregate(String(),this.mergeString)}`">
                <div class="fill" v-for="(rect,index) in scList" :key="index">
                    <div class="fill" style="border: 1px solid green;">
                        <el-popover @show="changeShow(index)" width="200" :placement="placement" :trigger="trigger">
                            <template #reference>
                                <div class="fill" style="display: flex;align-items: center;">
                                    <div class="text-two" style="width: 100%;text-align: center;" :title="itemValueList[index]">{{ itemValueList[index] }}</div>
                                </div>
                            </template>
                            <template #default>
                                <el-descriptions direction="vertical" :column="1" border>
                                    <el-descriptions-item label="选项名称">
                                        <el-input :id="'inputId'+index" maxlength="16" @input="changeInput" v-model="itemValueList[index]"></el-input>
                                    </el-descriptions-item>
                                </el-descriptions>
                            </template>
                        </el-popover>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Rect} from "../../utils/drawing/rect";

export default {
    props: {
        scaled: {
            type: Number,
            default: 1,
        },
        table: null,
        options: {
            default: []
        },
        placeholder: {
            default: '空',
            type: String,
        },
        itemValueList:{
            default: []
        }
    },
    data() {
        if (this.table.modes.configs == null) {
            this.table.modes.configs = {};
        }
        this.table.region.columDefinitions.orderBy(x => x);
        this.table.region.rowDefinitions.orderBy(x => x);
        let scale = this.scaled * 3;
        return {
            trigger: 'click',
            placement: 'top',
            scale,
            scList:[]
        }
    },
    mounted(){
        console.log(this.table)
        this.scList = this.table.region.getCells();
    },
    watch: {
        table: {
            deep: true,
            handler(n, o) {
                this.table.modes;
                if (n.modes.direction !== o.modes.direction) {
                    //TODO:变了啊
                    this.table.modes.configs = {};
                }
            }
        }
    },
    methods: {
        changeShow(index){
            console.log(343)
            document.getElementById('inputId'+index).focus()
        },
        changeInput(){
            this.$forceUpdate()
        },
        getSpace(array, edge) {
            let arr = [];
            for (let i = 0; i < array.length; i++) {
                let curr = array[i];
                arr.push((curr - (i === 0 ? 0 : array[i - 1])) * this.scale);
            }
            arr.push((edge - (arr.any() ? array.last() : 0)) * this.scale);
            return arr;
        },
        mergeString: (str, val) => {
            str += `${val}px `;
            return str;
        },
        mergeLength: (sum, val) => {
            return sum += (val + 4);
        },
        getAllCells(region) {
            return region.getCells();
        },
        setMode(index, mode) {
            this.table.modes.configs[index] = mode;
        },
        removeMode(index) {
            delete this.table.modes.configs[index];
        }
    }
}
</script>


<style scoped>
.fill {
    margin: 0;
    height: 100%;
    width: 100%;
}

.but {
    height: 100%;
    width: 100%;
    font-size: 20px;
}

.grid {
    display: grid;
    padding: 5px;
    grid-gap: 5px 5px;
}

.fill :hover {
    background-color: rgba(56, 196, 142, 0.78);
    transition-delay: 0.1s;
    transition-duration: 0.25s;
}
</style>