<template>
    <div class="app-container pt0Im pl0Im">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>教辅维护</a></el-breadcrumb-item>
                <el-breadcrumb-item v-if="!fileId">新增教辅</el-breadcrumb-item>
                <el-breadcrumb-item v-if="fileId">编辑教辅</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 180px);overflow: auto;">
            <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                <el-row v-if="!fileId">
                    <el-col :span="12">
                        <el-form-item label="添加文件" prop="file">
                            <el-upload :auto-upload="false" ref="zdyupload" v-model:file-list="form.file"
                                class="upload-demo" drag @change="handleChange" action="" accept=".pdf">
                                <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                                <div class="el-upload__text">
                                    拖到文件到此处或 <em>点击上传文件</em>
                                </div>
                                <template #tip>
                                    <div class="el-upload__tip">
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="文件名称" prop="fileName">
                            <el-input v-model="form.fileName" placeholder="最多64个字" maxlength="64" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="归属客户" prop="customId">
                            <el-select :disabled="role.includes(1)" style="width: 100%;" clearable @change="changeCustomId" v-model="form.customId"
                                placeholder="请选择客户">
                                <el-option v-for="item in customList" :key="item.customId"
                                    :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                    :value="item.customId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="学科" prop="subject">
                            <el-select v-model="form.subject" placeholder="请选择学科" style="width: 100%;">
                                <el-option v-for="item in subjectList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="学校" prop="schoolId">
                            <el-select style="width: 100%;" clearable @change="changeSchoolId" v-model="form.schoolId"
                                placeholder="请选择学校">
                                <el-option v-for="item in schoolList" :key="item.schoolId" :label="item.schoolName"
                                    :value="item.schoolId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="年级" prop="grade">
                            <el-select style="width: 100%;" @change="changeGrade" clearable v-model="form.grade"
                                placeholder="请选择年级">
                                <el-option v-for="item in gradeList" :key="item.name" :label="item.name"
                                    :value="item.name" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="老师" prop="teacherIdsList">
                            <el-select multiple style="width: 100%;" @change="changeTeacher" clearable
                                v-model="form.teacherIdsList" placeholder="请选择老师">
                                <el-option v-for="item in teacherList" :key="item.teacherId" :label="item.teacherName"
                                    :value="item.teacherId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-form>
        </el-card>

        <div>
            <div class="disflex-row-center cusbtbtn" :style="$store.state.zdy.isActive ? 'left:64px;' : 'left:201px;'">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submitForm">保 存</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { bookList } from "@/api/adminservice/public"
import { validatenull, isMobile } from '@/utils/validate'
import { queryCustoms, createCustoms, custominfo, updateCustoms, configCustom } from "@/api/adminservice/custom"
import { timestampToTime } from "@/utils/date";
import { qunQueryUser } from "@/api/adminservice/qun"
import { sceneCreate, sceneInfo, sceneUpdate, configMember } from "@/api/adminservice/scene"
import { teacherQueryOrRegUser } from "@/api/adminservice/classOrganizaMain"
import { eduFileInfo, eduFileUpdate, eduFileCreate, } from "@/api/adminservice/readOver"
import { zhktQueryDict, teacherListCondition, teacherList, schoolList } from "@/api/adminservice/classOrganizaMain"
import { ElLoading } from 'element-plus'

export default {
    name: "teachAssistantAdd",
    components: {},
    data() {
        return {
            fileId: '',
            readBook: false,
            formOpen: false,
            timestampToTime,
            memberId: '',
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 表单参数
            form: {
                file: [],
                fileName: '',
                customId: '',
                schoolId: '',
                grade: '',
                subject: '',
                teacherIdsList: [],
            },
            formList: [],
            filesList: [],
            token: this.$storage.get("token"),
            tableData: [
                {
                    memberAccount: JSON.parse(this.$storage.get('userInfo')).phone,
                    memberName: JSON.parse(this.$storage.get('userInfo')).nickName,
                    memberUserId: JSON.parse(this.$storage.get('userInfo')).userId,
                    memberAddress: ''
                }
            ],
            imgData: [],
            videoData: [],
            // 表单校验
            rules: {
                file: [
                    { required: true, message: "请选择文件", trigger: "change" },
                ],
                fileName: [
                    { required: true, message: "请输入文件名称", trigger: "blur" },
                ],
                customId: [
                    { required: true, message: "请选择客户", trigger: "change" },
                ],
                subject: [
                    { required: true, message: "请选择学科", trigger: "change" },
                ],
                schoolId: [
                    { required: true, message: "请选择学校", trigger: "change" },
                ],
                grade: [
                    { required: true, message: "请选择年级", trigger: "change" },
                ],
                teacherIdsList: [
                    { required: true, message: "请选择老师", trigger: "change" },
                ]
            },
            formRules: {
                adminAccount: [
                    {
                        required: true,
                        message: "请选输入",
                        trigger: "blur",
                    },
                ],
            },
            bookList: [],
            configObj: {
                bookIds: [],
                orgId: ''
            },
            customList: [],
            selectList: [],
            formId: '',
            peopleOpen: false,
            teacherList: [],
            schoolList: [],
            gradeList: [],
            loadingInstance: null,
            subjectList: [],
            role: '',
        };
    },
    created() {
        this.role = this.$storage.get("role")
    },
    mounted() {
        this.getList();
        this.getSubjectList();
        if (this.$route.query.fileId) {
            this.fileId = this.$route.query.fileId;
            eduFileInfo({ fileId: this.fileId }).then(res => {
                this.form = res.data.data;
                this.form.teacherIdsList = [];
                if (res.data.data.teacherList) {
                    res.data.data.teacherList.forEach(e => {
                        this.form.teacherIdsList.push(e.teacherId);
                    })
                }
                // this.form.teacherIdsList = this.form.teacherIds.split(',')
                this.getSchoolList();
                this.getTeacherList()
            })
        } else {
            if(this.$storage.get("role").includes(1)){
                this.form.customId = this.$storage.get("customId")
                this.changeCustomId()
            }
            this.getSchoolList();
        }

    },
    computed: {
    },
    methods: {
        handleChange(file, fileList) {
            console.log(fileList)
            console.log(fileList.length)

            this.form.file = []
            if (fileList[0]) {
                this.form.file.push(fileList[fileList.length - 1])
                this.form.fileName = fileList[fileList.length - 1].name.substring(0, fileList[fileList.length - 1].name.lastIndexOf("."));
            }
            // console.log(this.form.file)
        },
        changeGrade(grade) {
            console.log(this.teacherList);
            this.form.teacherIdsList = [];
            this.getTeacherList()
        },
        changeCustomId(customId) {
            this.form.schoolId = '';
            this.form.grade = '';
            this.form.teacherIdsList = [];
            this.gradeList = [];
            this.getSchoolList()
        },
        changeSchoolId(schoolId) {
            this.getTeacherList()
            this.form.grade = '';
            this.form.teacherIdsList = [];
            this.gradeList = [];
            this.schoolList.forEach(e => {
                if (e.schoolId == schoolId) {
                    console.log(e);
                    var gradeClassIsmList = e.gradeClassIsm.split(',')
                    if (gradeClassIsmList.indexOf('1') != -1) {
                        this.gradeList.push({ name: '一年级', num: 0, schoolType: 1 })
                        this.gradeList.push({ name: '二年级', num: 0, schoolType: 1 })
                        this.gradeList.push({ name: '三年级', num: 0, schoolType: 1 })
                        this.gradeList.push({ name: '四年级', num: 0, schoolType: 1 })
                        this.gradeList.push({ name: '五年级', num: 0, schoolType: 1 })
                        this.gradeList.push({ name: '六年级', num: 0, schoolType: 1 })
                    }
                    if (gradeClassIsmList.indexOf('2') != -1) {
                        this.gradeList.push({ name: '初一年级', num: 0, schoolType: 2 })
                        this.gradeList.push({ name: '初二年级', num: 0, schoolType: 2 })
                        this.gradeList.push({ name: '初三年级', num: 0, schoolType: 2 })
                    }
                    if (gradeClassIsmList.indexOf('3') != -1) {
                        this.gradeList.push({ name: '高一年级', num: 0, schoolType: 3 })
                        this.gradeList.push({ name: '高二年级', num: 0, schoolType: 3 })
                        this.gradeList.push({ name: '高三年级', num: 0, schoolType: 3 })
                    }
                }
            })
        },
        getSubjectList() {
            zhktQueryDict({ code: 'subject' }).then(res => {
                this.subjectList = res.data.data;
            })
        },
        getTeacherList() {
            var customId = ''
            if (this.form.customId) {
                customId = this.form.customId
            } else {
                customId = this.$storage.get('customId')
            }
            teacherListCondition({ customId: customId, schoolId: this.form.schoolId }).then(res => {
                this.teacherList = res.data.data;
                if (this.form.grade) {
                    this.teacherList.forEach(e => {
                        e.classList = e.classList.filter(itemA => {
                            if(itemA.grade){
                                return itemA.grade == this.form.grade
                            }else{
                                return true;
                            }
                            
                        })
                    })
                    this.teacherList = this.teacherList.filter(itemB => {
                        return itemB.classList.length != 0
                    })
                }
            })
        },
        getSchoolList() {
            var customId = ''
            if (this.form.customId) {
                customId = this.form.customId
            } else {
                customId = this.$storage.get('customId')
            }
            schoolList({ customId: customId }).then(res => {
                this.schoolList = res.data.data;
                if (this.form.schoolId) {
                    this.gradeList = [];
                    this.schoolList.forEach(e => {
                        if (e.schoolId == this.form.schoolId) {
                            var gradeClassIsmList = e.gradeClassIsm.split(',')
                            if (gradeClassIsmList.indexOf('1') != -1) {
                                this.gradeList.push({ name: '一年级', num: 0, schoolType: 1 })
                                this.gradeList.push({ name: '二年级', num: 0, schoolType: 1 })
                                this.gradeList.push({ name: '三年级', num: 0, schoolType: 1 })
                                this.gradeList.push({ name: '四年级', num: 0, schoolType: 1 })
                                this.gradeList.push({ name: '五年级', num: 0, schoolType: 1 })
                                this.gradeList.push({ name: '六年级', num: 0, schoolType: 1 })
                            }
                            if (gradeClassIsmList.indexOf('2') != -1) {
                                this.gradeList.push({ name: '初一年级', num: 0, schoolType: 2 })
                                this.gradeList.push({ name: '初二年级', num: 0, schoolType: 2 })
                                this.gradeList.push({ name: '初三年级', num: 0, schoolType: 2 })
                            }
                            if (gradeClassIsmList.indexOf('3') != -1) {
                                this.gradeList.push({ name: '高一年级', num: 0, schoolType: 3 })
                                this.gradeList.push({ name: '高二年级', num: 0, schoolType: 3 })
                                this.gradeList.push({ name: '高三年级', num: 0, schoolType: 3 })
                            }
                        }
                    })
                }
            });
        },
        getFileName(name) {
            return name.substring(0, name.lastIndexOf("."));//.txt
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.form.sceneBackground = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }
        },
        handlePicChange(e, type) {
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    this.$message.success('导入成功');
                    console.log(123, e);
                    this.imgData.push({
                        description: '',
                        resourceName: this.getFileName(e.name),
                        resourceId: '',
                        resourceUrl: e.response.data
                    });
                } else {
                    if (e.response.msg) {
                        this.$message.error(e.response.msg);
                    } else {
                        this.$message.error('导入失败');
                    }
                }
            }
            if (e.status == 'fail') {
                this.$message.error('导入失败');
            }
        },
        handleVideoChange(e, type) {
            if (e.response) {
                if (e.response.code == 1) {
                    this.$message.success('导入成功');
                    console.log(123, e);
                    this.videoData.push({
                        description: '',
                        resourceName: this.getFileName(e.name),
                        resourceId: '',
                        resourceUrl: e.response.data
                    });
                } else {
                    if (e.response.msg) {
                        this.$message.error(e.response.msg);
                    } else {
                        this.$message.error('导入失败');
                    }
                }
            }
            if (e.status == 'fail') {
                this.$message.error('导入失败');
            }
        },
        submitPeople() {
            if (this.selectList.length == 0) {
                this.$message.error("请选择数据");
                return
            }
            this.tableData.forEach((e, i) => {
                var b = e.formIds ? e.formIds.split(',') : []

                if (b.includes(this.formId)) {
                    b.splice(b.indexOf(this.formId), 1)
                    console.log(b)
                }
                this.selectList.forEach((j, k) => {
                    if (e.memberUserId == j.memberUserId) {
                        console.log(b)
                        if (b.includes(this.formId)) {

                        } else {
                            b.push(this.formId)
                        }

                    }

                })
                e.formIds = b.join(',')

            })
            console.log(this.selectList)
            console.log(this.formId)
            console.log(this.tableData)

            var memberId = []
            // this.selectList.forEach(e => {
            //     memberId.push(e.memberId)
            // })
            this.selectList = []
            this.peopleOpen = false;
        },
        handleSelectionChange(val) {
            console.log(val)
            this.selectList = val;
        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        beforeVideoUpload(rawFile) {
            // if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
            //     this.$message.error('图片必须是jpeg、png、jpg格式的')
            //     return false
            // } else if (rawFile.size / 1024 / 1024 > 10) {
            //     this.$message.error('图片大小不能超过10M')
            //     return false
            // }
            // return true
        },
        peopleDetail(row) {
            console.log(row)
            this.formId = row.formId;
            this.peopleOpen = true;
            setTimeout(() => {
                this.tableData.forEach((e, i) => {
                    console.log(e.formIds)
                    if (e.formIds) {
                        var tsArray = e.formIds.split(',')
                        if (tsArray.indexOf(this.formId) == -1) {
                            this.$refs.table.toggleRowSelection(this.tableData[i], false);
                        } else {
                            this.$refs.table.toggleRowSelection(this.tableData[i], true);
                        }
                    }
                })
            })

        },
        formDetail(row) {
            this.memberId = row.memberId;
            this.formOpen = true;
            setTimeout(() => {
                this.formList.forEach((e, i) => {
                    console.log(e.formIds)
                    e.memberList.forEach(j => {
                        if (j.memberId == this.memberId) {
                            this.selectList.push(this.formList[i])
                            this.$refs.formtable.toggleRowSelection(this.formList[i], true);
                        }
                    })
                })
            })
        },
        tovalidateUsername(rule, value, callback) {
            qunQueryUser({ mobile: value }).then(res => {
                const result = res.data.data
                if (!result) {
                    callback(new Error("该账号用户不存在"))
                } else {
                    if (this.form.adminName == '') {
                        this.form.adminName = res.data.data.nickName;
                    }
                    this.form.adminUserId = res.data.data.userId;
                    callback()
                }
            })
        },
        validateUsername(value,) {
            if (!isMobile(value.memberAccount)) {
                this.$message.error("手机号码格式不正确")
            }
            teacherQueryOrRegUser({ username: value.memberAccount, trueName: value.memberName }).then(res => {
                const result = res.data.data
                if (!result) {
                    value.memberAccount = ''
                    value.memberName = ''
                    this.$message.error("请输入正确账号")
                } else {
                    if (value.memberName == '') {
                        value.memberName = res.data.data.trueName
                    }
                    value.memberUserId = res.data.data.userId
                    // callback();
                }
            })
            // qunQueryUser({ mobile: value.memberAccount }).then(res => {
            //     const result = res.data.data
            //     if (!result) {
            //         value.memberAccount = ''
            //         value.memberName = ''
            //         this.$message.error("请输入正确账号")
            //     } else {
            //         value.memberName = res.data.data.nickName?res.data.data.nickName:value.memberAccount
            //         value.memberUserId = res.data.data.userId
            //     }
            // })
        },
        submitConfigForm() {
            configCustom(this.configObj).then(res => {
                if (res.data.code == 1) {
                    this.tableData.forEach(e => {
                        if (e.orgId) {
                            if (e.orgId == this.configObj.orgId) {
                                e.bookIds = this.configObj.bookIds
                            }
                        }
                    })
                    this.$message.success("配置成功");
                    this.addPeopleOpen = false;
                    this.configCancel();
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        configCancel() {
            this.readBook = false;
            this.configObj.bookIds = [];
            this.configObj.orgId = '';
        },
        configurationRow(index, row) {
            if (row.adminAccount == '') {
                this.$message.error("请填写管理员账号")
                return;
            }
            if (row.adminName == '') {
                this.$message.error("请填写管理员姓名")
                return;
            }
            this.readBook = true;
            this.configObj.bookIds = row.bookIds;
            this.configObj.orgId = row.orgId;
            console.log(index, row)
        },
        deleteRow(index) {
            console.log(index)
            if (this.tableData.length == 1) {
                this.tableData.splice(index, 1)
                // this.$message.error("不能全部删除")
            } else {
                this.tableData.splice(index, 1)
            }
        },
        deletePicRow(index) {
            this.imgData.splice(index, 1)
        },
        deleteVideoRow(index) {
            this.videoData.splice(index, 1)
        },
        onAddItem() {
            console.log(this.tableData)
            this.tableData.push({
                memberAccount: '',
                memberName: '',
                memberUserId: '',
                memberAddress: ''
            })
            setTimeout(() => {
                this.$refs.memberList.setScrollTop(1000000);
            })
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginDate === '' || !this.form.beginDate) {
                callback(new Error('请选择开始时间'))
            } else if (this.form.endDate === '' || !this.form.endDate) {
                callback(new Error('请选择结束时间'))
            } else {
                callback()
            }
        },
        checkOrgValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            var result = true;
            for (var i = 0; i < this.tableData.length; i++) {
                var e = this.tableData[i];
                if (e.memberAccount == '' || e.memberName == '') {
                    result = false
                    break;
                }
            }
            if (result) {
                callback()
            } else {
                callback(new Error('请填写完整信息'))
            }
        },
        startDateConfirm(e) {
            if (e > this.form.endDate) {
                this.form.endDate = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e < this.form.beginDate) {
                this.form.beginDate = '';
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date())
        },
        enddisabledDate(current) {
            if (this.form.beginDate) {
                return current && current < this.form.beginDate
            } else {
                return current && current < Number(new Date())
            }
        },
        /** 查询基础：基数数据列表 */
        getList() {
            this.loading = false;
            this.customList = []
            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                res.data.data.sort(function (a, b) {
                    return a.customName.localeCompare(b.customName);
                })
                res.data.data.forEach(e => {
                    if (e.status == 0) {
                        this.customList.push(e)
                    }
                })
                res.data.data.forEach(e => {
                    if (e.status == 1) {
                        this.customList.push(e)
                    }
                })
            });
        },


        /** 提交按钮 */
        submitForm() {
            console.log(this.form);
            console.log(this.imgData);
            console.log(this.videoData);
            console.log(this.tableData);
            this.$refs["form"].validate(valid => {
                if (valid) {
                    if (this.fileId) {
                        this.form.teacherIds = this.form.teacherIdsList.toString()
                        eduFileUpdate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                this.replace('/teachAssistantMain', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        });
                    } else {
                        this.loadingInstance = ElLoading.service({
                            lock: true,
                            text: '提交中',
                            background: 'rgba(0, 0, 0, 0.7)',
                        })
                        let formdata = new FormData();
                        this.form.file.map((item) => {
                            console.log(item)
                            formdata.append('file', item.raw)
                        })
                        formdata.append('customId', this.form.customId)
                        formdata.append('fileName', this.form.fileName)
                        formdata.append('grade', this.form.grade)
                        formdata.append('schoolId', this.form.schoolId)
                        formdata.append('subject', this.form.subject)
                        formdata.append('teacherIds', this.form.teacherIdsList.toString())

                        eduFileCreate(formdata).then(res => {
                            if (res.data.code == 1) {
                                this.loadingInstance.close()
                                this.$message.success("新增成功");
                                this.replace('/teachAssistantMain', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.loadingInstance.close()
                                this.$message.error("操作失败")
                            }

                        }).catch(err => {
                            this.loadingInstance.close()
                        });
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            this.replace('/teachAssistantMain')
            // this.$emit('isTg', true)
        },
    }
};
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 270px;
    height: 152px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 270px;
    height: 152px;
    text-align: center;
}

.disnone /deep/ .el-upload-list {
    display: none;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.custom /deep/ .el-checkbox__input {
    position: absolute;
    bottom: 0;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    /* height: 270px; */
    /* overflow-y: auto; */
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}
</style>