<template>
    <div class="app-container pt0Im pl0Im">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>表单记录</a></el-breadcrumb-item>
                <el-breadcrumb-item>表单记录详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <el-card shadow="never" v-loading="isSBBolean && { text: loadTxt }" :element-loading-text="loadTxt">
            <el-row>
                <!-- <el-col :span="16">
                    <el-row>
                        
                    </el-row>
                </el-col> -->
                <el-col :span="24">
                    <el-row>
                        <el-col :span="24">
                            <div style="display: flex;width: 100%;align-items: center;" class="mb10">
                                <div class="mr15">{{ formActive.finishName }}</div>
                                <el-dropdown>
                                    <el-button class="mr15" type="primary">显示<el-icon
                                            class="el-icon--right"><arrow-down /></el-icon></el-button>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item @click="isSHowDJ = !isSHowDJ"><span
                                                    :style="isSHowDJ ? 'color:#409EFF;' : 'color:#333;'">数据叠加显示</span></el-dropdown-item>
                                            <el-dropdown-item @click="isSHowPage = !isSHowPage"><span
                                                    :style="isSHowPage ? 'color:#409EFF;' : 'color:#333;'">仅显示笔迹页面</span></el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                                <el-button type="primary" @click="openIsPerview()">
                                    回放
                                </el-button>
                                <el-button type="primary" title="上一条" @click="lastPage" v-if="activeIndex != 0">
                                    上一条
                                </el-button>
                                <el-button v-if="activeIndex == 0" disabled>上一条</el-button>

                                <el-button class="mr15" type="primary" title="下一条" @click="nextPage"
                                    v-if="activeIndex != (dataList.length - 1)">
                                    下一条
                                </el-button>
                                <el-button class="mr15" v-if="activeIndex == (dataList.length - 1)" disabled>下一条</el-button>
                                <el-dropdown>
                                    <el-button class="mr15" type="primary"
                                        v-if="formActive.finishName != '填报' && !isSQ">识别<el-icon
                                            class="el-icon--right"><arrow-down /></el-icon></el-button>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item @click="getqueryResult">整体识别</el-dropdown-item>
                                            <el-dropdown-item @click="getqueryPageResult">当前页识别</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                                <!-- <el-button type="primary" @click="getqueryResult"
                                    v-if="formActive.finishName != '填报' && !isSQ">整体识别</el-button>
                                <el-button type="primary" @click="getqueryPageResult"
                                    v-if="formActive.finishName != '填报' && !isSQ">当前页识别</el-button> -->
                                <el-dropdown>
                                    <el-button class="mr15" type="primary">导出<el-icon
                                            class="el-icon--right"><arrow-down /></el-icon></el-button>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item @click="handleExport"
                                                v-if="formActive.finishName != '填报' && !isSQ">导出数据</el-dropdown-item>
                                            <el-dropdown-item @click="toDc">导出PDF</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                                <!-- <el-button type="primary" @click="handleExport()"
                                    v-if="formActive.finishName != '填报' && !isSQ">
                                    导出数据
                                </el-button>
                                <el-button type="primary" @click="toDc()">
                                    导出PDF
                                </el-button> -->
                                <el-button type="success" @click="goRecordConfirm(formActive)">
                                    {{ formActive.isFinish == 2 ? '取消' : '确认' }}
                                </el-button>
                                <el-button type="danger" @click="goRecordDelete(formActive)">
                                    {{ formActive.isDelete == 1 ? '恢复' : '删除' }}
                                </el-button>
                                <div class=" fs-14 ml15 text-one mr15" style="text-align: right;flex:1;height: 18px;">
                                    {{ writeName(formActive.recordName) }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{
                                        timestampToTime(formActive.createTime)
                                    }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span v-if="formActive.member">{{
    formActive.member.memberName ? formActive.member.memberName :
    formActive.member.memberAccount }}</span>
                                </div>
                                <div style="padding:8px;"
                                    @click="this.changeRecord(this.dataList[this.activeIndex]); isSQ = false"
                                    class="cursor">
                                    <el-icon v-if="isSQ && formActive.finishName != '填报'" siz="20" color="#409EFF">
                                        <ArrowRightBold />
                                    </el-icon>
                                </div>
                                <div style="padding:8px;" @click="isSQ = true" class="cursor">
                                    <el-icon v-if="!isSQ && formActive.finishName != '填报'" siz="20" color="#409EFF">
                                        <ArrowLeftBold />
                                    </el-icon>
                                </div>
                            </div>

                        </el-col>
                        <!-- <el-col :span="4">
                            <div class="chosebtn pull-right">
                                <div class="" v-if="active == 1"
                                    style="display: flex;align-items: center;transform: translateY(-3px);font-size: 14px;">
                                    <el-checkbox v-model="isSHowBj" />
                                    <div class="ml5" style="white-space: nowrap;">显示笔迹</div>
                                </div>
                            </div>
                        </el-col> -->
                    </el-row>

                </el-col>
            </el-row>

            <el-row>
                <el-col :span="24">
                    <div class="" v-if="formActive.finishName == '填报'">
                        <el-row v-show="active == 2" v-if="formActive.finishName == '填报'">
                            <el-col :span="24" id="lfimgwidth"
                                style="height: calc(100vh - 230px);overflow-y: auto;box-shadow:0 0 0 1px #dcdfe6;background: rgb(243, 243, 243);display: flex;flex-wrap: wrap;">
                                <div v-for="(item, index) in formActive.pageCount" class="cursor"
                                    @click="changeChoseeIndex(index)" style="margin: 10px;">
                                    <Canvasbox v-if="isCanvas" v-show="isSHowPage ? (strokeList[index].length != 0) : true"
                                        :borderColor="(((currentNum - 1) == index) ? 'red' : '#dfdfdf')"
                                        :bookData="formActive" :imgList="[]" :imwidth="lfimgwidth"
                                        :imheight="formActive.pageHeight / formActive.pageWidth * lfimgwidth"
                                        :canvasid="formActive.formId + '22' + index" :formDesignData="formDesignList[index]"
                                        :picSrc="formActive.formUrl + '/' + item + '.png'" :detail="strokeList[index]">
                                    </Canvasbox>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="" v-if="formActive.finishName != '填报'">
                        <el-row v-show="active == 2" v-if="!isSQ">
                            <el-col :span="12" id="lfimgwidth"
                                style="height: calc(100vh - 230px);overflow-y: auto;box-shadow:0 0 0 1px #dcdfe6;background: rgb(243, 243, 243);">
                                <div v-for="(item, index) in formActive.pageCount" class="cursor"
                                    @click="changeChoseeIndex(index)" style="margin: 10px;">
                                    <div v-if="isCanvas" v-show="isSHowPage ? (strokeList[index].length != 0) : true"
                                        :style="'margin:0 auto;width: ' + (lfimgwidth) + 'px;height: ' + formActive.pageHeight / formActive.pageWidth * (lfimgwidth) + 'px;position: relative;'">
                                        <Canvasbox v-if="isCanvas"
                                            :borderColor="(((currentNum - 1) == index) ? 'red' : '#dfdfdf')"
                                            :bookData="formActive" :imgList="[]" :imwidth="lfimgwidth"
                                            :imheight="formActive.pageHeight / formActive.pageWidth * lfimgwidth"
                                            :canvasid="formActive.formId + '22' + index"
                                            :formDesignData="formDesignList[index]"
                                            :picSrc="formActive.formUrl + '/' + item + '.png'" :detail="strokeList[index]">
                                        </Canvasbox>
                                        <div v-for="item in resultList" v-show="item.page == index + 1" v-if="isSHowDJ"
                                            :style="`position:absolute;left:${(item.isTable == 1 ? item.x1 : item.x) / formActive.pageWidth * (lfimgwidth) + 1}px;top:${(item.isTable == 1 ? item.y1 : item.y) / formActive.pageWidth * (lfimgwidth)}px;width:${(item.isTable == 1 ? item.w1 : item.width) / formActive.pageWidth * (lfimgwidth)}px;height:${(item.isTable == 1 ? item.h1 : item.height) / formActive.pageWidth * (lfimgwidth)}px;background:transparent;`">
                                            <div v-if="item.identifyType && item.identifyType != '勾选' && (item.page == index + 1)"
                                                class="inputStyle" :title="item.identifyValue"
                                                style="z-index: 999;color: rgb(252, 3, 3);font-weight: bold;font-size: 20px;overflow: hidden;">
                                                {{ item.identifyValue }}
                                            </div>
                                        </div>
                                        <div :class="item.identifyValue == 'Y' ? 'inputStyle zsj' : 'inputStyle tranaa'"
                                            v-if="isSHowDJ" v-for="item in resultList" v-show="item.page == index + 1"
                                            :style="`position:absolute;left:${(item.isTable == 1 ? item.x1 : item.x) / formActive.pageWidth * (lfimgwidth) + 1}px;top:${(item.isTable == 1 ? item.y1 : item.y) / formActive.pageWidth * (lfimgwidth)}px;width:${(item.isTable == 1 ? item.w1 : item.width) / formActive.pageWidth * (lfimgwidth)}px;height:${(item.isTable == 1 ? item.h1 : item.height) / formActive.pageWidth * (lfimgwidth)}px;background:transparent;`">
                                            <!-- <el-select class="tran" @change="selectChange($event, item)"
                                                v-if="item.identifyType && item.identifyType == '勾选' && (item.page == index + 1)"
                                                style="background:transparent;font-size: 20px;" v-model="item.identifyValue"
                                                placeholder=" ">
                                                <el-option key="yes" label="Y" value="Y" />
                                                <el-option key="no" label="N" value="N" />
                                            </el-select> -->
                                            <div style="z-index: 999;color: rgb(252, 3, 3);font-weight: bold;font-size: 20px;"
                                                class="tran"
                                                v-if="item.identifyType && item.identifyType == '勾选' && (item.page == index + 1) && item.identifyValue == 'Y'">
                                                {{ item.identifyValue }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </el-col>
                            <el-col :span="12" :v-loading="loading[formActive.recordId]">
                                <el-table v-if="active == 2" :row-class-name="isSHowNote ? tableRowClassName : ''"
                                    v-loading="loading[formActive.recordId]" class="ml15" :data="showTableTesult"
                                    style="width: 100%" height="calc(100vh - 230px)" border>
                                    <el-table-column align="center" prop="identifyName" label="表单项" width="150">
                                        <template #default="scope">
                                            <div v-if="scope.row.isTable != 1">
                                                {{ scope.row.identifyName }}
                                                {{ scope.row.isModify == 1 ? '●' : ' ○' }}
                                            </div>
                                            <div v-if="scope.row.isTable == 1">
                                                <div v-if="scope.row.tableType == '普通'">
                                                    {{ scope.row.identifyName }}
                                                </div>
                                                <div v-if="scope.row.tableType != '普通'">
                                                    {{ scope.row.identifyName }}
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" prop="identifyValue" label="书写结果">
                                        <template #default="scope">
                                            <div v-if="scope.row.isTable == 0">
                                                <div v-if="scope.row.identifyType != '勾选'">{{ scope.row.identifyValue }}
                                                </div>
                                                <div v-if="scope.row.identifyType == '勾选'">{{ scope.row.identifyValue }}
                                                </div>
                                            </div>
                                            <div v-else style="text-align: center;">
                                                <el-link v-if="scope.row.tableType && scope.row.tableType != '普通'"
                                                    style="width: 100%;" :underline="false" class="text-one" type="primary">
                                                    <div :title="scope.row.gxTxt">
                                                        {{ scope.row.gxTxt }}
                                                    </div>
                                                </el-link>
                                                <el-popover v-else trigger="hover" width="350px">
                                                    <template #reference>
                                                        <el-link style="width: 100%;" :underline="false" class="text-one"
                                                            type="primary">
                                                            <div class="text-one" style="text-align: center;width: 100%;">{{
                                                                scope.row.reuTxt ?
                                                                scope.row.reuTxt : '' }}
                                                            </div>
                                                        </el-link>
                                                    </template>
                                                    <p v-for="item in scope.row.reuList" style="white-space: nowrap;">
                                                        {{ item }}
                                                    </p>
                                                </el-popover>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" prop="cz" label="操作" width="60">
                                        <template #default="scope">
                                            <el-link @click="editTable(scope.row)" :underline="false" type="primary">
                                                <div>
                                                    更改
                                                </div>
                                            </el-link>
                                        </template>
                                    </el-table-column>

                                    <el-table-column align="center" prop="page" label="页码" width="60">
                                    </el-table-column>
                                    <el-table-column align="center" prop="zt" label="状态" width="60">
                                        <template #default="scope">
                                            <span style="color:red;">{{ scope.row.isModify == 1 ? '●' : '' }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" prop="lx" label="类型" width="180">
                                        <template #default="scope">
                                            <div v-if="scope.row.isTable == 1">
                                                <div v-if="scope.row.tableType == '普通'">
                                                    [T]表格
                                                </div>
                                                <div v-if="scope.row.tableType == '单选'">
                                                    [B]表格单选
                                                </div>
                                                <div v-if="scope.row.tableType == '多选'">
                                                    [B]表格多选
                                                </div>
                                            </div>
                                            <div v-else>
                                                <div v-if="scope.row.identifyType == '勾选'">[B]布尔</div>
                                                <div v-else>[S]{{ scope.row.identifyType }}</div>
                                            </div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-col>
                        </el-row>
                        <el-row v-show="active == 2" v-if="isSQ">
                            <el-col :span="24" id="lfimgwidth"
                                style="height: calc(100vh - 230px);overflow-y: auto;box-shadow:0 0 0 1px #dcdfe6;background: rgb(243, 243, 243);display: flex;flex-wrap: wrap;">
                                <div v-for="(item, index) in formActive.pageCount" class="cursor"
                                    @click="changeChoseeIndex(index)" style="margin: 10px;">
                                    <Canvasbox v-if="isCanvas"
                                        :borderColor="(((currentNum - 1) == index) ? 'red' : '#dfdfdf')"
                                        :bookData="formActive" :imgList="[]" :imwidth="lfimgwidth"
                                        :imheight="formActive.pageHeight / formActive.pageWidth * lfimgwidth"
                                        :canvasid="formActive.formId + '22' + index" :formDesignData="formDesignList[index]"
                                        :picSrc="formActive.formUrl + '/' + item + '.png'" :detail="strokeList[index]">
                                    </Canvasbox>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </el-card>

        <PerviewCanvas :currentNum="1" :strokeList="exStrList" :bookData="activeBookData" :xS="activeBookData.x"
            :yS="activeBookData.y" @canvasClose="canvasClPerview" v-if="isPerview">
        </PerviewCanvas>
        <PerviewCanvas :currentNum="currentNum" :strokeList="exLfStrList" :bookData="formActive"
            :formDesignData="JSON.parse(JSON.stringify(formDesignList))" @canvasClose="canvasClLfPerview"
            v-if="isLfPerview">
        </PerviewCanvas>
        <el-dialog :width="lfimgwidth * 2.0 + 45" draggable v-if="isEditPTTable" v-model="isEditPTTable" align-center
            style="border-radius: 20px" title="编辑">
            <div style="display: flex;">
                <div style="max-height: calc(100vh - 320px); overflow-y: auto;">
                    <Canvasbox v-if="isEditPTTable && (tableActive.pageHeight < tableActive.pageWidth)"
                        :bookData="tableActive" :imgList="[]" :xS="tableActive.x" :yS="tableActive.y"
                        :imwidth="(lfimgwidth * 1.0)"
                        :imheight="tableActive.pageHeight / tableActive.pageWidth * (lfimgwidth * 1.0)"
                        :canvasid="formActive.formId + '22111111'" :picSrc="tableActive.img"
                        :detail="tableActive.strokeList">
                    </Canvasbox>
                    <Canvasbox v-if="isEditPTTable && (tableActive.pageHeight > tableActive.pageWidth)"
                        :bookData="tableActive" :imgList="[]" :xS="tableActive.x" :yS="tableActive.y"
                        :imwidth="(lfimgwidth / 3 * 1.0)"
                        :imheight="tableActive.pageHeight / tableActive.pageWidth * (lfimgwidth / 3 * 1.0)"
                        :canvasid="formActive.formId + '22111111'" :picSrc="tableActive.img"
                        :detail="tableActive.strokeList">
                    </Canvasbox>
                </div>
                <div style="margin-left: 30px;max-height: calc(100vh - 320px); overflow-y: auto;"
                    v-if="tableActive.isTable == 1">
                    <div
                        :style="'overflow-x: auto;width: ' + (((lfimgwidth + 20) / tableActive.xLen < 40) ? (tableActive.xLen * 60) + 'px' : '100%') + ';'">
                        <div v-for="item in tableActive.yLen" style="display: flex;">
                            <el-popover @show="showzdy(item, items)" :width="400" trigger="click" ref="popoverRef"
                                v-for="items in tableActive.xLen">
                                <template #reference>
                                    <div class="text-one cursor zsybox" :title="getTableValue(item, items)"
                                        :style="'position: relative;width:' + (((lfimgwidth + 20) / tableActive.xLen < 40) ? 40 : ((lfimgwidth + 20) / tableActive.xLen)) + 'px !important;'">
                                        {{ getTableValue(item, items) }}
                                    </div>
                                </template>
                                <el-input v-model="zdyValue" spellcheck="false" type="textarea" ref="focusInput"
                                    style="font-weight: bold;font-size: 30px;" placeholder=" " />
                                <div class="disflex-row-center">
                                    <el-button @click="saveSeResult(item, items)" type="primary" class="mt10">确
                                        定</el-button>
                                </div>
                            </el-popover>

                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
        <el-dialog :width="lfimgwidth * 1.0 + 45" draggable v-if="isEditTable" v-model="isEditTable" align-center
            style="border-radius: 20px" title="编辑">
            <div style="max-height: calc(100vh - 320px); overflow-y: auto;">
                <div style="">
                    <Canvasbox v-if="isEditTable" :bookData="tableActive" :imgList="[]" :xS="tableActive.x"
                        :yS="tableActive.y" :imwidth="(lfimgwidth * 1.0)"
                        :imheight="tableActive.pageHeight / tableActive.pageWidth * (lfimgwidth * 1.0)"
                        :canvasid="formActive.formId + '22111111'" :picSrc="tableActive.img"
                        :detail="tableActive.strokeList">
                    </Canvasbox>
                </div>
                <div style="margin-top: 30px;" v-if="tableActive.isTable == 1">
                    <div :style="'overflow-x: auto;width: ' + (((lfimgwidth + 20) / tableActive.xLen < 40) ? (tableActive.xLen * 60) + 'px' : '100%') + ';'"
                        v-if="tableActive.tableType && tableActive.tableType != '普通'">
                        <div v-for="item in tableActive.yLen" style="display: flex;">
                            <div v-for="items in tableActive.xLen" class="text-one cursor zsybox"
                                :title="getTableValue(item, items)" @click="changTableGx(item, items)"
                                :class="getTableBg(item, items)"
                                :style="'position: relative;width:' + (((lfimgwidth + 20) / tableActive.xLen < 40) ? 40 : ((lfimgwidth + 20) / tableActive.xLen)) + 'px !important;'">
                                {{ getTableValue(item, items) }}
                            </div>
                        </div>
                    </div>
                    <div :style="'overflow-x: auto;width: ' + (((lfimgwidth + 20) / tableActive.xLen < 40) ? (tableActive.xLen * 60) + 'px' : '100%') + ';'"
                        v-else>
                        <div v-for="item in tableActive.yLen" style="display: flex;">
                            <el-popover @show="showzdy(item, items)" :width="400" trigger="click" ref="popoverRef"
                                v-for="items in tableActive.xLen">
                                <template #reference>
                                    <div class="text-one cursor zsybox" :title="getTableValue(item, items)"
                                        :style="'position: relative;width:' + (((lfimgwidth + 20) / tableActive.xLen < 40) ? 40 : ((lfimgwidth + 20) / tableActive.xLen)) + 'px !important;'">
                                        {{ getTableValue(item, items) }}
                                    </div>
                                </template>
                                <el-input v-model="zdyValue" spellcheck="false" type="textarea" ref="focusInput"
                                    style="font-weight: bold;font-size: 30px;" placeholder=" " />
                                <div class="disflex-row-center">
                                    <el-button @click="saveSeResult(item, items)" type="primary" class="mt10">确
                                        定</el-button>
                                </div>
                            </el-popover>

                        </div>
                    </div>
                </div>
                <div style="margin-top: 30px;" v-else>
                    <div :style="'overflow-x: auto;width: 180px;'"
                        v-if="tableActive.identifyType && tableActive.identifyType == '勾选'">
                        <div style="display: flex;">
                            <div @click="changeGx('Y')" class="cursor zsybox"
                                :style="tableActive.identifyValue == 'Y' ? 'background-color: #21ac74;' : ''">
                                是
                            </div>
                            <div @click="changeGx('N')" class="cursor zsybox"
                                :style="tableActive.identifyValue == 'N' ? 'background-color: #21ac74;' : ''">
                                否
                            </div>
                            <div @click="changeGx('')" class="cursor zsybox"
                                :style="!tableActive.identifyValue ? 'background-color: #21ac74;' : ''">
                                空
                            </div>
                        </div>
                    </div>
                    <div :style="'overflow-x: auto;'" v-else>
                        <div style="display: flex;align-items: center;">
                            <div style="width: 60px;">原数据：</div>
                            <div style="flex: 1;margin-right: 15px;padding:10px;background: #dcdfe6;text-align: left;">{{
                                tableActive.identifyValue }}</div>
                            <div @click="zdyValue = tableActive.identifyValue"
                                v-if="tableActive.identifyType != '日期' && tableActive.identifyType != '时间'"
                                style="width: 60px;height: 100%;min-height: 50px;border: 1px solid #dcdfe6;border-radius: 5px;display: flex;align-items: center;justify-content: center;">
                                <div style="">复制到</div>
                            </div>
                        </div>
                        <div style="display: flex;align-items: center;" class="mt15"
                            v-if="tableActive.identifyType != '日期' && tableActive.identifyType != '时间'">
                            <div style="width: 60px;">修改为：</div>
                            <div style="flex: 1;margin-right: 15px;">
                                <el-input v-model="zdyValue" spellcheck="false" type="textarea"
                                    style="font-weight: bold;font-size: 16px;" placeholder="" />
                            </div>
                            <div @click="zdyValue = ''"
                                style="width: 60px;height: 100%;min-height: 50px;border: 1px solid #dcdfe6;border-radius: 5px;display: flex;align-items: center;justify-content: center;">
                                <div style="">清 空</div>
                            </div>
                        </div>
                        <div style="display: flex;align-items: center;" class="mt15"
                            v-if="tableActive.identifyType == '日期'">
                            <div style="width: 60px;">修改为：</div>
                            <div style="flex: 1;margin-right: 15px;text-align: left;">
                                <el-date-picker clearable format="YYYY.MM.DD" value-format="YYYY.MM.DD" v-model="zdyValue"
                                    type="date" placeholder="请选择日期" />
                            </div>
                        </div>
                        <div style="display: flex;align-items: center;" class="mt15"
                            v-if="tableActive.identifyType == '时间'">
                            <div style="width: 60px;">修改为：</div>
                            <div style="flex: 1;margin-right: 15px;text-align: left;">
                                <el-time-picker clearable format="HH:mm:ss" value-format="HH:mm:ss" v-model="zdyValue"
                                    placeholder="请选择时间" />
                            </div>
                        </div>
                        <div class="disflex-row-center mt10">
                            <el-button type="primary" @click="changeZCResult">确 定</el-button>
                            <el-button @click="isEditTable = false">取 消</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
        <el-dialog draggable v-if="showPdfObj.isShow" style="border-radius: 20px;width:400px" v-model="showPdfObj.isShow"
            align-center title="导出pdf">
            <el-form v-model="showPdfObj" label-width="100px" style="margin: 0 auto">
                <el-form-item label="名称">
                    <el-input v-model="showPdfObj.pdfName"></el-input>
                </el-form-item>
                <el-form-item label="只导出笔迹页">
                    <el-switch size="large" active-text="是" inactive-text="否" inline-prompt
                        v-model="showPdfObj.isWrite"></el-switch>
                </el-form-item>
                <el-button @click="confirmDc" type="primary">导出</el-button>
            </el-form>
        </el-dialog>
        <canvasExport style="opacity: 0;" :zdyName="this.showPdfObj.pdfName" :formCover="formCover" :strokeList="pdfStrList" :bookData="dataList[activeIndex]"
            :formDesignData="JSON.parse(JSON.stringify(formDesignList))" @canvasClose="canvasClosess" v-if="isDc">
        </canvasExport>
        <CanvasWriteTablePlay v-if="isWP" :currentNum="currentNum" :strokeList="exStrList" :bookData="dataList[activeIndex]"
            :formDesignData="JSON.parse(JSON.stringify(formDesignList[currentNum - 1]))" @canvasClose="closeWp">
        </CanvasWriteTablePlay>
    </div>
</template>

<script>
import XLSX from "xlsx-js-style";

import { queryCustoms } from "@/api/adminservice/custom";
import { isIDCard, isMobile } from '@/utils/validate'
import {
    exportData
} from "@/api/demo/data";
import { timestampToTime } from "@/utils/date";
import { formQuery, deleteForm, formRelease, formStop, formRecordDesign, formInfo } from "@/api/adminservice/form"
import { download } from "@/api/adminservice/public"
import { queryScene } from "@/api/adminservice/scene"
import { recordCancelConfirm, recordConfirm, recordDelete, recordCancelDelete, queryAllRecords, queryResult, strokeQuery, tableInfo, saveResult, queryLastIdentifyNoImg, identifyQueryImg, formRecordResult, identifySinglePage, formRecordInfo } from "@/api/adminservice/formRecord"

export default {
    name: "formRecordIdentify",
    components: {
    },
    data() {
        return {
            isSQ: false,
            zdyValue: '',
            isWP: false,
            loadTxt: '识别进度',
            isSHowNote: false,
            isSHowPage: false,
            isSHowDJ: false,
            isSHowBj: true,
            isSBBolean: false,
            exLfStrList: [],
            activeBookData: {},
            cjradioList: ['采集时间', '采集人员', '状态'],
            isFilter: false,
            currentNum: 0,
            active: 2,
            tableData: [],
            showPdfObj: {
                isShow: false,
                pdfName: '',
                isWrite: true,
            },
            formActive: {
                pageCount: 0,
                member: {}
            },
            customList: [],
            strokeList: [],
            lfimgwidth: 400,
            statusList: [
                { name: '未铺设', value: 0 },
                { name: '铺设中', value: 1 },
                { name: '铺设成功', value: 2 },
                { name: '铺设失败', value: 3 },
                { name: '发布', value: 4 },
                { name: '停用', value: 5 },
            ],
            sceneList: [],
            formList: [],
            timestampToTime,
            isSuperAuth: this.$storage.get('isSuperAuth'),
            // 遮罩层
            loading: {},
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            isFilter: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            boleanObj: {},
            boleanAll: false,
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                customId: '',
                screenName: '',
                status: '',
                formId: '',
                sceneId: '',
                isDesign: '',
                customId: '',
                createTime: [],
                releaseTime: []
            },
            showTableTesult: [],
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            selectObj: [],
            result: [],
            isCanvas: false,
            isTwoCanvas: false,
            resultList: [],
            oldResultList: [],
            csobj: {},
            tableResultList: [],
            isPerview: false,
            isLfPerview: false,
            isEditTable: false,
            isEditPTTable: false,
            tableActive: {},
            activeIndex: 0,
            role: '',
            formDesignData: [],
            formDesignList: [],
            recordData: {},
            choseeIndex: 0,
            dqyData: [],
            elTitleList: [],
            pdfStrList:[],
            exStrList: [],
            isDc: false,
        };
    },
    created() {
        this.role = this.$storage.get("role")
        // this.getSceneList();
        // this.getFormList();
        // window.onkeydown = this.keyDown;
    },
    mounted() {
        if (this.$route.query.recordData) {
            this.recordData = JSON.parse(decodeURIComponent(this.$route.query.recordData))
            this.changeRecord(this.recordData, 0)
            this.dataList = JSON.parse(decodeURIComponent(this.$route.query.dataList))
            this.dataList.forEach((e, i) => {
                if (e.recordId == this.recordData.recordId) {
                    this.activeIndex = i;
                }
            })
        }
        this.lfimgwidth = (document.getElementById("lfimgwidth").clientWidth - 40)
    },
    watch: {
        $route(to, from) {
            // console.log('to', to)
        },
    },
    methods: {
        changeZCResult() {
            console.log(this.zdyValue);
            console.log(this.tableActive);
            console.log(this.tableActive.identifyType);
            if (this.tableActive.identifyType == '身份证号' && this.zdyValue) {
                console.log(isIDCard(this.zdyValue));
                if (!isIDCard(this.zdyValue)) {
                    this.$message.error("身份证格式不正确");
                    return
                }
            }
            if (this.tableActive.identifyType == '手机号' && this.zdyValue) {
                console.log(isMobile(this.zdyValue));
                if (!isMobile(this.zdyValue)) {
                    this.$message.error("手机号格式不正确");
                    return
                }
            }
            formRecordResult({ id: this.tableActive.designId, recordId: this.tableActive.recordId, value: this.zdyValue }).then(res => {
                if (res.data.code == 1) {
                    if (this.tableActive.identifyValue != null) {
                        this.tableActive.isModify = 1;
                    }
                    this.tableActive.identifyValue = this.zdyValue;
                    this.isEditTable = false;
                    this.isEditPTTable = false;
                    this.changeTableResult()
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        saveSeResult(item, items) {
            for (var i = 0; i < this.tableActive.tableList.length; i++) {
                var e = this.tableActive.tableList[i];
                if (item == e.rowNum && items == e.colNum) {
                    console.log(e);
                    formRecordResult({ id: e.designId, recordId: e.recordId, value: this.zdyValue }).then(res => {
                        if (res.data.code == 1) {
                            if (e.identifyValue != null) {
                                e.isModify = 1;
                            }
                            e.identifyValue = this.zdyValue;
                            // this.changeRecord(this.dataList[this.activeIndex], true)
                            this.tableActive.isModify = 1;
                            this.$refs['popoverRef'].forEach(m => {
                                m.hide()
                            })
                            this.changeTableResult()

                            console.log(this.$refs['popoverRef'])
                        } else {
                            if (res.data.msg) {
                                this.$message.error(res.data.msg);
                            } else {
                                this.$message.error("操作失败");
                            }
                        }
                    })
                    break;
                }
            }
        },
        goRecordConfirm(row) {
            var recordIds = [row.recordId]
            if (row.isFinish == 2) {
                recordCancelConfirm({ recordId: row.recordId }).then(res => {
                    if (res.data.code == 1) {
                        this.$message.success("取消成功");
                        this.getDetail();
                    } else {
                        this.$message.error("操作失败");
                    }
                })
            } else {
                recordConfirm({ recordIds: recordIds.toString() }).then(res => {
                    if (res.data.code == 1) {
                        this.$message.success("确认成功");
                        this.getDetail();
                    } else {
                        this.$message.error("操作失败");
                    }
                })
            }
        },
        goRecordDelete(row) {
            if (row.isDelete == 0) {
                recordDelete({ recordId: row.recordId }).then(res => {
                    if (res.data.code == 1) {
                        this.$message.success("删除成功");
                        this.getDetail();
                    } else {
                        this.$message.error("操作失败");
                    }
                })
            } else {
                recordCancelDelete({ recordId: row.recordId }).then(res => {
                    if (res.data.code == 1) {
                        this.$message.success("恢复成功");
                        this.getDetail();
                    } else {
                        this.$message.error("操作失败");
                    }
                })
            }
        },
        getDetail() {
            formRecordInfo({ recordId: this.formActive.recordId }).then(res => {
                this.formActive = res.data.data;
                if (this.formActive.isDelete == 1) {
                    this.formActive.finishName = '已删除'
                } else {
                    if (this.formActive.isFinish == 0) {
                        this.formActive.finishName = '填报'
                    } else if (this.formActive.isFinish == 1) {
                        this.formActive.finishName = '已识别'
                    } else if (this.formActive.isFinish == 2) {
                        this.formActive.finishName = '已确认'
                    }
                }
                // if (this.formActive.member) {
                //     if (this.formActive.member.memberName) {
                //         this.formActive.member.memberName = this.formActive.member.memberName
                //     } else {
                //         this.formActive.memberName = this.formActive.member.memberAccount
                //     }
                // }
            })
        },
        changeGx(val) {
            console.log(this.tableActive);
            formRecordResult({ id: this.tableActive.designId, recordId: this.tableActive.recordId, value: val }).then(res => {
                if (res.data.code == 1) {
                    if (this.tableActive.identifyValue != null) {
                        this.tableActive.isModify = 1;
                    }
                    this.tableActive.identifyValue = val
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        changTableGx(item, items) {
            let arr = []

            if (this.tableActive.tableType == '单选') {
                for (var i = 0; i < this.tableActive.tableList.length; i++) {
                    var e = this.tableActive.tableList[i];
                    if (item == e.rowNum && items == e.colNum) {
                        e.identifyValue = 'Y'
                    } else {
                        e.identifyValue = 'N'
                    }
                    arr.push(new Promise(function (resolve, reject) {
                        formRecordResult({ id: e.designId, recordId: e.recordId, value: e.identifyValue }).then(res => {
                            resolve(res.data.data)
                        })
                    }))

                }
            } else {
                for (var i = 0; i < this.tableActive.tableList.length; i++) {
                    var e = this.tableActive.tableList[i];
                    if (item == e.rowNum && items == e.colNum) {
                        if (e.identifyValue == 'Y' || !e.identifyValue) {
                            e.identifyValue = 'N'
                        } else {
                            e.identifyValue = 'Y'
                        }
                        arr.push(new Promise(function (resolve, reject) {
                            formRecordResult({ id: e.designId, recordId: e.recordId, value: e.identifyValue }).then(res => {
                                resolve(res.data.data)
                            })
                        }))
                    } else {

                    }

                }
            }
            Promise.all(arr).then((result) => {
                this.tableActive.isModify = 1;
                // this.changeRecord(this.dataList[this.activeIndex])
                var reuList = []
                var reuObj = {}
                var gxList = []
                this.tableActive.tableList.forEach(j => {
                    if (j.tableType == '单选' || j.tableType == '多选') {
                        if (j.identifyValue == 'Y') {
                            gxList.push(j.itemValue)
                        }
                    }
                    //else {
                    if (reuObj[j.rowNum]) {
                        reuObj[j.rowNum].push(j.identifyValue)
                    } else {
                        reuObj[j.rowNum] = [j.identifyValue]
                    }

                    // }
                })
                // if (e.tableType == '普通') {
                console.log(reuObj);
                for (let as in reuObj) {
                    this.tableActive.xLen = reuObj[as].length
                    reuList.push(reuObj[as].join(','))
                }
                this.tableActive.reuTxt = reuList.join(';')
                // } else {
                //     e.reuTxt = reuList.join(',')
                // }
                if (this.tableActive.tableType == '单选' || this.tableActive.tableType == '多选') {
                    this.tableActive.gxTxt = gxList.join(',')
                }
            })
        },
        showzdy(item, items) {
            this.zdyValue = '';
            for (var i = 0; i < this.tableActive.tableList.length; i++) {
                var e = this.tableActive.tableList[i];
                if (item == e.rowNum && items == e.colNum) {
                    this.zdyValue = e.identifyValue
                    break;
                }
            }
            this.$refs.focusInput.forEach(e => {
                e.focus();
            })
        },
        getTableBg(item, items) {
            for (var i = 0; i < this.tableActive.tableList.length; i++) {
                var e = this.tableActive.tableList[i];
                if (item == e.rowNum && items == e.colNum && e.identifyValue == 'Y') {
                    return 'tcBjs'
                    break;
                }
            }
        },
        getTableValue(item, items) {
            // console.log(this.tableActive, item, items);
            if (this.tableActive.tableType && this.tableActive.tableType != '普通') {
                for (var i = 0; i < this.tableActive.tableList.length; i++) {
                    var e = this.tableActive.tableList[i];
                    if (item == e.rowNum && items == e.colNum) {
                        return e.itemValue
                        break;
                    }
                }
            } else {
                for (var i = 0; i < this.tableActive.tableList.length; i++) {
                    var e = this.tableActive.tableList[i];
                    if (item == e.rowNum && items == e.colNum) {
                        return e.identifyValue
                        break;
                    }
                }
            }
        },
        changeList() {
            this.getList()
        },
        nextPage() {
            this.activeIndex++;
            this.changeRecord(this.dataList[this.activeIndex])
            this.currentNum = 0;
        },
        lastPage() {
            this.activeIndex--;
            this.changeRecord(this.dataList[this.activeIndex])
            this.currentNum = 0;
        },
        tableRowClassName: function (row, index) {
            // console.log(row.row)
            if (row.row.strokeList && row.row.strokeList.length === 0) {
                return 'hidden-row';
            }
            return '';
        },
        changeChoseeIndex(index) {
            // this.choseeIndex = index;
            if ((this.currentNum) == (index + 1)) {
                this.currentNum = 0
            } else {
                this.currentNum = index + 1;
            }

            setTimeout(() => {
                // this.getTableResult()
                if (this.currentNum != 0) {
                    this.showTableTesult = this.tableResultList.filter(itemA => {
                        return itemA.page.toString() == this.currentNum
                    })
                } else {
                    this.showTableTesult = this.tableResultList
                }
                this.isTwoCanvas = true;
            })
        },
        changeShowNote() {
            // console.log(this.isSHowNote);
        },
        keyDown(event) {
            // console.log(event.key)
            if (event.key === 'ArrowUp') {
                if (this.activeIndex - 1 >= 0) {
                    this.changeRecord(this.dataList[this.activeIndex - 1], this.activeIndex - 1)
                }
                // this.ctrl = true;
            } else if (event.key === 'ArrowDown') {
                if (this.activeIndex + 1 < this.dataList.length) {
                    this.changeRecord(this.dataList[this.activeIndex + 1], this.activeIndex + 1)
                }
            }
        },
        toDc(row) {
            this.showPdfObj.pdfName = (this.formActive.recordName + '-' + timestampToTime(this.formActive.createTime));
            this.showPdfObj.isShow = true;
            // this.isDc = true;
            // this.loading = true;
        },
        confirmDc() {
            console.log(this.exStrList);
            console.log(this.showPdfObj);
            if (!this.showPdfObj.pdfName || this.showPdfObj.pdfName == '') {
                this.$message.error('请输入导出名称')
                return;
            }
            if (this.showPdfObj.isWrite) {
                this.pdfStrList = this.exStrList.filter(itemA => {
                    return itemA.strokeList && itemA.strokeList.length != 0
                })
            }else{
                this.pdfStrList = this.exStrList
            }
            console.log(this.exStrList);
            this.isDc = true;
        },
        canvasClosess(e) {
            this.isDc = false;
            this.showPdfObj.isShow = false;
        },
        changeCustom() {
            this.dataList = [];
            this.queryParams.sceneId = '';
            this.queryParams.formId = '';
            this.formList = [];

            queryScene({ customId: this.$storage.get('customId') }).then(res => {
                if (this.role.includes(0)) {
                    if (this.queryParams.customId !== '') {
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.customId == this.queryParams.customId
                        })
                    }
                }
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.sceneType != 1;
                })
                this.sceneList = res.data.data;
                if (this.sceneList.length != 0) {
                    this.queryParams.sceneId = this.sceneList[0].sceneId;
                    this.getList();
                    this.getFormList();
                }
            });
        },
        changeScene() {
            this.dataList = [];
            this.queryParams.formId = '';
            this.getFormList();
            this.getList();
        },
        editTable(item) {
            this.zdyValue = ''
            console.log(item);
            if (item.isTable == 1) {
                tableInfo({ designId: item.designId, num: item.num, recordId: item.recordId }).then(ress => {
                    // console.log(ress.data.data);
                    if (ress.data.data) {
                        // console.log(obj.hasOwnProperty(e.num))
                        item.height = ress.data.data.height
                        item.width = ress.data.data.width
                        item.x = ress.data.data.x
                        item.y = ress.data.data.y
                        item.pageWidth = ress.data.data.width
                        item.pageHeight = ress.data.data.height
                        item.strokeList = ress.data.data.strokeList
                    }
                    identifyQueryImg({ designId: item.designId }).then(res => {
                        if (res.data.data) {
                            item.img = res.data.data;
                        }
                        console.log(item.reuList);
                        this.tableActive = item;
                        this.zdyValue = this.tableActive.identifyValue;
                        setTimeout(() => {
                            console.log(this.lfimgwidth);
                            if (item.isTable == 1 && item.tableType && item.tableType == '普通') {
                                this.isEditPTTable = true;
                            } else {
                                this.isEditTable = true;
                            }
                        })
                    })
                })
            } else {
                identifyQueryImg({ designId: item.designId }).then(res => {
                    if (res.data.data) {
                        item.img = res.data.data;
                    }
                    console.log(item.reuList);
                    this.tableActive = item;
                    this.zdyValue = this.tableActive.identifyValue;
                    setTimeout(() => {
                        console.log(this.lfimgwidth);
                        if (item.isTable == 1 && item.tableType && item.tableType == '普通') {
                            this.isEditPTTable = true;
                        } else {
                            this.isEditTable = true;
                        }
                    })
                })
            }

        },
        openIsPerview(item) {
            if (this.currentNum == 0) {
                this.$message.error('请选择页面')
                return
            }
            // this.activeBookData = item;
            // this.exStrList = [{ pageNum: 1, strokeList: item.strokeList }]
            // // console.log(item)
            // this.isPerview = true;
            this.changePlay()
        },
        closeWp() {
            this.isWP = false;
        },
        changePlay() {
            console.log(this.dataList[this.activeIndex])
            this.isWP = !this.isWP;
        },
        openIsLfPerview(item) {
            this.currentNum = item + 1;
            this.isLfPerview = true;
        },
        canvasClPerview(e) {
            this.isPerview = false;
        },
        canvasClLfPerview(e) {
            this.isLfPerview = false;
        },
        changeCjradio(e) {
            if (this.cjradioList.indexOf(e) == -1) {
                this.cjradioList.push(e)
            } else {
                if (this.cjradioList.length == 1) {
                    return
                }
                this.cjradioList.splice(this.cjradioList.indexOf(e), 1)
            }
        },
        selectChange(val, item) {
            // console.log(val, item)
            // if(item.isTable == 1){
            //     if(item.tableType == '单选'){

            //     }
            // }
        },
        changeTableResult() {
            console.log(this.resultList);
            this.resultList = []
            this.tableResultList.forEach(e => {
                if (e.isTable == 0) {
                    this.resultList.push(e)
                } else {
                    // console.log(e.tableList)
                    e.tableList.forEach(j => {
                        this.resultList.push(j)
                    })
                }
            })
        },
        sumbit() {
            // console.log(this.formActive)
            // console.log(this.resultList)
            // console.log(this.tableResultList)
            if (this.formActive.isIdentify == 0) {
                this.$message.error('请选择识别状态的数据')
                return
            }
            if (this.active == 2) {
                this.changeTableResult()
            }
            this.resultList.forEach(e => {
                this.oldResultList.forEach(j => {
                    if (e.designId == j.designId && e.identifyValue != j.identifyValue) {
                        e.isModify = 1
                    }
                })
            })
            saveResult({
                identifyList: this.resultList,
                recordId: this.formActive.recordId
            }).then(res => {
                if (res.data.code == 1) {
                    this.formActive.isIdentify = 2
                    this.$message.success("提交成功");
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        changeBolean() {
            console.log(this.boleanAll);
            if (this.boleanAll) {
                for (let as in this.boleanObj) {
                    this.boleanObj[as] = true;
                }
            } else {
                for (let as in this.boleanObj) {
                    this.boleanObj[as] = false;
                }
            }
        },
        writeName(txt) {
            if (this.formActive.isBook == 1) {
                return '《' + txt + '》'
            } else {
                return txt
            }
        },
        getqueryPageResult() {
            if (this.currentNum == 0) {
                this.$message.error('请选择页面')
                return
            }
            this.isSBBolean = true;
            let that = this;
            this.loadTxt = '识别中'
            identifySinglePage({ page: (this.currentNum), recordId: this.formActive.recordId }).then(res => {
                if (res.data.code == 1) {
                    that.loading[that.formActive.recordId] = true;
                    that.isTwoCanvas = false;
                    that.formActive.isIdentify = 1
                    that.dqyData = res.data.data;
                    let arr = []
                    let obj = {}
                    that.dqyData.forEach((e, i) => {
                        e.pageWidth = e.width
                        e.pageHeight = e.height
                        if (e.isTable == 1) {
                            e.x1 = e.x;
                            e.y1 = e.y;
                            e.h1 = e.height;
                            e.w1 = e.width;
                            if (!obj.hasOwnProperty(e.num)) {

                                // arr.push(new Promise(function (resolve, reject) {
                                //     obj[e.num] = {};
                                //     tableInfo({ designId: e.designId, num: e.num, recordId: e.recordId }).then(ress => {
                                //         if (ress.data.data) {
                                //             obj[e.num] = ress.data.data
                                //             e.height = ress.data.data.height
                                //             e.width = ress.data.data.width
                                //             e.x = ress.data.data.x
                                //             e.y = ress.data.data.y
                                //             e.pageWidth = ress.data.data.width
                                //             e.pageHeight = ress.data.data.height
                                //         }

                                //         resolve(res.data.data)
                                //     })
                                // }))
                            } else {
                                e.height = obj[e.num].height
                                e.width = obj[e.num].width
                                e.x = obj[e.num].x
                                e.y = obj[e.num].y
                                e.pageWidth = obj[e.num].width
                                e.pageHeight = obj[e.num].height
                            }
                        }
                    })
                    Promise.all(arr).then((result) => {
                        that.resultList.forEach(e => {
                            that.dqyData.forEach(j => {
                                if (j.designId == e.designId && j.recordId == e.recordId) {
                                    e = j
                                }
                            })
                        })
                        that.isSBBolean = false;

                        that.boleanAll = false;
                        for (let as in that.boleanObj) {
                            that.boleanObj[as] = false;
                        }
                        console.log(that.tableData, that.resultList, that.dqyData);
                        if (that.currentNum != 0) {
                            that.tableData = that.resultList.filter(itemA => {
                                return itemA.page.toString() == that.currentNum
                            })
                        } else {
                            that.tableData = that.resultList
                        }
                        that.isTwoCanvas = true;
                        setTimeout(() => {
                            that.loading[that.formActive.recordId] = false;
                            that.getTableResult();
                        })
                    })
                } else {

                }
            })
        },
        getqueryResult() {
            for (let as in this.loading) {
                this.loading[as] = false;
            }
            setTimeout(() => {
                let arr1 = []
                var recList = []
                this.isSBBolean = true;
                let that = this;
                this.loadTxt = '识别中'
                // setTimeout(() => {
                //     this.loadTxt = '识别进度222/' + recList.length
                // }, 1000);
                queryResult({ recordId: this.formActive.recordId }).then(res => {
                    console.log(JSON.parse(JSON.stringify(res.data.data)));
                    if (res.data.code == 1) {
                        res.data.data.isIdentify = 1
                        that.loading[that.formActive.recordId] = true;
                        that.isTwoCanvas = false;
                        that.formActive.isIdentify = 1
                        that.resultList = res.data.data;
                        that.oldResultList = JSON.parse(JSON.stringify(res.data.data))
                        let arr = []
                        let obj = {}
                        that.resultList.forEach((e, i) => {
                            e.pageWidth = e.width
                            e.pageHeight = e.height
                            if (e.isTable == 1) {
                                e.x1 = e.x;
                                e.y1 = e.y;
                                e.h1 = e.height;
                                e.w1 = e.width;
                                if (!obj.hasOwnProperty(e.num)) {

                                    // arr.push(new Promise(function (resolve, reject) {
                                    //     obj[e.num] = {};
                                    //     tableInfo({ designId: e.designId, num: e.num, recordId: e.recordId }).then(ress => {
                                    //         if (ress.data.data) {
                                    //             obj[e.num] = ress.data.data
                                    //             e.height = ress.data.data.height
                                    //             e.width = ress.data.data.width
                                    //             e.x = ress.data.data.x
                                    //             e.y = ress.data.data.y
                                    //             e.pageWidth = ress.data.data.width
                                    //             e.pageHeight = ress.data.data.height
                                    //         }

                                    //         resolve(res.data.data)
                                    //     })
                                    // }))
                                } else {
                                    e.height = obj[e.num].height
                                    e.width = obj[e.num].width
                                    e.x = obj[e.num].x
                                    e.y = obj[e.num].y
                                    e.pageWidth = obj[e.num].width
                                    e.pageHeight = obj[e.num].height
                                }
                            }
                        })
                        Promise.all(arr).then((result) => {
                            that.isSBBolean = false;

                            that.boleanAll = false;
                            for (let as in that.boleanObj) {
                                that.boleanObj[as] = false;
                            }
                            if (that.currentNum != 0) {
                                that.tableData = that.resultList.filter(itemA => {
                                    return itemA.page.toString() == that.currentNum
                                })
                            } else {
                                that.tableData = that.resultList
                            }
                            that.isTwoCanvas = true;
                            setTimeout(() => {
                                that.loading[that.formActive.recordId] = false;
                                that.getTableResult();
                            })
                        })

                    } else {
                    }
                })

            })
        },
        getTableResult() {
            this.tableResultList = [];
            var tableResultArrAY = JSON.parse(JSON.stringify(this.resultList))
            console.log('resultList', tableResultArrAY);
            var isArray = { num: '' }
            // console.log(tableResultArrAY)
            tableResultArrAY.forEach((e, i) => {
                if (e.recordId == this.formActive.recordId) {
                    if (e.isTable == 1) {
                        if (isArray.num != e.num) {
                            isArray = JSON.parse(JSON.stringify(e))
                            isArray.tableList = [e]
                            this.tableResultList.push(isArray)
                        } else {
                            isArray.strokeList = isArray.strokeList.concat(e.strokeList)
                            isArray.tableList.push(e)
                            this.tableResultList[this.tableResultList.length - 1] = isArray
                        }
                    } else {
                        this.tableResultList.push(e)
                        isArray = { num: '' }
                    }
                }
            })
            if (this.currentNum != 0) {
                this.showTableTesult = this.tableResultList.filter(itemA => {
                    return itemA.page.toString() == this.currentNum
                })
            } else {
                this.showTableTesult = this.tableResultList
            }
            this.tableResultList.forEach(e => {
                if (e.tableList) {
                    var reuList = []
                    var reuObj = {}
                    var gxList = []
                    e.tableList.forEach(j => {
                        if (j.tableType == '单选' || j.tableType == '多选') {
                            if (j.identifyValue == 'Y') {
                                gxList.push(j.itemValue)
                            }
                        }
                        //else {
                        if (reuObj[j.rowNum]) {
                            reuObj[j.rowNum].push(j.identifyValue)
                        } else {
                            reuObj[j.rowNum] = [j.identifyValue]
                        }

                        // }
                    })
                    // if (e.tableType == '普通') {
                    console.log(reuObj);
                    for (let as in reuObj) {
                        e.xLen = reuObj[as].length
                        reuList.push(reuObj[as].join(','))
                    }
                    e.reuTxt = reuList.join(';')
                    // } else {
                    //     e.reuTxt = reuList.join(',')
                    // }
                    if (e.tableType == '单选' || e.tableType == '多选') {
                        e.gxTxt = gxList.join(',')
                    }
                    e.reuList = reuList;
                    e.yLen = reuList.length;
                    console.log(reuObj);
                }
            })
            console.log(this.tableResultList)
        },
        changePages(val) {
            this.isTwoCanvas = false;
            // console.log(this.resultList)
            // console.log(val)
            this.currentNum = val;
            this.tableData = this.resultList.filter(itemA => {
                return itemA.page.toString() == this.currentNum
            })
            this.showTableTesult = this.tableResultList.filter(itemA => {
                return itemA.page.toString() == this.currentNum
            })
            setTimeout(() => {
                // this.getTableResult()
                this.isTwoCanvas = true;
            })
        },
        changeRecord(e, br) {
            this.resultList = [];
            this.formCover = '';
            this.active = 2;
            for (let as in this.loading) {
                this.loading[as] = false;
            }
            setTimeout(() => {
                // console.log(e)
                this.tableData = []
                this.strokeList = []
                this.exLfStrList = []
                if (!br) {
                    this.formDesignList = [];
                    this.isCanvas = false;
                }
                // this.currentNum = 1;
                this.formActive = e;
                console.log(this.formActive);
                if (this.formActive.isBook == 1) {
                    formInfo({ formId: this.formActive.formId }).then(res => {
                        this.formCover = res.data.data.formCover
                    })
                }
                this.loading[this.formActive.recordId] = true;
                let arr = []
                formRecordDesign({ recordId: this.formActive.recordId }).then(response => {
                    this.exStrList = []
                    this.formDesignData = response.data.data
                    for (var i = 0; i < this.formActive.pageCount; i++) {
                        if (!br) {
                            this.formDesignList.push([])
                        }
                        var _this = this;
                        arr.push(new Promise(function (resolve, reject) {
                            strokeQuery({ pageNum: i + 1, recordId: _this.formActive.recordId }).then(res => {
                                resolve(res.data.data)
                            })
                        }))
                    }
                    Promise.all(arr).then((result) => {
                        // console.log(result)
                        this.strokeList = result;
                        this.isCanvas = true;
                        result.forEach((e, i) => {
                            this.exStrList.push({
                                pageNum: i + 1,
                                strokeList: e
                            })
                            if (!br) {
                                this.formDesignData.forEach(m => {
                                    if (m.page == (i + 1)) {
                                        this.formDesignList[i].push(m)
                                    }
                                })
                            }
                        })
                    })
                    if (!this.isSQ) {
                        queryLastIdentifyNoImg({ recordId: this.formActive.recordId }).then(res => {
                            // console.log(res.data.data)
                            this.resultList = [...res.data.data];
                            this.oldResultList = JSON.parse(JSON.stringify(res.data.data))

                            let arr = []
                            let obj = {}
                            this.resultList.forEach((e, i) => {
                                e.pageWidth = e.width
                                e.pageHeight = e.height
                                // if (e.isTable == 1 && e.tableType != '普通'){
                                //     e.identifyValue = e.itemValue;
                                // }
                                if (e.isTable == 1) {
                                    e.x1 = e.x;
                                    e.y1 = e.y;
                                    e.h1 = e.height;
                                    e.w1 = e.width;
                                    if (!obj.hasOwnProperty(e.num)) {

                                        // arr.push(new Promise(function (resolve, reject) {
                                        //     // console.log(obj.hasOwnProperty(e.num))
                                        //     obj[e.num] = {};
                                        //     // if (!obj.hasOwnProperty(e.num)) {
                                        //     tableInfo({ designId: e.designId, num: e.num, recordId: e.recordId }).then(ress => {
                                        //         // console.log(ress.data.data);
                                        //         if (ress.data.data) {
                                        //             obj[e.num] = ress.data.data
                                        //             // console.log(obj.hasOwnProperty(e.num))
                                        //             e.height = ress.data.data.height
                                        //             e.width = ress.data.data.width
                                        //             e.x = ress.data.data.x
                                        //             e.y = ress.data.data.y
                                        //             e.pageWidth = ress.data.data.width
                                        //             e.pageHeight = ress.data.data.height
                                        //         }

                                        //         resolve(res.data.data)
                                        //     })
                                        //     // }

                                        // }))
                                    } else {
                                        e.height = obj[e.num].height
                                        e.width = obj[e.num].width
                                        e.x = obj[e.num].x
                                        e.y = obj[e.num].y
                                        e.pageWidth = obj[e.num].width
                                        e.pageHeight = obj[e.num].height
                                    }
                                }
                            })
                            Promise.all(arr).then((result) => {
                                // console.log(obj);
                                if (this.currentNum != 0) {
                                    this.tableData = this.resultList.filter(itemA => {
                                        return itemA.page.toString() == this.currentNum
                                    })
                                } else {
                                    this.tableData = this.resultList
                                }
                                this.getTableResult();
                                this.loading[this.formActive.recordId] = false;
                                this.isTwoCanvas = true;
                            })
                        })
                    }
                }).catch(error => {

                })
            })
        },
        changeActive(num) {
            this.active = num
            // this.getTableResult()
            if (this.currentNum != 0) {
                this.tableData = this.resultList.filter(itemA => {
                    return itemA.page.toString() == this.currentNum
                })
            } else {
                this.tableData = this.resultList
            }
            if (num == 1) {
                this.changeTableResult()
            }
            if (num == 2) {

            }
        },
        handleCurrentChange(val) {
            // console.log(val)
            this.selectObj = val;
        },
        handleSelectionChange(val) {
            // console.log(val)
            this.selectList = val;
        },
        tgMethod(e) {
            this.queryParams.screenName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        getSceneList() {
            this.customList = []
            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                // this.customList = res.data.data;
                res.data.data.sort(function (a, b) {
                    return a.customName.localeCompare(b.customName);
                })
                res.data.data.forEach(e => {
                    if (e.status == 0) {
                        this.customList.push(e)
                    }
                })
                res.data.data.forEach(e => {
                    if (e.status == 1) {
                        this.customList.push(e)
                    }
                })
                this.queryParams.customId = res.data.data[0].customId;
                queryScene({ customId: this.$storage.get('customId') }).then(res => {
                    if (this.role.includes(0)) {
                        if (this.queryParams.customId !== '') {
                            res.data.data = res.data.data.filter(itemA => {
                                return itemA.customId == this.queryParams.customId
                            })
                        }
                    }
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.sceneType != 1;
                    })
                    this.sceneList = res.data.data;
                    this.queryParams.sceneId = res.data.data[0].sceneId
                    this.getList();
                });
            });

        },
        // 取消按钮
        cancel() {
            this.replace('/formRecord')
            // this.$emit('isTg', true)
        },
        getFormList() {
            formQuery({ customId: this.$storage.get('customId') }).then(res => {
                // res.data.data = res.data.data.filter(itemA => {
                //     return itemA.status == 4
                // })
                // if (this.queryParams.customId !== '') {
                //     res.data.data = res.data.data.filter(itemA => {
                //         return itemA.customId == this.queryParams.customId
                //     })
                // }
                if (this.queryParams.sceneId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.sceneId.toString().indexOf(this.queryParams.sceneId) != -1
                    })
                }
                console.log(this.formList);
                this.formList = res.data.data;
            });
        },
        /** 查询基础：基数数据列表 */
        getList(e) {
            if (e) {
                this.queryParams.pageNum = e.page;
                this.queryParams.pageSize = e.limit;
            }
            this.dataList = [];
            this.boleanAll = false;
            this.boleanObj = {};
            if (!this.role.includes(0)) {
                this.queryParams.customId = this.$storage.get('customId');
            }
            queryAllRecords(this.queryParams).then(res => {
                this.dataList = res.data.data.list;
                this.dataList.forEach(e => {
                    this.boleanObj[e.recordId] = false
                })
                this.total = res.data.data.total;
                if (this.dataList.length != 0) {
                    this.changeRecord(this.dataList[this.activeIndex])
                }
            });
        },

        // 停用
        handleStop(row) {
            if (!this.selectObj.formId) {
                this.$message.error("请选择数据");
                return;
            }
            formStop({ formId: this.selectObj.formId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("停用成功");
                    this.getList();
                } else {
                    this.$message.error("停用失败");
                }
            })
        },
        handleRelease() {
            if (!this.selectObj.formId) {
                this.$message.error("请选择数据");
                return;
            }
            formRelease({ formId: this.selectObj.formId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("发布成功");
                    this.getList();
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        handleXz() {
            if (!this.selectObj.formId) {
                this.$message.error("请选择数据");
                return;
            }
            download(this.selectObj.formUrl + '/original.pdf', this.selectObj.formName)
        },
        hadlePs() {
            if (!this.selectObj.formId) {
                this.$message.error("请选择数据");
                return;
            }
            let routeUrl = this.$router.resolve({
                path: '/ZBDesigner',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { userId: JSON.parse(this.$storage.get('userInfo')).userId, formId: this.selectObj.formId }
            })
            window.open(routeUrl.href, '_blank')

            // var url = "http://192.168.101.116:8080/?userId=" + JSON.parse(this.$storage.get('userInfo')).userId + "&formId=" + this.selectObj.formId
            // window.open(url)
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            deleteForm({ formId: row.formId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getList();
                } else {
                    this.$message.error("删除失败");
                }
            })
        },
        pdCmm(identifyName, identifyNameList, i) {
            if (identifyNameList.includes(identifyName + i)) {
                i = i + 1;
                this.pdCmm(identifyName, identifyNameList, i)
            } else {
                identifyNameList.push(identifyName + i)
                return (identifyName + i)
            }
        },
        goDetail(row) {
            this.$router.push({
                path: "/formRecordDetail",
                query: {
                    formId: row.formId,
                }
            });
        },
        /** 导出按钮操作 */
        handleExport() {
            this.elTitleList = [];
            const wb = XLSX.utils.book_new()
            var timestamp = timestampToTime(Date.parse(new Date()));
            let jlObj = {}
            var parentIdObj = {}
            var tableData = []
            var parentIdList = []
            console.log(this.formActive);
            var identifyNameList = []
            console.log('tableResultList', this.tableResultList, identifyNameList);
            if (this.formActive.isBook == 1) {
                this.tableResultList.forEach(e => {
                    var sxjg = ''
                    if (e.isTable == 0) {
                        sxjg = e.identifyValue
                    } else {
                        if (e.tableType && e.tableType != '普通') {
                            sxjg = e.gxTxt
                        } else {
                            sxjg = e.reuTxt
                        }
                    }
                    if (e.parentId) {
                        if (parentIdObj.hasOwnProperty(e.parentId)) {
                            parentIdObj[e.parentId].push(sxjg)
                        } else {
                            parentIdList.push({
                                label: e.identifyName,
                                prop: e.parentId,
                                width: 180
                            })
                            parentIdObj[e.parentId] = [sxjg]
                        }
                    } else {
                        var designId = e.designId.substr(0, 35)
                        if (identifyNameList.includes(e.identifyName)) {
                            var identifyName = ''
                            identifyName = this.pdCmm(e.identifyName, identifyNameList, 1)
                            console.log(identifyName, identifyNameList);
                            this.elTitleList.push({
                                label: identifyNameList[identifyNameList.length - 1],
                                prop: designId,
                                width: 180
                            })
                        } else {
                            identifyNameList.push(e.identifyName)
                            this.elTitleList.push({
                                label: e.identifyName,
                                prop: designId,
                                width: 180
                            })
                        }
                        parentIdObj[designId] = [sxjg]
                    }
                })
                console.log(this.elTitleList, identifyNameList);
                console.log(parentIdObj);
                var maxNum = 0
                Object.keys(parentIdObj).forEach(key => {
                    if (parentIdObj[key].length >= maxNum) {
                        maxNum = parentIdObj[key].length
                    }
                });

                for (var i = 0; i < maxNum; i++) {
                    tableData.push({})
                }
                if (this.elTitleList.length != 0) {
                    this.elTitleList.forEach((m) => {
                        tableData.forEach((j, k) => {
                            if (parentIdObj[m.prop][k]) {
                                tableData[k][m.label] = Number(parentIdObj[m.prop][k]) ? Number(parentIdObj[m.prop][k]) : parentIdObj[m.prop][k]
                            } else {
                                tableData[k][m.label] = ''
                            }
                        })
                    })
                } else {
                    parentIdList.forEach((m) => {
                        tableData.forEach((j, k) => {
                            if (parentIdObj[m.prop][k]) {
                                tableData[k][m.label] = Number(parentIdObj[m.prop][k]) ? Number(parentIdObj[m.prop][k]) : parentIdObj[m.prop][k]
                            } else {
                                tableData[k][m.label] = ''
                            }
                        })
                    })
                }
                var cjryname = '';
                if (this.formActive.member.memberName) {
                    cjryname = this.formActive.member.memberName;
                } else {
                    cjryname = this.formActive.member.memberAccount;
                }
                tableData.forEach((j, k) => {
                    tableData[k]['采集人员'] = cjryname;
                })
            } else {
                this.tableResultList.forEach(e => {
                    var sxjg = ''
                    if (e.isTable == 0) {
                        sxjg = e.identifyValue
                    } else {
                        if (e.tableType && e.tableType != '普通') {
                            sxjg = e.gxTxt
                        } else {
                            sxjg = e.reuTxt
                        }
                    }
                    var obj = {
                        '表单项': e.identifyName,
                        '书写结果': sxjg
                    }
                    tableData.push(obj)
                })
            }

            console.log(tableData);
            const data = XLSX.utils.json_to_sheet(tableData)//此处tableData.value为表格的数据
            for (const cell in data) {
                if (cell != '!ref') {
                    // console.log(XLSX.utils.decode_cell(cell));
                    if (XLSX.utils.decode_cell(cell).r == 0) {
                        data[cell]['s'] = {
                            border: {
                                bottom: { style: 'thin' },
                                left: { style: 'thin' },
                                right: { style: 'thin' },
                                top: { style: 'thin' }
                            },
                            font: {
                                bold: true
                            },
                            alignment: {
                                vertical: 'center',
                                horizontal: 'center'
                            },
                            fill: {
                                fgColor: { rgb: 'eeece1' }
                            }
                        }
                    } else {
                        data[cell]['s'] = {
                            border: {
                                bottom: { style: 'thin' },
                                left: { style: 'thin' },
                                right: { style: 'thin' },
                                top: { style: 'thin' }
                            },
                        }
                    }
                    // if(cell )

                }
                // console.log(data[cell]);
            }
            // const header = [
            //     [{
            //         v: this.formActive.formName,
            //         t: 's',
            //         s: {
            //             font: {
            //                 bold: true,
            //                 sz: 14,
            //                 name: '宋体'
            //             },
            //             // alignment 对齐方式
            //             alignment: {
            //                 vertical: 'center', // 垂直居中
            //                 horizontal: 'center', //水平居中
            //             },
            //             // fi11 颜色填充属性
            //             fill: {
            //                 fgColor: { rgb: '87CEEB' }
            //             }
            //         }
            //     }]
            // ]
            // data.unshift(...header);
            XLSX.utils.book_append_sheet(wb, data, this.formActive.recordName)//test-data为自定义的sheet表名
            var name = '';
            if (this.formActive.member.memberName) {
                name = this.formActive.member.memberName;
            } else {
                name = this.formActive.member.memberAccount;
            }
            if (this.formActive.isBook == 1) {
                XLSX.writeFile(wb, this.formActive.formName + '-' + name + '-' + this.formActive.recordName + '.xlsx')//test.xlsx为自定义的文件名
            } else {
                XLSX.writeFile(wb, this.formActive.formName + '-' + timestampToTime(this.formActive.createTime) + '-' + name + '.xlsx')//test.xlsx为自定义的文件名
            }
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                title: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
            // this.$refs['form'].resetFields()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.$router.push({
                path: "/formMainAdd",
            });
            // this.reset();
            // this.open = true;
            // this.form.px = 10;
            // this.title = "添加基础：基数数据";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.$router.push({
                path: "/formMainAdd",
                query: {
                    formId: row.formId,
                }
            });
        }
    }
};
</script>
<style scoped>
.tcBjs {
    background-color: #21ac74;
}

.el-table /deep/ .hidden-row {
    display: none;
}

.zsj /deep/ .el-input__inner {
    color: rgb(252, 3, 3);
    text-align: right;
    /* font-weight: bold; */
}

.tranaa /deep/ .el-input__inner {
    color: transparent;
    /* font-weight: bold; */
}

.el-input__wrapper {}

.mr0 /deep/ .el-form-item {
    margin-right: 0 !important;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.w-90 {
    margin-bottom: 15px;
    margin-top: 10px;
}

.w-90 /deep/ .el-card.is-always-shadow {
    width: 90%;
    margin: 0 auto;
    cursor: pointer;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.image {
    width: 100%;
    display: block;
}

.sfdff /deep/ .el-checkbox {
    transform: translateY(3px);
    height: auto !important;
}

.chosebtn {
    padding-top: 10px;
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
}

.chosebtn div {
    margin-right: 10px;
}

.chosebtn img {
    padding: 5px;

    margin: 0 auto;
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.bor-lan {
    border: 1px solid #21ac74
}

.wbba {
    word-break: break-all;
}

.cusdix {
    height: 28px;
    /* padding-left: 10px; */
    padding-right: 10px;
}

.inputStyle {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 99;

}

.inputStyle /deep/ .el-textarea__inner {
    height: 100% !important;
    color: unset !important;
    text-decoration: none !important;
    text-decoration-style: none !important;
    resize: none !important;
    outline: none !important;
    min-height: auto !important;
    padding: 0 2px !important;
}

.inputStyle /deep/ .el-select__popper.el-popper {
    padding: 0;
    z-index: 99999999 !important;
}

.inputStyle .el-input__prefix {
    display: inline-block;
    position: relative;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    padding: 0 30px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    padding-left: 30px;
    left: 0;
    visibility: hidden;
}

.inputStyle .el-input__inner {
    position: absolute;
}

.inputStyle /deep/ .el-textarea__inner {
    background: transparent;
    padding: 5px;
}

.inputStyle /deep/ .el-select {
    height: 100%;
}

.inputStyle /deep/ .select-trigger {
    height: 100%;
}

.inputStyle /deep/ .el-select .el-input {
    height: 100%;
}

.tran /deep/ .el-input__wrapper {
    background: transparent;
}

.pxbutton {
    padding: 7px 15px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #e4e7ed;
    font-size: 13px;
}

.fdsdf {
    padding-right: 10px;
    width: 85px;
}

/deep/ .pagination-container {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}

.zsybox {
    width: 40px;
    height: 40px;
    padding: 10px;
    line-height: 40px;
    border: 1px solid rgb(223, 223, 223);
}
</style>