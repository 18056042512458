<template>
    <div class="app-container" @click="visible = false;">
        <el-card>
            <div class="bre9e" style="position: relative;">
                <el-table header-cell-class-name="bgf2" highlight-current-row class="sssstt"
                    :height="'calc(100vh - ' + (isSxShow ? '167' : '167') + 'px)'" v-loading="loading" :data="dataList">
                    <el-table-column align="center" width="150px" label="模块编号" prop="moduleNo">
                        <template #default="scope">
                            <div :title="scope.row.moduleNo" class="text-one">
                                {{ scope.row.moduleNo }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="150px" label="模块名称" prop="moduleName">
                        <template #default="scope">
                            <div :title="scope.row.moduleName" class="text-one">
                                {{ scope.row.moduleName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="120px" label="默认标志" prop="isDefaultTxt">
                        <template #default="scope">
                            <div class="text-one" v-if="scope.row.isDefault == 0">
                                N
                            </div>
                            <div class="text-one" v-if="scope.row.isDefault == 1">
                                Y
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" fixed="right" width="150" label="编辑"
                        class-name="small-padding fixed-width">
                        <template #default="scope">
                            <el-button link icon="Edit" type="primary" @click="handleUpdate(scope.row)">修改
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <!-- <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
                :limit.sync="queryParams.pageSize" @pagination="getList" /> -->
        </el-card>
        <el-dialog :title="adminForm.moduleId ? '修改业务模块' : ''" v-model="isAddAdmin" width="40%">
            <el-form v-if="isAddAdmin" ref="adminForm" :model="adminForm" :rules="adminFormRules" label-width="120px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="模块名称" prop="moduleName">
                            <el-input v-model="adminForm.moduleName" placeholder="请输入模块名称" maxlength="64" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="是否默认" prop="isDefault">
                            <el-radio-group v-model="adminForm.isDefault">
                                <el-radio :label="0">否</el-radio>
                                <el-radio :label="1">是</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="adminSubmitForm">
                        确定
                    </el-button>
                    <el-button @click="isAddAdmin = false">
                        取消
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { bookList } from "@/api/adminservice/public"
const router = useRouter();
import {
    queryAllGroup,
    getData,
    delData,
    addData,
    updateData,
    exportData
} from "@/api/demo/data";
import { timestampToTime } from "@/utils/date";
import { moduleList, moduleUpdate, adminuserDelete } from "@/api/adminservice/custom";
let validatePhone = (rule, value, callback) => {
    if (!value) {
        return callback(new Error("手机号不能为空"))
    } else {
        const reg = /^1[3456789]\d{9}$/
        if (reg.test(value)) {
            callback();
        } else {
            return callback(new Error('请输入正确的手机号'));
        }
    }
}
export default {
    name: "businessModule",
    components: {
    },
    data() {
        return {
            isAddAdmin: false,
            adminForm: {},
            adminFormRules: {
                moduleName: [
                    { required: true, message: "请输入模块名称", trigger: "blur" },
                ],
                isDefault: [
                    { required: true, message: "请选择是否默认", trigger: "change" },
                ],
            },
            visible: false,
            isSxShow: true,
            isSuperAuth: this.$storage.get('isSuperAuth'),
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            readBook: false,
            readBookList: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                status: '',
                customName: '',
                isStop: '',
                customType: ''
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            }
        };
    },
    created() {
        this.getList();
        this.getBookList();
    },
    activated() {
        // console.log('fdssfd',this.$route.query.isSx)
        if (this.$route.query.isSx == 1) {
            this.getList();
        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        adminSubmitForm() {
            this.$refs["adminForm"].validate(valid => {
                if (valid) {
                    moduleUpdate(this.adminForm).then(res => {
                        if (res.data.code == 1) {
                            this.$message.success("操作成功");
                            this.isAddAdmin = false;
                            this.getList();
                        } else {
                            this.$message.error("操作失败");
                        }
                    })
                }
            }).catch(e => {
                console.log(e);
            })
        },
        readbookIds(row) {
            this.readBookList = [];
            this.bookList.forEach(e => {
                row.bookIds.forEach(j => {
                    if (e.id == j) {
                        this.readBookList.push(e)
                    }
                })
            })
            this.readBook = true;
        },
        getBookList() {
            bookList().then(res => {
                this.bookList = res.data.data;
            });
        },
        tgMethod(e) {
            this.queryParams.customName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        dateStringToTimestamp(str) {
            return new Date(str).getTime();
        },
        /** 查询基础：基数数据列表 */
        getList() {
            this.loading = false;
            moduleList().then(res => {
                this.dataList = res.data.data;
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            adminuserDelete({ adminId: row.id }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getList();
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.did);
            this.single = selection.length !== 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.adminForm = {
                roleName: '',
                roleType: 0,
                username: ''
            };
            this.isAddAdmin = true;
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            console.log(row);
            var obj = JSON.parse(JSON.stringify(row))
            this.adminForm = obj;
            this.adminForm.roleType = Number(obj.roleType);
            this.isAddAdmin = true;
        }
    }
};
</script>
<style scoped>
.sssstt /deep/ .el-scrollbar__view {
    display: block !important;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}
</style>