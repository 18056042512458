import request from "@/utils/request";

export const  userLogin =(dataObj) =>{
    return request({
        url: "/authservice/admin/login",
        method:"post",
        data:dataObj
    })
}

// 获取验证码
export const  requestCode =(data) =>{
    return request({
        url: "/authservice/admin/requestCode",
        method:"post",
        data: data
    })
}

// 用户重置密码
export const  resetPassword =(data) =>{
    return request({
        url: "/authservice/admin/resetPassword",
        method:"post",
        data: data
    })
}

// 用户注册
export const  register =(data) =>{
    return request({
        url: "/authservice/admin/register",
        method:"post",
        data: data
    })
}

export const  codeLogin =(data) =>{
    return request({
        url: "/authservice/admin/codeLogin",
        method:"post",
        data:data
    })
}

export const  resetPwd =(data) =>{
    return request({
        url: "/authservice/admin/resetPassword",
        method:"post",
        data:data
    })
}

export const  userHasReg =(username) =>{
    return request({
        url: "/authservice/admin/hasReg?username="+username,
        method:"get",
    })
}

export const  getMobileCode =(data) =>{
    return request({
        url: "/authservice/admin/requestCode",
        method:"post",
        data:data
    })
}

export const  isAuthGroup =(username) =>{
    return request({
        url: "/authservice/admin/isAdminUser?username="+username,
        method:"get",
    })
}

export const  scanCodeLogin =(username) =>{
    return request({
        url: "/webservice/scanCodeLogin",
        method:"get",
    })
}

export const  queryResult =(ewmcode) =>{
    return request({
        url: "/webservice/queryResult?ewmcode="+ewmcode ,
        method:"get",
    })
}
// 查询校区列表
export const  querySchoolList =(query) =>{
    return request({
        url: "/businessservice/orgPersonnel/querySchoolList",
        method:"get",
        params: query
    })
}