<template>
    <div class="app-container" @click="visible = false;">
        <el-card>
            <el-row>
                <el-col :span="24" class="mr0 mb0">
                    <div class="pull-right">
                        <el-popover :visible="visible" popper-style="width: 320px !important;" placement="bottom"
                            :min-width="'auto'">
                            <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true"
                                label-width="68px">
                                <el-row class="">
                                    <el-col :span="24" class="mr0"
                                        v-if="role.includes(0) || role.includes(1) || role.includes(2) || role.includes(3)">
                                        <el-form-item label="学校" prop="schoolId" class="w-100">
                                            <el-select style="width: 100%;" clearable @change="changeSchool"
                                                v-model="queryParams.schoolId" placeholder="请选择学校">
                                                <el-option v-for="item in schoolList" :key="item.schoolId"
                                                    :label="item.schoolName" :value="item.schoolId" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="">
                                        <el-form-item label="班级" prop="classId" class="w-100">
                                            <el-select style="width: 100%;" clearable @change="changeClass"
                                                v-model="queryParams.classId" placeholder="请选择班级">
                                                <el-option v-for="item in classList" :key="item.classId"
                                                    :label="item.className" :value="item.classId" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="">
                                        <el-form-item label="教辅名称" prop="termId" class="w-100">
                                            <el-select clearable class="w-100" @change="getChapterList()"
                                                v-model="queryParams.fileId" placeholder="请选择教辅">
                                                <el-option :label="item.fileName" :value="item.fileId"
                                                    v-for="item in eduFileList" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <div style="margin:0 auto;">
                                        <el-button @click="getList()" type="primary">查找</el-button>
                                    </div>
                                </el-row>
                            </el-form>
                            <template #reference>
                                <el-button type="primary" @click.stop="visible = true;">筛选条件</el-button>
                            </template>
                        </el-popover>
                    </div>
                </el-col>
            </el-row>
            <!-- <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
                <el-row class="">
                    <el-col :span="6" class="mr0"
                        v-if="role.includes(0) || role.includes(1) || role.includes(2) || role.includes(3)">
                        <el-form-item label="学校" prop="schoolId">
                            <el-select style="width: 100%;" clearable @change="changeSchool" v-model="queryParams.schoolId"
                                placeholder="请选择学校">
                                <el-option v-for="item in schoolList" :key="item.schoolId" :label="item.schoolName"
                                    :value="item.schoolId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="">
                        <el-form-item label="班级" prop="classId">
                            <el-select style="width: 100%;" clearable @change="changeClass" v-model="queryParams.classId"
                                placeholder="请选择班级">
                                <el-option v-for="item in classList" :key="item.classId" :label="item.className"
                                    :value="item.classId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="">
                        <el-form-item label="教辅名称" prop="termId">
                            <el-select clearable @change="getChapterList()" v-model="queryParams.fileId"
                                placeholder="请选择教辅">
                                <el-option :label="item.fileName" :value="item.fileId" v-for="item in eduFileList" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="role.includes(0) || role.includes(1) || role.includes(2) || role.includes(3) ? 6 : 12"
                        class="mb0">
                        <el-button style="width:100px" class="pull-right" type="primary" @click="getList()">查找</el-button>
                    </el-col>
                </el-row>
            </el-form>
            <el-divider style="position: relative;" v-if="isSxShow">
                <img @click="isSxShow = false;" style="transform: translateY(-100%);" class="sxShowcss"
                    src="../../assets/img/arrow-up.png" alt="">
            </el-divider> -->
            <!-- <el-row>
                <el-button style="width:150px" type="primary" @click="generateSet()">生成班级章节错题集</el-button>
            </el-row> -->
            <div class="bre9e mt15" style="position: relative;">
                <!-- <img @click="isSxShow = true;" v-if="!isSxShow" style="transform: translateY(-36px);" class="sxShowcss"
                    src="../../assets/img/arrow-down.png" alt=""> -->
                <el-table header-cell-class-name="bgf2" highlight-current-row class="sssstt"
                    :height="'calc(100vh - ' + (isSxShow ? '213' : '213') + 'px)'" v-loading="loading" :data="dataList">
                    <el-table-column align="center" type="index" width="60" prop="studentNo" label="序号">
                    </el-table-column>
                    <el-table-column align="center" label="章节名称" prop="chapterName">
                        <template #default="scope">
                            <div @click="openZjmc(scope.row)" :title="scope.row.chapterName" style="color: #409efe;"
                                class="text-one cursor">
                                {{ scope.row.chapterName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="100" label="批阅状态" prop="readStatus">
                        <template #default="scope">
                            <span v-if="scope.row.readStatus == 0">未批阅</span>
                            <span v-if="scope.row.readStatus == 1">批阅中</span>
                            <span v-if="scope.row.readStatus == 2">批阅完成</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="100" label="批阅数" prop="errorCount">
                        <template #default="scope">
                            {{ scope.row.readCount }} / {{ scope.row.stuCount }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="100" label="题数" prop="questionCount">
                        <template #default="scope">
                            {{ scope.row.questionCount }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="100" label="全对人数" prop="rightCount">
                        <template #default="scope">
                            <div @click="openQds(scope.row)" :title="scope.row.rightCount" style="color: #409efe;"
                                class="text-one cursor">
                                {{ scope.row.rightCount }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="100" label="全对率" prop="rightRate">
                        <template #default="scope">
                            {{ scope.row.rightRate }}%
                        </template>
                    </el-table-column>
                    <el-table-column align="center" fixed="right" width="200" label="操作"
                        class-name="small-padding fixed-width">
                        <template #default="scope">
                            <el-button style="width: 160px;" size="mini" type="text"
                                @click="generateStuSet(scope.row)">导出班级章节错题本
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>
        <el-dialog title="全对人数" v-model="scShow" width="600px">
            <div class="bre9e">
                <el-table header-cell-class-name="bgf2" highlight-current-row class="sssstt" :height="`calc(100vh - 400px)`"
                    v-loading="loading" :data="cwrsObj">
                    <el-table-column align="center" type="index" width="60" prop="studentNo" label="序号">
                    </el-table-column>
                    <el-table-column align="center" label="姓名" prop="studentName">
                        <template #default="scope">
                            {{ scope.row.studentName }}
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>
        <el-dialog :title="showTit" v-model="jfyl" width="80%">
            <div id="jfyl" style="height: calc(100vh - 330px); overflow-y: auto;background: rgb(243, 243, 243);">
                <el-row>
                    <el-col :span="12" v-for="item in kPage">
                        <div style="padding: 10px;">
                            <div
                                :style="'margin:0 auto;width: ' + tcimgwidth + 'px;height: ' + fileData.fileHeight / fileData.fileWidth * tcimgwidth + 'px;position: relative;'">
                                <img :style="'pointer-events: none;position:absolute;border:1px solid #eeeeee;border-radius:10px;width:' + tcimgwidth + 'px;left:0;height:' + fileData.fileHeight / fileData.fileWidth * tcimgwidth + 'px'"
                                    :src="fileData.imgFileUrl + '' + item + '.png_nostyle'" alt="">
                                <div v-for="items in kData[item]"
                                    :style="`position:absolute;left:${(items.x) / fileData.fileWidth * tcimgwidth + 1}px;top:${(items.y) / fileData.fileWidth * tcimgwidth}px;width:${(items.width) / fileData.fileWidth * tcimgwidth}px;height:${(items.height) / fileData.fileWidth * tcimgwidth}px;background:rgba(93, 201, 241, 0.5);border:1px solid #333;font-size:12px;overflow:hidden;color:red;`">
                                    {{ items.name }}
                                    <span style="color: #333;">错{{ items.errPNum }}人</span>
                                </div>
                            </div>

                        </div>
                    </el-col>
                </el-row>

            </div>
        </el-dialog>
    </div>
</template>

<script>
import { timestampToTime } from "@/utils/date";
import { download } from "@/api/adminservice/public";
import { readClassErrorReport, readChapterReport, eduFileInfo, fileAreaInfos, fileAreaQuestion } from "@/api/adminservice/readOver";
import { schoolUserAuthCondition, schoolListAuth, classList, zhktQueryDict, schoolList, schoolDelete, teacherList, teacherDelete } from "@/api/adminservice/classOrganizaMain";
import { eduFileList, chapterList } from "@/api/adminservice/readOver"
import {
    Document, ImageRun, Packer, Paragraph, HeadingLevel, TextRun, SymbolRun, AlignmentType, WidthType, BorderStyle, Table, TableRow, TableCell, convertInchesToTwip, VerticalAlign, TableLayoutType
} from 'docx';
import JSZip from "jszip";
import { commSetting } from "@/utils/date"
import { ElLoading } from 'element-plus'

const createHeading = (text1, text2) => {
    return new Paragraph({
        alignment: AlignmentType.CENTER,
        heading: HeadingLevel.HEADING_1,
        children: [
            new TextRun({
                text: text1,
            }),
        ],
    });
};
const createZw = (text1) => {
    return new Paragraph({
        alignment: AlignmentType.LEFT,
        heading: HeadingLevel.HEADING_4,
        children: [
            new TextRun({
                text: text1,
            }),
        ],
    });
};
const createKg = (text1) => {
    return new Paragraph({
        alignment: AlignmentType.LEFT,
        heading: HeadingLevel.HEADING_4,
        children: [
            new TextRun({
                text: '',
                break: 3,
            }),
        ],
    });
};
// 生成tablecell图片
const createImg = (photo, width, height, type = 0) => {
    return new Paragraph({
        children: [
            new ImageRun({
                data: Buffer.from(photo, 'base64'),
                transformation: {
                    width,
                    height,
                },
            }),
        ],
    });
}

export default {
    name: "ChapterReport",
    components: {

    },
    data() {
        return {
            visible: false,
            loadingInstance: null,
            isSxShow: true,
            tcimgwidth: 400,
            showTit: '',
            fileData: {},
            jfyl: false,
            schoolList: [],
            eduFileList: [],
            chapterList: [],
            cwrsObj: {},
            chapterName: '',
            className: '',
            fileName: '',
            token: this.$storage.get("token"),
            // 遮罩层
            loading: false,
            // 非单个禁用
            single: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                schoolId: '',
                classId: '',
                fileId: '',
                chapterId: '',
                sortType: 0
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            schoolId: '',
            scShow: false,
            classList: [],
            role: '',
            wdcTxt: '',
            kPage: [],
            kData: {},
        };
    },
    created() {
        // this.getList();

        schoolUserAuthCondition().then(res => {
            if (!this.role.includes(0) && !this.role.includes(1) && !this.role.includes(2) && !this.role.includes(3)) {
                this.classList = res.data.data.classList;
            }
        })
        this.role = this.$storage.get("role")
        this.getZhktQueryDict();
        // if (this.role.includes(0) || this.role.includes(1)) {
        //     this.getList();
        // }
        // this.getList();
    },
    activated() {
        // console.log('fdssfd',this.$route.query.isSx)
        if (this.$route.query.isSx == 1) {
            this.getList();
        }
    },
    mounted() {
    },
    methods: {
        openZjmc(row) {
            this.kPage = [];
            this.kData = {}
            var objList = []
            row.errorQuestionList.forEach(e => {
                objList.push(e.areaId)
            })
            objList = Array.from(new Set(objList));
            var obj = {}
            this.loadingInstance = ElLoading.service({
                lock: true,
                text: '加载中',
                background: 'rgba(0, 0, 0, 0.7)',
            })
            fileAreaInfos({ areaIds: objList.toString() }).then(respn => {
                respn.data.data.forEach(e => {
                    obj[e.areaId] = e
                })
                console.log(obj);
                for (var i = row.statPage; i <= row.endPage; i++) {
                    this.kPage.push(i)
                    this.kData[i] = []
                    row.errorQuestionList.forEach(e => {
                        obj[e.areaId].questionList.forEach(j => {
                            if (i == j.page) {
                                j.name = e.name;
                                j.areaId = e.areaId;
                                if (e.errList) {
                                    j.errPNum = e.errList.length;
                                } else {
                                    j.errPNum = 0;
                                }

                                this.kData[i].push(j)
                            }
                        })
                    })
                }
                console.log(this.kPage, this.kData);
                this.chapterName = row.chapterName
                console.log(row);
                this.showTit = this.chapterName + '-' + this.className;
                eduFileInfo({ fileId: this.queryParams.fileId }).then(res => {
                    this.fileData = res.data.data;
                    this.jfyl = true;
                    setTimeout(() => {
                        this.tcimgwidth = (document.getElementById("jfyl").clientWidth) / 2 - 20;
                        console.log(this.tcimgwidth);
                        // this.areaData.forEach((e, i) => {
                        //     var reftxt = 'areaDatatable' + i;
                        //     e.forEach((j, k) => {
                        //         if (j.chapterId == this.chapterId) {
                        //             this.$refs[reftxt][0].toggleRowSelection(e[k], true);
                        //         }
                        //     })
                        // })
                        this.loadingInstance.close()
                    })
                }).catch(error => {
                    console.log(error);
                    this.loadingInstance.close()
                })
            }).catch(error => {
                console.log(error);
                this.loadingInstance.close()
            })


        },
        openQds(row) {
            console.log(row);
            this.cwrsObj = row.rightStuList;
            this.scShow = true;
        },
        generateSet() {
            if (this.dataList.length == 0) {
                this.$message.warning('当前列表没有数据')
                return
            }
            var ctList = []
            this.dataList.forEach(e => {
                if (e.errorCount && e.errorCount != 0) {
                    ctList.push(e)
                }
            })
            if (ctList.length == 0) {
                this.$message.warning('当前列表没有错题')
                return
            }
            let arr = [];
            var _this = this;

            let zip = new JSZip();//初始化
            zip.generateAsync({ type: "blob" }).then(function (content) {
                ctList.forEach(row => {
                    var wtxt = row.errorQuestionList[0].chapterName + '-' + row.studentName + '错题本';
                    var children = []
                    children.push(createHeading(wtxt))
                    row.errorQuestionList.forEach(e => {
                        children.push(createZw(e.name))
                        e.questionList.forEach(j => {
                            if (j.image) {
                                var xj = j.image.split(',')
                                if (e.fileWidth < e.fileHeight) {
                                    children.push(createImg(xj[1], j.width * (600 / e.fileWidth), j.height * (600 / e.fileWidth), 1))
                                } else {
                                    children.push(createImg(xj[1], (j.width * (600 / e.fileWidth)) * 2, (j.height * (600 / e.fileWidth)) * 2, 1))
                                }
                            }
                        })
                        children.push(createKg())
                    })
                    var documents = new Document({
                        ...commSetting,
                        sections: [
                            {
                                children: children,
                            },
                        ],
                    });
                    arr.push(new Promise(function (resolve, reject) {

                        Packer.toBlob(documents).then((blob) => {
                            zip.file(wtxt + ".docx", blob);
                            resolve('')
                        });
                    }))

                })
                Promise.all(arr).then((result) => {
                    zip.generateAsync({ type: "blob" }).then(function (content) {
                        //使用blob下载zip
                        saveAs(content, _this.wdcTxt + ".zip");

                    });
                })
            });
        },
        generateStuSet(rows) {
            console.log(rows);
            if (rows.errorStuList.length == 0) {
                this.$message.warning('当前章节没有错题')
                return
            }
            var objList = []
            this.dataList.forEach(e => {
                e.errorStuList.forEach(j => {
                    j.questionList.forEach(m => {
                        objList.push(m.areaId)
                    })
                })
            })
            objList = Array.from(new Set(objList));
            var obj = {}
            this.loadingInstance = ElLoading.service({
                lock: true,
                text: '导出中',
                background: 'rgba(0, 0, 0, 0.7)',
            })
            fileAreaInfos({ areaIds: objList.toString() }).then(res => {
                res.data.data.forEach(e => {
                    obj[e.areaId] = e
                })
                console.log(obj);
                var wtxtzip = rows.chapterName + '-' + this.className + '错题本';
                var children = []
                let arr = [];
                var _this = this;
                let zip = new JSZip();//初始化
                zip.generateAsync({ type: "blob" }).then(function (content) {
                    rows.errorStuList.forEach(row => {
                        var wtxt = row.questionList[0].chapterName + '-' + row.studentName + '错题本';
                        var children = []
                        children.push(createHeading(wtxt))
                        row.questionList.forEach(e => {
                            children.push(createZw(e.name))
                            obj[e.areaId].questionList.forEach(j => {
                                if (j.image) {
                                    var xj = j.image.split(',')
                                    if (e.fileWidth < e.fileHeight) {
                                        children.push(createImg(xj[1], j.width * (600 / e.fileWidth), j.height * (600 / e.fileWidth), 1))
                                    } else {
                                        children.push(createImg(xj[1], (j.width * (600 / e.fileWidth)) * 2, (j.height * (600 / e.fileWidth)) * 2, 1))
                                    }
                                }
                            })
                            children.push(createKg())
                        })
                        var documents = new Document({
                            ...commSetting,
                            sections: [
                                {
                                    children: children,
                                },
                            ],
                        });
                        arr.push(new Promise(function (resolve, reject) {

                            Packer.toBlob(documents).then((blob) => {
                                zip.file(wtxt + ".docx", blob);
                                resolve('')
                            });
                        }))

                    })
                    Promise.all(arr).then((result) => {
                        _this.loadingInstance.close()
                        zip.generateAsync({ type: "blob" }).then(function (content) {
                            //使用blob下载zip
                            saveAs(content, wtxtzip + ".zip");

                        });
                    })
                });

            })



            // children.push(createHeading(wtxt))
            // row.errorQuestionList.forEach(e => {
            //     children.push(createZw(e.name))
            //     e.questionList.forEach(j => {
            //         // 600
            //         if (j.image) {
            //             var xj = j.image.split(',')
            //             // console.log(xj[1]);
            //             if (e.fileWidth < e.fileHeight) {
            //                 children.push(createImg(xj[1], j.width * (600 / e.fileWidth), j.height * (600 / e.fileWidth), 1))
            //             } else {
            //                 children.push(createImg(xj[1], (j.width * (600 / e.fileWidth)) * 2, (j.height * (600 / e.fileWidth)) * 2, 1))
            //             }
            //         }
            //     })
            // })
            // var documents = new Document({
            //     ...commSetting,
            //     sections: [
            //         {
            //             children: children,
            //         },
            //     ],
            // });

            // Packer.toBlob(documents).then((blob) => {
            //     const x = new XMLHttpRequest()
            //     x.open('GET', URL.createObjectURL(blob), true)
            //     x.responseType = 'blob'
            //     x.onload = function () {
            //         const url = window.URL.createObjectURL(x.response)
            //         const a = document.createElement('a')
            //         a.href = url
            //         a.download = wtxt + '.docx'
            //         a.click()
            //     }
            //     x.send()
            // });
        },
        openCwrs(row) {
            this.cwrsObj = row;
            this.scShow = true;
        },
        getChapterList() {
            this.dataList = [];
            this.queryParams.chapterId = '';
            this.chapterList = [];
            this.fileName = '';
            if (this.queryParams.fieldId) {
                this.eduFileList.forEach(e => {
                    if (e.fieldId == this.queryParams.fieldId) {
                        this.fileName = e.fileName;
                    }
                })
            }
            if (this.queryParams.fileId) {
                chapterList({ fileId: this.queryParams.fileId }).then(res => {
                    this.chapterList = res.data.data;
                })
            }

        },
        changeSchool() {
            this.dataList = [];
            this.classList = [];
            this.eduFileList = [];
            this.chapterList = [];
            this.queryParams.classId = '';
            this.queryParams.fileId = '';
            this.queryParams.chapterId = '';

            if (this.queryParams.schoolId) {
                classList({ customId: this.$storage.get('customId'), schoolId: this.queryParams.schoolId }).then(res => {
                    this.classList = res.data.data;
                    // this.getList();
                });
                eduFileList(this.queryParams).then(res => {
                    this.eduFileList = res.data.data;
                })
            }


        },
        changeClass() {
            this.dataList = [];
            this.eduFileList = [];
            this.chapterList = [];
            this.queryParams.grade = '';
            this.queryParams.fileId = '';
            this.queryParams.chapterId = '';
            console.log(this.classList);
            this.eduFileList = [];
            if (this.queryParams.classId) {
                this.classList.forEach(e => {
                    if (e.classId == this.queryParams.classId) {
                        this.className = e.className
                        this.wdcTxt = e.className
                        if (e.schoolId) {
                            this.queryParams.schoolId = e.schoolId;
                            this.wdcTxt = e.schoolName + '' + this.wdcTxt;
                        }
                        if (e.grade) {
                            this.queryParams.grade = e.grade;
                        }
                        eduFileList(this.queryParams).then(res => {
                            this.eduFileList = res.data.data;
                        })
                    }
                })

            }
        },
        getZhktQueryDict() {

            schoolListAuth({ customId: this.$storage.get('customId'), adminUserId: ((this.role.includes(3) || this.role.includes(2)) && !this.role.includes(1)) ? JSON.parse(this.$storage.get('userInfo')).userId : '' }).then(res => {
                this.schoolList = res.data.data;
                if (this.schoolList && this.schoolList.length != 0) {
                    this.queryParams.schoolId = this.schoolList[0].schoolId
                    this.changeSchool()
                }
                // this.getList();
            });
            // classList({ customId: this.$storage.get('customId'), schoolId: this.queryParams.schoolId }).then(res => {
            //     this.classList = res.data.data;
            // });
        },
        /** 查询基础：基数数据列表 */
        getList() {
            // this.queryParams = {
            //     chapterId: "1784422498310426624",
            //     classId: "1747519902052200449",
            //     fileId: "1784407361583190016",
            //     schoolId: "1747519773521948673",
            //     sortType: 1
            // }
            this.dataList = [];
            if (this.role.includes(0) || this.role.includes(1) || this.role.includes(2) || this.role.includes(3)) {
                if (!this.queryParams.schoolId) {
                    this.$message.warning("请选择学校")
                    return;
                }
            }
            if (!this.queryParams.classId) {
                this.$message.warning("请选择班级")
                return;
            }
            if (!this.queryParams.fileId) {
                this.$message.warning("请选择教辅名称")
                return;
            }

            if (!this.role.includes(0)) {
                this.queryParams.customId = this.$storage.get('customId');
            }
            this.loading = true;
            readChapterReport(this.queryParams).then(res => {
                this.dataList = res.data.data;
                this.loading = false;
                this.dataList.forEach(e => {
                    e.errorQuestionList.forEach(j => {
                        // fileAreaQuestion({ areaId: j.areaId }).then(respon => {
                        //     j.questionList = respon.data.data;
                        // })
                        j.errList = [];
                        e.errorStuList.forEach(m => {
                            m.questionList.forEach(n => {
                                if (n.areaId == j.areaId) {
                                    j.errList.push(n)
                                }
                            })
                        })
                    })
                })
                console.log(this.dataList)
            })
        },

    }
};
</script>
<style scoped>
.disnone /deep/ .el-upload-list {
    display: none;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.sssstt /deep/ .el-scrollbar__view {
    display: block !important;
}
</style>