import request from "@/utils/request";
// 创建教辅资料
export const  eduFileCreate =(data) =>{
    return request({
        url: "/adminservice/zhkt/eduFile/file/create",
        method:"post",
        ContentType:'multipart/form-data',
        data:data
    })
}
// 删除教辅资料
export const  eduFileDel =(query) =>{
    return request({
        url: "/adminservice/zhkt/eduFile/file/delete",
        method:"get",
        params: query
    })
}
// 删除教辅资料（文件标识）
export const  eduFileDelById =(query) =>{
    return request({
        url: "/adminservice/zhkt/eduFile/eduFile/deleteById",
        method:"get",
        params: query
    })
}
// 查询教辅资料详情
export const  eduFileInfo =(query) =>{
    return request({
        url: "/adminservice/zhkt/eduFile/file/info",
        method:"get",
        params: query
    })
}
// 查询教辅资料列表
export const  eduFileList =(data) =>{
    return request({
        url: "/adminservice/zhkt/eduFile/file/list",
        method:"post",
        data: data
    })
}
// 更新教辅资料
export const  eduFileUpdate =(data) =>{
    return request({
        url: "/adminservice/zhkt/eduFile/file/update",
        method:"post",
        data: data
    })
}
// 批量修改教辅资料章节
export const  eduChapterEdit =(data) =>{
    return request({
        url: "/adminservice/zhkt/eduFile/chapter/edit",
        method:"post",
        data: data
    })
}
// 删除教辅资料章节
export const  eduChapterDelete =(query) =>{
    return request({
        url: "/adminservice/zhkt/eduFile/chapter/delete",
        method:"get",
        params: query
    })
}
// 查询教辅资料章节列表
export const  chapterList =(query) =>{
    return request({
        url: "/adminservice/zhkt/eduFile/chapter/list",
        method:"get",
        params: query
    })
}
// 查询教辅资料批阅区域列表
export const  fileAreaList =(data) =>{
    return request({
        url: "/adminservice/zhkt/eduFile/fileArea/list",
        method:"post",
        data: data
    })
}
// 教辅资料批阅区域配置章节
export const  fileAreaConfig =(data) =>{
    return request({
        url: "/adminservice/zhkt/eduFile/fileArea/config",
        method:"post",
        data: data
    })
}
// 删除教辅资料批阅区域
export const  fileAreaDel =(query) =>{
    return request({
        url: "/adminservice/zhkt/eduFile/fileArea/delete",
        method:"get",
        params: query
    })
}
// 新增或修改教辅资料批阅区域
export const  fileAreaEdit =(data) =>{
    return request({
        url: "/adminservice/zhkt/eduFile/fileArea/edit",
        method:"post",
        data: data
    })
}
// 移除批阅区域章节信息
export const  fileAreaRemove =(query) =>{
    return request({
        url: "/adminservice/zhkt/eduFile/fileArea/remove",
        method:"get",
        params: query
    })
}
// 教辅资料批阅区域排序
export const  fileAreaSort =(data) =>{
    return request({
        url: "/adminservice/zhkt/eduFile/fileArea/sort",
        method:"post",
        data: data
    })
}
// 班级错题报告
export const  readClassErrorReport =(data) =>{
    return request({
        url: "/adminservice/zhkt/read/classErrorReport",
        method:"post",
        data: data
    })
}
// 班级学生报告
export const  readClassStuReport =(data) =>{
    return request({
        url: "/adminservice/zhkt/read/classStuReport",
        method:"post",
        data: data
    })
}
// 班级批阅对比统计
export const  readClassReadStat =(data) =>{
    return request({
        url: "/adminservice/zhkt/read/classReadStat",
        method:"post",
        data: data
    })
}
// 班级错题对比统计
export const  readClassErrorStat =(data) =>{
    return request({
        url: "/adminservice/zhkt/read/read/classErrorStat",
        method:"post",
        data: data
    })
}
// 教辅章节批阅报告
export const  readChapterReport =(data) =>{
    return request({
        url: "/adminservice/zhkt/read/chapterReport",
        method:"post",
        data: data
    })
}
// 复制教辅资料
export const  eduFileCopy =(data) =>{
    return request({
        url: "/adminservice/zhkt/eduFile/file/copy",
        method:"post",
        data: data
    })
}

// 查询批阅区域及题目信息
export const  fileAreaInfos =(query) =>{
    return request({
        url: "/adminservice/zhkt/eduFile/fileArea/infos",
        method:"get",
        params: query
    })
}

// 查询批阅区域题目信息
export const  fileAreaQuestion =(query) =>{
    return request({
        url: "/adminservice/zhkt/eduFile/fileArea/question",
        method:"get",
        params: query
    })
}