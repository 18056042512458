<template>
    <el-button @click="jfhl" size="small" round plain
        style="position: absolute;top: 0;left: 50%;transform: translate(47px, 20px);color: #409Eff;border-color: #409Eff;">
        均分行列
    </el-button>

    <div class="grid" style="grid-template-columns:1fr auto 1fr;grid-template-rows:1fr auto 1fr;overflow: auto;">
        <div style="position: relative;">
            <div style="display: flex;display: flex;position: absolute;right: 0;">
                <el-popover :visible="isOpen" trigger="click" placement="bottom" :width="160">
                    <el-form :model="form" label-width="auto">
                        <el-form-item label="行">
                            <el-input-number v-model="form.row" :min="1" :max="100" />
                        </el-form-item>
                        <el-form-item label="列">
                            <el-input-number v-model="form.col" :min="1" :max="100" />
                        </el-form-item>
                    </el-form>
                    <div style="text-align: right; margin: 0">
                        <el-button size="small" text @click="isOpen = false">关闭</el-button>
                        <el-button @click="sumbitRowCol" size="small" type="primary">确定</el-button>
                    </div>
                    <template #reference>
                        <el-button style="height: 60px;color: #409Eff;border-color: #409Eff;" @click="changeShow">输入行列</el-button>
                    </template>
                </el-popover>
            </div>
        </div>
        <div class="grid" style="margin:0 auto 5px auto;" :style="`width:${getSpace(table.region.columDefinitions, table.region.rectangle.Width)
            .aggregate(0, this.mergeLength)}px;height:50px;grid-template-columns:${getSpace(table.region.columDefinitions, table.region.rectangle.Width)
                .aggregate(String(), this.mergeString)}`">
            <div style="display: flex;flex-direction:column;justify-content:space-between;"
                v-for="(item, index) in table.region.columDefinitions.concat([table.region.rectangle.Width])" :key="item">
                <el-button style="margin-bottom: 5px;height: 25px;" v-if="index == 0" class="but" type="info"
                    disabled>-</el-button>
                <el-button style="margin-bottom: 5px;height: 25px;" v-else class="but" type="info"
                    @click="() => { removeCol(index - 1) }">-</el-button>
                <el-button style="margin-left: 0 !important;height: 25px;" class="but" type="success"
                    @click="() => { insertCol(index) }">+</el-button>
            </div>
        </div>
        <div></div>
        <div class="grid" style="margin:0 0 0 auto;" :style="`height:${getSpace(table.region.rowDefinitions, table.region.rectangle.Height)
            .aggregate(0, this.mergeLength)}px;height:20px;grid-template-rows:${getSpace(table.region.rowDefinitions, table.region.rectangle.Height)
                .aggregate(String(), this.mergeString)}`">
            <div style="display: flex;justify-content:space-between;"
                v-for="(item, index) in table.region.rowDefinitions.concat([table.region.rectangle.Height])" :key="item">
                <el-button v-if="index === 0" class="but" type="info" disabled>-</el-button>
                <el-button v-else class="but" type="info" @click="() => { removeRow(index - 1) }">-</el-button>
                <el-button class="but" type="primary" @click="() => { insertRow(index) }">+</el-button>
            </div>
        </div>
        <div style="margin: auto;">
            <div class="grid" :style="`grid-template-columns:${getSpace(table.region.columDefinitions, table.region.rectangle.Width)
                .aggregate(String(), this.mergeString)};grid-template-rows:${getSpace(table.region.rowDefinitions, table.region.rectangle.Height)
                    .aggregate(String(), this.mergeString)}`">
                <div class="fill" v-for="item in getAllCells(table.region)" :key="item">
                    <el-popover width="200" :placement="placement" :trigger="trigger">
                        <template #reference>
                            <div class="fill" style="border: 1px solid #b7b7b7;" :oncontextmenu="rightClick"></div>
                        </template>
                        <template #default>
                            <el-descriptions direction="vertical" :column="2" border>
                                <el-descriptions-item label="X">
                                    {{ (item.rectangle.Left * scale + offset).toFixed(2) }}
                                    <!-- <el-statistic :value="item.rectangle.Left * scale + offset" /> -->
                                </el-descriptions-item>
                                <el-descriptions-item label="Y">
                                    {{ (item.rectangle.Top * scale + offset).toFixed(2) }}
                                    <!-- <el-statistic :value="item.rectangle.Top * scale + offset" /> -->
                                </el-descriptions-item>
                                <el-descriptions-item label="宽">
                                    {{ (item.rectangle.Width * scale + offset).toFixed(2) }}
                                    <!-- <el-statistic :value="item.rectangle.Width * scale + offset" /> -->
                                </el-descriptions-item>
                                <el-descriptions-item label="高">
                                    {{ (item.rectangle.Height * scale + offset).toFixed(2) }}
                                    <!-- <el-statistic :value="item.rectangle.Height * scale + offset" /> -->
                                </el-descriptions-item>
                            </el-descriptions>
                        </template>
                    </el-popover>
                </div>
            </div>
        </div>
        <!-- <div class="grid" style="margin:0 auto 0 0;" :style="`height:${getSpace(table.region.rowDefinitions, table.region.rectangle.Height)
            .aggregate(0, this.mergeLength)}px;height:20px;grid-template-rows:${getSpace(table.region.rowDefinitions, table.region.rectangle.Height)
                .aggregate(String(), this.mergeString)}`">
            <div v-for="(item, index) in table.region.rowDefinitions.concat([table.region.rectangle.Height])" :key="item">
                <div v-if="index === 0"></div>
                <el-button v-else class="but" type="danger" @click="() => { removeRow(index - 1) }">×</el-button>
            </div>
        </div> -->
        <!-- <div></div> -->
        <!-- <div class="grid" style="margin:0 auto 0 auto;" :style="`width:${getSpace(table.region.columDefinitions, table.region.rectangle.Width)
            .aggregate(0, this.mergeLength)}px;height:20px;grid-template-columns:${getSpace(table.region.columDefinitions, table.region.rectangle.Width)
                .aggregate(String(), this.mergeString)}`">
            <div v-for="(item, index) in table.region.columDefinitions.concat([table.region.rectangle.Width])" :key="item">
                <div v-if="index === 0"></div>
                <el-button v-else class="but" type="danger" @click="() => { removeCol(index - 1) }">×</el-button>
            </div>
        </div> -->
        <!-- <div></div> -->
    </div>
</template>

<script>
import { Rect } from "../../utils/drawing/rect";

export default {
    props: {
        table: null,
        scaled: {
            type: Number,
            default: 1,
        },
        offset: {
            type: Number,
            default: 0,
        }
    },
    data() {
        this.table.region.columDefinitions.orderBy(x => x);
        this.table.region.rowDefinitions.orderBy(x => x);
        let scale = this.scaled * 3;
        return {
            form: {
                row: 1,
                col: 1
            },
            placement: 'top-end',
            trigger: 'click',
            scale,
            isOpen: false,
        }
    },
    methods: {
        sumbitRowCol() {
            this.table.region.rowDefinitions = [];
            this.table.region.columDefinitions = [];
            console.log(this.table.region.rectangle.Height / this.form.row)
            console.log(this.table.region.rectangle.width / this.form.col)
            for (var i = 1; i < this.form.row; i++) {
                console.log(i);
                this.table.region.rowDefinitions.push((this.table.region.rectangle.Height / this.form.row * i).toFixed(2));
            }
            for (var i = 1; i < this.form.col; i++) {
                console.log(i);
                this.table.region.columDefinitions.push((this.table.region.rectangle.width / this.form.col * i).toFixed(2));
            }
            console.log(this.table);
            this.isOpen = false;
        },
        changeShow() {
            console.log(this.table);
            this.form.col = this.table.region.columDefinitions.length + 1;
            this.form.row = this.table.region.rowDefinitions.length + 1;
            this.isOpen = true;
        },
        jfhl() {
            this.form.col = this.table.region.columDefinitions.length + 1;
            this.form.row = this.table.region.rowDefinitions.length + 1;
            this.table.region.rowDefinitions = [];
            this.table.region.columDefinitions = [];
            for (var i = 1; i < this.form.row; i++) {
                console.log(i);
                this.table.region.rowDefinitions.push((this.table.region.rectangle.Height / this.form.row * i).toFixed(2));
            }
            for (var i = 1; i < this.form.col; i++) {
                console.log(i);
                this.table.region.columDefinitions.push((this.table.region.rectangle.width / this.form.col * i).toFixed(2));
            }
        },
        rightClick(...args) {
        },
        getSpace(array, edge) {
            console.log(array);
            let arr = [];
            for (let i = 0; i < array.length; i++) {
                let curr = array[i];
                arr.push((curr - (i === 0 ? 0 : array[i - 1])) * this.scale);
            }
            arr.push((edge - (arr.any() ? array.last() : 0)) * this.scale);
            return arr;
        },
        mergeString: (str, val) => {
            str += `${val}px `;
            return str;
        },
        mergeLength: (sum, val) => {
            return sum += (val + 4);
        },
        getAllCells(region) {
            return region.getCells();
        },
        insertRow(index) {
            console.log(this.table.region.rowDefinitions[index - 1])

            let top = index === 0 ? 0 : Number(this.table.region.rowDefinitions[index - 1]);
            let bottom = index === this.table.region.rowDefinitions.length
                ? this.table.region.rectangle.Height
                : Number(this.table.region.rowDefinitions[index]);
            this.table.region.rowDefinitions.insert(index, ((top + bottom) / 2).toFixed().toInt());
        },
        insertCol(index) {
            console.log(this.table.region.columDefinitions[index - 1])
            let left = index === 0 ? 0 : Number(this.table.region.columDefinitions[index - 1]);
            let right = index === this.table.region.columDefinitions.length
                ? this.table.region.rectangle.Width
                : Number(this.table.region.columDefinitions[index]);
            this.table.region.columDefinitions.insert(index, (((left + right) / 2).toFixed()).toInt());
        },
        removeRow(index) {
            this.table.region.rowDefinitions.removeAt(index);
        },
        removeCol(index) {
            this.table.region.columDefinitions.removeAt(index);
        }
    }
}
</script>

<style scoped>
.fill {
    margin: 0;
    height: 100%;
    width: 100%;
}

.but {
    height: 100%;
    width: 100%;
    font-size: 20px;
}

.grid {
    display: grid;
    padding: 5px;
    grid-gap: 5px 5px;
}

.fill :hover {
    background-color: rgba(56, 196, 142, 0.78);
    transition-delay: 0.1s;
    transition-duration: 0.25s;
}</style>