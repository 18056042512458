<template>
    <div class="app-container pt0Im pl0Im">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>奖评管理</a></el-breadcrumb-item>
                <el-breadcrumb-item>奖评详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-card>
            <el-descriptions :column="3" class="detailFF" title="基本信息">
                <el-descriptions-item width="33.3%" label="奖评类别">{{ form.awardContent }}</el-descriptions-item>
                <el-descriptions-item width="33.3%" label="学校">{{ form.schoolName }}</el-descriptions-item>
                <el-descriptions-item width="33.3%" label="星级数"><el-rate style="transform: translateY(4px);" disabled v-model="form.starNum" /></el-descriptions-item>
                <el-descriptions-item width="100%" label="教授班级">{{ form.gradeClassIsmTxt }}</el-descriptions-item>
            </el-descriptions>
        </el-card>
    </div>
</template>

<script>
import { validatenull, isMobile } from '@/utils/validate'
import { formGroupForm } from "@/api/adminservice/form"
import { bookList } from "@/api/adminservice/public"
import { queryCustoms, queryTreeCustomOrg, createCustoms, custominfo, configCustom, customOrgBook } from "@/api/adminservice/custom"
import { qunQueryUser, qunCreate, qunUpdate, queryCustomQun, queryQunInfo } from "@/api/adminservice/qun"
import { awardInfo, zhktQueryDict, schoolCreate, schoolUpdate, schoolInfo, teacherQueryOrRegUser, teacherCreate, teacherUpdate, teacherDelete } from "@/api/adminservice/classOrganizaMain"
import { download } from "@/api/adminservice/public";

export default {
    name: "commentSetDetail",
    components: {},
    data() {
        return {
            awardId: '',
            isCreateTeacher: false,
            token: this.$storage.get("token"),
            classIsmList: [],
            memberList: [],
            formList: [],
            customId: '',
            groupData: {},
            id: '',
            schoolId: '',
            readBook: false,
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            subjectList: [],
            teacherForm: {
                teacherNo: '',
                username: '',
                teacherName: '',
                subject: '',
                subjectList: [],
                schoolId: '',
                gender: ''
            },
            teacherFormRules: {
                username: [
                    { required: true, message: "请输入账号", trigger: "blur" },
                    { validator: this.validateUsername, trigger: "blur" }],
                teacherName: [
                    { required: true, message: "请输入教师姓名", trigger: "blur" },
                ],
                subjectList: [
                    { required: true, message: "请选择学科", trigger: "change" },
                ],
                gender: [
                    { required: true, message: "请选择性别", trigger: "change" },
                ]
            },
            // 表单参数
            form: {
                adminName: '',
                adminUserId: '',
                adminUsername: '',
                customId: '',
                gradeClassIsm: '',
                schoolName: '',
                shortName: '',
                gradeClassIsmList: [],
                gradeClassIsm: '',
                gradeClassIsmTxt: '',
            },
            tableData: [{
                adminAccount: '',
                orgName: ''
            }],
            // 表单校验
            rules: {
                // customId:[
                //     { required: true, message: "请选择客户", trigger: "change" },
                // ],
                // gradeClassIsmList:[
                //     { required: true, message: "请选择年级班制", trigger: "change" },
                // ],
                // schoolName: [
                //     { required: true, message: "请输入学校名称", trigger: "blur" },
                // ],
                // shortName:[
                //     { required: true, message: "请输入学校简称", trigger: "blur" },
                // ],
                // adminUsername: [
                //     { required: true, message: "请输入管理员账号", trigger: "blur" },
                //     { validator: this.validateUsername, trigger: "blur" }
                // ],
                // adminName: [
                //     { required: true, message: "请输入管理员姓名", trigger: "blur" },
                // ],
            },
            bookList: [],
            customList: [],
            orgList: [],
            oldBookIds: [],
            oldFromIds: [],
            chooseBookIds: [],
            chooseBook: false,
            id: ''
        };
    },
    created() {
        this.getZhktQueryDict()
    },
    mounted() {
        this.getList()

        if (this.$route.query.awardId) {
            this.awardId = this.$route.query.awardId;
            this.getInfo()
        } else {
        }
    },
    computed: {
    },
    methods: {
        deleteTeacher(row) {
            teacherDelete({ teacherId: row.teacherId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getInfo()
                } else {
                    this.$message.error("操作失败")
                }
            })
        },
        validateUsername(rule, value, callback) {
            if (!isMobile(this.teacherForm.username)) {
                callback(new Error("手机号码格式不正确"))
            }
            teacherQueryOrRegUser({ username: this.teacherForm.username, trueName: this.teacherForm.teacherName, gender: this.teacherForm.gender }).then(res => {
                const result = res.data.data
                if (!result) {
                    callback(new Error("请输入正确账号"))
                } else {
                    if (this.teacherForm.teacherName == '') {
                        this.teacherForm.teacherName = res.data.data.trueName
                    }
                    if (this.teacherForm.gender == '') {
                        this.teacherForm.gender = res.data.data.gender
                    }
                    callback();
                }
            })
        },
        teacherSubmitForm() {
            this.$refs["teacherForm"].validate(valid => {
                if (valid) {
                    console.log(1);
                    this.teacherForm.schoolId = this.schoolId;
                    this.teacherForm.subject = this.teacherForm.subjectList.join(',')
                    if (this.teacherForm.teacherId) {
                        teacherUpdate(this.teacherForm).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("修改成功");
                                this.isCreateTeacher = false;
                                this.getInfo()
                            } else {
                                this.$message.error("操作失败")
                            }
                        });
                    } else {
                        teacherCreate(this.teacherForm).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("新增成功");
                                this.isCreateTeacher = false;
                                this.getInfo()
                            } else {
                                this.$message.error("操作失败")
                            }
                        });
                    }

                }
            });
        },
        editTeacherDetail(row) {
            this.teacherForm = JSON.parse(JSON.stringify(row));
            console.log(this.teacherForm);
            this.isCreateTeacher = true;
        },
        downloadTemplate() {
            download('https://zbh-oss-formal.oss-cn-beijing.aliyuncs.com/common/excel/%E6%99%BA%E6%85%A7%E8%AF%BE%E5%A0%82%E8%80%81%E5%B8%88%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx', '导入教师模板')
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
        },
        handleChange(e, type) {
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    this.$message.success('导入成功')
                    this.getInfo()
                } else {
                    if (e.response.msg) {
                        this.$message.error(e.response.msg)
                    } else {
                        this.$message.error('导入失败')
                    }
                }
            }
            if (e.status == 'fail') {
                this.$message.error('导入失败')
            }
        },
        newaddTeacher() {
            this.teacherForm = {
                teacherNo: '',
                username: '',
                teacherName: '',
                subject: '',
                subjectList: [],
                schoolId: '',
                gender: ''
            }
            this.isCreateTeacher = true;
        },
        getZhktQueryDict() {
            zhktQueryDict({ code: 'grade_class_ism' }).then(res => {
                this.classIsmList = res.data.data;
            })
            zhktQueryDict({ code: 'subject' }).then(res => {
                this.subjectList = res.data.data;
            })
        },
        checkMemberValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            var result = true;
            for (var i = 0; i < this.memberList.length; i++) {
                var e = this.memberList[i];
                if (e.username == '' || e.memberName == '') {
                    result = false
                    break;
                }
            }
            if (result) {
                callback()
            } else {
                callback(new Error('请填写完整信息'))
            }
        },
        getInfo() {
            awardInfo({ awardId: this.awardId }).then(res => {
                this.form = res.data.data;
                this.form.gradeClassIsmTxt = '';
                var gragradeClassIsmTxt = [];
                this.form.classList.forEach(j => {
                    gragradeClassIsmTxt.push(j.className)
                })
                this.form.gradeClassIsmTxt = gragradeClassIsmTxt.toString(',')
            })
        },
        validateMemberUsername(value) {
            qunQueryUser({ mobile: value.username }).then(res => {
                const result = res.data.data
                if (!result) {
                    value.username = ''
                    value.memberName = ''
                    this.$message.error("请输入正确账号")
                } else {
                    if (value.memberName == '') {
                        value.memberName = res.data.data.nickName
                    }

                    value.userId = res.data.data.userId
                }
            })
        },
        // validateUsername(rule, value, callback) {
        //     qunQueryUser({ mobile: value }).then(res => {
        //         const result = res.data.data
        //         if (!result) {
        //             this.form.adminName = ''
        //             this.form.adminUserId = ''
        //             callback(new Error("该账号用户不存在"))
        //         } else {
        //             if (this.form.adminName == '') {
        //                 this.form.adminName = res.data.data.nickName;
        //             }
        //             this.form.adminUserId = res.data.data.userId;
        //             callback()
        //         }
        //     })
        // },
        changeCustom() {
            this.formList = [];
            this.orgList = [];
            this.form.formIds = [];
            queryTreeCustomOrg({ customId: this.form.customId }).then(res => {
                this.orgList = res.data.data;
                res.data.data.forEach((e, i) => {
                    e.label = e.orgName
                    e.value = e.orgId
                    e.children = e.childrenList;
                    e.disabled = true
                    this.ergodicList(e.childrenList)
                })
                console.log(res.data.data)
                this.orgList = res.data.data;
            })
            formGroupForm({ customId: this.form.customId }).then(res => {
                this.formList = res.data.data;
                res.data.data.forEach(e => {
                    this.oldFromIds.forEach(j => {
                        if (e.formId == j) {
                            this.form.formIds.push(e.formId)
                        }
                    })
                })
            })
        },
        deleteMemberRow(index) {
            this.memberList.splice(index, 1)
        },
        onMemberAddItem() {
            this.memberList.push({
                username: '',
                memberName: ''
            })
        },
        onAddItem() {
            console.log(this.tableData)
            this.tableData.push({
                adminAccount: '',
                orgName: ''
            })
        },
        /** 查询基础：基数数据列表 */
        getList() {
            this.loading = false;

            // bookList().then(res => {
            //     this.bookList = res.data.data;
            // });
            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                this.customList = res.data.data;
            });
        },


        /** 提交按钮 */
        submitForm() {
            console.log(this.form)
            this.$refs["form"].validate(valid => {
                if (valid) {
                    console.log(this.form)
                    this.form.gradeClassIsm = this.form.gradeClassIsmList && this.form.gradeClassIsmList.join(",")
                    if (this.$route.query.schoolId) {
                        schoolUpdate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                this.replace('/classOrganizaMain', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        });
                    } else {
                        schoolCreate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("新增成功");
                                this.replace('/classOrganizaMain', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            this.replace('/commentSet')
            // this.$emit('isTg', true)
        },
        ergodicList(childrenList) {
            if (childrenList.length != 0) {
                childrenList.forEach(e => {
                    e.label = e.orgName
                    e.value = e.orgId
                    console.log(this.findParentNode(e.orgId))
                    if (this.findParentNode(e.orgId).indexOf(2) == -1) {
                        e.disabled = true
                    }
                    if (e.orgType == 5) {
                        // debugger
                        e.disabled = true
                        console.log(e)
                    }
                    if (e.childrenList.length != 0) {
                        e.children = e.childrenList
                        this.ergodicList(e.childrenList)
                    }
                })
            }
        },
        // id 指的是当前点击的节点id;
        findParentNode(id) {
            // debugger
            // 初始化所需数据
            var firstParentObj = {}; // 记录直系父级的名称和id即接口要传的数据
            var parentIds = []; // 记录所有的父级ids
            var parentIdsQueue = []; // 记录排队的

            // 将树放到排队系列
            var parentIdsQueue = JSON.parse(JSON.stringify(this.orgList));

            // 开始遍历排队的树
            while (parentIdsQueue.length) {
                //抽取第一个排队的数据 
                let item = parentIdsQueue.shift();

                let { childrenList } = item;
                if (item.orgId === id) {
                    // 第一层就找到了
                    if (!item.parentArr) {
                        firstParentObj = {
                            id: item.orgId,
                            name: item.orgType
                        };
                        parentIds = [item.orgType];
                    } else {
                        // 获取当前节点的parentArr
                        let len = item.parentArr.length;
                        firstParentObj = item.parentArr[len - 1];
                        item.parentArr.forEach(a => {
                            parentIds.push(a.name);
                        });
                        parentIds.push(item.orgType);
                    }

                    // 结束遍历
                    parentIdsQueue = [];

                    break;
                } else if (childrenList && childrenList.length) {
                    let len = childrenList.length;
                    for (let i = len - 1; i >= 0; i--) {
                        // 新建一个数组用于记录它的父亲节点
                        childrenList[i].parentArr = [];

                        // 把它的历史父亲节点们先放入
                        if (item.parentArr) {
                            childrenList[i].parentArr = childrenList[i].parentArr.concat(
                                item.parentArr
                            );
                        }

                        // 再放入当前的父亲节点
                        childrenList[i].parentArr.push({
                            id: item.orgId,
                            name: item.orgType
                        });

                        // 加入到排队序列中
                        parentIdsQueue.unshift(childrenList[i]);
                    }

                }
            }
            return parentIds

        },
    }
};
</script>
<style scoped>
.disnone /deep/ .el-upload-list {
    display: none;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}

.detailFF /deep/ .el-input__suffix {
    display: none;
}

.detailFF /deep/ .el-select .el-input.is-disabled .el-input__wrapper {
    cursor: unset;
    background: transparent;
    border: 0;
    box-shadow: none;
    padding: 0;
}

.detailFF /deep/ .el-select .el-select__tags-text {
    color: #333;
}

.detailFF /deep/ .el-select .el-input.is-disabled .el-input__inner {
    color: #333;
    -webkit-text-fill-color: #333;
}

.detailFF /deep/ .el-input.is-disabled {
    cursor: unset;
}
</style>