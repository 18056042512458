<template>
    <div class="app-container pt0Im">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>班级管理</a></el-breadcrumb-item>
                <el-breadcrumb-item v-if="!classId">新增班级</el-breadcrumb-item>
                <el-breadcrumb-item v-if="classId">编辑班级</el-breadcrumb-item>

            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 160px);overflow: auto;">
            <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="班级名称" prop="className">
                            {{ form.className }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="年级" prop="grade">
                            {{ form.grade }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="班主任" prop="leadTeacherId">
                            <el-select style="width: 100%;" clearable @change="changeLeadTeacher" v-model="form.leadTeacherId" placeholder="请选择教师">
                                <el-option v-for="item in teacherList" :key="item.teacherId" :label="item.teacherName"
                                    :value="item.teacherId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="性别" prop="gender">
                            {{ form.gender == 0?'男':form.gender == 1?'女':'' }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="班主任账号" prop="username">
                            {{ form.username }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="所属学校" prop="schoolName">
                            {{ form.schoolName }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="所属客户" prop="customName">
                            {{ form.customName }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- <el-row>
                    <el-col>
                        <el-form-item label="群成员" prop="memberList">
                            <el-table ref="memberList" border :data="memberList" style="width: 600px" max-height="250">
                                <el-table-column align="center" prop="username" label="成员账号">
                                    <template #default="scope">
                                        <el-input @blur="validateMemberUsername(scope.row)" v-model="scope.row.username"
                                            placeholder="最多16个字" maxlength="16" />
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" prop="memberName" label="成员姓名">
                                    <template #default="scope">
                                        <el-input v-model="scope.row.memberName" placeholder="最多16个字" maxlength="16" />
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" fixed="right" label="操作" width="160">
                                    <template #default="scope">
                                        <el-button link type="primary" size="small"
                                            @click.prevent="deleteMemberRow(scope.$index)">
                                            删除
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div style="width: 100%;">
                                <el-button plain type="primary" class="mt10" style="width: 600px"
                                    @click="onMemberAddItem">增加</el-button>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row> -->
            </el-form>
        </el-card>

        <div>
            <div class="disflex-row-center cusbtbtn" :style="$store.state.zdy.isActive ? 'left:64px;' : 'left:201px;'">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submitForm">保 存</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { formGroupForm } from "@/api/adminservice/form"
import { bookList } from "@/api/adminservice/public"
import { queryCustoms, queryTreeCustomOrg, createCustoms, custominfo, configCustom, customOrgBook } from "@/api/adminservice/custom"
import { qunQueryUser, qunCreate, qunUpdate, queryCustomQun, queryQunInfo } from "@/api/adminservice/qun"
import { teacherList,zhktQueryDict,schoolListAuth,schoolCreate,schoolUpdate,schoolInfo,classInfo,classUpdate } from "@/api/adminservice/classOrganizaMain"

export default {
    name: "demoAdd",
    components: {},
    data() {
        return {
            classIsmList: [],
            memberList: [],
            formList: [],
            customId: '',
            groupData: {},
            id: '',
            classId:'',
            readBook: false,
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 表单参数
            form: {
                classId: '',
                className: '',
                classNo:'',
                grade:'',
                leadTeacherId:'',
                schoolId:'',
                schoolType:'',
            },
            tableData: [{
                adminAccount: '',
                orgName: ''
            }],
            // 表单校验
            rules: {
                leadTeacherId:[
                    { required: true, message: "请选择班主任", trigger: "change" },
                ],
            },
            bookList: [],
            customList: [],
            orgList: [],
            oldBookIds: [],
            oldFromIds: [],
            chooseBookIds: [],
            chooseBook: false,
            id: '',
            teacherList:[],
        };
    },
    created() {
    },
    mounted() {
        this.getList()

        if (this.$route.query.classId) {
            this.classId = this.$route.query.classId;
            this.getInfo()
            this.getZhktQueryDict()

        } else {
            this.getZhktQueryDict()
        }
    },
    computed: {
    },
    methods: {
        changeLeadTeacher(){
            this.form.gender = null
            this.form.username = ''
            console.log(this.form.leadTeacherId);
            this.teacherList.forEach(e=>{
                if(e.teacherId == this.form.leadTeacherId){
                    console.log(e);
                    this.form.gender = e.gender;
                    this.form.username = e.username; 
                }
            })
        },
        getZhktQueryDict() {
            zhktQueryDict({ code: 'grade_class_ism' }).then(res => {
                this.classIsmList = res.data.data;
            })
            teacherList({ customId: this.$storage.get('customId'),classId:this.classId,pageNum: 1,pageSize: 10000000, }).then(res => {
                this.teacherList = res.data.data.list;
            })
        },
        checkMemberValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            var result = true;
            for (var i = 0; i < this.memberList.length; i++) {
                var e = this.memberList[i];
                if (e.username == '' || e.memberName == '') {
                    result = false
                    break;
                }
            }
            if (result) {
                callback()
            } else {
                callback(new Error('请填写完整信息'))
            }
        },
        getInfo() {
            classInfo({ classId: this.classId }).then(res => {
                this.form = res.data.data;
                // this.form.gradeClassIsmList = this.form.gradeClassIsm.split(',')
            })
        },
        validateMemberUsername(value) {
            qunQueryUser({ mobile: value.username }).then(res => {
                const result = res.data.data
                if (!result) {
                    value.username = ''
                    value.memberName = ''
                    this.$message.error("请输入正确账号")
                } else {
                    if(value.memberName == ''){
                        value.memberName = res.data.data.nickName
                    }
                    
                    value.userId = res.data.data.userId
                }
            })
        },
        validateUsername(rule, value, callback) {
            qunQueryUser({ mobile: value }).then(res => {
                const result = res.data.data
                if (!result) {
                    this.form.adminName = ''
                    this.form.adminUserId = ''
                    callback(new Error("该账号用户不存在"))
                } else {
                    if(this.form.adminName == ''){
                        this.form.adminName = res.data.data.nickName;
                    }
                    this.form.adminUserId = res.data.data.userId;
                    callback()
                }
            })
        },
        changeCustom() {
            this.formList = [];
            this.orgList = [];
            this.form.formIds = [];
            queryTreeCustomOrg({ customId: this.form.customId }).then(res => {
                this.orgList = res.data.data;
                res.data.data.forEach((e, i) => {
                    e.label = e.orgName
                    e.value = e.orgId
                    e.children = e.childrenList;
                    e.disabled = true
                    this.ergodicList(e.childrenList)
                })
                console.log(res.data.data)
                this.orgList = res.data.data;
            })
            formGroupForm({ customId: this.form.customId }).then(res => {
                this.formList = res.data.data;
                res.data.data.forEach(e => {
                    this.oldFromIds.forEach(j => {
                        if (e.formId == j) {
                            this.form.formIds.push(e.formId)
                        }
                    })
                })
            })
        },
        deleteMemberRow(index) {
            this.memberList.splice(index, 1)
        },
        onMemberAddItem() {
            this.memberList.push({
                username: '',
                memberName: ''
            })
        },
        onAddItem() {
            console.log(this.tableData)
            this.tableData.push({
                adminAccount: '',
                orgName: ''
            })
        },
        /** 查询基础：基数数据列表 */
        getList() {
            this.loading = false;

            // bookList().then(res => {
            //     this.bookList = res.data.data;
            // });
            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                this.customList = res.data.data;
            });
        },


        /** 提交按钮 */
        submitForm() {
            console.log(this.form)
            this.$refs["form"].validate(valid => {
                if (valid) {
                    console.log(this.form)
                    this.form.gradeClassIsm = this.form.gradeClassIsmList &&  this.form.gradeClassIsmList.join(",")
                    if (this.$route.query.classId) {
                        classUpdate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                this.replace('/classManagement', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        });
                    } else {
                        // schoolCreate(this.form).then(res => {
                        //     if (res.data.code == 1) {
                        //         this.$message.success("新增成功");
                        //         this.replace('/classManagement', { isSx: 1 })
                        //         // this.$emit('isTg', true);
                        //     } else {
                        //         this.$message.error("操作失败")
                        //     }

                        // });
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            this.replace('/classManagement')
            // this.$emit('isTg', true)
        },
        ergodicList(childrenList) {
            if (childrenList.length != 0) {
                childrenList.forEach(e => {
                    e.label = e.orgName
                    e.value = e.orgId
                    console.log(this.findParentNode(e.orgId))
                    if (this.findParentNode(e.orgId).indexOf(2) == -1) {
                        e.disabled = true
                    }
                    if (e.orgType == 5) {
                        // debugger
                        e.disabled = true
                        console.log(e)
                    }
                    if (e.childrenList.length != 0) {
                        e.children = e.childrenList
                        this.ergodicList(e.childrenList)
                    }
                })
            }
        },
        // id 指的是当前点击的节点id;
        findParentNode(id) {
            // debugger
            // 初始化所需数据
            var firstParentObj = {}; // 记录直系父级的名称和id即接口要传的数据
            var parentIds = []; // 记录所有的父级ids
            var parentIdsQueue = []; // 记录排队的

            // 将树放到排队系列
            var parentIdsQueue = JSON.parse(JSON.stringify(this.orgList));

            // 开始遍历排队的树
            while (parentIdsQueue.length) {
                //抽取第一个排队的数据 
                let item = parentIdsQueue.shift();

                let { childrenList } = item;
                if (item.orgId === id) {
                    // 第一层就找到了
                    if (!item.parentArr) {
                        firstParentObj = {
                            id: item.orgId,
                            name: item.orgType
                        };
                        parentIds = [item.orgType];
                    } else {
                        // 获取当前节点的parentArr
                        let len = item.parentArr.length;
                        firstParentObj = item.parentArr[len - 1];
                        item.parentArr.forEach(a => {
                            parentIds.push(a.name);
                        });
                        parentIds.push(item.orgType);
                    }

                    // 结束遍历
                    parentIdsQueue = [];

                    break;
                } else if (childrenList && childrenList.length) {
                    let len = childrenList.length;
                    for (let i = len - 1; i >= 0; i--) {
                        // 新建一个数组用于记录它的父亲节点
                        childrenList[i].parentArr = [];

                        // 把它的历史父亲节点们先放入
                        if (item.parentArr) {
                            childrenList[i].parentArr = childrenList[i].parentArr.concat(
                                item.parentArr
                            );
                        }

                        // 再放入当前的父亲节点
                        childrenList[i].parentArr.push({
                            id: item.orgId,
                            name: item.orgType
                        });

                        // 加入到排队序列中
                        parentIdsQueue.unshift(childrenList[i]);
                    }

                }
            }
            return parentIds

        },
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}
</style>