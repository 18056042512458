import request from "@/utils/request";

// 创建活动
export const activityCreate =(data) =>{
    return request({
        url: "/adminservice/zbhsign/activity/create",
        method:"post",
        data: data
    })
}

// 删除活动
export const activityDelete =(query) =>{
    return request({
        url: "/adminservice/zbhsign/activity/delete",
        method:"get",
        params: query
    })
}

// 查询活动详情
export const activityInfo =(query) =>{
    return request({
        url: "/adminservice/zbhsign/activity/info",
        method:"get",
        params: query
    })
}

// 查询活动详情无需登录
export const activityInfoNoLogin =(query) =>{
    return request({
        url: "/authservice/admin/activity/info",
        method:"get",
        params: query
    })
}

// 分页查询活动列表
export const activityList =(data) =>{
    return request({
        url: "/adminservice/zbhsign/activity/list",
        method:"post",
        data: data
    })
}

// 发布或取消发布活动
export const activityReleaseOrCancel =(query) =>{
    return request({
        url: "/adminservice/zbhsign/activity/releaseOrCancel",
        method:"get",
        params: query
    })
}

// 分享或取消分享活动
export const activityShareOrCancel =(query) =>{
    return request({
        url: "/adminservice/zbhsign/activity/shareOrCancel",
        method:"get",
        params: query
    })
}

// 结束或取消结束活动
export const activityStopOrCancel =(query) =>{
    return request({
        url: "/adminservice/zbhsign/activity/stopOrCancel",
        method:"get",
        params: query
    })
}

// 更新活动
export const activityUpdate =(data) =>{
    return request({
        url: "/adminservice/zbhsign/activity/update",
        method:"post",
        data: data
    })
}

// 删除活动图片素材
export const activityFileDelete =(query) =>{
    return request({
        url: "/adminservice/zbhsign/activityFile/delete",
        method:"get",
        params: query
    })
}

// 上传活动图片素材
export const activityFileUpload =(data) =>{
    return request({
        url: "/adminservice/zbhsign/activityFile/upload",
        method:"post",
        ContentType:'multipart/form-data',
        data: data
    })
}
// 查询签到本列表
export const zbhsignBookList =(query) =>{
    return request({
        url: "/adminservice/zbhsign/book/list",
        method:"get",
        params: query
    })
}
// 活动素材分享或取消分享
export const activityFileShareOrCancel =(query) =>{
    return request({
        url: "/adminservice/zbhsign/activityFile/shareOrCancel",
        method:"get",
        params: query
    })
}
// 删除活动书写页
export const activityDeleteWritePage =(data) =>{
    return request({
        url: "/adminservice/zbhsign/activity/deleteWritePage",
        method:"post",
        data: data
    })
}
// 活动图片素材置顶置顶
export const activityFileTop =(query) =>{
    return request({
        url: "/adminservice/zbhsign/activityFile/top",
        method:"get",
        params: query
    })
}
// 活动图片素材设置主题图片
export const activityFileTheme =(query) =>{
    return request({
        url: "/adminservice/zbhsign/activityFile/theme",
        method:"get",
        params: query
    })
}
// 取消设置主题图片
export const activityFileCancelTheme =(query) =>{
    return request({
        url: "/adminservice/zbhsign/activityFile/cancelTheme",
        method:"get",
        params: query
    })
}