import request from "@/utils/request";

// 创建备课
export const lessonCreate =(data) =>{
    return request({
        url: "/adminservice/zhkt/lesson/create",
        method:"post",
        data: data
    })
}

// 删除备课信息
export const lessonDelete =(query) =>{
    return request({
        url: "/adminservice/zhkt/lesson/delete",
        method:"get",
        params: query
    })
}

// 删除备课文件
export const lessonDeleteFile =(query) =>{
    return request({
        url: "/adminservice/zhkt/lesson/deleteFile",
        method:"get",
        params: query
    })
}

// 查询备课信息
export const lessonInfo =(query) =>{
    return request({
        url: "/adminservice/zhkt/lesson/info",
        method:"get",
        params: query
    })
}

// 查询备课列表
export const lessonList =(data) =>{
    return request({
        url: "/adminservice/zhkt/lesson/list",
        method:"post",
        data: data
    })
}

// 发布备课
export const lessonRelease =(query) =>{
    return request({
        url: "/adminservice/zhkt/lesson/lesson/release",
        method:"get",
        params: query
    })
}

// 更新备课
export const lessonUpdate =(data) =>{
    return request({
        url: "/adminservice/zhkt/lesson/update",
        method:"post",
        data: data
    })
}

// 上传备课文件
export const lessonUpload =(data) =>{
    return request({
        url: "/adminservice/zhkt/lesson/upload",
        method:"post",
        data: data
    })
}

// 创建答题卡
export const answerCardCreate =(data) =>{
    return request({
        url: "/adminservice/zhkt/answerCard/create",
        method:"post",
        data: data
    })
}

// 删除答题卡
export const answerCardDelete =(query) =>{
    return request({
        url: "/adminservice/zhkt/answerCard/delete",
        method:"get",
        params: query
    })
}

// 查询答题卡信息
export const answerCardInfo =(data) =>{
    return request({
        url: "/adminservice/zhkt/answerCard/info",
        method:"post",
        data: data
    })
}

// 更新答题卡
export const answerCardUpdate =(data) =>{
    return request({
        url: "/adminservice/zhkt/answerCard/update",
        method:"post",
        data: data
    })
}

// 创建反馈卡
export const backCardCreate =(data) =>{
    return request({
        url: "/adminservice/zhkt/backCard/create",
        method:"post",
        data: data
    })
}

// 删除反馈卡
export const backCardDelete =(query) =>{
    return request({
        url: "/adminservice/zhkt/backCard/delete",
        method:"get",
        params: query
    })
}

// 查询反馈卡信息
export const backCardInfo =(query) =>{
    return request({
        url: "/adminservice/zhkt/backCard/info",
        method:"get",
        params: query
    })
}

// 更新反馈卡
export const backCardUpdate =(data) =>{
    return request({
        url: "/adminservice/zhkt/backCard/update",
        method:"post",
        data: data
    })
}

// 上传反馈卡内容图
export const backCardUpload =(data) =>{
    return request({
        url: "/adminservice/zhkt/backCard/upload",
        method:"post",
        data: data
    })
}

// 查询用户教师身份列表
export const userTeachers =(query) =>{
    return request({
        url: "/adminservice/zhkt/teacher/userTeachers",
        method:"get",
        params: query
    })
}

// 查询互动课堂智能本列表
export const  lessonListBook =(query) =>{
    return request({
        url: "/adminservice/zhkt/lesson/listBook",
        method:"get",
        params: query
    })
}