<template>
    <el-switch size="large" width="70" inline-prompt active-value="horizontal" active-text="行数据" inactive-value="vertical"
        inactive-text="列数据" @change="changeSwitch" style="--el-switch-on-color: #31acee; --el-switch-off-color: #4ad71b;position: absolute;top: 0;left: 50%;transform: translate(47px, 12px);"
        v-model="table.modes.fielddirection"></el-switch>
    <div class="grid" :style="'grid-template-columns:'+(table.modes.fielddirection === 'horizontal'?'auto':'1fr')+' auto 1fr;grid-template-rows:'+(table.modes.fielddirection === 'horizontal'?'1fr':'auto')+' auto 1fr;overflow: auto;'">
        <div class="js1" v-if="table.modes.fielddirection === 'vertical'"></div>
        <div v-if="table.modes.fielddirection === 'vertical'" class="grid" style="margin:0 auto 10px auto;" :style="`width:${getSpace(table.region.columDefinitions, table.region.rectangle.Width)
            .aggregate(0, this.mergeLength)}px;height:70px;grid-template-columns:${getSpace(table.region.columDefinitions, table.region.rectangle.Width)
                .aggregate(String(), this.mergeString)}`">
            <div v-for="(item, index) in table.region.columDefinitions.concat([table.region.rectangle.Width])" :key="item">
                <el-popover @show="showSelect('selectRefvt' + index)" width="200" :placement="placement" :trigger="trigger">
                    <el-select :ref="'selectRefvt' + index" @change="(val) => setMode(index, val)"
                        style="--el-select-input-focus-border-color: #30d230;" :placeholder="placeholder"
                        v-model="table.modes.configs[index]">
                        <el-option style="--el-color-primary: #30d230" v-for="item in options" :key="item.value"
                            :label="item.name" :value="item" />
                    </el-select>
                    <template #reference>
                        <el-button title="新建属性" v-if="!table.modes.configs[index]" class="but" @click="() => { }" style="color: #67C23A;border-color: #67C23A;">+
                        </el-button>
                        <el-button v-if="table.modes.configs[index]" :title="(table.modes.configs[index].name)"
                            style="overflow: hidden;color: #67C23A;border-color: #67C23A;" class="but">{{ table.modes.configs[index].name }}</el-button>
                    </template>
                </el-popover>
            </div>
            <div v-for="(item, index) in table.region.columDefinitions.concat([table.region.rectangle.Width])" :key="item">
                <el-popover @show="showSelect('selectRefvb' + index)" width="200" :placement="placement" :trigger="trigger">
                    <el-select :ref="'selectRefvb' + index" @change="(val) => setfieldMode(index, val)"
                        style="--el-select-input-focus-border-color: #30d230;" :placeholder="placeholder"
                        v-model="table.modes.fields[index]">
                        <el-option style="--el-color-primary: #30d230" v-for="items in fieldoptions" :key="items.value"
                            :label="items.name" :value="items" />
                    </el-select>
                    <template #reference>
                        <el-button title="关联属性" v-if="!table.modes.fields[index]" class="but" @click="() => { }" style="color: #67C23A;border-color: #67C23A;">+
                        </el-button>
                        <el-button v-if="table.modes.fields[index]" :title="table.modes.fields[index].name"
                            style="overflow: hidden;color: #67C23A;border-color: #67C23A;" class="but">{{ table.modes.fields[index].name
                            }}</el-button>
                    </template>
                </el-popover>
            </div>
        </div>
        <!-- <div class="js2" v-else style="height:40px"></div> -->
        <!-- <div class="js3" v-if="table.modes.fielddirection === 'horizontal'"></div> -->
        <div v-if="table.modes.fielddirection === 'horizontal'" class="grid" style="margin:0 0 0 auto;" :style="`height:${getSpace(table.region.rowDefinitions, table.region.rectangle.Height)
            .aggregate(0, this.mergeLength)}px;grid-template-rows:${getSpace(table.region.rowDefinitions, table.region.rectangle.Height)
                .aggregate(String(), this.mergeString)};grid-auto-flow: column;`">
            <div v-for="(item, index) in table.region.rowDefinitions.concat([table.region.rectangle.Height])" :key="item">
                <el-popover @show="showSelect('selectRefvc' + index)" width="200" placement="left" :trigger="trigger">
                    <el-select :ref="'selectRefvc' + index" @change="(val) => setMode(index, val)"
                        :placeholder="placeholder" v-model="table.modes.configs[index]">
                        <el-option v-for="items in options" :key="items.value" :label="items.name" :value="items" />
                    </el-select>
                    <template #reference>
                        <el-button title="新建属性" v-if="!table.modes.configs[index]" class="but" style="color: #409Eff;border-color: #409Eff;">
                            +
                        </el-button>
                        <el-button v-if="table.modes.configs[index]" :title="(table.modes.configs[index].name)"
                            style="overflow: hidden;color: #409Eff;border-color: #409Eff;" class="but">{{ table.modes.configs[index].name }}</el-button>
                    </template>
                </el-popover>
            </div>
            <div v-for="(item, index) in table.region.rowDefinitions.concat([table.region.rectangle.Height])" :key="item">
                <el-popover @show="showSelect('selectRefvd' + index)" width="200" placement="left" :trigger="trigger">
                    <el-select :ref="'selectRefvd' + index" @change="(val) => setfieldMode(index, val)"
                        :placeholder="placeholder" v-model="table.modes.fields[index]">
                        <el-option v-for="items in fieldoptions" :key="items.value" :label="items.name" :value="items" />
                    </el-select>
                    <template #reference>
                        <el-button title="关联属性" v-if="!table.modes.fields[index]" class="but" style="color: #409Eff;border-color: #409Eff;">
                            +
                        </el-button>
                        <el-button v-if="table.modes.fields[index]" :title="table.modes.fields[index].name"
                            style="overflow: hidden;color: #409Eff;border-color: #409Eff;" class="but">{{ table.modes.fields[index].name
                            }}</el-button>
                    </template>
                </el-popover>
            </div>

        </div>
        <!-- <div class="js4"></div> -->
        <div v-if="table.modes.fielddirection === 'horizontal'" class="grid" style="margin:0 auto 0 0;" :style="`height:${getSpace(table.region.rowDefinitions, table.region.rectangle.Height)
            .aggregate(0, this.mergeLength)}px;height:20px;grid-template-rows:${getSpace(table.region.rowDefinitions, table.region.rectangle.Height)
                .aggregate(String(), this.mergeString)}`">
            <div v-for="(item, index) in table.region.rowDefinitions.concat([table.region.rectangle.Height])" :key="item">
                <el-button title="删除属性" class="but" type="danger" @click="() => { removeMode(index) }">×</el-button>
            </div>
        </div>
        <div class="js5" v-else></div>
        <div class="js6" v-if="table.modes.fielddirection === 'vertical'"></div>
        <div v-if="table.modes.fielddirection === 'vertical'" class="grid" style="margin:0 auto 0 auto;" :style="`width:${getSpace(table.region.columDefinitions, table.region.rectangle.Width)
            .aggregate(0, this.mergeLength)}px;height:38px;grid-template-columns:${getSpace(table.region.columDefinitions, table.region.rectangle.Width)
                .aggregate(String(), this.mergeString)}`">
            <div v-for="(item, index) in table.region.columDefinitions.concat([table.region.rectangle.Width])" :key="item">
                <el-button title="删除属性" class="but" type="danger" @click="() => { removeMode(index) }">×</el-button>
            </div>
        </div>
        <div class="js6" v-if="table.modes.fielddirection === 'vertical'"></div>
        <div class="js6" v-if="table.modes.fielddirection === 'vertical'"></div>

        <!-- <div v-else></div> -->
        <div class="tb55" style="">
            <div class="grid" :style="`grid-template-columns:${getSpace(table.region.columDefinitions, table.region.rectangle.Width)
                .aggregate(String(), this.mergeString)};grid-template-rows:${getSpace(table.region.rowDefinitions, table.region.rectangle.Height)
                    .aggregate(String(), this.mergeString)}`">
                <div class="fill" v-for="(rect, index) in table.region.getCells()" :key="index">
                    <div class="fill" :style="'border: 1px solid #b7b7b7;' + (logRect(rect,index)?'background:#ededed;':'')">
                        <!-- <el-popover width="200" :placement="placement" :trigger="trigger">
                            <template #reference> -->
                                <div class="fill"></div>
                            <!-- </template>
                            <template #default>
                                <el-descriptions direction="vertical" :column="2" border>
                                    <el-descriptions-item label="X">
                                        <div v-if="rect.rectangle.Left">
                                            <span>{{ rect.rectangle.Left * scale }}</span>
                                        </div>
                                    </el-descriptions-item>
                                    <el-descriptions-item label="Y">
                                        <div v-if="rect.rectangle.Top">
                                            <span>{{ rect.rectangle.Top * scale }}</span>
                                        </div>
                                    </el-descriptions-item>
                                    <el-descriptions-item label="宽">
                                        <div v-if="rect.rectangle.Width">
                                            <span>{{ rect.rectangle.Width * scale }}</span>
                                        </div>
                                    </el-descriptions-item>
                                    <el-descriptions-item label="高">
                                        <div v-if="rect.rectangle.Height">
                                            <span>{{ rect.rectangle.Height * scale }}</span>
                                        </div>
                                    </el-descriptions-item>
                                    <el-descriptions-item label="字段模式">
                                        <div v-if="table.modes.fielddirection === 'horizontal'">
                                            <div v-if="table.modes.fields[rect.row]">
                                                <span>{{ table.modes.fields[rect.row].name }}</span>
                                            </div>
                                            <span v-else>空</span>
                                        </div>
                                        <div v-if="table.modes.fielddirection === 'vertical'">
                                            <div v-if="table.modes.fields[rect.col]">
                                                <span>{{ table.modes.fields[rect.col].name }}</span>
                                            </div>
                                            <span v-else>空</span>
                                        </div>
                                    </el-descriptions-item>
                                </el-descriptions>
                            </template>
                        </el-popover> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- <div v-if="table.modes.fielddirection === 'horizontal'" class="grid" style="margin:0 auto 0 0;" :style="`height:${getSpace(table.region.rowDefinitions, table.region.rectangle.Height)
            .aggregate(0, this.mergeLength)}px;height:20px;grid-template-rows:${getSpace(table.region.rowDefinitions, table.region.rectangle.Height)
                .aggregate(String(), this.mergeString)}`">
            <div v-for="(item, index) in table.region.rowDefinitions.concat([table.region.rectangle.Height])" :key="item">
                <el-button class="but" type="danger" @click="() => { removeMode(index) }">×</el-button>
            </div>
        </div>
        <div v-else></div> -->
        <div v-if="table.modes.fielddirection !== 'horizontal'"></div>
        <div></div>
        <!-- <div v-if="table.modes.fielddirection === 'vertical'" class="grid" style="margin:0 auto 0 auto;" :style="`width:${getSpace(table.region.columDefinitions, table.region.rectangle.Width)
            .aggregate(0, this.mergeLength)}px;height:20px;grid-template-columns:${getSpace(table.region.columDefinitions, table.region.rectangle.Width)
                .aggregate(String(), this.mergeString)}`">
            <div v-for="(item, index) in table.region.columDefinitions.concat([table.region.rectangle.Width])" :key="item">
                <el-button class="but" type="danger" @click="() => { removeMode(index) }">×</el-button>
            </div>
        </div>
        <div v-else></div> -->
        <div></div>
    </div>
</template>

<script>
import { Rect } from "../../utils/drawing/rect";

export default {
    props: {
        scaled: {
            type: Number,
            default: 1,
        },
        table: null,
        fieldoptions: {
            default: []
        },
        options: {
            default: []
        },
        placeholder: {
            default: '空',
            type: String,
        }
    },
    data() {
        console.log(this.table.modes);
        console.log(this.fieldoptions);
        // this.table.modes.direction = this.table.modes.fielddirection;
        if (this.table.modes.fields == null || !this.table.modes.fields) {
            this.table.modes.fields = {};
        }
        if (this.table.modes.configs == null || !this.table.modes.configs) {
            this.table.modes.configs = {};
        }
        this.table.region.columDefinitions.orderBy(x => x);
        this.table.region.rowDefinitions.orderBy(x => x);
        let scale = this.scaled * 3;
        return {
            trigger: 'click',
            placement: 'top',
            scale,
        }
    },
    watch: {
        table: {
            deep: true,
            handler(n, o) {
                this.table.modes;
                if (n.modes.fielddirection !== o.modes.fielddirection) {
                    //TODO:变了啊
                    this.table.modes.fields = {};
                    this.table.modes.configs = {};
                    this.table.modes.direction = n.modes.fielddirection;
                }
                if (n.modes.direction !== o.modes.direction) {
                    //TODO:变了啊
                    this.table.modes.fields = {};
                    this.table.modes.configs = {};
                    this.table.modes.direction = n.modes.fielddirection;
                }
            }
        }
    },
    methods: {
        logRect(rect,index){
            console.log(this.table);
            console.log(rect,index);
            if(this.table.modes.direction == 'horizontal'){
                if(this.table.modes.configs[rect.row] && this.table.modes.fields[rect.row]){
                    return true;
                }else{
                    return false;
                }
            }else{
                if(this.table.modes.configs[rect.col] && this.table.modes.fields[rect.col]){
                    return true;
                }else{
                    return false;
                }
            }
        },
        showSelect(name) {
            console.log(name);
            this.$refs[name][0].toggleMenu()
        },
        changeSwitch() {
            this.table.modes.fields = {};
            this.table.modes.configs = {};
            this.table.modes.direction = this.table.modes.fielddirection;
        },
        getSpace(array, edge) {
            let arr = [];
            for (let i = 0; i < array.length; i++) {
                let curr = array[i];
                arr.push((curr - (i === 0 ? 0 : array[i - 1])) * this.scale);
            }
            arr.push((edge - (arr.any() ? array.last() : 0)) * this.scale);
            return arr;
        },
        mergeString: (str, val) => {
            str += `${val}px `;
            return str;
        },
        mergeLength: (sum, val) => {
            return sum += (val + 4);
        },
        getAllCells(region) {
            return region.getCells();
        },
        setfieldMode(index, mode) {
            console.log(mode)
            console.log(this.table);
            this.table.modes.fields[index] = mode;

            this.table.template.options.forEach(m => {
                if (m.name == mode.fieldType) {
                    this.table.modes.configs[index] = m;
                }
            })
        },
        setMode(index, mode) {
            this.table.modes.configs[index] = mode;
        },
        removeMode(index) {
            delete this.table.modes.configs[index];
            delete this.table.modes.fields[index];
        }
    }
}
</script>


<style scoped>
.fill {
    margin: 0;
    height: 100%;
    width: 100%;
}

.but {
    height: 100%;
    width: 100%;
    font-size: 18px;
}

.grid {
    display: grid;
    padding: 5px;
    grid-gap: 5px 5px;
}

.fill :hover {
    background-color: rgba(56, 196, 142, 0.78);
    transition-delay: 0.1s;
    transition-duration: 0.25s;
}
</style>