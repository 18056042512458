<template>
    <div>
        <img width="30" v-if="!isFullscreen" src="@/assets/img/fullscreen.png" title="全屏" @click="click" >
        <!-- <img width="30" v-if="isFullscreen" src="@/assets/img/exit-fullscreen.png" title="取消全屏" @click="click" > -->
    </div>
</template>

<script>
    import screenfull from 'screenfull'

    export default {
        name: 'Screenfull',
        data() {
            return {
                isFullscreen: false
            }
        },
        mounted() {
            this.init()
        },
        beforeDestroy() {
            this.destroy()
        },
        methods: {
            click() {
                if (!screenfull.isEnabled) {
                    this.$message({
                        message: '你的浏览器不支持全屏',
                        type: 'warning'
                    })
                    return false
                }
                screenfull.toggle()
            },
            change() {
                this.isFullscreen = screenfull.isFullscreen
            },
            init() {
                if (screenfull.isEnabled) {
                    screenfull.on('change', this.change)
                }
            },
            destroy() {
                if (screenfull.isEnabled) {
                    screenfull.off('change', this.change)
                }
            }
        }
    }
</script>

<style scoped>
    .screenfull-svg {
        display: inline-block;
        cursor: pointer;
        fill: #5a5e66;
        ;
        width: 20px;
        height: 20px;
        vertical-align: 10px;
    }
</style>