<template>
    <div class="homePage">
        <div class="top-top">
            <div class="top-box">
                <div class="tt-tit">
                    <img src="../../assets/homePage/w-logo.png" alt="">
                    智慧教育平台
                </div>
                <div class="tr-img">
                    <el-dropdown class="cursor">
                        <el-avatar style="margin-left: 15px;" :size="30" :src="userHeadImg + '?_=' + Date.now()" />
                        <template #dropdown>
                            <el-dropdown-menu>
                                <div class="cursor" style="text-align: center;">{{ popname }}</div>
                                <!-- <el-dropdown-item @click="openJs">打开教师助手</el-dropdown-item> -->
                                <el-dropdown-item style="text-align: center;" @click="logout">安全退出</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
            </div>
            <div class="hello">
                上午好，{{ popname }}老师
            </div>
            <div class="center-box">
                <div class="ct-it-box">
                    <div class="ct-sm ct-1" @click="toPage('famousCourseManage', 'famousGspList')">
                        <img src="../../assets/homePage/bg-1.png" alt="">
                        <div class="zyzx">
                            智慧教研
                        </div>
                    </div>
                    <div class="ct-sm ct-2" @click="toPage('lessonManage', 'myLess')">
                        <img src="../../assets/homePage/bg-2.png" alt="">
                        <div class="zyzx">
                            智慧课堂
                        </div>
                    </div>
                    <div class="ct-sm ct-3">
                        <img src="../../assets/homePage/bg-3.png" alt="">
                        <div class="zyzx">
                            智慧批阅
                        </div>
                    </div>
                    <div class="ct-sm ct-4">
                        <img src="../../assets/homePage/bg-4.png" alt="">
                        <div class="zyzx">
                            智慧作业
                        </div>
                    </div>
                    <div class="ct-sm ct-5">
                        <img src="../../assets/homePage/bg-5.png" alt="">
                        <div class="zyzx">
                            智慧考试
                        </div>
                    </div>
                </div>
            </div>

            <div class="bottom-box">
                <div class="bt-it-box">
                    <div class="bt-sm" @click="toPage('systemManage', 'news')">
                        <img src="../../assets/homePage/smlogo-1.png" alt="">
                        <div>学校管理</div>
                    </div>

                    <div class="bt-sm" @click="toPage('systemManage', 'banner')">
                        <img src="../../assets/homePage/smlogo-2.png" alt="">
                        <div>班级管理</div>
                    </div>
                    <div class="bt-sm" @click="toPage('systemManage', 'bannerNotice')">
                        <img src="../../assets/homePage/smlogo-3.png" alt="">
                        <div>老师维护</div>
                    </div>
                    <div class="bt-sm" @click="toPage('systemManage', 'workBrieManage')">
                        <img src="../../assets/homePage/smlogo-4.png" alt="">
                        <div>学生维护</div>
                    </div>
                    <div class="bt-sm">
                        <img src="../../assets/homePage/smlogo-5.png" alt="">
                        <div>奖评维护</div>
                    </div>
                    <div class="bt-sm">
                        <img src="../../assets/homePage/smlogo-6.png" alt="">
                        <div>点阵教辅</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    components: {},
    name: "homePage",
    data() {
        return {
            popname: "",
            userHeadImg: "",
            role:"",
        }
    },
    computed: {

    },
    watch: {

    },
    created() {
        this.role = this.$storage.get("role")
        this.popname = JSON.parse(this.$storage.get("userInfo")).trueName
        this.userHeadImg = JSON.parse(this.$storage.get("userInfo")).avatar
    },
    mounted() {
    },
    methods: {
        toPage(activePath, activeSecondPath) {
            window.sessionStorage.setItem('activePath', activePath)
            window.sessionStorage.setItem('activeSecondPath', activeSecondPath)
            this.$router.push({ path: '/home' });
        },
        logout() {
            this.$confirm("您正在退出系统，是否继续？", "退出系统", {
                confirmButtonText: "退出",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.$storage.remove("token")
                this.$storage.remove("userInfo")
                this.$router.push({ path: "/login" });
            })
        },
    }
}
</script>
  
<style lang="scss" scoped>
.homePage {
    height: 100vh;
    background-color: #f2f2f2;

    .top-top {
        height: 35vh;
        // background-color: #3b62f8;
        // background:linear-gradient(to bottom,#37C07C,#4CCA8D,#37C07C)
        // background: linear-gradient(to bottom right, #37C07C , #4CCA8D,#37C07C);
        background: url(../../assets/homePage/toptopbg.png) no-repeat;
        background-size: 100% 100%;

        .top-box {
            padding: 2vh 5vw;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .hello {
            padding: 6vh 10vw;
            color: #fff;
            font-size: 48px;
            text-align: left;
        }

        .tt-tit {
            display: flex;
            align-items: center;
            color: #fff;
            font-size: 22px;
            // font-weight: bold;

            img {
                width: 25px;
                margin-right: 10px;
            }
        }

        .tr-img {
            display: flex;
            align-items: center;

            img {
                width: 20px;
                height: 24px;
                margin-left: 15px;
            }
        }

        .bottom-box {
            background: #fff;
            margin: 5vh 10vw;
            border-radius: 10px;
            box-shadow: 1px 0px 6px 1px rgba(155, 155, 155, 0.75);

            .bt-it-box {
                display: flex;
                padding: 20px 20px 5px 20px;
                flex-wrap: wrap;
            }

            .bt-sm {
                width: 17.5%;
                border-radius: 10px;
                margin: 0 1%;
                margin-bottom: 15px;
                display: flex;
                align-items: center;
                padding: 20px 0;
                justify-content: center;
                border: 2px solid #e2e8fb;
                cursor: pointer;

                img {
                    width: 15%;
                    margin-right: 20px;
                }
            }
        }

        .center-box {
            background: #fff;
            margin: 5vh 10vw;
            border-radius: 10px;
            box-shadow: 1px 0px 4px 1px rgba(155, 155, 155, 0.75);

            .ct-sm {
                position: relative;
                width: 18%;
                border-radius: 5px;
                overflow: hidden;
                height: 8vh;
                margin: 0 1%;
                cursor: pointer;

                img {
                    width: 50%;
                }
            }

            .zyzx {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                font-size: 25px;
                white-space: nowrap;
                color: #fff;
                // font-weight: bold;
            }

            .ct-it-box {
                display: flex;
                padding: 20px;
            }

            .ct-1 {
                background-color: #27d995;

                img {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }
            }

            .ct-2 {
                background-color: #28cbe0;

                img {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }
            }

            .ct-3 {
                background-color: #2891e0;

                img {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }

            .ct-4 {
                background-color: #faa12d;

                img {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }

            .ct-5 {
                background-color: #fa7c2d;

                img {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }

            .ct-6 {
                background-color: #ff7f2e;

                img {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }
        }
    }
}
</style>
  