<template>
    <div class="app-container pt0Im">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>奖评管理</a></el-breadcrumb-item>
                <el-breadcrumb-item v-if="!awardId">创建奖评</el-breadcrumb-item>
                <el-breadcrumb-item v-if="awardId">编辑奖评</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 160px);overflow: auto;">
            <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="奖评类别" prop="awardContent">
                            <el-input maxlength="128" placeholder="请输入奖评类别" v-model="form.awardContent" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="学校" prop="schoolId">
                            <el-select style="width: 100%;" clearable @change="changeSchoolId" v-model="form.schoolId"
                                placeholder="请选择学校">
                                <el-option v-for="item in schoolList" :key="item.schoolId" :label="item.schoolName"
                                    :value="item.schoolId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="星级数" prop="starNum">
                            <el-rate v-model="form.starNum" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="教授班级" prop="classIds">
                            <el-select v-model="form.classIds" multiple placeholder="请选择班级" style="width: 100%;">
                                <el-option v-for="item in classList" :key="item.classId" :label="item.className"
                                    :value="item.classId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>

        <div>
            <div class="disflex-row-center cusbtbtn" :style="$store.state.zdy.isActive ? 'left:64px;' : 'left:201px;'">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submitForm">保 存</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { formGroupForm } from "@/api/adminservice/form"
import { bookList } from "@/api/adminservice/public"
import { queryCustoms, queryTreeCustomOrg, createCustoms, custominfo, configCustom, customOrgBook } from "@/api/adminservice/custom"
import { qunQueryUser, qunCreate, qunUpdate, queryCustomQun, queryQunInfo } from "@/api/adminservice/qun"
import { awardUpdate, awardInfo, awardCreate, classList, schoolUserAuthCondition, schoolListAuth, schoolList, zhktQueryDict, schoolCreate, schoolUpdate, schoolInfo, classBatch, classCreate } from "@/api/adminservice/classOrganizaMain"

export default {
    name: "classManagementVirtualAdd",
    components: {},
    data() {
        return {
            classIsmList: [],
            memberList: [],
            id: '',
            awardId: '',
            schoolId: '',
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 表单参数
            form: {
                schoolId: '',
                className: '',
                isVirtual: 1,
                classIds: []
            },
            tableData: [{
                adminAccount: '',
                orgName: ''
            }],
            classList: [],
            // 表单校验
            rules: {
                schoolId: [
                    { required: true, message: "请选择学校", trigger: "change" },
                ],
                classIds: [
                    { required: true, message: "请选择班级", trigger: "change" },
                ],
                starNum: [
                    { required: true, message: "请选择星级", trigger: "change" },
                ],
                awardContent: [
                    { required: true, message: "请输入奖评类别", trigger: "blur" },
                ]
            },
            schoolList: [],
            gradeClassIsmList: [],
            id: '',
            role: '',
            classOldList: [],
        };
    },
    created() {
        this.role = this.$storage.get("role")
        this.getZhktQueryDict()
        this.getList()
        if (this.$route.query.awardId) {
            this.awardId = this.$route.query.awardId;
            this.getInfo();
        }
    },
    mounted() {

    },
    computed: {
    },
    methods: {
        getInfo() {
            awardInfo({ awardId: this.awardId }).then(res => {
                classList({ customId: this.$storage.get('customId'), schoolId: res.data.data.schoolId }).then(res => {
                    this.classList = res.data.data;
                });
                this.form = res.data.data;
                this.form.classIds = []
                res.data.data.classList.forEach(e => {
                    this.form.classIds.push(e.classId)
                })
            })
        },
        getZhktQueryDict() {
            zhktQueryDict({ code: 'grade_class_ism' }).then(res => {
                this.classIsmList = res.data.data;
            })
        },
        checkMemberValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            var result = true;
            for (var i = 0; i < this.memberList.length; i++) {
                var e = this.memberList[i];
                if (e.username == '' || e.memberName == '') {
                    result = false
                    break;
                }
            }
            if (result) {
                callback()
            } else {
                callback(new Error('请填写完整信息'))
            }
        },
        validateMemberUsername(value) {
            qunQueryUser({ mobile: value.username }).then(res => {
                const result = res.data.data
                if (!result) {
                    value.username = ''
                    value.memberName = ''
                    this.$message.error("请输入正确账号")
                } else {
                    if (value.memberName == '') {
                        value.memberName = res.data.data.nickName
                    }

                    value.userId = res.data.data.userId
                }
            })
        },
        changeSchoolId(schoolId) {
            this.form.classIds = '';
            console.log(schoolId);
            this.classList = [];
            if (!this.role.includes(0) && !this.role.includes(1) && !this.role.includes(2) && !this.role.includes(3)) {
                this.classList = this.classOldList.filter(itemA => {
                    return itemA.schoolId == schoolId
                })
            } else {
                classList({ customId: this.$storage.get('customId'), schoolId: schoolId }).then(res => {
                    this.classList = res.data.data;
                });
            }
        },
        deleteMemberRow(index) {
            this.memberList.splice(index, 1)
        },
        onMemberAddItem() {
            this.memberList.push({
                username: '',
                memberName: ''
            })
        },
        onAddItem() {
            console.log(this.tableData)
            this.tableData.push({
                adminAccount: '',
                orgName: ''
            })
        },
        uniqueBy(arr, key) {
            const seen = new Set();
            return arr.filter((item) => {
                const seenValue = seen.has(item[key]);
                seen.add(item[key]);
                return !seenValue;
            });
        },
        /** 查询基础：基数数据列表 */
        getList() {
            this.loading = false;
            schoolListAuth({ customId: this.$storage.get('customId'), adminUserId: ((this.role.includes(3) || this.role.includes(2)) && !this.role.includes(1)) ? JSON.parse(this.$storage.get('userInfo')).userId : '' }).then(res => {
                this.schoolList = res.data.data;
                schoolUserAuthCondition().then(res => {
                    if (!this.role.includes(0) && !this.role.includes(1) && !this.role.includes(2) && !this.role.includes(3)) {
                        this.classOldList = res.data.data.classList;
                        this.schoolList = [];
                        res.data.data.classList.forEach(e => {
                            console.log(e);
                            this.schoolList.push(e)
                        })
                        this.schoolList = this.uniqueBy(this.schoolList, 'schoolId')
                    }
                })
            });
        },


        /** 提交按钮 */
        submitForm() {
            console.log(this.form)
            console.log(this.classList);

            console.log(this.form);
            this.$refs["form"].validate(valid => {
                if (valid) {
                    var obj = JSON.parse(JSON.stringify(this.form))
                    obj.classIds = this.form.classIds.toString()
                    if (this.awardId) {
                        awardUpdate(obj).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                this.replace('/commentSet', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        });
                    } else {
                        awardCreate(obj).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("新增成功");
                                this.replace('/commentSet', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            this.replace('/commentSet')
            // this.$emit('isTg', true)
        },

    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}
</style>