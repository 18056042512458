<template>
    <div class="app-container" @click="visible = false;">
        <el-card shadow="never">
            <el-row>
                <el-col :span="24" class="mr0 mb0">
                    <div class="pull-right">
                        <el-popover :visible="visible" popper-style="width: 320px !important;" placement="bottom"
                            :min-width="'auto'">
                            <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true"
                                label-width="85px" @submit.native.prevent="getList()">
                                <el-row class="mt15">
                                    <el-col :span="24" class="mr0">
                                        <el-form-item label="状态" class="w-100">
                                            <el-select clearable class="w-100" v-model="queryParams.isAuth"
                                                placeholder="请选择">
                                                <el-option label="未授权" :value="0" />
                                                <el-option label="已授权" :value="1" />
                                                <el-option label="已拒绝" :value="2" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="mr0">
                                        <el-form-item label="手机号" class="w-100">
                                            <el-input clearable class="w-100" v-model="queryParams.mobile" placeholder="请输入手机号">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <div style="margin:0 auto;">
                                        <el-button @click="getList()" type="primary">查找</el-button>
                                    </div>
                                </el-row>
                            </el-form>
                            <template #reference>
                                <el-button type="primary" @click.stop="visible = true;">筛选条件</el-button>
                            </template>
                        </el-popover>
                    </div>
                </el-col>
            </el-row>
            <!-- <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true" label-width="100px">
                <el-row class="mt15">
                    <el-col :span="8" class="">
                        <el-form-item label="状态">
                            <el-select clearable @change="getList()" v-model="queryParams.isAuth" placeholder="请选择">
                                <el-option label="未授权" :value="0" />
                                <el-option label="已授权" :value="1" />
                                <el-option label="已拒绝" :value="2" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="">
                        <el-form-item label="申请人手机号">
                            <el-input v-model="queryParams.mobile" placeholder="请输入手机号" class="input-with-select">
                                <template #append>
                                    <el-button @click="getList()" type="primary" icon="el-icon-search" />
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-divider style="position: relative;" v-if="isSxShow">
                <img @click="isSxShow = false;" style="transform: translateY(-100%);" class="sxShowcss"
                    src="../../assets/img/arrow-up.png" alt="">
            </el-divider> -->
            <div class="bre9e mt15" style="position: relative;">
                <img @click="isSxShow = true;" v-if="!isSxShow" style="transform: translateY(-36px);" class="sxShowcss"
                    src="../../assets/img/arrow-down.png" alt="">
                <el-table header-cell-class-name="bgf2" @current-change="handleCurrentChange" highlight-current-row
                    class="sssstt" :height="'calc(100vh - ' + (isSxShow ? '265' : '230') + 'px)'" v-loading="loading"
                    :data="dataList">
                    <el-table-column align="center" width="200" label="硬件序列号" prop="penSerial">
                        <template #default="scope">
                            <div :title="scope.row.penSerial" class="text-one">
                                {{ scope.row.penSerial }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="200" label="笔来源" prop="penSource">
                        <template #default="scope">
                            <div :title="scope.row.penSource" class="text-one">
                                {{ scope.row.penSource }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="200" label="申请人手机号" prop="mobile">
                        <template #default="scope">
                            <div v-if="scope.row.mobile" :title="scope.row.mobile" class="text-one">
                                {{ scope.row.mobile.substr(0, 3) + ' ' + scope.row.mobile.substr(3, 4) + ' ' +
                                    scope.row.mobile.substr(7, 4) }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="70" label="状态" prop="isAuth">
                        <template #default="scope">
                            <div v-if="scope.row.isAuth == 0" style="color: #409eff;">
                                未授权
                            </div>
                            <div v-if="scope.row.isAuth == 1">
                                已授权
                            </div>
                            <div v-if="scope.row.isAuth == 2">
                                已拒绝
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="140" label="申请时间" prop="createTime">
                        <template #default="scope">
                            <div>{{ timestampToTime(scope.row.createTime) }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="140" label="授权时间" prop="authTime">
                        <template #default="scope">
                            <div>{{ timestampToTime(scope.row.authTime) }}</div>
                        </template>
                    </el-table-column>


                    <el-table-column align="center" fixed="right" width="200" label="操作"
                        class-name="small-padding fixed-width">
                        <template #default="scope">
                            <el-button v-if="scope.row.isAuth == 0" type="primary" size="small"
                                @click="applyApply(scope.row)">
                                同意
                            </el-button>
                            <el-button v-if="scope.row.isAuth == 0" type="danger" size="small"
                                @click="applyRefuse(scope.row)">
                                拒绝
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="zdyfy">
                <pagination :pageSizes="[10, 20, 30]" v-show="total > 0" :total="total" :page.sync="queryParams.current"
                    :limit.sync="queryParams.size" @pagination="getList" />
            </div>
        </el-card>
    </div>
</template>

<script>
import { bookList } from "@/api/adminservice/public"
import perviewSiginCanvas from "@/components/perviewSiginCanvas.vue"
import {
    queryAllGroup,
    getData,
    delData,
    addData,
    updateData,
    exportData
} from "@/api/demo/data";
import { penAuthApplyPageList, penAuthApplyAgree, penAuthApplyRefuse } from "@/api/adminservice/pen";
import { activityInfo, activityFileUpload, activityCreate, activityDelete, activityList, activityReleaseOrCancel, activityShareOrCancel, activityStopOrCancel, activityUpdate, activityFileDelete, zbhsignBookList, activityFileShareOrCancel, activityDeleteWritePage, activityFileTop, activityFileTheme, activityFileCancelTheme } from "@/api/adminservice/signIn"
import { timestampToTime } from "@/utils/date";
import { queryCustoms, stopCustoms, delCustoms, queryTreeCustomOrg } from "@/api/adminservice/custom";
import { queryCustomQun } from "@/api/adminservice/qun";
import { download } from "@/api/adminservice/public";
import { classList, zhktQueryDict, schoolList, schoolDelete, teacherList, teacherDelete } from "@/api/adminservice/classOrganizaMain";

export default {
    name: "groupSet",
    components: {
        perviewSiginCanvas
    },
    data() {
        return {
            visible: false,
            isSxShow: true,
            timestampToTime,
            showFileType: '图片素材',
            fileShow: false,
            isShare: false,
            shareUrl: '',
            selectCzObj: {},
            selectObj: {},
            schoolList: [],
            filesList: [],
            showUrlList: [],
            token: this.$storage.get("token"),
            isSuperAuth: this.$storage.get('isSuperAuth'),
            // 遮罩层
            loading: false,
            // 选中数组
            ids: [],
            readBook: false,
            readBookList: [],
            showFileList: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                customId: '',
                isAuth: 0,
                endTime: [],
                releaseTime: [],
                current: 1,
                size: 30,
                mobile: ''
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            orgList: [],
            customList: [],
            schoolId: '',
            scShow: false,
            subjectList: [],
            classList: [],
            role: '',
            isPerview: false,
            isTimeFalse: true,
        };
    },
    created() {
        this.role = this.$storage.get("role")
        this.getZhktQueryDict();
        this.getList();
    },
    activated() {
        // console.log('fdssfd',this.$route.query.isSx)
        if (this.$route.query.isSx == 1) {
            this.getList();
            if (this.$route.query.customId) {
                this.queryParams.customId = this.$route.query.customId
                this.changeCustom()
            } else {
            }
        }
    },
    mounted() {
        this.getList();
        this.getBookList();
    },
    methods: {
        applyApply(row) {
            penAuthApplyAgree({ applyId: row.id }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("操作成功");
                    this.getList();
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        applyRefuse(row) {
            this.$prompt('请输入拒绝原因', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({ value }) => {
                penAuthApplyRefuse({ applyId: row.id, reason: value }).then(res => {
                    if (res.data.code == 1) {
                        this.$message.success("操作成功");
                        this.getList();
                    } else {
                        this.$message.error("操作失败");
                    }
                })
            }).catch(() => {

            });

        },
        canvasClPerview(e) {
            this.isPerview = false;
        },
        handlePerview(row) {
            this.selectCzObj = row;
            activityInfo({ activityId: this.selectCzObj.activityId }).then(res => {
                this.selectCzObj = res.data.data;
                console.log(this.selectCzObj);
                this.isPerview = true;
            })
        },
        handleShare(row) {
            this.shareUrl = '';
            this.isShare = true;
            this.shareUrl = 'www.baidu.com';
            setTimeout(() => {
                var iframe = document.getElementById("ckpxpfx"); // 获取到要关闭的iframe元素
                iframe.setAttribute("src", 'https://zbh.zbform.com/sign?activityId=' + row.activityId);
            })
            // this.shareUrl = 'https://zbsign.zbform.com/share?activityId='+row.activityId;
        },
        gbIfram() {
            var iframe = document.getElementById("ckpxpfx"); // 获取到要关闭的iframe元素
            iframe.setAttribute("src", '');
            console.log(iframe);
            if (typeof iframe !== "undefined" && iframe != null) {
                var contentWindow = iframe.contentWindow; // 获取到iframe内部的window对象
                if (typeof contentWindow !== "undefined" && contentWindow != null) {
                    console.log(1122);
                    contentWindow.close(); // 调用close()方法关闭iframe
                } else {
                    console.log("无效的iframe或者未加载完成！");
                }
            } else {
                console.log("没有找到指定的iframe元素！");
            }
        },
        handleFx() {
            if (!this.selectObj || !this.selectObj.activityId) {
                this.$message.error("请选择数据");
                return;
            }
            // if (this.selectObj.processStatus == 1 || this.selectObj.processStatus == 2) {
            //     this.$message.error("发布和停用的数据不能铺设");
            //     return;
            // }
            activityShareOrCancel({ activityId: this.selectObj.activityId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("操作成功");
                    this.getList();
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        handleStop() {
            if (!this.selectObj || !this.selectObj.activityId) {
                this.$message.error("请选择数据");
                return;
            }
            if (this.selectObj.status != 1 && this.selectObj.status != 2) {
                this.$message.error("请选择发布或者结束的数据");
                return;
            }
            activityStopOrCancel({ activityId: this.selectObj.activityId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("操作成功");
                    this.getList();
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        handleRelease() {
            if (!this.selectObj || !this.selectObj.activityId) {
                this.$message.error("请选择数据");
                return;
            }
            // if (this.selectObj.processStatus == 1 || this.selectObj.processStatus == 2) {
            //     this.$message.error("发布和停用的数据不能铺设");
            //     return;
            // }
            activityReleaseOrCancel({ activityId: this.selectObj.activityId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("操作成功");
                    this.getList();
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        handleCurrentChange(val) {
            console.log(val)
            this.selectObj = val;
        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        handleSCChange(e, type) {
            console.log(e, type)
            console.log(this.filesList);
            if (!(e.raw.type == 'image/jpeg' || e.raw.type == 'image/png' || e.raw.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (e.raw.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            let formdata = new FormData();
            formdata.append('files', e.raw)
            formdata.append('activityId', this.selectCzObj.activityId)
            activityFileUpload(formdata).then(res => {
                if (res.data.code == 1) {
                    this.getActivtyInfo()
                    if (this.isTimeFalse) {
                        this.$message.success("新增成功");
                    }
                    this.isTimeFalse = false;
                    setTimeout(() => {
                        this.isTimeFalse = true;
                    }, 5000)

                } else {
                    this.$message.error("操作失败")
                }
            })
        },
        zdTop(fileId, index) {
            activityFileTop({ fileId: fileId }).then(res => {
                if (res.data.code == 1) {
                    this.getActivtyInfo()
                    // this.showFileList.splice(index, 1);
                    // this.showUrlList.splice(index, 1);
                    this.$message.success('操作成功');
                } else {
                    this.$message.error('操作失败');
                }
            })
        },
        zdZhu(item, index) {
            if (item.isTheme == 0) {
                activityFileTheme({ fileId: item.fileId }).then(res => {
                    if (res.data.code == 1) {
                        this.getActivtyInfo()
                        // this.showFileList.splice(index, 1);
                        // this.showUrlList.splice(index, 1);
                        this.$message.success('操作成功');
                    } else {
                        this.$message.error('操作失败');
                    }
                })
            } else {
                activityFileCancelTheme({ fileId: item.fileId }).then(res => {
                    if (res.data.code == 1) {
                        this.getActivtyInfo()
                        // this.showFileList.splice(index, 1);
                        // this.showUrlList.splice(index, 1);
                        this.$message.success('操作成功');
                    } else {
                        this.$message.error('操作失败');
                    }
                })
            }

        },
        sharePicId(fileId, index) {
            activityFileShareOrCancel({ fileId: fileId }).then(res => {
                if (res.data.code == 1) {
                    if (this.showFileList[index].isShare == 1) {
                        this.showFileList[index].isShare = 0
                    } else {
                        this.showFileList[index].isShare = 1
                    }
                    // this.showFileList.splice(index, 1);
                    // this.showUrlList.splice(index, 1);
                    this.$message.success('操作成功');
                } else {
                    this.$message.error('操作失败');
                }
            })
        },
        delPicId(fileId, index) {
            activityFileDelete({ fileId: fileId }).then(res => {
                if (res.data.code == 1) {
                    this.showFileList.splice(index, 1);
                    this.showUrlList.splice(index, 1);
                    this.$message.success('删除成功');
                } else {
                    this.$message.error('操作失败');
                }
            })
        },
        handlePic(row) {
            this.selectCzObj = row;
            this.fileShow = true;
            this.getActivtyInfo()
            // showUrlList
        },
        handleDp(row) {
            // this.$router.push({
            //     path: '/signIn',// 这里的路径就可以正常的写，不需要添加_blank: true
            //     query:{
            //         activityId:row.activityId
            //     }
            // })
            let routeUrl = this.$router.resolve({
                path: '/signIn',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: {
                    activityId: row.activityId
                }
            })
            window.open(routeUrl.href, '_blank')
        },
        getActivtyInfo() {
            this.showUrlList = [];

            activityInfo({ activityId: this.selectCzObj.activityId }).then(res => {
                this.showFileList = res.data.data.fileList;
                this.showFileList.forEach(e => {
                    this.showUrlList.push(e.fileUrl)
                })

            })
        },
        changeCustomId() {
            this.queryParams.classId = '';
            this.queryParams.schoolId = '';
            schoolList({ customId: this.queryParams.customId ? this.queryParams.customId : this.$storage.get('customId') }).then(res => {
                this.schoolList = res.data.data;
                this.getList();
            });
        },
        changeSchool() {
            this.queryParams.classId = '';
            classList({ customId: this.$storage.get('customId'), schoolId: this.queryParams.schoolId }).then(res => {
                this.classList = res.data.data;
                this.getList();
            });
        },
        getZhktQueryDict() {
            schoolList({ customId: this.$storage.get('customId') }).then(res => {
                this.schoolList = res.data.data;
            });
            this.customList = []
            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                res.data.data.sort(function (a, b) {
                    return a.customName.localeCompare(b.customName);
                })
                res.data.data.forEach(e => {
                    if (e.status == 0) {
                        this.customList.push(e)
                    }
                })
                res.data.data.forEach(e => {
                    if (e.status == 1) {
                        this.customList.push(e)
                    }
                })
            });
            classList({ customId: this.$storage.get('customId'), schoolId: this.queryParams.schoolId }).then(res => {
                this.classList = res.data.data;
            });
        },
        excelAdd() {
            schoolList({ customId: this.$storage.get('customId') }).then(res => {
                this.schoolList = res.data.data;
            });
            this.schoolId = ''
            this.scShow = true;
        },
        goDetail(row) {
            this.$router.push({
                path: "/eventMainDetail",
                query: {
                    activityId: row.activityId,
                }
            });
        },
        downloadTemplate() {
            download('https://zbh-oss-formal.oss-cn-beijing.aliyuncs.com/common/excel/%E6%99%BA%E6%85%A7%E8%AF%BE%E5%A0%82%E8%80%81%E5%B8%88%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx', '导入教师模板')
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            // if (file.url) {
            //     delLessonFile({ fileUrl: file.url })
            // }
            // if (file.response) {
            //     delLessonFile({ fileUrl: file.response.data })
            // }
        },
        handleChange(e, type) {
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    this.$message.success('导入成功')
                    this.scShow = false;
                    this.getList()
                } else {
                    if (e.response.msg) {
                        this.$message.error(e.response.msg)
                    } else {
                        this.$message.error('导入失败')
                    }
                }
            }
            if (e.status == 'fail') {
                this.$message.error('导入失败')
            }
        },
        // id 指的是当前点击的节点id;
        findParentNode(id) {
            // debugger
            // 初始化所需数据
            var firstParentObj = {}; // 记录直系父级的名称和id即接口要传的数据
            var parentIds = []; // 记录所有的父级ids
            var parentIdsQueue = []; // 记录排队的

            // 将树放到排队系列
            var parentIdsQueue = JSON.parse(JSON.stringify(this.orgList));

            // 开始遍历排队的树
            while (parentIdsQueue.length) {
                //抽取第一个排队的数据 
                let item = parentIdsQueue.shift();

                let { childrenList } = item;
                if (item.orgId === id) {
                    // 第一层就找到了
                    if (!item.parentArr) {
                        firstParentObj = {
                            id: item.orgId,
                            name: item.orgName
                        };
                        parentIds = [item.orgName];
                    } else {
                        // 获取当前节点的parentArr
                        let len = item.parentArr.length;
                        firstParentObj = item.parentArr[len - 1];
                        item.parentArr.forEach(a => {
                            parentIds.push(a.name);
                        });
                        parentIds.push(item.orgName);
                    }

                    // 结束遍历
                    parentIdsQueue = [];

                    break;
                } else if (childrenList && childrenList.length) {
                    let len = childrenList.length;
                    for (let i = len - 1; i >= 0; i--) {
                        // 新建一个数组用于记录它的父亲节点
                        childrenList[i].parentArr = [];

                        // 把它的历史父亲节点们先放入
                        if (item.parentArr) {
                            childrenList[i].parentArr = childrenList[i].parentArr.concat(
                                item.parentArr
                            );
                        }

                        // 再放入当前的父亲节点
                        childrenList[i].parentArr.push({
                            id: item.orgId,
                            name: item.orgName
                        });

                        // 加入到排队序列中
                        parentIdsQueue.unshift(childrenList[i]);
                    }

                }
            }
            // console.log(parentIds)
            return parentIds

        },
        changeCustom() {

        },
        readbookIds(row) {
            this.readBookList = [];
            this.bookList.forEach(e => {
                row.bookIds.forEach(j => {
                    if (e.id == j) {
                        this.readBookList.push(e)
                    }
                })
            })
            this.readBook = true;
        },
        getBookList() {
            bookList().then(res => {
                this.bookList = res.data.data;
            });
        },
        tgMethod(e) {
            this.queryParams.customName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        /** 查询基础：基数数据列表 */
        getList(e) {
            if (e) {
                this.queryParams.current = e.page;
                this.queryParams.size = e.limit;
            }
            if (this.queryParams.endTime && this.queryParams.endTime.length != 0) {
                this.queryParams.beginEndTime = this.queryParams.endTime[0]
                this.queryParams.endEndTime = this.queryParams.endTime[1]
            } else {
                this.queryParams.beginEndTime = ''
                this.queryParams.endEndTime = ''
            }
            if (this.queryParams.releaseTime && this.queryParams.releaseTime.length != 0) {
                this.queryParams.beginReleaseTime = this.queryParams.releaseTime[0]
                this.queryParams.endReleaseTime = this.queryParams.releaseTime[1]
            } else {
                this.queryParams.beginReleaseTime = ''
                this.queryParams.endReleaseTime = ''
            }
            if (!this.role.includes(0)) {
                this.queryParams.customId = this.$storage.get('customId');
            }
            this.loading = false;
            penAuthApplyPageList(this.queryParams).then(res => {
                this.dataList = res.data.data.records;
                this.total = res.data.data.total;

            })
            // queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
            //     this.customList = res.data.data;
            //     if (!this.$route.query.customId) {
            //         this.queryParams.customId = res.data.data[0].customId;
            //         this.changeCustom()
            //     } else {
            //     }
            // });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            activityDelete({ activityId: row.activityId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getList();
                } else {
                    this.$message.error("删除失败");
                }
            })
        },

        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有基础：基数数据数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(function () {
                    return exportData(queryParams);
                })
                .then(response => {
                    this.download(response.msg);
                });
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                title: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
            // this.$refs['form'].resetFields()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.current = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.did);
            this.single = selection.length !== 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.$router.push({
                path: "/eventMainAdd",
                query: {
                }
            });
            // this.reset();
            // this.open = true;
            // this.form.px = 10;
            // this.title = "添加基础：基数数据";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.$router.push({
                path: "/eventMainAdd",
                query: {
                    activityId: row.activityId,
                }
            });
            // this.taskdata = row;
            // this.open = true;
        }
    }
};
</script>
<style scoped>
.disnone /deep/ .el-upload-list {
    display: none;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.csbbb .eicon {
    /* display: none; */
    position: absolute;
    right: 20px;
    top: 5px;
    background-color: #e4e7ed;
}

.csbbb:hover .eicon {
    display: block;
}

.csbbb .share {
    /* display: none; */
    position: absolute;
    right: 50px;
    top: 5px;
    background-color: #e4e7ed;
}

.csbbb .Top {
    position: absolute;
    right: 80px;
    top: 5px;
    background-color: #e4e7ed;
}

.csbbb .zhuti {
    position: absolute;
    right: 110px;
    top: 5px;
    background-color: #e4e7ed;
}

.csbbb:hover .share {
    display: block;
}

.sssstt /deep/ .el-scrollbar__view {
    display: block !important;
}

.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #21ac74;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
</style>