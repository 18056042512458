<template>
    <div class="app-container pt0Im pl0Im">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>场景管理</a></el-breadcrumb-item>
                <el-breadcrumb-item v-if="!sceneId">新增场景</el-breadcrumb-item>
                <el-breadcrumb-item v-if="sceneId">编辑场景</el-breadcrumb-item>

            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 180px);overflow: auto;">
            <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="场景名称" prop="sceneName">
                            <el-input v-model="form.sceneName" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="场景简称" prop="sceneShortName">
                            <el-input v-model="form.sceneShortName" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="管理员账号" prop="adminAccount">
                            <el-input @blur="form.adminName = ''" v-model="form.adminAccount" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="管理员姓名" prop="adminName">
                            <el-input v-model="form.adminName" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="归属客户" prop="customId">
                            <el-select :disabled="role.includes(1)" style="width: 100%;" clearable v-model="form.customId" placeholder="请选择客户">
                                <el-option v-for="item in customList" :key="item.customId" :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                    :value="item.customId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- <el-row>
                    <el-col :span="12">
                        <el-form-item label="场景类型" prop="sceneType">
                            <el-radio-group v-model="form.sceneType">
                                <el-radio :label="0">表单</el-radio>
                                <el-radio :label="1">明信片</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row> -->

                <el-row>
                    <el-col>
                        <el-form-item label="场景简述" prop="sceneDescription">
                            <el-input :rows="5" v-model="form.sceneDescription" type="textarea" placeholder="最多1000个字"
                                maxlength="1000" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="!sceneId">
                    <el-col>
                        <el-form-item label="场景成员" prop="memberList">
                            <el-table ref="memberList" border :data="tableData" style="width: 600px" max-height="250">
                                <el-table-column align="center" prop="adminAccount" label="账号">
                                    <template #default="scope">
                                        <el-input v-if="!scope.row.memberId" @blur="validateUsername(scope.row)"
                                            v-model="scope.row.memberAccount" placeholder="最多16个字" maxlength="16" />
                                        <div v-else>{{ scope.row.memberAccount }}</div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" prop="adminName" label="姓名">
                                    <template #default="scope">
                                        <el-input v-model="scope.row.memberName" placeholder="最多16个字" maxlength="16" />
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" prop="formIds" v-if="sceneId" label="表单数">
                                    <template #default="scope">
                                        {{ scope.row.formIds ? scope.row.formIds.split(',').length : 0 }}
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" fixed="right" label="操作" width="160">
                                    <template #default="scope">
                                        <el-button link type="primary" size="small"
                                            @click.prevent="deleteRow(scope.$index)">
                                            删除
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div style="width: 100%;">
                                <el-button plain type="primary" class="mt10" style="width: 600px"
                                    @click="onAddItem">增加</el-button>
                            </div>

                        </el-form-item>
                    </el-col>
                </el-row>

                <!-- <el-row v-if="sceneId">
                    <el-col>
                        <el-form-item label="场景表单" prop="formList">
                            <el-table border :data="formList" style="width: 100%" max-height="250">
                                <el-table-column align="center" width="170" label="编号" prop="formNo" />
                                <el-table-column align="center" label="文件名称" prop="fileName" />
                                <el-table-column align="center" label="表单名称" prop="formName" />
                                <el-table-column align="center" width="60" label="页数" prop="pageCount" />
                                <el-table-column align="center" label="分类" prop="formType" />
                                <el-table-column align="center" width="140" label="创建时间" prop="createTime">
                                    <template #default="scope">
                                        <div>{{ timestampToTime(scope.row.createTime) }}</div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" width="120" prop="adminAccount" label="授权人员">
                                    <template #default="scope">
                                        <el-button type="primary" size="small" @click.prevent="peopleDetail(scope.row)">
                                            人员配置
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                    </el-col>
                </el-row> -->
            </el-form>
        </el-card>

        <div>
            <div class="disflex-row-center cusbtbtn" :style="$store.state.zdy.isActive ? 'left:64px;' : 'left:201px;'">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submitForm">保 存</el-button>
            </div>
        </div>
        <el-dialog :title="'人员配置'" v-model="peopleOpen" width="60%">
            <el-col class="custom">
                <el-table @selection-change="handleSelectionChange" ref="table" border :data="tableData" style="width: 100%"
                    max-height="250">
                    <el-table-column align="center" type="selection" width="30" />
                    <el-table-column align="center" prop="adminAccount" label="账号">
                        <template #default="scope">
                            <div>{{ scope.row.memberAccount }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="adminName" label="姓名">
                        <template #default="scope">
                            {{ scope.row.memberName }}
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitPeople">确 定</el-button>
                    <el-button @click="peopleOpen = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { validatenull, isMobile } from '@/utils/validate'
import { bookList } from "@/api/adminservice/public"
import { queryCustoms, createCustoms, custominfo, updateCustoms, configCustom } from "@/api/adminservice/custom"
import { timestampToTime } from "@/utils/date";
import { qunQueryUser } from "@/api/adminservice/qun"
import { sceneCreate, sceneInfo, sceneUpdate, configMember } from "@/api/adminservice/scene"
import { teacherQueryOrRegUser } from "@/api/adminservice/classOrganizaMain"

export default {
    name: "scenarioMainAdd",
    components: {},
    data() {
        return {
            sceneId: '',
            readBook: false,
            formOpen: false,
            timestampToTime,
            memberId: '',
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 表单参数
            form: {
                sceneType: 0,
                sceneDescription: '',
                showType:2
            },
            formList: [],
            tableData: [
                {
                    memberAccount: JSON.parse(this.$storage.get('userInfo')).phone,
                    memberName: JSON.parse(this.$storage.get('userInfo')).nickName,
                    memberUserId: JSON.parse(this.$storage.get('userInfo')).userId,
                    formIds: ''
                }
            ],
            // 表单校验
            rules: {
                sceneName: [
                    { required: true, message: "请输入场景名称", trigger: "blur" },
                ],
                customId: [
                    { required: true, message: "请选择客户", trigger: "change" },
                ],
                sceneType: [
                    { required: true, message: "请选择场景类型", trigger: "change" },
                ],
                sceneShortName: [
                    { required: true, message: "请输入场景简称", trigger: "blur" },
                ],
                adminAccount: [
                    { required: true, message: "请输入管理员账号", trigger: "blur" },
                    { validator: this.tovalidateUsername, trigger: "blur" }
                ],
                adminName: [
                    { required: true, message: "请输入管理员姓名", trigger: "blur" },
                ],
                memberList: [
                    { required: true, validator: this.checkOrgValidator }
                ]
            },
            formRules: {
                adminAccount: [
                    {
                        required: true,
                        message: "请选输入",
                        trigger: "blur",
                    },
                ],
            },
            bookList: [],
            configObj: {
                bookIds: [],
                orgId: ''
            },
            customList: [],
            selectList: [],
            formId: '',
            peopleOpen: false,
            role: '',
        };
    },
    created() {
        this.role = this.$storage.get("role")
    },
    mounted() {
        this.getList();

        if (this.$route.query.sceneId) {
            this.sceneId = this.$route.query.sceneId;
            sceneInfo({ sceneId: this.sceneId }).then(res => {
                this.form = res.data.data;
                this.tableData = res.data.data.memberList;
                this.bookList = res.data.data.bookList;
                this.formList = res.data.data.formList;
                qunQueryUser({ mobile: this.form.adminAccount }).then(res => {
                    this.form.adminUserId = res.data.data.userId;
                })
            })
        } else {
            if(this.$storage.get("role").includes(1)){
                this.form.customId = this.$storage.get("customId")
            }
        }

    },
    computed: {
    },
    methods: {
        submitPeople() {
            if (this.selectList.length == 0) {
                this.$message.error("请选择数据")
                return
            }
            this.tableData.forEach((e, i) => {
                var b = e.formIds ? e.formIds.split(',') : []

                if (b.includes(this.formId)) {
                    b.splice(b.indexOf(this.formId), 1)
                    console.log(b)
                }
                this.selectList.forEach((j, k) => {
                    if (e.memberUserId == j.memberUserId) {
                        console.log(b)
                        if (b.includes(this.formId)) {

                        } else {
                            b.push(this.formId)
                        }

                    }

                })
                e.formIds = b.join(',')

            })
            console.log(this.selectList)
            console.log(this.formId)
            console.log(this.tableData)

            var memberId = []
            // this.selectList.forEach(e => {
            //     memberId.push(e.memberId)
            // })
            this.selectList = []
            this.peopleOpen = false;
        },
        handleSelectionChange(val) {
            console.log(val)
            this.selectList = val;
        },
        peopleDetail(row) {
            console.log(row)
            this.formId = row.formId;
            this.peopleOpen = true;
            setTimeout(() => {
                this.tableData.forEach((e, i) => {
                    console.log(e.formIds)
                    if (e.formIds) {
                        var tsArray = e.formIds.split(',')
                        if (tsArray.indexOf(this.formId) == -1) {
                            this.$refs.table.toggleRowSelection(this.tableData[i], false);
                        } else {
                            this.$refs.table.toggleRowSelection(this.tableData[i], true);
                        }
                    }
                })
            })

        },
        formDetail(row) {
            this.memberId = row.memberId;
            this.formOpen = true;
            setTimeout(() => {
                this.formList.forEach((e, i) => {
                    console.log(e.formIds)
                    e.memberList.forEach(j => {
                        if (j.memberId == this.memberId) {
                            this.selectList.push(this.formList[i])
                            this.$refs.formtable.toggleRowSelection(this.formList[i], true);
                        }
                    })
                })
            })
        },
        tovalidateUsername(rule, value, callback) {
            if (!isMobile(value)) {
                callback(new Error("手机号码格式不正确"))
                return
            }
            teacherQueryOrRegUser({ username: value,trueName:value }).then(res => {
                const result = res.data.data
                if (!result) {
                    callback(new Error("该账号用户不存在"))
                } else {
                    if (this.form.adminName == '') {
                        this.form.adminName = res.data.data.trueName;
                    }

                    this.form.adminUserId = res.data.data.userId;
                    callback()
                }
            })
        },
        validateUsername(value,) {
            if (!isMobile(value.memberAccount)) {
                this.$message.error("手机号码格式不正确")
                return
            }
            teacherQueryOrRegUser({ username: value.memberAccount,trueName:value.memberAccount }).then(res => {
                const result = res.data.data
                if (!result) {
                    value.memberAccount = ''
                    value.memberName = ''
                    this.$message.error("请输入正确账号")
                } else {
                    value.memberName = res.data.data.trueName?res.data.data.trueName:value.memberAccount
                    value.memberUserId = res.data.data.userId
                }
            })
        },
        submitConfigForm() {
            configCustom(this.configObj).then(res => {
                if (res.data.code == 1) {
                    this.tableData.forEach(e => {
                        if (e.orgId) {
                            if (e.orgId == this.configObj.orgId) {
                                e.bookIds = this.configObj.bookIds
                            }
                        }
                    })
                    this.$message.success("配置成功");
                    this.addPeopleOpen = false;
                    this.configCancel();
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        configCancel() {
            this.readBook = false;
            this.configObj.bookIds = [];
            this.configObj.orgId = '';
        },
        configurationRow(index, row) {
            if (row.adminAccount == '') {
                this.$message.error("请填写管理员账号")
                return;
            }
            if (row.adminName == '') {
                this.$message.error("请填写管理员姓名")
                return;
            }
            this.readBook = true;
            this.configObj.bookIds = row.bookIds;
            this.configObj.orgId = row.orgId;
            console.log(index, row)
        },
        deleteRow(index) {
            console.log(index)
            if (this.tableData.length == 1) {
                this.tableData.splice(index, 1)
                // this.$message.error("不能全部删除")
            } else {
                this.tableData.splice(index, 1)
            }
        },
        onAddItem() {
            console.log(this.tableData)
            this.tableData.push({
                memberAccount: '',
                memberName: '',
                memberUserId: '',
                formIds: ''
            })
            setTimeout(() => {
                this.$refs.memberList.setScrollTop(1000000);
            })
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginDate === '' || !this.form.beginDate) {
                callback(new Error('请选择开始时间'))
            } else if (this.form.endDate === '' || !this.form.endDate) {
                callback(new Error('请选择结束时间'))
            } else {
                callback()
            }
        },
        checkOrgValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            var result = true;
            for (var i = 0; i < this.tableData.length; i++) {
                var e = this.tableData[i];
                if (e.memberAccount == '' || e.memberName == '') {
                    result = false
                    break;
                }
            }
            if (result) {
                callback()
            } else {
                callback(new Error('请填写完整信息'))
            }
        },
        startDateConfirm(e) {
            if (e > this.form.endDate) {
                this.form.endDate = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e < this.form.beginDate) {
                this.form.beginDate = '';
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date())
        },
        enddisabledDate(current) {
            if (this.form.beginDate) {
                return current && current < this.form.beginDate
            } else {
                return current && current < Number(new Date())
            }
        },
        /** 查询基础：基数数据列表 */
        getList() {
            this.loading = false;

            bookList().then(res => {
                this.bookList = res.data.data;
            });
            this.customList = [];
            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                res.data.data.sort(function (a, b) {
                    return a.customName.localeCompare(b.customName);
                })
                res.data.data.forEach(e => {
                    if (e.status == 0) {
                        this.customList.push(e)
                    }
                })
                res.data.data.forEach(e => {
                    if (e.status == 1) {
                        this.customList.push(e)
                    }
                })
                if (this.customList.length == 1) {
                    this.form.customId = this.customList[0].customId
                }
            });
        },


        /** 提交按钮 */
        submitForm() {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    console.log(this.form)
                    var isTg = false;
                    if (this.form.memberList && this.form.memberList.length != 0) {
                        for (var i = 0; i < this.form.memberList.length; i++) {
                            if (this.form.memberList[i].memberAccount == this.form.adminAccount) {
                                isTg = true;
                                break;
                            }
                        }
                        if (!isTg) {
                            this.form.memberList.push({
                                memberAccount: this.form.adminAccount,
                                memberName: this.form.adminName,
                                memberUserId: this.form.adminUserId,
                                formIds: ''
                            })
                        }
                    }
                    this.form.showType = 2;

                    if (this.sceneId) {
                        sceneCreate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                this.replace('/scenarioMain', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        });
                    } else {
                        console.log(1);
                        this.form.memberList = this.tableData;
                        console.log(1);

                        sceneCreate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("新增成功");
                                this.replace('/scenarioMain', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            this.replace('/scenarioMain')
            // this.$emit('isTg', true)
        },
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.custom /deep/ .el-checkbox__input {
    position: absolute;
    bottom: 0;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    /* height: 270px; */
    /* overflow-y: auto; */
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}
</style>