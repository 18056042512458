import request from "@/utils/request";
/*查询组内群列表*/
export const  qunList =(zuId) =>{
    return request({
        url: "/webgroupservice/qun/list?zuId="+zuId,
        method:"get",
    })
}
/*创建群*/
export const  createQun =(obj) =>{
    return request({
        url: "/webgroupservice/qun/create",
        method:"post",
        data:obj
    })
}
/*更新群信息*/
export const  updateQun =(obj) =>{
    return request({
        url: "/webgroupservice/qun/update",
        method:"post",
        data:obj
    })
}
/*查询群信息*/
export const  qunInfo =(qunId) =>{
    return request({
        url: "/webgroupservice/qun/qunInfo?qunId="+qunId,
        method:"get",
    })
}
/*解散群*/
export const  deleteQun =(qunId) =>{
    return request({
        url: "/webgroupservice/qun/delete?qunId="+qunId,
        method:"get",
    })
}
/*申请入群*/
export const  joinQunApply =(obj) =>{
    return request({
        url: "/authservice/admin/joinQunApply",
        method:"post",
        data:obj
    })
}

/*管理员群信息*/
export const  adminQunInfo =(accountId) =>{
    return request({
        url: "/webgroupservice/qun/adminQunInfo?accountId="+accountId,
        method:"get",
    })
}

/*群信息*/
export const  authQunInfo =(qunId) =>{
    return request({
        url: "/authservice/webGroup/qunInfo?qunId="+qunId,
        method:"get",
    })
}
