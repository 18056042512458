<template>
    <div class="app-container pt0Im pl0Im" id="preWid">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>表单记录</a></el-breadcrumb-item>
                <el-breadcrumb-item>表单记录详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-card v-loading="isDc" shadow="never" style="height: calc(100vh - 150px);overflow: auto;" v-if="isShow">
            <el-row>
                <div class="pull-right w-100 mb10 chosebtn"
                    style="display: flex;justify-content: flex-end;align-items: center;">
                    <div>
                        <img @click="changeActive(2)" :class="active == 2 ? 'bor-lan' : ''" src="@/assets/img/k-two.png"
                            alt="">

                    </div>
                    <div>
                        <img @click="changeActive(3)" :class="active == 3 ? 'bor-lan' : ''" src="@/assets/img/k-six.png"
                            alt="">
                    </div>
                    <el-button class="ml15 mr15" type="primary" @click="openIsPerview()">
                        笔迹回放
                    </el-button>
                    <div title="上一条" @click="lastPage" class="cursor" v-if="activeIndex != 0">
                        上一条
                    </div>
                    <div title="下一条" @click="nextPage" class="ml10 cursor" v-if="activeIndex != (dataList.length - 1)">
                        下一条
                    </div>
                    <!-- <el-icon title="播放" @click="changePlay" class="ml10 cursor" :size="20">
                        <VideoPlay />
                    </el-icon> -->
                    <el-icon @click="toDc" title="导出" class="ml10 cursor" :size="20">
                        <Download />
                    </el-icon>
                </div>
            </el-row>
            <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                <el-descriptions :column="3" class="detailFF" title="基本信息">
                    <el-descriptions-item label="归属客户">
                        {{ recordData.customName }}
                    </el-descriptions-item>
                    <el-descriptions-item label="应用场景">
                        {{ recordData.sceneName }}
                    </el-descriptions-item>
                    <el-descriptions-item label="表单状态">
                        <span class="" v-if="recordData.isFinish === 0">填报</span>
                        <span class="" v-if="recordData.isFinish == 1">识别</span>
                        <span class="" v-if="recordData.isFinish == 2">确认</span>
                        <span class="" v-if="recordData.isFinish == 3">删除</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="表单名称">
                        {{ dataList[activeIndex].fileName }}
                    </el-descriptions-item>
                    <el-descriptions-item label="采集人员">
                        {{ dataList[activeIndex].memberName }}
                    </el-descriptions-item>
                    <el-descriptions-item label="采集时间">
                        {{ timestampToTime(dataList[activeIndex].createTime) }}
                    </el-descriptions-item>
                </el-descriptions>
            </el-form>
            <!-- <div class="demo-image__preview mt15" style="    background: #f3f3f3;display: flex;flex-wrap: wrap;">
                <el-image v-for="(item, index) in srcList" style="width: 30%; height: auto;margin-top: 15px;margin-bottom: 15px;margin-left: 1.5%;margin-right: 1.5%;" :src="item" :zoom-rate="1.2"
                    :preview-src-list="srcList" :initial-index="index" :infinite="false" fit="cover" />
            </div> -->
            <div class="demo-image__preview mt15" style="background: #f3f3f3;display: flex;flex-wrap: wrap;">
                <div v-for="(item, index) in dataList[activeIndex].pageCount"
                    :style="'width: ' + (active == 2 ? '45' : '30') + '%; height: auto;margin-top: 15px;margin-bottom: 15px;margin-left: 1.5%;margin-right: 1.5%;'">
                    <Canvasbox v-if="isCanvas" class="cursor" @click="changeChoseeIndex(index)"
                        :borderColor="choseeIndex == index ? 'red' : '#dfdfdf'" :bookData="dataList[activeIndex]"
                        :imgList="[]" :imwidth="preWid" :formDesignData="formDesignList[index]"
                        :imheight="dataList[activeIndex].pageHeight / dataList[activeIndex].pageWidth * preWid"
                        :canvasid="dataList[activeIndex].formId + '' + index"
                        :picSrc="dataList[activeIndex].formUrl + '/' + item + '.png'" :detail="strokeList[index]">
                    </Canvasbox>
                    <!-- <Canvasbox v-if="dataList[activeIndex].pageHeight<dataList[activeIndex].pageWidth && isCanvas" class="cursor" @click="openIsPerview" :bookData="dataList[activeIndex]"
                        :imgList="[]" :imwidth="900"
                        :imheight="dataList[activeIndex].pageHeight / dataList[activeIndex].pageWidth * 900"
                        :canvasid="dataList[activeIndex].formId + '' + index"
                        :picSrc="dataList[activeIndex].formUrl + '/' + item + '.png'" :detail="strokeList[index]">
                    </Canvasbox> -->
                </div>
                <!-- <el-image v-for="(item, index) in srcList" style="width: 300px; height: auto" :src="item" :zoom-rate="1.2"
                    :preview-src-list="srcList" :initial-index="index" :infinite="false" fit="cover" /> -->
            </div>
        </el-card>
        <!-- <el-row v-if="isShow">
            <div style="margin: 0 auto;">
                <el-pagination @current-change="changePages" v-model:current-page="currentNum" background
                    layout="prev, pager, next" :pageCount="dataList[activeIndex].pageCount" />
            </div>
        </el-row> -->
        <canvasExport style="opacity: 0;" :strokeList="exStrList" :bookData="dataList[activeIndex]"
            :formDesignData="JSON.parse(JSON.stringify(formDesignList))" @canvasClose="canvasClosess" v-if="isDc">
        </canvasExport>
        <CanvasWritePlay v-if="isWP" :currentNum="currentNum" :strokeList="exStrList" :bookData="dataList[activeIndex]"
            :formDesignData="JSON.parse(JSON.stringify(formDesignList[currentNum - 1]))" @canvasClose="closeWp">
        </CanvasWritePlay>
        <PerviewCanvas :currentNum="currentNum" :strokeList="exStrList" :bookData="dataList[activeIndex]"
            :formDesignData="JSON.parse(JSON.stringify(formDesignList))" @canvasClose="canvasClPerview" v-if="isPerview">
        </PerviewCanvas>
    </div>
</template>

<script>
import { bookList } from "@/api/adminservice/public"
import { queryCustoms, queryTreeCustomOrg, createCustoms, custominfo, configCustom, customOrgBook } from "@/api/adminservice/custom"
import { qunQueryUser, qunCreate, qunUpdate, queryCustomQun } from "@/api/adminservice/qun"
import { formCreate, formInfo, updateForm, formRecordDesign } from "@/api/adminservice/form"
import { queryScene } from "@/api/adminservice/scene"
import { timestampToTime } from "@/utils/date";

import { strokeQuery } from "@/api/adminservice/formRecord";

export default {
    name: "demoAdd",
    components: {},
    data() {
        return {
            isWP: false,
            active: 3,
            isPerview: false,
            currentNum: 1,
            activeIndex: 0,
            fileList: [],
            dataList: [],
            formId: '',
            groupData: {},
            timestampToTime,
            id: '',
            readBook: false,
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            srcList: [],
            srcurl: '',
            // 表单参数
            form: {
                adminName: '',
                adminUserId: '',
                orgId: '',
                file: [],
                isDesign: false
            },
            tableData: [{
                adminAccount: '',
                orgName: ''
            }],
            // 表单校验
            rules: {
            },
            bookList: [],
            configObj: {
                bookIds: [],
                orgId: ''
            },
            customList: [],
            orgList: [],
            oldBookIds: [],
            sceneList: [],
            recordId: '',
            recordData: {},
            strokeList: [],
            exStrList: [],
            isDc: false,
            isCanvas: false,
            isShow: false,
            preWid: 300,
            formDesignData: [],
            formDesignList: [],
            choseeIndex: 0,
        };
    },
    created() {

    },
    mounted() {
        if (this.$route.query.recordData) {
            this.recordData = JSON.parse(this.$route.query.recordData);
            console.log(this.recordData)
            this.exStrList = []
            let arr = []
            this.dataList = JSON.parse(this.$route.query.dataList)
            this.dataList.forEach((e, i) => {
                if (e.recordId == this.recordData.recordId) {
                    this.activeIndex = i;
                }

            })
            formRecordDesign({ recordId: this.dataList[this.activeIndex].recordId }).then(res => {
                this.formDesignData = res.data.data;
                this.changeRecord(this.dataList[this.activeIndex])
            }).catch(error => {
                this.changeRecord(this.dataList[this.activeIndex])
            })
            this.isShow = true;
        } else {

        }
        console.log(this.strokeList)
        console.log(document.getElementById('preWid').clientWidth - 40);
        this.preWid = (document.getElementById('preWid').clientWidth - 40) * 30 / 100;
        // this.getList();
    },
    computed: {
    },
    methods: {
        changeActive(num) {
            if (num == 3) {
                this.preWid = (document.getElementById('preWid').clientWidth - 40) * 30 / 100;
            } else {
                this.preWid = (document.getElementById('preWid').clientWidth - 40) * 45 / 100;
            }
            this.isCanvas = false;
            this.active = num
            setTimeout(() => {
                this.isCanvas = true;
            })
        },
        openIsPerview() {
            // this.activeIndex = index;
            // console.log(this.currentNum)
            // console.log(this.dataList[this.activeIndex]);
            // console.log(this.exStrList)
            // this.isPerview = true;

            this.currentNum = this.choseeIndex + 1;
            this.changePlay()
        },
        changeChoseeIndex(index) {
            this.choseeIndex = index;
        },
        closeWp() {
            this.isWP = false;
        },
        changePlay() {
            console.log(this.dataList[this.activeIndex])
            this.isWP = !this.isWP;
        },
        changePages(val) {
            this.isCanvas = false;
            console.log(val)
            this.currentNum = val;
            // this.tableData = this.resultList.filter(itemA => {
            //     return itemA.page.toString() == this.currentNum
            // })
            setTimeout(() => {
                this.isCanvas = true;
            })
        },
        changeRecord(e) {
            formRecordDesign({ recordId: e.recordId }).then(res => {
                this.formDesignData = res.data.data;
                this.strokeList = []
                this.formDesignList = [];
                this.exStrList = []
                this.isCanvas = false;
                this.currentNum = 1;
                let arr = []
                for (var i = 0; i < e.pageCount; i++) {
                    var _this = this;
                    this.formDesignList.push([])
                    arr.push(new Promise(function (resolve, reject) {
                        strokeQuery({ pageNum: i + 1, recordId: e.recordId }).then(res => {
                            resolve(res.data.data)
                        })
                    }))
                }
                Promise.all(arr).then((result) => {
                    console.log(result)
                    this.strokeList = result;
                    this.isCanvas = true;
                    result.forEach((e, i) => {
                        this.exStrList.push({
                            pageNum: i + 1,
                            strokeList: e
                        })
                        this.formDesignData.forEach(m => {
                            if (m.page == (i + 1)) {
                                this.formDesignList[i].push(m)
                            }
                        })
                    })
                })
            }).catch(error => {

            })
            console.log(e)

        },
        nextPage() {
            this.activeIndex++;
            this.changeRecord(this.dataList[this.activeIndex])
            this.choseeIndex = 0;
        },
        lastPage() {
            this.activeIndex--;
            this.changeRecord(this.dataList[this.activeIndex])
            this.choseeIndex = 0;
        },
        toDc(row) {
            console.log(this.exStrList)
            this.isDc = true;
            // this.loading = true;
        },
        handleChange(file, fileList) {
            console.log(fileList)
            console.log(fileList.length)

            this.form.file = []
            if (fileList[0]) {
                this.form.file.push(fileList[fileList.length - 1])
                this.form.fileName = fileList[fileList.length - 1].name
                this.form.formName = fileList[fileList.length - 1].name
            }
            // console.log(this.form.file)
        },
        changeOrg() {
            console.log(this.$refs['tsd'])
            console.log(this.oldBookIds);
            this.form.bookIds = [];
            customOrgBook({ orgId: this.form.orgId }).then(res => {
                this.bookList = res.data.data;
                res.data.data.forEach(e => {
                    this.oldBookIds.forEach(j => {
                        if (e.id == j) {
                            this.form.bookIds.push(e.id)
                        }
                    })
                })
            })
        },
        validateUsername(rule, value, callback) {
            qunQueryUser({ mobile: value }).then(res => {
                const result = res.data.data
                if (!result) {
                    callback(new Error("该账号用户不存在"))
                } else {
                    this.form.adminName = res.data.data.nickName;
                    this.form.adminUserId = res.data.data.userId;

                    callback()
                }
            })
        },
        changeCustom() {
            this.orgList = [];
            this.form.orgId = '';
            queryTreeCustomOrg({ customId: this.form.customId }).then(res => {
                this.orgList = res.data.data;
                res.data.data.forEach((e, i) => {
                    e.label = e.orgName
                    e.value = e.orgId
                    e.children = e.childrenList;
                    e.disabled = true
                    this.ergodicList(e.childrenList)
                })
                console.log(res.data.data)
                this.orgList = res.data.data;
            })
        },
        submitConfigForm() {
            console.log(this.configObj)
            configCustom(this.configObj).then(res => {
                if (res.data.code == 1) {
                    this.tableData.forEach(e => {
                        if (e.orgId) {
                            if (e.orgId == this.configObj.orgId) {
                                e.bookIds = this.configObj.bookIds
                            }
                        }
                    })
                    this.$message.success("配置成功");
                    this.addPeopleOpen = false;
                    this.configCancel();
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        configCancel() {
            this.readBook = false;
            this.configObj.bookIds = [];
            this.configObj.orgId = '';
        },
        configurationRow(index, row) {
            if (row.adminAccount == '') {
                this.$message.error("请填写管理员账号")
                return;
            }
            if (row.orgName == '') {
                this.$message.error("请填写组织名称")
                return;
            }
            this.readBook = true;
            this.configObj.bookIds = row.bookIds;
            this.configObj.orgId = row.orgId;
            console.log(index, row)
        },
        deleteRow(index) {
            console.log(index)
            if (this.tableData.length == 1) {
                this.$message.error("不能全部删除")
            } else {
                this.tableData.splice(index, 1)
            }
        },
        onAddItem() {
            console.log(this.tableData)
            this.tableData.push({
                adminAccount: '',
                orgName: ''
            })
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginDate === '' || !this.form.beginDate) {
                callback(new Error('请选择开始时间'))
            } else if (this.form.endDate === '' || !this.form.endDate) {
                callback(new Error('请选择结束时间'))
            } else {
                callback()
            }
        },
        startDateConfirm(e) {
            if (e > this.form.endDate) {
                this.form.endDate = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e < this.form.beginDate) {
                this.form.beginDate = '';
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date())
        },
        enddisabledDate(current) {
            if (this.form.beginDate) {
                return current && current < this.form.beginDate
            } else {
                return current && current < Number(new Date())
            }
        },
        /** 查询基础：基数数据列表 */
        getList() {
            this.loading = false;

            // bookList().then(res => {
            //     this.bookList = res.data.data;
            // });
            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                this.customList = res.data.data;
            });
            queryScene({ customId: this.$storage.get('customId') }).then(res => {
                this.sceneList = res.data.data;
            })
        },


        /** 提交按钮 */
        submitForm() {
            console.log(this.form)
            this.$refs["form"].validate(valid => {
                if (valid) {
                    console.log(this.form)
                    if (this.formId) {
                        if (this.form.isDesign == false) {
                            this.form.isDesign = 0
                        } else {
                            this.form.isDesign = 1
                        }
                        updateForm(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                this.replace('/formMain')
                            } else {
                                this.$message.error("操作失败")
                            }
                        });
                    } else {
                        let formdata = new FormData();
                        this.form.file.map((item) => {
                            console.log(item)
                            formdata.append('file', item.raw)
                        })
                        formdata.append('customId', this.form.customId)
                        formdata.append('fileName', this.form.fileName)
                        formdata.append('formName', this.form.formName)
                        formdata.append('formType', this.form.formType)
                        formdata.append('isDesign', this.form.isDesign == true ? 1 : 0)
                        formdata.append('sceneId', this.form.sceneId)

                        formCreate(formdata).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("新增成功");
                                this.replace('/formMain')
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });
                    }

                }
            });
        },
        canvasClosess(e) {
            this.isDc = false;
        },
        canvasClPerview(e) {
            this.isPerview = false;
        },
        // 取消按钮
        cancel() {
            this.replace('/formRecord')
            // this.$emit('isTg', true)
        },
        ergodicList(childrenList) {
            if (childrenList.length != 0) {

                childrenList.forEach(e => {
                    e.label = e.orgName
                    e.value = e.orgId
                    console.log(this.findParentNode(e.orgId))
                    if (this.findParentNode(e.orgId).indexOf(2) == -1) {
                        e.disabled = true
                    }
                    if (e.orgType == 5) {
                        // debugger
                        e.disabled = true
                        console.log(e)
                    }
                    if (e.childrenList.length != 0) {
                        e.children = e.childrenList

                        this.ergodicList(e.childrenList)
                    }
                })
            }
        },
        // id 指的是当前点击的节点id;
        findParentNode(id) {
            // debugger
            // 初始化所需数据
            var firstParentObj = {}; // 记录直系父级的名称和id即接口要传的数据
            var parentIds = []; // 记录所有的父级ids
            var parentIdsQueue = []; // 记录排队的

            // 将树放到排队系列
            var parentIdsQueue = JSON.parse(JSON.stringify(this.orgList));

            // 开始遍历排队的树
            while (parentIdsQueue.length) {
                //抽取第一个排队的数据 
                let item = parentIdsQueue.shift();

                let { childrenList } = item;
                if (item.orgId === id) {
                    // 第一层就找到了
                    if (!item.parentArr) {
                        firstParentObj = {
                            id: item.orgId,
                            name: item.orgType
                        };
                        parentIds = [item.orgType];
                    } else {
                        // 获取当前节点的parentArr
                        let len = item.parentArr.length;
                        firstParentObj = item.parentArr[len - 1];
                        item.parentArr.forEach(a => {
                            parentIds.push(a.name);
                        });
                        parentIds.push(item.orgType);
                    }

                    // 结束遍历
                    parentIdsQueue = [];

                    break;
                } else if (childrenList && childrenList.length) {
                    let len = childrenList.length;
                    for (let i = len - 1; i >= 0; i--) {
                        // 新建一个数组用于记录它的父亲节点
                        childrenList[i].parentArr = [];

                        // 把它的历史父亲节点们先放入
                        if (item.parentArr) {
                            childrenList[i].parentArr = childrenList[i].parentArr.concat(
                                item.parentArr
                            );
                        }

                        // 再放入当前的父亲节点
                        childrenList[i].parentArr.push({
                            id: item.orgId,
                            name: item.orgType
                        });

                        // 加入到排队序列中
                        parentIdsQueue.unshift(childrenList[i]);
                    }

                }
            }
            return parentIds

        },
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.chosebtn img {
    height: 30px;
    object-fit: contain;
    margin-left: 15px;
}

.bor-lan {
    border: 1px solid #409eff
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}
</style>