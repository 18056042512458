<template>
    <div class="app-container pt0Im pl0Im">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>客户管理</a></el-breadcrumb-item>
                <el-breadcrumb-item v-if="!customId">客户详情</el-breadcrumb-item>
                <el-breadcrumb-item v-if="customId">客户详情</el-breadcrumb-item>

            </el-breadcrumb>
        </div>
        <div style="height: calc(100vh - 150px);overflow: auto;">
            <el-card>
                <el-descriptions class="detailFF" title="基本信息">
                    <el-descriptions-item label="客户名称">{{ form.customName }}</el-descriptions-item>
                    <el-descriptions-item label="客户简称">{{ form.customAbbreviation }}</el-descriptions-item>
                    <!-- <el-descriptions-item label="管理员账号">{{ form.adminAccount.substr(0, 3) + ' ' +
                        form.adminAccount.substr(3, 4) + ' ' +
                        form.adminAccount.substr(7, 4) }}
                    </el-descriptions-item> -->
                    <!-- <el-descriptions-item label="管理员姓名">{{ form.adminName }}</el-descriptions-item> -->
                    <el-descriptions-item label="客户状态">{{ form.status == 0 ? '正式客户' : form.status == 1 ? '演示客户' : ''
                    }}</el-descriptions-item>
                    <!-- <el-descriptions-item label="客户类型">{{ form.customTxt }}</el-descriptions-item> -->
                </el-descriptions>
            </el-card>
            <el-card class="mt15">
                <el-row>
                    <el-col :span="12">
                        <div class="mb15" style="display: flex;justify-content: space-between;">
                            <div class="card-header" style="font-size: 16px;width: 70px;text-align: left;">管理员</div>
                            <!-- <el-button type="primary" size="small" @click="newaddadmin">
                                新增管理员
                            </el-button> -->
                        </div>
                        <el-form-item prop="adminList" label-width="0px" class="mr10" style="width: 100%">
                            <div class="bre9e w-100">
                                <el-table header-cell-class-name="bgf2" :data="form.adminList" style="width: 100%">
                                    <el-table-column align="center" type="index" label="序号" width="60" />
                                    <el-table-column align="center" width="" prop="adminName" label="管理员姓名">
                                        <template #default="scope">
                                            <div>
                                                {{ scope.row.adminName }}
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" width="140" prop="adminAccount" label="管理员账号">
                                        <template #default="scope">
                                            <div>{{ scope.row.adminAccount.substr(0, 3) + ' ' +
                                                scope.row.adminAccount.substr(3, 4) + ' ' +
                                                scope.row.adminAccount.substr(7, 4) }}</div>
                                        </template>
                                    </el-table-column>

                                    <!-- <el-table-column align="center" width="150" label="编辑">
                                        <template #default="scope">
                                            <el-button link icon="Edit" @click.prevent="editDetail(scope.row, 1)"
                                                type="primary">
                                                修改
                                            </el-button>
                                            <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                                icon-color="#626AEF" title="确定删除该管理员"
                                                @confirm="deleteAdminRow(scope.row, scope.$index)">
                                                <template #reference>
                                                    <el-button link icon="Delete" type="danger">
                                                        删除
                                                    </el-button>
                                                </template>
                                            </el-popconfirm>
                                        </template>
                                    </el-table-column> -->
                                </el-table>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
            <el-card class="mt15">
                <el-row>
                    <el-col :span="24">
                        <div class="mb15" style="display: flex;justify-content: space-between;">
                            <div class="card-header" style="font-size: 16px;width: 70px;text-align: left;">业务模块</div>
                        </div>
                        <el-form-item prop="adminList" label-width="0px" class="mr10" style="width: 100%">
                            <div class="bre9e w-100">
                                <el-table header-cell-class-name="bgf2" ref="moduleList" border :data="form.moduleList"
                                    style="width: 100%">
                                    <el-table-column align="center" prop="moduleName" label="业务名称">
                                        <template #default="scope">
                                            {{ scope.row.moduleName }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" prop="authTime" label="授权日期">
                                        <template #default="scope">
                                            <div>
                                                {{ timestampToTime(scope.row.authTime) }}
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" prop="endTime" label="截止日期">
                                        <template #default="scope">
                                            <div>
                                                {{ timestampToTime(scope.row.authTime) }}
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" prop="resourceNum" label="资源数">
                                        <template #default="scope">
                                            <div>{{ scope.row.resourceNum }}</div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column align="center" prop="accountNum" label="账号数">
                                        <template #default="scope">
                                            <div>{{ scope.row.accountNum }}</div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
            <el-row class="mt15">
                <el-form style="width: 100%;" ref="form" :model="form" :rules="rules" label-width="120px">
                    <el-row class="">
                        <el-col :span="12">
                            <el-card class="mr5">
                                <template #header>
                                    <div class="card-header">
                                        群组配置
                                    </div>
                                </template>
                                <div>
                                    <!-- <el-row>
                                        <el-col :span="24">
                                            <el-form-item label="业务类型" prop="businessTxt" label-width="90px">
                                                <div>
                                                    {{ form.businessTxt }}
                                                </div>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col>
                                            <el-form-item label="服务时间" prop="serviceTime" label-width="90px">
                                                <div>
                                                    {{ timestampToTime(form.beginDate).substring(0, 10) }} 至 {{
                                                        timestampToTime(form.endDate).substring(0, 10) }}
                                                </div>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="24">
                                            <el-form-item label="组织授权数" prop="maxOrgCount" label-width="90px">
                                                <div>
                                                    {{ form.maxOrgCount }}
                                                </div>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="24">
                                            <el-form-item label="群组授权数" prop="maxGroupCount" label-width="90px">
                                                <div>
                                                    {{ form.maxGroupCount }}
                                                </div>
                                            </el-form-item>
                                        </el-col>
                                    </el-row> -->
                                    <el-row v-if="form.bookIds && form.bookIds.length != 0">
                                        <el-col>
                                            <el-form-item label="资源库:" prop="bookIds" label-width="90px">
                                            </el-form-item>
                                            <div>
                                                <div class="fl-warp"
                                                    style="display: flex;max-height: 300px;overflow-y: auto;">
                                                    <div v-for="item in bookList" class="csbbb"
                                                        :style="form.bookIds.indexOf(item.id) != -1 ? '' : 'display:none'">
                                                        <img style="width: 90px;margin-top: 10px;"
                                                            :src="this.$store.state.zdy.imgUrl + '/book/' + item.id + '/img/cover.png_original'"
                                                            alt="" class="image">
                                                        <div style="width: 90px;text-align: center;" class="text-one mb15">
                                                            {{
                                                                item.bookName }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col>
                                            <div class="mb5 mt15"
                                                style="display: flex;justify-content: space-between;width: 100%;">
                                                <div>组织</div>
                                            </div>
                                            <el-form-item prop="memberList" label-width="0" class="mr10">
                                                <div class="bre9e w-100">
                                                    <el-table header-cell-class-name="bgf2" :data="orgData"
                                                        style="width: 100%">
                                                        <el-table-column align="center" width="" prop="orgName"
                                                            label="组织名称">
                                                            <template #default="scope">
                                                                <div :title="scope.row.orgName" class="text-one">
                                                                    {{ scope.row.orgName }}
                                                                </div>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column align="center" width="140" prop="adminAccount"
                                                            label="管理员账号">

                                                        </el-table-column>
                                                        <el-table-column align="center" width="140" prop="adminName"
                                                            label="管理员姓名">

                                                        </el-table-column>
                                                        <el-table-column align="center" width="100px" prop="" label="操作">
                                                            <template #default="scope">
                                                                <el-button type="primary" size="small"
                                                                    @click.prevent="qzDetail(scope.row)">
                                                                    查看群组
                                                                </el-button>
                                                            </template>
                                                        </el-table-column>
                                                    </el-table>
                                                </div>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="12">
                            <el-card class="ml5">
                                <template #header>
                                    <div class="card-header">
                                        表单配置
                                    </div>
                                </template>
                                <div>
                                    <!-- <el-row>
                                        <el-col>
                                            <el-form-item label="表单管理日期" prop="serviceTime">
                                                <div>
                                                    {{ timestampToTime(form.formBeginDate).substring(0, 10) }} 至 {{
                                                        timestampToTime(form.formEndDate).substring(0, 10) }}
                                                </div>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col>
                                            <el-form-item label="点阵资源授权数" prop="dotAuthCount">
                                                {{ form.dotAuthCount }}
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col>
                                            <el-form-item label="采集端授权数" prop="collectPartCount">
                                                {{ form.collectPartCount }}
                                            </el-form-item>
                                        </el-col>
                                    </el-row> -->
                                    <el-row>
                                        <el-col>
                                            <div class="mb5 mt15"
                                                style="display: flex;justify-content: space-between;width: 100%;">
                                                <div>场景</div>
                                            </div>
                                            <el-form-item prop="memberList" label-width="0" class="mr10">
                                                <div class="bre9e w-100">
                                                    <el-table header-cell-class-name="bgf2" :data="sceneData"
                                                        style="width: 100%">
                                                        <el-table-column align="center" width="" prop="sceneName"
                                                            label="场景名称">
                                                            <template #default="scope">
                                                                <div :title="scope.row.sceneName" class="text-one">
                                                                    {{ scope.row.sceneName }}
                                                                </div>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column align="center" width="140" prop="adminAccount"
                                                            label="管理员账号">

                                                        </el-table-column>
                                                        <el-table-column align="center" width="140" prop="adminName"
                                                            label="管理员姓名">

                                                        </el-table-column>
                                                        <el-table-column align="center" width="100" prop="" label="操作">
                                                            <template #default="scope">
                                                                <el-button type="primary" size="small"
                                                                    @click.prevent="formDetail(scope.row)">
                                                                    查看表单
                                                                </el-button>
                                                            </template>
                                                        </el-table-column>
                                                    </el-table>
                                                </div>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                </el-form>
            </el-row>
        </div>
        <el-dialog :title="showTitle" v-model="qzOpen" width="600px">
            <el-col class="custom">
                <el-table ref="formtable" border :data="qzData" style="width: 100%" height="50vh">
                    <el-table-column align="center" width="320" label="群组名称" prop="orgName" />
                    <el-table-column align="center" width="120" label="群主" prop="adminName" />
                    <el-table-column align="center" width="120" label="群主账号" prop="adminAccount" />
                </el-table>
            </el-col>
        </el-dialog>
        <el-dialog :title="showTitle" v-model="fromOpen" width="700px">
            <el-col class="custom">
                <el-table ref="formtable" border :data="formData" style="width: 100%" height="50vh">
                    <el-table-column align="center" width="320" label="表单名称" prop="formName" />
                    <el-table-column align="center" width="200" label="文件名称" prop="fileName">
                        <template #default="scope">
                            <div :title="scope.row.fileName" class="text-one">
                                {{ scope.row.fileName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="140" label="发布时间" prop="releaseTime">
                        <template #default="scope">
                            <div>
                                {{ timestampToTime(scope.row.releaseTime) }}
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-dialog>
    </div>
</template>

<script>
import { bookList } from "@/api/adminservice/public"
import { createCustoms, custominfo, updateCustoms, configCustom } from "@/api/adminservice/custom"
import { queryScene, sceneInfo } from "@/api/adminservice/scene"
import { timestampToTime, changeTel } from "@/utils/date";
import { queryTreeCustomOrg } from "@/api/adminservice/custom";

export default {
    name: "demoAdd",
    components: {},
    data() {
        return {
            customId: '',
            timestampToTime: timestampToTime,
            readBook: false,
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            showTitle: '',
            qzOpen: false,
            orgData: [],
            qzData: [],
            fromOpen: false,
            sceneData: [],
            formData: [],
            // 非单个禁用
            single: true,
            // 表单参数
            form: {
                adminAccount: ''
            },
            tableData: [{
                adminAccount: '',
                adminName: '',
                orgName: ''
            }],
            // 表单校验
            rules: {
                // customName: [
                //     { required: true, message: "请输入客户名称", trigger: "blur" },
                // ],
                // customAbbreviation: [
                //     { required: true, message: "请输入客户简称", trigger: "blur" },
                // ],
                // adminAccount: [
                //     { required: true, message: "请输入管理员账号", trigger: "blur" },
                // ],
                // adminName: [
                //     { required: true, message: "请输入管理员姓名", trigger: "blur" },
                // ],
                // maxGroupCount: [
                //     { required: true, message: "请输入最大群组数", trigger: "blur" },
                // ],
                // serviceTime: [
                //     { required: true, validator: this.checkValidator }
                // ],
                // bookIds: [
                //     { required: true, message: "请选择资源库书本", trigger: "change" },
                // ],
                // orgList: [
                //     { required: true, validator: this.checkOrgValidator }
                // ]
            },
            bookList: [],
            configObj: {
                bookIds: [],
                orgId: ''
            }
        };
    },
    created() {
    },
    mounted() {
        if (this.$route.query.customId) {
            this.customId = this.$route.query.customId;
            this.getQueryTreeCustomOrg()
            this.getScene()
            custominfo({ customId: this.customId }).then(res => {
                if (res.data.data.customType) {
                    res.data.data.customTypeList = res.data.data.customType && res.data.data.customType.split(',')
                } else {
                    res.data.data.customTypeList = [];
                }
                if (res.data.data.businessType) {
                    res.data.data.businessTypeList = res.data.data.businessType && res.data.data.businessType.split(',')
                } else {
                    res.data.data.businessTypeList = [];
                }
                res.data.data.customTxt = ''
                res.data.data.customTypeList.forEach((j, k) => {
                    if (j == 1) {
                        res.data.data.customTxt += '表单客户'
                    } else if (j == 2) {
                        res.data.data.customTxt += '群笔记客户'
                    }
                    if (k == res.data.data.customTypeList.length - 1) {

                    } else {
                        res.data.data.customTxt += ','
                    }

                })
                res.data.data.businessTxt = ''
                res.data.data.businessTypeList && res.data.data.businessTypeList.forEach((j, k) => {
                    if (j == 0) {
                        res.data.data.businessTxt += '党建'
                    } else if (j == 1) {
                        res.data.data.businessTxt += '教研'
                    } else if (j == 2) {
                        res.data.data.businessTxt += '作业'
                    }
                    if (k == res.data.data.businessTypeList.length - 1) {

                    } else {
                        res.data.data.businessTxt += ','
                    }
                })
                this.form = res.data.data;
                var customTypeList = res.data.data.customType && res.data.data.customType.split(',')
                this.tableData = res.data.data.orgList;
                this.bookList = res.data.data.bookList;
            })
        } else {
            this.getList();
        }

    },
    computed: {
    },
    methods: {
        deleteAdminRow(row, index) {
            this.form.adminList.splice(index, 1)

        },
        getScene() {
            queryScene({ customId: this.$storage.get('customId') }).then(res => {
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.customId == this.customId
                })
                this.sceneData = res.data.data;
            });
        },
        formDetail(row) {
            this.formData = [];
            this.showTitle = row.sceneName + ':表单'
            console.log(row);
            sceneInfo({ sceneId: row.sceneId }).then(res => {
                this.formData = res.data.data.formList;
                this.fromOpen = true;
            })
        },
        qzDetail(row) {
            this.qzData = [];
            this.qzOpen = true;
            this.showTitle = row.orgName + ':群组'
            this.ergoQzdicList(row.childrenList);
            console.log(this.qzData);
        },
        getQueryTreeCustomOrg() {
            queryTreeCustomOrg({ customId: this.customId }).then(res => {
                res.data.data.forEach(e => {
                    this.ergodicList(e.childrenList);
                })
            })
        },
        ergoQzdicList(childrenList) {
            if (childrenList.length != 0) {
                childrenList.forEach(e => {
                    if (e.orgType == 5) {
                        this.qzData.push(e)
                    }
                    if (e.childrenList.length != 0) {
                        this.ergoQzdicList(e.childrenList)
                    }
                })
            }
        },
        ergodicList(childrenList) {
            if (childrenList.length != 0) {
                childrenList.forEach(e => {
                    if (e.orgType == 2) {
                        this.orgData.push(e)
                    }
                    if (e.childrenList.length != 0) {
                        this.ergodicList(e.childrenList)
                    }
                })
            }
        },
        submitConfigForm() {
            console.log(this.configObj)
            configCustom(this.configObj).then(res => {
                if (res.data.code == 1) {
                    this.tableData.forEach(e => {
                        if (e.orgId) {
                            if (e.orgId == this.configObj.orgId) {
                                e.bookIds = this.configObj.bookIds
                            }
                        }
                    })
                    this.$message.success("配置成功");
                    this.addPeopleOpen = false;
                    this.configCancel();
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        configCancel() {
            this.readBook = false;
            this.configObj.bookIds = [];
            this.configObj.orgId = '';
        },
        configurationRow(index, row) {
            if (row.adminAccount == '') {
                this.$message.error("请填写管理员账号")
                return;
            }
            if (row.adminName == '') {
                this.$message.error("请填写管理员姓名")
                return;
            }
            if (row.orgName == '') {
                this.$message.error("请填写组织名称")
                return;
            }
            this.readBook = true;
            this.configObj.bookIds = row.bookIds;
            this.configObj.orgId = row.orgId;
            console.log(index, row)
        },
        deleteRow(index) {
            console.log(index)
            if (this.tableData.length == 1) {
                this.$message.error("不能全部删除")
            } else {
                this.tableData.splice(index, 1)
            }
        },
        onAddItem() {
            console.log(this.tableData)
            this.tableData.push({
                adminAccount: '',
                adminName: '',
                orgName: ''
            })
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginDate === '' || !this.form.beginDate) {
                callback(new Error('请选择开始时间'))
            } else if (this.form.endDate === '' || !this.form.endDate) {
                callback(new Error('请选择结束时间'))
            } else {
                callback()
            }
        },
        checkOrgValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            var result = true;
            for (var i = 0; i < this.tableData.length; i++) {
                var e = this.tableData[i];
                if (e.adminAccount == '' || e.adminName == '' || e.orgName == '') {
                    result = false
                    break;
                }
            }
            if (result) {
                callback()
            } else {
                callback(new Error('请填写完整信息'))
            }
        },
        startDateConfirm(e) {
            if (e > this.form.endDate) {
                this.form.endDate = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e < this.form.beginDate) {
                this.form.beginDate = '';
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date())
        },
        enddisabledDate(current) {
            if (this.form.beginDate) {
                return current && current < this.form.beginDate
            } else {
                return current && current < Number(new Date())
            }
        },
        /** 查询基础：基数数据列表 */
        getList() {
            this.loading = false;

            bookList().then(res => {
                this.bookList = res.data.data;
            });
        },


        /** 提交按钮 */
        submitForm() {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    this.form.orgList = this.tableData;
                    console.log(this.form)
                    if (this.customId) {
                        updateCustoms(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                this.replace('/customerManage')
                            } else {
                                this.$message.error("操作失败")
                            }
                        });
                    } else {
                        debugger
                        createCustoms(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("新增成功");
                                this.replace('/customerManage')
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            this.replace('/customerManage')
            // this.$emit('isTg', true)
        },
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.custom /deep/ .el-checkbox__input {
    position: absolute;
    bottom: 0;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: 500px;
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}
</style>