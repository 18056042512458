<template>
  <div class="policy-content">
    <p class="text-center padding-p font-24">云尚群笔记隐私权政策</p>
    <p class="text-right padding-p font-16">更新日期：2022年6月14日</p>
    <p class="text-right padding-p font-16">生效日期：2022年6月14日</p>
    <p class="text-left padding-p font-15">提示条款：</p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      南京孜博汇信息科技有限公司专业从事数字化书写领域研发与技术输出，基于云尚群笔记，
      建立专业细分的数据应用服务模型，为社会各行业提供专业、标准的数据采集服务，
      帮助您挖掘传统书写的大数据应用价值。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      我司知晓个人信息对您十分重要，将按法律法规要求，采取相应安全保护措施，尽力保
      护您的个人信息安全可控。为说明云尚群笔记会如何收集、使用和存储您的个人信息及您享
      有何种权利，我司将通过本政策向您阐述相关事宜，其中要点如下：
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （1）我司将逐一说明我司收集的您的个人信息类型及其对应的用途，以便您了
      解我司针对某一特定功能所收集的具体个人信息的类别、使用理由及收集方式。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （2）当您使用一些功能时，我司会在获得您的同意后，收集您的一些信息。
      除非按照相关法律法规要求必须收集，拒绝提供这些信息仅会使您无法使用
      相关特定功能，但不影响您正常使用其他功能。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （3）您可以访问、更正、删除您的个人信息，也可以撤回同意、注销帐号、
      投诉举报以及设置隐私功能。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （4）为了在本政策下收集您的信息，或者向您提供服务、优化我司的服务
      以及保障您的帐号安全，需要向您索取相关的权限；其中的敏感权限例如通
      讯录、精确地理位置、摄像头、麦克风、相册、在其他应用上层显示等均不
      会默认开启，只有在您明确同意后才会向我司授权。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （5）您在使用云尚群笔记过程中，涉及到的音、视频等相关资料的下载，
      已获得了相关版权方的许可，该下载操作只有经过您本人的明确授权后
      才能正常进行，且将以缓存的方式存在于您的客户端上。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （6）需要特别说明的是，本隐私政策不适用于其他第三方通过云尚群笔记
      相关产品网页或客户端直接向您提供的服务（统称“第三方服务”），
      如您通过云尚群笔记客户端绑定个人支付宝（钱包）和企业办公支付以实现
      的支付、转账、红包等支付相关功能，由第三方服务提供者向您提供服务。
      您向该第三方服务提供者提供的信息不适用于本政策，您在选择使用第三服务
      前应充分了解第三方服务的产品功能及隐私保护政策，再选择是否开通功能。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      本隐私政策部分将帮助您了解以下内容：
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      一、我司收集的信息
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      二、我司如何使用收集的信息
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      三、我司如何共享、转让、公开披露您的信息
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      四、我司如何保护您的信息
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      五、您如何管理自己的信息
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      六、本隐私权政策如何更新
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      七、如何联系我司
    </p>
    <p class="text-left title-p padding-title-p line-h font-15">
      一、我司收集的信息
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      云尚群笔记结合对表单、云尚群笔记数码本、相关书写的笔迹。云尚群笔记均是
      通过数码笔在纸面上书写的同时，自动获取书写的介质信息和书写的笔迹数据
      包括本子、页码、笔迹坐标等。并且将这些信息捕捉后上传服务器进行数据
      处理和加工，最终在云尚群笔记客户端呈现给您实时数据传输、图片自动生成、
      笔迹识别转化、书写行为记录等价值用。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      在您使用上述产品的过程中，我司会按照如下方式收集您在使用产品时主动
      提供或因为使用服务而产生的信息，用以向您提供服务、优化我司的服务以及
      保障您的帐号安全：
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      1、用户注册数据信息
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      当您注册云尚群笔记时，我司会收集您的手机号码、姓名、性别、电子邮件地址、
      位置等信息，必要的时候会补充个人所在组织的公司、部门、职位、电话等信息。
      您也可以使用第三方帐户（如微信）登录并使用云尚群笔记，选择通过微信登录，
      为验证您的微信帐户以登录使用云尚群笔记服务，经您授权同意后，我们将
      按照本隐私条款和《微信隐私保护指引》
      （https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy）
      收集您的昵称、头像、性别。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      2、书写数据信息
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      我司服务的提供以智能笔的数据抓取、分析为基础，书写数据的收集与分析是我司为您提供良好服务的重要前提。需要说明的是，如果云尚群笔记数据权隶属于个人，则我司承诺，只有该个人能够查看自己的表单、书写等数据，未经您的许可不会将您的数据提供他人查阅。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      3、音视频数据信息
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      如果在使用过程中，根据业务功能的需要，我司需要访问您手机相机、蓝牙、音频和麦克风、摄像头、在其他应用上层显示等。您可在您的设备的 “设置-应用权限”中查看上述权限的状态，并可自行决定这些权限随时的开启或关闭。请您注意，您开启任一权限，即表明您授权我司可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限，即表明您取消了授权，我司将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      4、使用第三方服务时产生的信息
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      您使用云尚群笔记，可能会涉及到一些第三方服务，例如第三方支付平台等，该服务由外部第三方主体提供，我司无法获得您在使用该类服务时产生的信息。但是，如果您已明示同意该第三方获得您的地理位置信息，该第三方将通过云尚群笔记接口获得您的终端地理位置信息。该信息属于敏感信息，拒绝提供该信息仅会使您无法使用上述第三方服务，但不影响您正常使用云尚群笔记。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      5、依法收集、使用的信息
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      根据相关法律法规及国家标准，以下情形中，我司可能会收集、使用您的相关个人信息无需征求你的授权同意：
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （1）与个人信息控制者履行法律法规规定的义务相关的；
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （2）与国家安全、国防安全直接相关的；
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （3）与公共安全、公共卫生、重大公共利益直接相关的；
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （4）与刑事侦查、起诉、审判和判决执行等直接相关的；
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （5） 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （6）所涉及的个人信息是个人信息主体自行向社会公众公开的；
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （7）根据个人信息主体要求签订和履行合同所必需的；
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （8）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （9）维护所提供产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障；
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （10）个人信息控制者为新闻单位，且其开展合法的新闻报道所必需的；
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （11）个人信息控制者为学术研究机构，出于公共利益开展统计或学术研究所必要， 且其对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标 识化处理的。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      6、其他信息
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      需要注明的是，我司向您提供的功能和服务是不断更新和发展的，如果某一功能或服务未在前述说明中且收集了您的信息，通过页面提示、交互流程、网站公告等方式另行向您说明信息收集的内容、范围和目的，以征得您的同意。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      收集这些信息是为了帮助您完成产品注册，保护您帐号的安全。在某些情况下，您可以选择不向我司提供个人数据。若您不提供这类信息，您可能无法正常使用我司的服务。
    </p>
    <p class="text-left title-p padding-title-p line-h font-15">
      二、我司如何使用收集的信息
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      我司严格遵守法律法规的规定及与用户的约定，将收集的信息用于以下用途。若我司超出以下用途使用您的信息，我司将再次向您进行说明，并征得您的同意。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      1、向您提供服务
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      2、同步、共享和存储您上传或下载的数据以及执行上传和下载所需的数据。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      3、产品开发和服务优化
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      例如，当我司的系统发生故障时，我司会记录和分析系统故障时产生的信息，优化我司的服务。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      4、安全保障
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      例如，我司会将您的信息用于身份验证、安全防范、反诈骗监测、存档备份、客户的安全服务等用途。例如，您下载或安装的安全软件会对恶意程序或病毒进行检测，或为您识别诈骗信息。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      5、管理软件
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      例如，进行软件认证、软件升级等。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      6、邀请您参与有关我司服务的调查
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      为了确保服务的安全，帮助我司更好地了解我司产品的运行情况，我司可能记录相关信息，例如，您使用产品的频率、故障信息、总体使用情况、性能数源。我司不会将我司存储在分析软件中的信息与您在产品中提供的个人身份信息相结合。
    </p>
    <p class="text-left title-p padding-title-p line-h font-15">
      三、我司如何共享、转移、公开披露您的信息
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （一）共享
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      我司不会与云尚群笔记服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外：
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      1、在获取您同意的情况下共享：获得您的明确同意后，我司会与其他方共享您的个人信息。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      2、在法定情形下的共享：我司可能会根据法律法规规定、诉讼、争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      3、您主动选择情况下的共享：只有共享您的个人信息、个人敏感信息，才能提供您所要求的第三方产品和服务，如：
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （1）支付功能：在您通过云尚群笔记客户端个人支付宝（钱包）和企业办公支付功能实现收付、转账、红包等支付相关功能时，您同意我司向支付宝提供您的个人基础信息（UserID、头像、昵称等信息）及身份信息（姓名）以便开通或绑定支付宝账号；您同意在上述支付功能开通期间，授权我司获得您登录云尚群笔记账号所绑定的支付宝账户信息（含昵称、头像、账户名），否则您将无法使用收付、转账、红包等支付相关功能。您在使用支付宝公司的支付相关服务时，需阅读并遵守《支付宝服务协议》、《支付宝隐私权政策》等协议和规则。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      为完成您在云尚群笔记上的订单支付，您需要提供支付宝账户并选择付款方式，我司会将您或您所在企业组织绑定的云尚群笔记账户会员名、对应的支付宝账户会员名、订单支付相关信息、订单安全相关设备信息及其他反洗钱法律要求的必要信息通过在应用程序中嵌入的支付宝软件工具开发包（SDK）等方式与支付宝公司共享。如您选择由其他金融机构为您提供支付服务的，我司还会将您的包括银行卡号、有效期在内的银行卡支付必要信息与您选择的相应金融机构共享。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      您可以请求他人为您付款，那么您需要提供代付人的支付宝账户及/或手机号码。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      为使我司及时获悉并确认您的支付进度及状态，为您提供售后与争议解决服务，您同意我司可自您所选择的交易对象、支付宝公司或您选择的其他金融机构处收集与支付进度相关信息。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （2）侵权投诉处置
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      在您被他人投诉侵犯知识产权或其他合法权利时，需要向投诉人披露您的必要资料，以便进行投诉处理。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （3）与关联公司间共享
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      为便于我司基于云尚群笔记账号共同向您提供服务，识别账号异常，保护云尚群笔记关联公司或其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会与我司的关联公司共享。我司只会共享必要的个人信息（如为便于您使用云尚群笔记账号使用我司关联公司产品或服务，我司会向关联公司共享您必要的账号信息），如果我司共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （4）与授权合作伙伴共享
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      仅为实现本隐私政策中声明的目的，我司的某些服务将由我司和授权合作伙伴共同提供。我司仅会出于合法、正当、必要、特定、明确的目的共享您的信息，授权合作伙伴只能接触到其履行职责所需信息，且不得将此信息用于其他任何目的，除非另行获得您的明示同意除外。对于涉及儿童个人信息的，我司不允许合作伙伴进行转委托。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      1）供应商、服务提供商和其他合作伙伴。我司将信息发送给支持我司业务的供应商、服务提供商和其他合作伙伴，这些支持包括受我司委托提供的技术合作研发服务、提供云尚群笔记平台软件推广部署服务、提供客户服务、支付便利以及其他合作场景。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      2）为了使您能够接收信息推送、在第三方平台分享信息、使用地图服务、播放视频等必要的功能用途，我司的应用中会嵌入授权合作伙伴的SDK或其他类似的应用程序。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      3）我司会对授权合作伙伴获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并与授权合作伙伴约定严格的数据保护措施，令其按照我司的委托目的、服务说明、本隐私权政策以及其他任何相关的保密和安全措施来处理个人信息。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （二）转移
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      我司不会将您的个人信息转移给任何公司、组织和个人，但在获取您明确同意的情况下我司可以帮助您转让，甚至删除有关信息：存储在阿里云服务器上的数据，根据您的需要，可以通过接口或导出的方式，将数据转移到其他地方，或删除。该操作需要由您提出正式的书面申请，并且经过云尚群笔记的审核确认后，协调双方的技术或管理人员进行相关的操作或开放相关接口，如果转移或删除在云尚群笔记阿里云服务器上的数据后，云尚群笔记不再承担对个人或组织的数据管理职责，数据的丢失或错误由个人或组织自行承担。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （三）公开披露
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      我司仅会在以下情况下，公开披露您的个人信息：
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      1、获得您明确同意或基于您的主动选择，我司可能会公开披露您的个人信息；
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      2、如果我司确定您出现违反法律法规或严重违反云尚群笔记相关协议规则的情况，或为保护云尚群笔记及其关联公司用户或公众的人身财产安全免遭侵害，我司可能依据法律法规或云尚群笔记相关协议规则，披露关于您的个人信息，包括相关违规行为以及云尚群笔记已对您采取的措施。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （四）共享、转移、公开披露个人信息时事先征得授权同意的例外
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      1、与国家安全、国防安全有关的；
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      2、与公共安全、公共卫生、重大公共利益有关的；
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      3、与犯罪侦查、起诉、审判和判决执行等有关的；
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      5、您自行向社会公众公开的个人信息；
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      此外请您注意，根据法律规定，共享、转让经匿名化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
    </p>
    <p class="text-left title-p padding-title-p line-h font-15">
      四、我司如何保护您的信息
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （一）技术安全防护
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      为保障您的信息安全，我司已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （二）人员安全管理
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      我司对可能接触到您的信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们的操作情况等措施。我方会按现有技术提供相应的安全措施来保护您的信息，提供合理的安全保障，我方将尽力做到使您的信息不被泄漏、毁损或丢失。我司会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （三）存储安全
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      我司会将包括您在内的所有用户的书写笔迹数据存储在阿里云平台上的服务器上。一般而言，我司仅在为实现目的所必需的时间内保留您的个人信息，例如：手机号码：若您需要使用云尚群笔记相关产品，我司需要一直保存您的手机号码，以保证您正常使用该服务，当您注销云尚群笔记帐户后，我司将删除相应的信息。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      当我司的产品或服务发生停止运营的情形时，我司将以推送通知、公告等形式通知您，并在合理的期限内删除您的个人信息或进行匿名化处理。所谓“匿名化处理”，是指通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。个人信息经匿名化处理后所得的信息不属于个人信息。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      正如上一部分已经提及的，我司会采取合理可行的措施存储您的个人信息，尽力避免收集无关的个人信息。我司只会在限于达成本政策所述目的所需的期限以及所适用法律法规所要求的期限内保留您的个人信息。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （四）密码保护提示
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      互联网并非绝对安全的环境，使用云尚群笔记时请使用复杂密码，协助我司保证您的账号安全。我司将尽力保障您发送给我司的信息的安全。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （五）信息安全事件处理
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      发生个人信息安全事件后，我司将按照法律法规的要求（最迟不迟于30个自然日内）向您告知：安全事件的基本情况和可能的影响、我司已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我司将邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我司会采取合理、有效的方式发布公告。同时，我司还将按照监管部门要求，上报个人信息安全事件的处置情况。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （六）其他
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      互联网环境并非百分之百安全，尽管我司有符合业内标准的安全措施，但请注意在互联网上不存在“完善的安全措施”，我司将基于正常的商业努力确保您的信息的安全。
    </p>
    <p class="text-left title-p padding-title-p line-h font-15">
      五、您如何管理自己的信息
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      您可以在使用我司服务的过程中，访问、修改和删除您提供的注册信息和其他个人信息，也可按照通知指引与我司联系。您访问、修改和删除个人信息的范围和方式将取决于您使用的具体服务。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      我司将按照本政策所述，仅为实现我司产品或服务的功能，收集、使用您的信息。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      如您发现我司违反法律、行政法规的规定或者双方的约定收集、使用您的个人信息，您可以要求我司删除。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      如您发现我司收集、存储的您的个人信息有错误的，您也可以要求我司更正。请通过本政策列明的联系方式与我司联系。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      如您需要注销账号，可以登录手机云尚群笔记客户端，通过“我的-关于云尚群笔记-产品服务-联系客服”进行注销，我司的客服会全程协助您注销账号，并在24小时内完成账号注销。在您主动注销账号之后，我司将停止为您提供产品或服务，并根据相关法律的要求删除您的个人信息。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      在您访问、修改和删除相关信息时，我司可能会要求您进行身份验证，以保障帐号的安全。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      请您理解，由于技术所限、法律或监管要求，我司可能无法满足您的所有要求，我司会在合理的期限内答复您的请求。
    </p>
    <p class="text-left title-p padding-title-p line-h font-15">
      六、本隐私权政策如何更新
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      我司的隐私政策可能变更。未经您明确同意我司不会限制您按照本隐私政策所应享有的权利。我司会在云尚群笔记相关产品网页或客户端上公告发布对本隐私政策所做的任何变更。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      对于重大变更，我司还会提供更为显著的通知（包括我司会通过公示的方式进行意见征集、公告通知甚至向您提供弹窗提示）。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      本政策所指的重大变更包括但不限于：
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      1、我司的服务模式发生重大变化。如处理个人信息的目的、处理个人信息的类型以及个人信息的使用方式等；
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      2、我司在控制权、组织架构等方面发生重大变化。如并购重组等引起的控制者变更等；
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      3、个人信息共享、转让或公开披露的主要对象发生变化；
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      4、您参与个人信息处理方面的权利及其行使方式发生重大变化；
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      5、我司负责处理用户信息安全的责任部门、联络方式及投诉渠道发生变化时；
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      6、用户信息安全影响评估报告表明存在高风险时。
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      在变更的情形下，若您继续使用我司的服务，即表示同意受经修订的政策约束。
    </p>
    <p class="text-left title-p padding-title-p line-h font-15">
      七、如何联系我司
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      如果您对云尚群笔记的隐私保护政策或数据处理有任何疑问、意见或建议，可以通过以下方式联系我司：
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （1）邮寄方式：
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      南京孜博汇信息科技有限公司
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      地址：南京市栖霞区马群街道紫东路1号E4栋225室
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （2）电话方式：025-84618008
    </p>
    <p class="text-left padding-p text-indent-28 line-h font-15">
      （3）一般情况下，我司将在收到您相关联系信息并核实您身份后十五个工作日内回复。如果您对我司的回复不满意，特别是您认为我司的个人信息处理行为损害了您的合法权益，您还可以通过向南京市栖霞区有管辖权的法院提起诉讼来寻求解决方案。
    </p>
  </div>
</template>

<script>
export default {
  name: "policy"
}
</script>

<style scoped>
.policy-content{
  width: 96%;
  margin: 0 auto;
  padding: 10px 0 10px 0;
  color: #000;
}
.padding-p{
  margin-block-start: 0.2em;
  margin-block-end: 0.2em;
}

.padding-title-p{
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}
.font-14{
  font-size: 14px;
}
.font-15{
  font-size: 15px;
}
.font-16{
  font-size: 16px;
}
.font-18{
  font-size: 18px;
}
.font-24{
  font-size: 24px;
}
.text-left{
  text-align: left;
}
.text-center{
  text-align: center;
}
.text-right{
  text-align: right;
}
.text-indent-28{
  text-indent: 28px;
}
.line-h{
  line-height: 24px;
  text-align: justify;
}
.title-p{
  font-weight: bold;
}
</style>
