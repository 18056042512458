<template>
    <div class="app-container" @click="visible = false;">
        <!-- <el-card class="mb15">
            <el-row>
                <el-col :span="24" class="mb0">
                    <el-button type="primary" @click="handleAdd">创建活动</el-button>
                    <el-button type="primary" @click="handleFx">停止/恢复分享</el-button>
                    <el-button type="primary" @click="handleRelease">发布/取消发布</el-button>
                    <el-button type="primary" @click="handleStop">结束/取消结束</el-button>
                </el-col>
            </el-row>
        </el-card> -->
        <el-card>
            <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
                <el-row class="mt15 mb0">
                    <el-col :span="24" class="mr0 mb0">
                        <div class="pull-right">
                            <el-popover :visible="visible" popper-style="width: 320px !important;" placement="bottom"
                                :min-width="'auto'">
                                <el-row>
                                    <el-col :span="24" class="" v-if="role.includes(0)">
                                        <el-form-item label="归属客户" class="w-100">
                                            <el-select clearable class="w-100" @change="getList()" v-model="queryParams.customId"
                                                placeholder="请选择">
                                                <el-option v-for="item in customList" :key="item.customId"
                                                    :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                                    :value="item.customId" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="">
                                        <el-form-item label="教辅" class="w-100">
                                            <el-select clearable class="w-100" @change="getChapterList()" v-model="queryParams.fileId"
                                                placeholder="请选择">
                                                <el-option :label="item.fileName" :value="item.fileId"
                                                    v-for="item in dataList" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <template #reference>
                                    <el-button type="primary" @click.stop="visible = true;">筛选条件</el-button>
                                </template>
                            </el-popover>
                        </div>
                    </el-col>
                    <!-- <el-col :span="6" class="" v-if="role.includes(0)">
                        <el-form-item label="归属客户">
                            <el-select clearable @change="getList()" v-model="queryParams.customId" placeholder="请选择">
                                <el-option v-for="item in customList" :key="item.customId"
                                    :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                    :value="item.customId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="">
                        <el-form-item label-width="55px" label="教辅">
                            <el-select clearable @change="getChapterList()" v-model="queryParams.fileId" placeholder="请选择">
                                <el-option :label="item.fileName" :value="item.fileId" v-for="item in dataList" />
                            </el-select>
                        </el-form-item>
                    </el-col> -->
                </el-row>
            </el-form>
            <el-row>
                <el-col :span="6">
                    <el-row>
                        <el-col class="mt15">
                            <el-card>
                                <div style="display: flex;width: 100%;" class="">
                                    <el-button class="" type="primary" @click="handleChapter">结构授权</el-button>
                                </div>
                                <div style="height:calc(100vh - 320px);overflow-y:auto;" class="mt15">
                                    <div @click="toActiveChapter(item)"
                                        :style="`display:flex;${chapterId == item.chapterId ? 'background-color: #21ac741a;color:#21ac74' : ''}`"
                                        class="cursor" v-for="item in chapterList">
                                        <div class="text-one mb10 fs-14 mt10 pl10 pr10" style="flex:1">{{ item.chapterName
                                        }}</div>
                                        <el-icon class="cursor pt10 pr10" @click.stop="goFileArea(item)">
                                            <Setting />
                                        </el-icon>
                                    </div>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="18">
                    <div class="ml15">
                        <el-row class="mt15">
                            <el-col :span="12">
                                <div class="bre9e">
                                    <el-table header-cell-class-name="bgf2" highlight-current-row
                                        @current-change="handleCurrentChange" height="calc(100vh - 230px)" stripe
                                        :data="chapterAreaData" row-key="id">
                                        <el-table-column align="center" prop="name" label="题目">
                                            <template #default="scope">
                                                <div :title="scope.row.name" class="text-one">
                                                    {{ scope.row.name }}
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center" width="60" prop="page" label="页码">
                                        </el-table-column>
                                        <el-table-column align="center" fixed="right" label="操作" width="150">
                                            <template #default="scope">
                                                <el-button link icon="Edit" @click.prevent="editAreaDetail(scope.row)"
                                                    type="primary">
                                                    修改
                                                </el-button>
                                                <el-popconfirm confirm-button-text="是" cancel-button-text="否"
                                                    :icon="InfoFilled" icon-color="#626AEF" title="确定移除该题目"
                                                    @confirm="deleteAreaRow(scope.row)">
                                                    <template #reference>
                                                        <el-button link icon="Delete" type="danger">
                                                            移除
                                                        </el-button>
                                                    </template>
                                                </el-popconfirm>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </el-col>
                            <el-col :span="12" id="rgimgwidth">
                                <div v-if="selectObj?.page"
                                    style="height: calc(100vh - 230px); overflow-y: auto;background: rgb(243, 243, 243);margin-left: 15px;">
                                    <div style="margin: 10px;">
                                        <div
                                            :style="'margin:0 auto;width: ' + rgimgwidth + 'px;height: ' + fileData.fileHeight / fileData.fileWidth * rgimgwidth + 'px;position: relative;'">
                                            <img :style="'pointer-events: none;position:absolute;border:1px solid #eeeeee;border-radius:10px;width:' + rgimgwidth + 'px;left:0;height:' + fileData.fileHeight / fileData.fileWidth * rgimgwidth + 'px'"
                                                :src="fileData.imgFileUrl + '' + selectObj.page + '.png_nostyle'" alt="">
                                            <div v-for="item in chapterAreaData" v-show="selectObj.page == item.page"
                                                :style="`position:absolute;left:${(item.x) / fileData.fileWidth * rgimgwidth + 1}px;top:${(item.y) / fileData.fileWidth * rgimgwidth}px;width:${(item.width) / fileData.fileWidth * rgimgwidth}px;height:${(item.height) / fileData.fileWidth * rgimgwidth}px;background:${item.areaId == selectObj.areaId ? 'rgba(149, 239, 65, 0.5)' : 'rgba(93, 201, 241, 0.5)'};border:1px solid #333;font-size:12px;overflow:hidden;color:red;`">
                                                {{ item.name }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </el-card>
        <el-dialog :title="'配置章节'" v-model="isChapter" width="50%" draggable>
            <el-table max-height="500" border stripe :data="chapterData" row-key="id">
                <el-table-column align="center" prop="chapterName" label="章节名称">
                </el-table-column>
                <el-table-column align="center" width="90" prop="page" label="起始页码">
                </el-table-column>
                <el-table-column align="center" fixed="right" label="操作" width="160">
                    <template #default="scope">
                        <el-button @click.prevent="editChapterDetail(scope.row)" type="primary" size="small">
                            修改
                        </el-button>
                        <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                            icon-color="#626AEF" title="确定删除该章节" @confirm="deleteChapterRow(scope.row)">
                            <template #reference>
                                <el-button link type="primary" size="small">
                                    删除
                                </el-button>
                            </template>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <div style="width: 100%;">
                <el-button plain type="primary" class="mt10" style="width: 600px" @click="onChapterAddItem">增加</el-button>
            </div>
            <!-- <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitChapterData">
                        确定
                    </el-button>
                    <el-button @click="isChapter = false">
                        取消
                    </el-button>
                </div>
            </template> -->
        </el-dialog>
        <el-dialog :title="this.chapterForm.chapterId ? '修改章节' : '新增章节'" v-model="isCreateChapter" width="700px">
            <el-form v-if="isCreateChapter" ref="chapterForm" :model="chapterForm" :rules="chapterFormRules"
                label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="章节名称" prop="chapterName">
                            <el-input v-model="chapterForm.chapterName" placeholder="最多32个字" maxlength="32" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="起始页码" prop="page">
                            <el-input-number :min="1" v-model="chapterForm.page" :step="1" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="chapterSubmitForm">
                        确定
                    </el-button>
                    <el-button @click="isCreateChapter = false">
                        取消
                    </el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="'修改题目'" v-model="isCreateArea" width="700px">
            <el-form v-if="isCreateArea" ref="areaForm" :model="areaForm" :rules="areaFormRules" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="题目名称" prop="name">
                            <el-input v-model="areaForm.name" placeholder="最多32个字" maxlength="32" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="areaqeesSubmitForm">
                        确定
                    </el-button>
                    <el-button @click="isCreateArea = false">
                        取消
                    </el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="'配置题目'" v-model="isConfigQuestion" width="80%" draggable>
            <el-row class="">
                <el-col :span="12">
                    <div class="" style="display: flex;align-items: center;transform: translateY(-3px);font-size: 14px;">
                        <el-checkbox v-model="isSHowNoPz" size="large" />
                        <div class="ml5" style="white-space: nowrap;">仅显示未配置题目</div>
                    </div>
                    <el-table v-show="(index + 1) == pageNum" v-for="(item, index) in areaData" v-if="isConfigQuestion"
                        :class="'areaDatatable' + index" :row-class-name="isSHowNoPz ? tableRowClassName : ''"
                        @selection-change="handleSelectionFormChange($event, index)" height="50vh" border stripe
                        :data="item" row-key="id" :ref="'areaDatatable' + index">
                        <el-table-column align="center" type="selection" width="40" />
                        <el-table-column align="center" prop="name" label="题目">
                        </el-table-column>
                        <el-table-column align="center" width="90" prop="page" label="页码">
                        </el-table-column>
                        <el-table-column align="center" prop="chapterName" label="所属章节">
                        </el-table-column>
                    </el-table>
                    <div class="zdyfy">
                        <pagination :limit.sync="10" :layout="'prev, pager, next, jumper'" :page.sync="pageNum"
                            v-show="total > 0" :total="total" @pagination="changePage" />
                    </div>
                </el-col>
                <el-col :span="12" id="tcimgwidth">
                    <div
                        style="height: calc(100vh - 370px); overflow-y: auto;background: rgb(243, 243, 243);margin-left: 15px;">
                        <div style="margin: 10px;">
                            <div
                                :style="'margin:0 auto;width: ' + tcimgwidth + 'px;height: ' + fileData.fileHeight / fileData.fileWidth * tcimgwidth + 'px;position: relative;'">
                                <img :style="'pointer-events: none;position:absolute;border:1px solid #eeeeee;border-radius:10px;width:' + tcimgwidth + 'px;left:0;height:' + fileData.fileHeight / fileData.fileWidth * tcimgwidth + 'px'"
                                    :src="fileData.imgFileUrl + '' + pageNum + '.png_nostyle'" alt="">
                                <div v-for="item in areaData[pageNum - 1]" v-show="pageNum == item.page"
                                    :style="`position:absolute;left:${(item.x) / fileData.fileWidth * tcimgwidth + 1}px;top:${(item.y) / fileData.fileWidth * tcimgwidth}px;width:${(item.width) / fileData.fileWidth * tcimgwidth}px;height:${(item.height) / fileData.fileWidth * tcimgwidth}px;background:${selectFormList[pageNum - 1].some(obj => obj.areaId == item.areaId) ? 'rgba(149, 239, 65, 0.5)' : 'rgba(93, 201, 241, 0.5)'};border:1px solid #333;font-size:12px;overflow:hidden;color:red;`">
                                    {{ item.name }}
                                </div>
                            </div>

                        </div>
                    </div>
                </el-col>
            </el-row>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="areaSubmitForm">
                        确定
                    </el-button>
                    <el-button @click="isConfigQuestion = false">
                        取消
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { bookList } from "@/api/adminservice/public"
import perviewSiginCanvas from "@/components/perviewSiginCanvas.vue"
import {
    queryAllGroup,
    getData,
    delData,
    addData,
    updateData,
    exportData
} from "@/api/demo/data";
import Sortable from 'sortablejs'

import { activityInfo, activityFileUpload, activityCreate, activityDelete, activityList, activityReleaseOrCancel, activityShareOrCancel, activityStopOrCancel, activityUpdate, activityFileDelete, zbhsignBookList, activityFileShareOrCancel, activityDeleteWritePage, activityFileTop } from "@/api/adminservice/signIn"
import { timestampToTime } from "@/utils/date";
import { queryCustoms, stopCustoms, delCustoms, queryTreeCustomOrg } from "@/api/adminservice/custom";
import { queryCustomQun } from "@/api/adminservice/qun";
import { download } from "@/api/adminservice/public";
import { zhktQueryDict, schoolList, schoolDelete, teacherList, teacherDelete } from "@/api/adminservice/classOrganizaMain";
import { fileAreaSort, fileAreaRemove, fileAreaEdit, fileAreaDel, fileAreaConfig, eduFileList, eduChapterDelete, eduFileDel, eduChapterEdit, chapterList, fileAreaList, eduFileInfo } from "@/api/adminservice/readOver"

export default {
    name: "teachingAidsStructure",
    components: {
        perviewSiginCanvas
    },
    data() {
        return {
            visible: false,
            total: 1,
            pageNum: 1,
            fileData: {},
            rgimgwidth: 400,
            tcimgwidth: 400,
            isSHowNoPz: false,
            areaData: [],
            chapterAreaData: [],
            chapterData: [],
            chapterList: [],
            isChapter: false,
            isConfigQuestion: false,
            timestampToTime,
            showFileType: '图片素材',
            fileShow: false,
            isShare: false,
            shareUrl: '',
            selectCzObj: {},
            selectObj: {},
            schoolList: [],
            filesList: [],
            showUrlList: [],
            token: this.$storage.get("token"),
            isSuperAuth: this.$storage.get('isSuperAuth'),
            // 遮罩层
            loading: false,
            // 选中数组
            ids: [],
            readBook: false,
            readBookList: [],
            showFileList: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                customId: '',
                fileId: ''
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            chapterFormRules: {
                chapterName: [{ required: true, message: "请输入章节名称", trigger: "blur" },],
                page: [{ required: true, message: "请输入章节名称", trigger: "blur" },]
            },
            areaFormRules: {
                name: [{ required: true, message: "请输入题目名称", trigger: "blur" },],
            },
            orgList: [],
            customList: [],
            schoolId: '',
            scShow: false,
            role: '',
            isPerview: false,
            isTimeFalse: true,
            isCreateChapter: false,
            chapterForm: {},
            areaForm: {
                name: ''
            },
            chapterId: '',
            selectFormList: [],
            isCreateArea: false,
            scTable: [],
        };
    },
    created() {
        this.role = this.$storage.get("role")
        this.getZhktQueryDict();
        this.getList();

    },
    activated() {
        // console.log('fdssfd',this.$route.query.isSx)
        if (this.$route.query.isSx == 1) {
            this.getList();
            if (this.$route.query.customId) {
                this.queryParams.customId = this.$route.query.customId
                this.changeCustom()
            } else {
            }
        }
    },
    mounted() {
        this.getList();
        this.getBookList();
        this.rgimgwidth = (document.getElementById("rgimgwidth").clientWidth - 40)
    },
    methods: {
        toCloseAll(m) {
            if (this.areaData[m].length != 0) {
                // 获取所有已展开元素
                const expanded = this.$el.getElementsByClassName(
                    "el-table__expand-icon--expanded"
                );
                if (expanded) {
                    for (let i = 0; i < expanded.length; i++) {
                        // 点击事件：触发展开图标的点击事件
                        expanded[i].click();
                    }
                }
            }
        },
        //行拖拽
        rowDrop(i) {
            setTimeout(() => {
                const tbody = document.querySelector(`.areaDatatable${i} .el-table__body-wrapper tbody`)
                console.log(tbody)
                const _this = this
                // var scTable = JSON.parse(JSON.stringify(_this.areaData[i]))
                Sortable.create(tbody, {
                    filter: '.el-table__row--level-1',
                    onStart(evt) {
                        console.log(evt);
                        // _this.toCloseAll(i)
                    },
                    onMove(evt, originalEvent) {
                        if (evt.related.innerText.indexOf('子单元格') != -1) {
                            return false
                        } else {

                        }
                    },
                    onEnd(evt) {
                        console.log(evt);
                        var total = 0
                        var childList = [];
                        var childStart = [];
                        _this.areaData[i].forEach((e, i) => {
                            total++;
                            if (e.children) {
                                childStart.push(total);
                                childList.push(e.children.length)
                                e.children.forEach((j, k) => {
                                    total++;
                                })

                            }
                        })
                        var newNum = evt.newIndex + 1;
                        var oldNum = evt.oldIndex + 1;
                        childStart.forEach((e, i) => {
                            if (evt.oldIndex + 1 > e) {
                                oldNum = oldNum - childList[i]
                            }
                            if (evt.newIndex + 1 > e) {
                                newNum = newNum - childList[i]
                            }
                        })
                        const currRow = _this.scTable[i].splice(oldNum - 1, 1)[0]
                        _this.scTable[i].splice(newNum - 1, 0, currRow)
                        console.log(_this.scTable[i])
                    }
                })
            })

        },
        changePage(e) {
            this.rowDrop(e.page - 1);
            console.log(e.page, e.limit);
            this.pageNum = e.page;
        },
        tableRowClassName: function (row, index) {
            console.log(row.row)
            if (row.row.chapterId) {
                return 'hidden-row';
            }
            return '';
        },
        areaSubmitForm() {
            var areaIds = []
            this.selectFormList.forEach(e => {
                e.forEach(j => {
                    areaIds.push(j.areaId)
                })
            })
            if (areaIds.length == 0) {
                this.$message.error("请选择数据")
                return
            }
            console.log(this.selectFormList)
            var sortArea = []
            this.scTable.forEach(e => {
                e.forEach(j => {
                    sortArea.push(j.areaId)
                })
            })
            fileAreaSort({ areaIds: sortArea }).then(respon => {
                fileAreaConfig({ areaIds: areaIds.join(','), chapterId: this.chapterId }).then(res => {
                    if (res.data.code == 1) {
                        this.$message.success("配置成功");
                        this.isConfigQuestion = false;
                        this.selectFormList = [];
                        this.toActiveChapter();
                    } else {
                        this.$message.error("操作失败")
                    }
                })
            }).catch(error => {
                fileAreaConfig({ areaIds: areaIds.join(','), chapterId: this.chapterId }).then(res => {
                    if (res.data.code == 1) {
                        this.$message.success("配置成功");
                        this.isConfigQuestion = false;
                        this.selectFormList = [];
                        this.toActiveChapter();
                    } else {
                        this.$message.error("操作失败")
                    }
                })
            })
        },
        handleSelectionFormChange(val, index) {
            console.log(val);
            this.selectFormList[index] = val;
        },
        toActiveChapter(item) {
            if (item) {
                console.log(item);
                this.chapterId = item.chapterId;
            }
            console.log(this.chapterId);
            fileAreaList({ fileId: this.queryParams.fileId, chapterId: this.chapterId }).then(res => {
                this.chapterAreaData = res.data.data;
                if (item) {
                    if (this.chapterAreaData.length != 0) {
                        this.selectObj = this.chapterAreaData[0]
                    }
                }
            })
        },
        goFileArea(item) {
            this.selectFormList = [];
            this.chapterId = item.chapterId;
            fileAreaList({ fileId: this.queryParams.fileId }).then(res => {
                this.areaData = [];
                this.scTable = [];
                this.pageNum = 1;
                var lsdata = res.data.data;
                console.log(lsdata);

                // this.areaData = res.data.data;
                for (var i = 0; i < this.total / 10; i++) {
                    console.log(i);
                    this.areaData.push([]);
                    this.selectFormList.push([]);
                    lsdata.forEach((e, m) => {
                        console.log(e.name);
                        if (e.page == (i + 1)) {
                            this.areaData[i].push(e)
                        }
                    })
                }
                for (var i = 0; i < this.total / 10; i++) {
                    this.areaData[i] = this.areaData[i].sort(function (a, b) {
                        if (a.sort) {
                            if (a.sort > b.sort) { return 1; }
                            if (a.sort < b.sort) { return -1; }
                        }
                        // else {
                        //     if (a.name > b.name) { return 1; }
                        //     if (a.name < b.name) { return -1; }
                        // }

                        return 0;
                    })
                }
                this.isSHowNoPz = false;
                this.isConfigQuestion = true;
                setTimeout(() => {
                    this.tcimgwidth = (document.getElementById("tcimgwidth").clientWidth - 40)
                    this.areaData.forEach((e, i) => {
                        var reftxt = 'areaDatatable' + i;
                        e.forEach((j, k) => {
                            if (j.chapterId == this.chapterId) {
                                this.$refs[reftxt][0].toggleRowSelection(e[k], true);
                            }
                        })
                    })
                    this.scTable = JSON.parse(JSON.stringify(this.areaData))
                    console.log(this.areaData);
                    console.log(this.scTable);
                    setTimeout(() => {
                        this.rowDrop(0);
                    })
                })

            })
        },
        areaqeesSubmitForm() {
            this.$refs["areaForm"].validate(valid => {
                if (valid) {
                    console.log(1);
                    var subchapterList = {
                        fileId: this.queryParams.fileId,
                        areaList: [this.areaForm]
                    }
                    fileAreaEdit(subchapterList).then(res => {
                        if (res.data.code == 1) {
                            this.$message.success("操作成功");
                            this.isCreateArea = false;
                            this.toActiveChapter();
                        } else {
                            this.$message.error("操作失败");
                        }
                    })
                }
            });
        },
        chapterSubmitForm() {
            this.$refs["chapterForm"].validate(valid => {
                if (valid) {
                    console.log(1);
                    var subchapterList = {
                        fileId: this.queryParams.fileId,
                        chapterList: [{
                            page: this.chapterForm.page,
                            chapterName: this.chapterForm.chapterName,
                            chapterId: this.chapterForm.chapterId,
                        }]
                    }
                    eduChapterEdit(subchapterList).then(res => {
                        if (res.data.code == 1) {
                            this.$message.success("操作成功");
                            this.isCreateChapter = false;
                            this.handleChapter();
                        } else {
                            this.$message.error("操作失败");
                        }
                    })
                }
            });
        },
        onChapterAddItem() {
            this.chapterForm = {
                chapterName: '',
                page: ''
            }
            this.isCreateChapter = true;
        },
        editAreaDetail(row) {
            this.areaForm = row;
            this.isCreateArea = true;
        },
        editChapterDetail(row) {
            this.chapterForm = row;
            this.isCreateChapter = true;
        },
        deleteChapterRow(row) {
            console.log(row)
            eduChapterDelete({ chapterId: row.chapterId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.handleChapter();
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        deleteAreaRow(row) {
            console.log(row)
            var areaIds = []
            this.chapterAreaData.forEach(e => {
                if (e.areaId != row.areaId) {
                    areaIds.push(e.areaId)
                }
            })
            fileAreaRemove({ areaId: row.areaId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("移除成功");
                    // this.handleChapter();
                    this.toActiveChapter();
                } else {
                    this.$message.error("操作失败");
                }
            })
        },

        submitChapterData() {
            var subchapterList = {
                fileId: this.queryParams.fileId,
                chapterList: this.chapterData
            }
            eduChapterEdit(subchapterList).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("操作成功");
                    this.isChapter = false;
                    this.getChapterList();
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        handleChapter(e) {
            if (this.queryParams.fileId == '') {
                this.$message.error('请选择教辅')
                return;
            }
            chapterList({ fileId: this.queryParams.fileId }).then(res => {
                this.chapterData = res.data.data;
                this.chapterList = res.data.data;
                this.isChapter = true;
            })
        },
        getChapterList() {
            this.selectObj = {}
            this.chapterAreaData = [];
            chapterList({ fileId: this.queryParams.fileId }).then(res => {
                this.chapterList = res.data.data;
                console.log(this.chapterList);
                if (this.chapterList.length != 0) {
                    this.chapterId = this.chapterList[0].chapterId
                    this.toActiveChapter(this.chapterList[0])
                }
            })
            eduFileInfo({ fileId: this.queryParams.fileId }).then(res => {
                this.fileData = res.data.data;
                this.total = res.data.data.pages * 10;
            })
        },
        canvasClPerview(e) {
            this.isPerview = false;
        },
        handlePerview(row) {
            this.selectCzObj = row;
            activityInfo({ activityId: this.selectCzObj.activityId }).then(res => {
                this.selectCzObj = res.data.data;
                console.log(this.selectCzObj);
                this.isPerview = true;
            })
        },
        handleShare(row) {
            this.shareUrl = '';
            this.isShare = true;
            this.shareUrl = 'www.baidu.com';
            setTimeout(() => {
                var iframe = document.getElementById("ckpxpfx"); // 获取到要关闭的iframe元素
                iframe.setAttribute("src", 'https://zbh.zbform.com/sign?activityId=' + row.activityId);
            })
            // this.shareUrl = 'https://zbsign.zbform.com/share?activityId='+row.activityId;
        },
        gbIfram() {
            var iframe = document.getElementById("ckpxpfx"); // 获取到要关闭的iframe元素
            iframe.setAttribute("src", '');
            console.log(iframe);
            if (typeof iframe !== "undefined" && iframe != null) {
                var contentWindow = iframe.contentWindow; // 获取到iframe内部的window对象
                if (typeof contentWindow !== "undefined" && contentWindow != null) {
                    console.log(1122);
                    contentWindow.close(); // 调用close()方法关闭iframe
                } else {
                    console.log("无效的iframe或者未加载完成！");
                }
            } else {
                console.log("没有找到指定的iframe元素！");
            }
        },
        handleFx() {
            if (!this.selectObj || !this.selectObj.activityId) {
                this.$message.error("请选择数据");
                return;
            }
            // if (this.selectObj.processStatus == 1 || this.selectObj.processStatus == 2) {
            //     this.$message.error("发布和停用的数据不能铺设");
            //     return;
            // }
            activityShareOrCancel({ activityId: this.selectObj.activityId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("操作成功");
                    this.getList();
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        handleStop() {
            if (!this.selectObj || !this.selectObj.activityId) {
                this.$message.error("请选择数据");
                return;
            }
            if (this.selectObj.status != 1 && this.selectObj.status != 2) {
                this.$message.error("请选择发布或者结束的数据");
                return;
            }
            activityStopOrCancel({ activityId: this.selectObj.activityId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("操作成功");
                    this.getList();
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        handleRelease() {
            if (!this.selectObj || !this.selectObj.activityId) {
                this.$message.error("请选择数据");
                return;
            }
            // if (this.selectObj.processStatus == 1 || this.selectObj.processStatus == 2) {
            //     this.$message.error("发布和停用的数据不能铺设");
            //     return;
            // }
            activityReleaseOrCancel({ activityId: this.selectObj.activityId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("操作成功");
                    this.getList();
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        handleCurrentChange(val) {
            console.log(val)
            this.selectObj = val;
        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        handleSCChange(e, type) {
            console.log(e, type)
            console.log(this.filesList);
            if (!(e.raw.type == 'image/jpeg' || e.raw.type == 'image/png' || e.raw.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (e.raw.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            let formdata = new FormData();
            formdata.append('files', e.raw)
            formdata.append('activityId', this.selectCzObj.activityId)
            activityFileUpload(formdata).then(res => {
                if (res.data.code == 1) {
                    this.getActivtyInfo()
                    if (this.isTimeFalse) {
                        this.$message.success("新增成功");
                    }
                    this.isTimeFalse = false;
                    setTimeout(() => {
                        this.isTimeFalse = true;
                    }, 5000)

                } else {
                    this.$message.error("操作失败")
                }
            })
        },
        zdTop(fileId, index) {
            activityFileTop({ fileId: fileId }).then(res => {
                if (res.data.code == 1) {
                    this.getActivtyInfo()
                    // this.showFileList.splice(index, 1);
                    // this.showUrlList.splice(index, 1);
                    this.$message.success('操作成功');
                } else {
                    this.$message.error('操作失败');
                }

            })
        },
        sharePicId(fileId, index) {
            activityFileShareOrCancel({ fileId: fileId }).then(res => {
                if (res.data.code == 1) {
                    if (this.showFileList[index].isShare == 1) {
                        this.showFileList[index].isShare = 0
                    } else {
                        this.showFileList[index].isShare = 1
                    }
                    // this.showFileList.splice(index, 1);
                    // this.showUrlList.splice(index, 1);
                    this.$message.success('操作成功');
                } else {
                    this.$message.error('操作失败');
                }
            })
        },
        delPicId(fileId, index) {
            activityFileDelete({ fileId: fileId }).then(res => {
                if (res.data.code == 1) {
                    this.showFileList.splice(index, 1);
                    this.showUrlList.splice(index, 1);
                    this.$message.success('删除成功');
                } else {
                    this.$message.error('操作失败');
                }
            })
        },
        handlePic(row) {
            this.selectCzObj = row;
            this.fileShow = true;
            this.getActivtyInfo()
            // showUrlList
        },
        handleDp(row) {
            // this.$router.push({
            //     path: '/signIn',// 这里的路径就可以正常的写，不需要添加_blank: true
            //     query:{
            //         activityId:row.activityId
            //     }
            // })
            let routeUrl = this.$router.resolve({
                path: '/signIn',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: {
                    activityId: row.activityId
                }
            })
            window.open(routeUrl.href, '_blank')
        },
        getActivtyInfo() {
            this.showUrlList = [];

            activityInfo({ activityId: this.selectCzObj.activityId }).then(res => {
                this.showFileList = res.data.data.fileList;
                this.showFileList.forEach(e => {
                    this.showUrlList.push(e.fileUrl)
                })

            })
        },
        changeCustomId() {
            this.queryParams.classId = '';
            this.queryParams.schoolId = '';
            schoolList({ customId: this.queryParams.customId ? this.queryParams.customId : this.$storage.get('customId') }).then(res => {
                this.schoolList = res.data.data;
                this.getList();
            });
        },
        getZhktQueryDict() {
            schoolList({ customId: this.$storage.get('customId') }).then(res => {
                this.schoolList = res.data.data;
            });
            this.customList = []
            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.customType.includes('040');
                })
                res.data.data.sort(function (a, b) {
                    return a.customName.localeCompare(b.customName);
                })
                res.data.data.forEach(e => {
                    if (e.status == 0) {
                        this.customList.push(e)
                    }
                })
                res.data.data.forEach(e => {
                    if (e.status == 1) {
                        this.customList.push(e)
                    }
                })
                this.customList.forEach((e,i) => {
                    if (e.customName == '南京孜博汇信息科技有限公司') {
                        this.customList.splice(i,1)
                        this.customList.unshift(e)
                    }
                })
            });
        },
        excelAdd() {
            schoolList({ customId: this.$storage.get('customId') }).then(res => {
                this.schoolList = res.data.data;
            });
            this.schoolId = ''
            this.scShow = true;
        },
        goDetail(row) {
            this.$router.push({
                path: "/eventMainDetail",
                query: {
                    activityId: row.activityId,
                }
            });
        },
        downloadTemplate() {
            download('https://zbh-oss-formal.oss-cn-beijing.aliyuncs.com/common/excel/%E6%99%BA%E6%85%A7%E8%AF%BE%E5%A0%82%E8%80%81%E5%B8%88%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx', '导入教师模板')
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            // if (file.url) {
            //     delLessonFile({ fileUrl: file.url })
            // }
            // if (file.response) {
            //     delLessonFile({ fileUrl: file.response.data })
            // }
        },
        handleChange(e, type) {
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    this.$message.success('导入成功')
                    this.scShow = false;
                    this.getList()
                } else {
                    if (e.response.msg) {
                        this.$message.error(e.response.msg)
                    } else {
                        this.$message.error('导入失败')
                    }
                }
            }
            if (e.status == 'fail') {
                this.$message.error('导入失败')
            }
        },
        // id 指的是当前点击的节点id;
        findParentNode(id) {
            // debugger
            // 初始化所需数据
            var firstParentObj = {}; // 记录直系父级的名称和id即接口要传的数据
            var parentIds = []; // 记录所有的父级ids
            var parentIdsQueue = []; // 记录排队的

            // 将树放到排队系列
            var parentIdsQueue = JSON.parse(JSON.stringify(this.orgList));

            // 开始遍历排队的树
            while (parentIdsQueue.length) {
                //抽取第一个排队的数据 
                let item = parentIdsQueue.shift();

                let { childrenList } = item;
                if (item.orgId === id) {
                    // 第一层就找到了
                    if (!item.parentArr) {
                        firstParentObj = {
                            id: item.orgId,
                            name: item.orgName
                        };
                        parentIds = [item.orgName];
                    } else {
                        // 获取当前节点的parentArr
                        let len = item.parentArr.length;
                        firstParentObj = item.parentArr[len - 1];
                        item.parentArr.forEach(a => {
                            parentIds.push(a.name);
                        });
                        parentIds.push(item.orgName);
                    }

                    // 结束遍历
                    parentIdsQueue = [];

                    break;
                } else if (childrenList && childrenList.length) {
                    let len = childrenList.length;
                    for (let i = len - 1; i >= 0; i--) {
                        // 新建一个数组用于记录它的父亲节点
                        childrenList[i].parentArr = [];

                        // 把它的历史父亲节点们先放入
                        if (item.parentArr) {
                            childrenList[i].parentArr = childrenList[i].parentArr.concat(
                                item.parentArr
                            );
                        }

                        // 再放入当前的父亲节点
                        childrenList[i].parentArr.push({
                            id: item.orgId,
                            name: item.orgName
                        });

                        // 加入到排队序列中
                        parentIdsQueue.unshift(childrenList[i]);
                    }

                }
            }
            // console.log(parentIds)
            return parentIds

        },
        changeCustom() {

        },
        readbookIds(row) {
            this.readBookList = [];
            this.bookList.forEach(e => {
                row.bookIds.forEach(j => {
                    if (e.id == j) {
                        this.readBookList.push(e)
                    }
                })
            })
            this.readBook = true;
        },
        getBookList() {
            bookList().then(res => {
                this.bookList = res.data.data;
            });
        },
        tgMethod(e) {
            this.queryParams.customName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        /** 查询基础：基数数据列表 */
        getList(e) {
            if (!this.role.includes(0)) {
                this.queryParams.customId = this.$storage.get('customId');
            }
            this.loading = false;
            this.queryParams.fileId = '';
            this.selectObj = {};
            this.chapterId = '';
            this.chapterAreaData = [];
            this.chapterList = [];
            eduFileList(this.queryParams).then(res => {
                this.dataList = res.data.data;
            })
            // queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
            //     this.customList = res.data.data;
            //     if (!this.$route.query.customId) {
            //         this.queryParams.customId = res.data.data[0].customId;
            //         this.changeCustom()
            //     } else {
            //     }
            // });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            activityDelete({ activityId: row.activityId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getList();
                } else {
                    this.$message.error("删除失败");
                }
            })
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.$router.push({
                path: "/eventMainAdd",
                query: {
                    activityId: row.activityId,
                }
            });
            // this.taskdata = row;
            // this.open = true;
        }
    }
};
</script>
<style scoped>
.el-table /deep/ .hidden-row {
    display: none;
}

.disnone /deep/ .el-upload-list {
    display: none;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.csbbb .eicon {
    /* display: none; */
    position: absolute;
    right: 20px;
    top: 5px;
    background-color: #e4e7ed;
}

.csbbb:hover .eicon {
    display: block;
}

.csbbb .share {
    /* display: none; */
    position: absolute;
    right: 50px;
    top: 5px;
    background-color: #e4e7ed;
}

.csbbb .Top {
    position: absolute;
    right: 80px;
    top: 5px;
    background-color: #e4e7ed;
}

.csbbb:hover .share {
    display: block;
}

.sssstt /deep/ .el-scrollbar__view {
    display: block !important;
}

.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #21ac74;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}</style>