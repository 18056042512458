<template>
    <div id="login-box">
        <el-container>
            <el-container>
                <!--顶部-->
                <!--登录框-->
                <el-main>
                    <div id="login-form">
                        <el-form ref="loginFormRef" :model="loginForm" :rules="loginRules" v-if="!isSucess">
                            <p class="login-type">{{ sceneName }}<br>邀请你加入</p>
                            <el-form-item prop="memberAccount">
                                <el-input size="large" type="text" v-model="loginForm.memberAccount" placeholder="请输入账号"
                                    prefix-icon="Iphone"></el-input>
                            </el-form-item>
                            <el-form-item style="padding-top: 15vh;">
                                <el-button size="large" class="loginBtn" type="primary" :loading="loginBtnLoading"
                                    @click="subUserLogin">加入</el-button>
                            </el-form-item>
                        </el-form>
                        <p class="login-type" v-if="isSucess">你已成功加入</p>
                    </div>
                </el-main>
                <!--底部-->
                <el-footer>
                    <el-row justify="center" type="flex">
                        <el-col style="text-align: center;">
                            版权所有©南京孜博汇信息科技有限公司 | 苏ICP备18041825号-1
                        </el-col>
                    </el-row>
                </el-footer>
            </el-container>
        </el-container>
    </div>
</template>
  
<script>
import { userLogin, isAuthGroup } from "@/api/user/login";
import { qunQueryUser,adminqueryUser } from "@/api/adminservice/qun";
import { addMember } from "@/api/adminservice/scene"
import { validatenull, isMobile } from '@/utils/validate'
import { adminQueryOrRegUser } from "@/api/adminservice/public"

export default ({
    name: "userLogin",
    data() {
        return {
            loginBtnLoading: false,
            isSucess:false,
            /*登录数据绑定对象*/
            loginForm: {
                memberAccount: '',
                memberName: "",
                memberUserId: "",
                sceneId: ""
            },
            /*登录校验规则*/
            loginRules: {
                memberAccount: [
                    { required: true, message: "请输入登录账号", trigger: "blur" },
                    { validator: this.validateUsername, trigger: "blur" }
                ],
            },
            sceneName: ''
        }
    },
    created() {
        let that = this
        this.loginForm.sceneId = this.$route.query.sceneId;
        this.sceneName = this.$route.query.sceneName;
    },
    methods: {
        validateUsername(rule, value, callback) {
            // adminqueryUser({ mobile: value }).then(res => {
            //     const result = res.data.data
            //     if (!result) {
            //         callback(new Error("该账号用户不存在"))
            //     } else {
            //         this.loginForm.memberName = res.data.data.nickName;
            //         this.loginForm.memberUserId = res.data.data.userId;
            //         callback()
            //     }
            // })
            if (!isMobile(value)) {
                callback(new Error("手机号码格式不正确"))
                return
            }
            adminQueryOrRegUser({ username: value,trueName:value }).then(res => {
                const result = res.data.data
                if (!result) {
                    callback(new Error("该账号用户不存在"))
                } else {
                    this.loginForm.memberName = res.data.data.trueName?res.data.data.trueName:value;
                    this.loginForm.memberUserId = res.data.data.userId;
                    callback()
                }
            })
        },
        subUserLogin() {
            this.$refs.loginFormRef.validate(valid => {
                if (!valid) return
                console.log(this.loginForm)
                // let pwd = this.$encrypt(this.loginForm.password)
                // let loginInfo = { username: this.loginForm.username, password: this.loginForm.password }
                this.loginBtnLoading = true
                addMember(this.loginForm).then(res => {
                    if (res.data.code === 1) {
                        this.loginBtnLoading = false
                        this.isSucess = true;
                    } else {
                        this.$message.error(res.data.msg)
                        this.loginBtnLoading = false
                    }
                })
                // userLogin(loginInfo).then(res => {
                //     if (res.data.code === 1) {
                //         const info = res.data.data
                //         debugger
                //         this.$storage.set("userInfo", JSON.stringify(info.userInfo));
                //         this.$storage.set("token", info.token);
                //         this.$storage.set("role", info.role);
                //         if (info.custom) {
                //             this.$storage.set("customId", info.custom.customId);
                //         } else {
                //             this.$storage.set("customId", '');
                //         }


                //         // this.$storage.set("lastGroupAccount",JSON.stringify(info.lastGroupAccount));
                //         // this.$storage.set("groupAccounts",JSON.stringify(info.groupAccounts));
                //         this.loginBtnLoading = false
                //         this.$router.push("/home")
                //     } else {
                //         this.loginBtnLoading = false
                //         this.$message.error(res.data.msg)
                //     }
                // })
            })
            // this.$storage.set("token",'fdsfdsdfdsds');
            // this.$router.push("/home")
        },
        toRes() {
            window.location.href = "https://zhuce.zbform.com"
        },
        toCodeLogin() {
            console.log("验证码登录")
            this.$router.push("/codeLogin")
        },
        toResetPwd() {
            this.$router.push("/resetPwd")
        },
    }
})
</script >
  <!--样式-->
<style lang="scss" scoped>
@import url(../../styles/oldLogin.scss);

/*登录框*/
#login-box {
    width: 100%;
    height: 100%;

    .el-container {
        height: 100%;
    }

    .el-header {
        padding: 0 0;
        font-size: 18px;
        text-align: center;
        height: 80px !important;

        .el-link {
            color: #4c596e;
            text-decoration: none;
        }

        .el-link:hover {
            color: #2489f2;
            text-decoration: none;
        }
    }

    .el-footer {
        color: #4c596e;
        text-align: center;
        height: 40px !important;
        font-size: 14px;
    }

    .el-aside {
        width: 34%;
        background-image: url("../../assets/login_l_bg.jpg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .el-main {
        padding: 0 0;
        position: relative;
    }

    .el-row {
        height: 100%;
        align-items: center;
    }

    .el-col {
        child-align: middle;
    }
}

.tab-one {
    cursor: pointer;
    font-size: 14px;
    color: #7f8997;
}

.tab-one>a:hover {
    color: #428bfa;
}
</style>
  
  