<template>
    <div class="app-container" @click="visible = false;">
        <el-card shadow="never" style="height: calc(100vh - 130px);">
            <el-row v-if="!role.includes(1)">
                <el-col :span="24" class="mr0 mb0">
                    <div class="pull-right">
                        <el-popover :visible="visible" popper-style="width: 320px !important;" placement="bottom"
                            :min-width="'auto'">
                            <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
                                <el-row class="mt15">
                                    <el-col :span="24" class="mr0">
                                        <el-form-item label="客户名称" prop="termId" class="w-100">
                                            <el-select clearable class="w-100" v-model="queryParams.customId"
                                                placeholder="请选择客户">
                                                <el-option v-for="item in customList" :key="item.customId"
                                                    :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                                    :value="item.customId" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <div style="margin:0 auto;">
                                        <el-button @click="changeCustom()" type="primary">查找</el-button>
                                    </div>
                                </el-row>
                            </el-form>
                            <template #reference>
                                <el-button type="primary" @click.stop="visible = true;">筛选条件</el-button>
                            </template>
                        </el-popover>
                    </div>
                </el-col>
            </el-row>
            <!-- <el-divider /> -->

            <el-empty style="padding-top: 20vh;" v-if="!isShow" :description="'请选择客户组织结构'" />
            <vue3-tree-org v-if="isShow" center style="height: calc(100vh - 190px);" :data="treeData"
                :before-drag-end="beforeDrag" :horizontal="horizontal" :collapsable="collapsable" :label-style="style"
                :node-draggable="true" :scalable="true" :only-one-node="onlyOneNode" :default-expand-level="100"
                :clone-node-drag="cloneNodeDrag" @on-contextmenu="onMenus" :props="treeProps" @on-node-click="onNodeClick"
                @on-node-drag-start="dragStart">
                <!-- 自定义节点内容 -->
                <template v-slot="{ node }">
                    <div class="tree-org-node__text node-label zdyedit">
                        <div style="font-size: 12px;" class="custom-content">{{
                            node.$$data.orgType == 0 ? '客户' : node.$$data.orgType == 1 ? '类别' : node.$$data.orgType == 2 ?
                                '组织' : node.$$data.orgType == 3 ? '类别' : node.$$data.orgType == 5 ? '群组' : ''
                        }}</div>
                        <div class="sss" style="min-width: 120px;">{{ node.label }}</div>
                        <div v-if="node.$$data.adminList" style="font-size: 12px;min-width: 100px;" class="custom-content">
                            {{ node.$$data.adminList[0].adminName }}
                        </div>
                        <div class="editbox" v-if="node.$$data.orgType != 5">
                            <el-button v-if="node.$$data.orgType != 2 && node.$$data.orgType != 3" size="large" type="text"
                                icon="el-icon-sold-out" title="创建客户类别" @click="handleCreateCustom(node, 1)">
                            </el-button>
                            <el-button v-if="node.$$data.orgType != 2 && node.$$data.orgType != 3" size="large" type="text"
                                icon="el-icon-document-add" title="创建组织" @click="handleCustomOrgCreateOrg(node)">
                            </el-button>
                            <el-button v-if="node.$$data.orgType == 2 || node.$$data.orgType == 3" size="large" type="text"
                                icon="el-icon-sold-out" title="创建组织类别" @click="handleCreateCustom(node, 3)">
                            </el-button>
                            <el-button v-if="node.$$data.orgType == 2" size="large" type="text" icon="el-icon-notebook"
                                title="配置资源库" @click="setBook(node)">
                            </el-button>
                            <el-button size="large" type="text" icon="el-icon-grid" title="创建下级群组"
                                v-if="screenZj(node.$$data)" @click="handleCreateCustom(node, 5)">
                            </el-button>
                            <el-button v-if="node.$$data.orgType != 0" size="large" type="text" icon="el-icon-edit"
                                title="编辑" @click="handleUpdate(node)">
                            </el-button>
                            <el-popconfirm v-if="node.$$data.orgType != 0" confirm-button-text="是" cancel-button-text="否"
                                :icon="InfoFilled" icon-color="#626AEF" title="确定删除" @confirm="handleDelete(node)">
                                <template #reference>
                                    <el-button size="large" title="删除" type="text" icon="el-icon-delete">
                                    </el-button>
                                </template>
                            </el-popconfirm>
                        </div>
                        <!-- <div class="editbox" v-if="node.$$data.orgType == 5">
                            <el-button size="large" type="text" icon="el-icon-edit" title="编辑" @click="editbj(node.$$data)">
                            </el-button>
                            <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                icon-color="#626AEF" title="确定删除" @confirm="handleDelete(node)">
                                <template #reference>
                                    <el-button size="large" title="删除" type="text" icon="el-icon-delete">
                                    </el-button>
                                </template>
                            </el-popconfirm>

                        </div> -->
                    </div>
                </template>
                <!-- 自定义展开按钮 -->
                <!-- <template v-slot:expand="{ node }">
                    <div>{{ node.children.length }}</div>
                </template> -->
            </vue3-tree-org>
        </el-card>
        <el-dialog :title="createKhCustomForm.orgId ? '修改组织' : '创建组织'" v-model="createKhCustomShow" width="60%"
            z-index="1000">
            <el-form ref="createKhCustomForm" :model="createKhCustomForm" :rules="createKhCustomRules" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="管理员账号" prop="adminAccount">
                            <el-input v-model="createKhCustomForm.adminAccount" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="管理员姓名" prop="adminName">
                            <el-input v-model="createKhCustomForm.adminName" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="组织名称" prop="orgName">
                            <el-input v-model="createKhCustomForm.orgName" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="介绍类型" prop="introduceType">
                            <el-radio-group v-model="createKhCustomForm.introduceType">
                                <el-radio :label="0">富文本</el-radio>
                                <el-radio :label="1">官网</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-show="createKhCustomForm.introduceType == 0">
                    <el-col :span="24">
                        <el-form-item label="内容" prop="fwbIntroduce">
                            <TEditor :height="'40vh'" ref="editor" :content="createKhCustomForm.fwbIntroduce"
                                v-model="createKhCustomForm.fwbIntroduce" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-show="createKhCustomForm.introduceType == 1">
                    <el-col :span="24">
                        <el-form-item label="内容" prop="gwIntroduce">
                            <el-input v-model="createKhCustomForm.gwIntroduce" placeholder="最多64个字" maxlength="64" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitKhOrgForm">确 定</el-button>
                    <el-button @click="createKhCustomShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="'创建'" v-model="createCustomShow" width="60%">
            <el-col>
                <el-form-item label="名称" prop="termId">
                    <el-input v-model="createdObj.orgName" placeholder="最多16个字" maxlength="16" />
                </el-form-item>
            </el-col>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitzdyForm">确 定</el-button>
                    <el-button @click="createCustomShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="'编辑类别'" v-model="customOrgUpdateShow" width="60%">
            <el-col :span="12">
                <el-form-item label="类别名称" prop="termId">
                    <el-input v-model="UpdateObj.orgName" placeholder="最多16个字" maxlength="16" />
                </el-form-item>
            </el-col>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitUpdateForm">确 定</el-button>
                    <el-button @click="customOrgUpdateShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="'配置云本'" v-if="readBook" v-model="readBook" width="60%">
            <el-col class="custom">
                <el-link type="primary" @click="openBook" :underline="false">资源配置</el-link>
                <div>
                    <div class="fl-warp" style="display: flex;">
                        <div v-for="item in bookList" class="csbbb"
                            :style="configObj.bookIds.indexOf(item.id) != -1 ? '' : 'display:none'">
                            <el-icon @click="delBookId(item.id)" class="eicon" style="cursor: pointer;">
                                <Delete />
                            </el-icon>
                            <img style="width: 100px;margin-top: 10px;"
                                :src="this.$store.state.zdy.imgUrl + '/book/' + item.id + '/img/cover.png_original'" alt=""
                                class="image">
                            <div style="width: 100px;text-align: center;" class="text-one mb15">{{
                                item.bookName }}</div>
                        </div>
                    </div>
                </div>
                <!-- <el-checkbox-group v-model="configObj.bookIds">
                    <el-checkbox :label="item.id" v-for="item in bookList">
                        <img style="width: 100px;margin-top: 10px;"
                            :src="this.$store.state.zdy.imgUrl+'/book/' + item.id + '/img/cover.png_original'"
                            alt="" class="image">
                        <div style="width: 100px;text-align: center;" class="text-one mb20">{{ item.bookName }}</div>
                    </el-checkbox>
                </el-checkbox-group> -->
            </el-col>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitConfigForm">确 定</el-button>
                    <el-button @click="configCancel">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="'创建群组'" v-model="createQzShow" width="60%">
            <el-form ref="qzform" :model="qzform" :rules="createQzRules" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="群名称" prop="qunName">
                            <el-input v-model="qzform.qunName" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="群主账号" prop="adminAccount">
                            <el-input v-model="qzform.adminAccount" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="云本配置" prop="bookIds">
                            <el-col class="custom">
                                <el-link type="primary" @click="openQzBook" :underline="false">资源配置</el-link>
                                <div>
                                    <div class="fl-warp" style="display: flex;">
                                        <div v-for="item in qzbookList" class="csbbb"
                                            :style="qzform.bookIds.indexOf(item.id) != -1 ? '' : 'display:none'">
                                            <el-icon @click="delQzBookId(item.id)" class="eicon" style="cursor: pointer;">
                                                <Delete />
                                            </el-icon>
                                            <img style="width: 100px;margin-top: 10px;"
                                                :src="this.$store.state.zdy.imgUrl + '/book/' + item.id + '/img/cover.png_original'"
                                                alt="" class="image">
                                            <div style="width: 100px;text-align: center;" class="text-one mb15">{{
                                                item.bookName }}</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <el-checkbox-group v-model="qzform.bookIds">
                                    <el-checkbox :label="item.id" v-for="item in qzbookList">
                                        <img style="width: 100px;margin-top: 10px;"
                                            :src="this.$store.state.zdy.imgUrl+'/book/' + item.id + '/img/cover.png_original'"
                                            alt="" class="image">
                                        <div style="width: 100px;" class="text-one mb15">{{ item.bookName }}</div>
                                    </el-checkbox>
                                </el-checkbox-group> -->
                            </el-col>

                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitQzForm">确 定</el-button>
                    <el-button @click="configQzCancel">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="'配置云本'" v-model="chooseBook" width="670px">
            <el-col class="custom ylsb" style="">
                <div v-for="item in bookList" :style="configObj.bookIds.indexOf(item.id) != -1 ? 'display:none' : ''">
                    <div class="ylqs" @click="setChooseBook(item.id)"
                        :style="chooseBookIds.indexOf(item.id) != -1 ? 'background: #a3d6ff' : ''">
                        <img style="width: 100px;margin-top: 10px;"
                            :src="this.$store.state.zdy.imgUrl + '/book/' + item.id + '/img/cover.png_original'" alt=""
                            class="image">
                        <div style="width: 100px;" class="text-one mb15">{{ item.bookName }}</div>
                    </div>
                </div>
            </el-col>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitChooseBookForm">确 定</el-button>
                    <el-button @click="chooseBookCancel">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="'配置云本'" v-model="chooseQzBook" width="670px">
            <el-col class="custom ylsb" style="">
                <div v-for="item in qzbookList" :style="qzform.bookIds.indexOf(item.id) != -1 ? 'display:none' : ''">
                    <div class="ylqs" @click="setChooseBook(item.id)"
                        :style="chooseBookIds.indexOf(item.id) != -1 ? 'background: #a3d6ff' : ''">
                        <img style="width: 100px;margin-top: 10px;"
                            :src="this.$store.state.zdy.imgUrl + '/book/' + item.id + '/img/cover.png_original'" alt=""
                            class="image">
                        <div style="width: 100px;" class="text-one mb15">{{ item.bookName }}</div>
                    </div>
                </div>
            </el-col>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitQzChooseBookForm">确 定</el-button>
                    <el-button @click="chooseQzBookCancel">取 消</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import TEditor from '@/components/TEditor.vue';
import { bookList } from "@/api/adminservice/public"
import {
    queryAllGroup,
    getData,
    delData,
    addData,
    updateData,
    exportData
} from "@/api/demo/data";
import { timestampToTime } from "@/utils/date";
import { queryCustoms, stopCustoms, custominfo, customOrgDel, queryTreeCustomOrg, customOrgQueryTreeOrg, customOrgCreate, customOrgUpdate, customOrgCreateOrg, configCustom, customOrgBook, moveOrg } from "@/api/adminservice/custom";
import { qunQueryUser, qunCreate } from "@/api/adminservice/qun"
// https://zbh-oss-formal.oss-cn-beijing.aliyuncs.com/common/excel/%E7%BB%84%E7%BB%87%E6%88%90%E5%91%98%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx

export default {
    name: "arrayMaintenance",
    components: {
        TEditor
    },
    data() {
        return {
            visible: false,
            chooseBook: false,
            chooseQzBook: false,
            qzbookList: [],
            qzform: {
                bookIds: []
            },
            createQzRules: {
                qunName: [
                    { required: true, message: "请输入群名称", trigger: "blur" },
                ],
                adminAccount: [
                    { required: true, message: "请输入群主账号", trigger: "blur" },
                    { validator: this.validateUsername, trigger: "blur" }
                ],
                bookIds: [
                    { required: true, message: "请选择资源库书本", trigger: "change" },
                ],
            },
            createKhCustomForm: {
                adminAccount: '',
                adminName: '',
                customId: '',
                orgName: '',
                parentOrgId: '',
                introduceType: 0,
                introduce: '',
                fwbIntroduce: '',
                gwIntroduce: ''
            },
            bookList: [],
            treeProps: {
                id: 'orgId',
                pid: 'parentId',
                label: 'orgName',
                children: 'childrenList'
            },
            treeData: {

            },
            horizontal: true,
            collapsable: true,
            onlyOneNode: false,
            cloneNodeDrag: false,
            expandAll: true,
            style: {
                background: "#fff",
                color: "#5e6d82",
            },
            customOrgUpdateShow: false,
            createCustomShow: false,
            isSuperAuth: this.$storage.get('isSuperAuth'),
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            readBook: false,
            readBookList: [],
            allBookList: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                customId: '',
                orgId: '',
            },
            customList: [],
            orgList: [],
            // 表单参数
            form: {},
            // 表单校验
            createKhCustomRules: {
                adminAccount: [
                    { required: true, message: "请输入管理员账号", trigger: "blur" }
                ],
                adminName: [
                    { required: true, message: "请输入管理员姓名", trigger: "blur" }
                ],
                orgName: [
                    { required: true, message: "请输入组织名称", trigger: "blur" }
                ],
            },
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            createdObj: {
                orgName: '',
                parentOrgId: '',
                orgType: 0      // /*客户及其下级分类：0-客户，1-客户类别，2-客户组织，3-组织类别，4-群组组织，5-群组*/
            },
            UpdateObj: {
                orgId: '',
                orgName: ''
            },
            isShow: false,
            createKhCustomShow: false,
            orgType: 0,
            configObj: {
                bookIds: [],
                orgId: ''
            },
            createQzShow: false,
            chooseBookIds: [],
            role: '',
        };
    },
    created() {
        this.role = this.$storage.get("role")
        this.getList();
        this.getBookList();
        if (this.$storage.get("role").includes(1)) {
            this.queryParams.customId = this.$storage.get("customId")
            this.changeCustom()
        }
    },
    methods: {
        delBookId(id) {
            this.configObj.bookIds.splice(this.configObj.bookIds.indexOf(id), 1)
        },
        setChooseBook(id) {
            if (this.chooseBookIds.indexOf(id) === -1) {
                this.chooseBookIds.push(id)
            } else {
                this.chooseBookIds.splice(this.chooseBookIds.indexOf(id), 1)
            }
        },
        chooseBookCancel() {
            this.chooseBook = false;
        },
        submitChooseBookForm() {
            this.configObj.bookIds = this.configObj.bookIds.concat(this.chooseBookIds)
            this.chooseBook = false;
        },
        openBook() {
            this.chooseBookIds = [];
            this.chooseBook = true;
        },
        openQzBook() {
            this.chooseBookIds = [];
            this.chooseQzBook = true;
        },
        delQzBookId(id) {
            this.qzform.bookIds.splice(this.qzform.bookIds.indexOf(id), 1)
        },
        chooseQzBookCancel() {
            this.chooseQzBook = false;
        },
        submitQzChooseBookForm() {
            this.qzform.bookIds = this.qzform.bookIds.concat(this.chooseBookIds)
            this.chooseQzBook = false;
        },
        editbj(row) {
            this.$router.push({
                path: "/groupSetAdd",
                query: {
                    customId: this.queryParams.customId,
                    groupData: JSON.stringify(row),
                    orgId: row.orgId
                }
            });
        },
        beforeDrag(e, f) {
            if (!f) {
                return false
            }
            console.log(f)
            console.log(e.$$data.orgId)
            console.log(this.findParentNode(e.$$data.orgId))
            if (e.$$data.orgType == 2) {
                if (this.findParentNode(f.$$data.orgId).indexOf(2) != -1) {
                    this.$message.error("组织不能移动到组织及组织以下结构")
                    return false
                } else {
                    moveOrg({ orgId: e.$$data.orgId, parentOrgId: f.$$data.orgId }).then(res => {
                        console.log(res)
                        if (res.data.code == 1) {
                            this.$message.success("移动成功");
                            return true
                        } else {
                            console.log(434343)
                            this.$message.error("操作失败")
                            this.changeCustom()
                            return false

                        }
                    })
                }
            } else {
                if (this.findParentNode(f.$$data.orgId).indexOf(2) == -1) {
                    this.$message.error("请选择有组织下的结构")
                    return false
                } else if (f.$$data.orgType == 5) {
                    this.$message.error("不能选择群组")
                    return false
                } else {
                    moveOrg({ orgId: e.$$data.orgId, parentOrgId: f.$$data.orgId }).then(res => {
                        console.log(res)
                        if (res.data.code == 1) {
                            this.$message.success("移动成功");
                            return true
                        } else {
                            console.log(434343)
                            this.$message.error("操作失败")
                            this.changeCustom()
                            return false

                        }
                    })
                }
            }

        },
        dragStart(node) {
            console.log(node)
        },
        validateUsername(rule, value, callback) {
            qunQueryUser({ mobile: value }).then(res => {
                const result = res.data.data
                if (!result) {
                    callback(new Error("该账号用户不存在"))
                } else {
                    this.qzform.adminName = res.data.data.nickName;
                    this.qzform.adminUserId = res.data.data.userId;

                    callback()
                }
            })
        },
        submitQzForm() {
            this.$refs["qzform"].validate(valid => {
                if (valid) {
                    console.log(this.qzform)
                    // if (this.customId) {
                    //     qunUpdate(this.form).then(res => {
                    //         if (res.data.code == 1) {
                    //             this.$message.success("编辑成功");
                    //             this.replace('/groupSet',{customId:this.customId})
                    //         } else {
                    //             this.$message.error("操作失败")
                    //         }
                    //     });
                    // } else {
                    qunCreate(this.qzform).then(res => {
                        if (res.data.code == 1) {
                            this.$message.success("新增成功");
                            this.changeCustom();
                            this.configQzCancel()
                            // this.$emit('isTg', true);
                        } else {
                            this.$message.error("操作失败")
                        }

                    });
                    // }

                }
            });
        },
        configQzCancel() {
            this.qzform = {}
            this.createQzShow = false;
        },
        submitConfigForm() {
            console.log(this.configObj)
            configCustom(this.configObj).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("配置成功");
                    this.addPeopleOpen = false;
                    this.configCancel();
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        configCancel() {
            this.readBook = false;
            this.configObj.bookIds = [];
            this.configObj.orgId = '';
        },
        setBook(row) {
            if (this.$storage.get('role').includes(1)) {
                var st = Number(this.$storage.get('030StTime'))
                var end = Number(this.$storage.get('030EdTime'))
                var nT = new Date().getTime();
                if (nT < st || nT > end) {
                    this.$message.error('当前客户没有权限')
                    return
                }
                if (end < (nT + 2592000000)) {
                    this.$message.warning('当前客户权限快过期')
                }
            }
            console.log(row)
            this.configObj.orgId = row.$$data.orgId;
            this.configObj.bookIds = [];

            customOrgBook({ orgId: row.$$data.orgId }).then(res => {
                // this.bookList = res.data.data;
                res.data.data.forEach(e => {
                    this.configObj.bookIds.push(e.id)
                })
            })
            this.readBook = true;
        },
        screenZj(row) {
            // console.log(row)
            if (this.findParentNode(row.orgId).indexOf(2) == -1) {
                return false
            } else {
                return true
            }

        },
        // id 指的是当前点击的节点id;
        findParentNode(id) {
            // debugger
            // 初始化所需数据
            var firstParentObj = {}; // 记录直系父级的名称和id即接口要传的数据
            var parentIds = []; // 记录所有的父级ids
            var parentIdsQueue = []; // 记录排队的

            // 将树放到排队系列
            var parentIdsQueue = JSON.parse(JSON.stringify(this.dataList));

            // 开始遍历排队的树
            while (parentIdsQueue.length) {
                //抽取第一个排队的数据 
                let item = parentIdsQueue.shift();

                let { childrenList } = item;
                if (item.orgId === id) {
                    // 第一层就找到了
                    if (!item.parentArr) {
                        firstParentObj = {
                            id: item.orgId,
                            name: item.orgType
                        };
                        parentIds = [item.orgType];
                    } else {
                        // 获取当前节点的parentArr
                        let len = item.parentArr.length;
                        firstParentObj = item.parentArr[len - 1];
                        item.parentArr.forEach(a => {
                            parentIds.push(a.name);
                        });
                        parentIds.push(item.orgType);
                    }

                    // 结束遍历
                    parentIdsQueue = [];

                    break;
                } else if (childrenList && childrenList.length) {
                    let len = childrenList.length;
                    for (let i = len - 1; i >= 0; i--) {
                        // 新建一个数组用于记录它的父亲节点
                        childrenList[i].parentArr = [];

                        // 把它的历史父亲节点们先放入
                        if (item.parentArr) {
                            childrenList[i].parentArr = childrenList[i].parentArr.concat(
                                item.parentArr
                            );
                        }

                        // 再放入当前的父亲节点
                        childrenList[i].parentArr.push({
                            id: item.orgId,
                            name: item.orgType
                        });

                        // 加入到排队序列中
                        parentIdsQueue.unshift(childrenList[i]);
                    }

                }
            }
            return parentIds

        },
        screenLabel(nextChildren) {
            console.log(nextChildren)
            // if (nextChildren) {
            //     if (nextChildren.length != 0) {
            //         return nextChildren[0].orgType
            //     }else{
            //         return true
            //     }
            // }else{
            return true
            // }
        },
        submitKhOrgForm() {
            console.log(this.createKhCustomForm)
            this.$refs["createKhCustomForm"].validate(valid => {
                if (valid) {
                    // debugger
                    if (this.createKhCustomForm.introduceType == 1) {
                        this.createKhCustomForm.introduce = this.createKhCustomForm.gwIntroduce
                        this.createKhCustomForm.appIntroduce = this.createKhCustomForm.introduce
                    } else if (this.createKhCustomForm.introduceType == 0) {
                        this.createKhCustomForm.introduce = this.createKhCustomForm.fwbIntroduce
                        var text = JSON.parse(JSON.stringify(this.createKhCustomForm.introduce))
                        text = text.replace(/<video/gi, "<video style='width:100% !important;height:30% !important;background:#333 !important;'")
                        text = text.replace(/<img/gi, "<img style='width:auto !important;max-width: 100% !important;height:auto !important;vertical-align: middle !important;max-height:100%;margin:0 auto !important;'")
                        text = text.replace(/<p style="/gi, '<p style="text-indent: unset !important;')
                        this.createKhCustomForm.appIntroduce = text
                    }
                    if (this.createKhCustomForm.orgId) {
                        customOrgUpdate(this.createKhCustomForm).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("修改成功");
                                this.changeCustom();
                                this.createKhCustomShow = false;
                                this.createKhCustomForm = {
                                    adminAccount: '',
                                    adminName: '',
                                    customId: '',
                                    orgName: '',
                                    parentOrgId: '',
                                    introduceType: 0,
                                    introduce: '',
                                    fwbIntroduce: '',
                                    gwIntroduce: ''
                                }
                            } else {
                                this.$message.error("操作失败");
                            }
                        })
                    } else {
                        customOrgCreateOrg(this.createKhCustomForm).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("创建成功");
                                this.changeCustom();
                                this.createKhCustomShow = false;
                                this.createKhCustomForm = {
                                    adminAccount: '',
                                    adminName: '',
                                    customId: '',
                                    orgName: '',
                                    parentOrgId: '',
                                    introduceType: 0,
                                    introduce: '',
                                    fwbIntroduce: '',
                                    gwIntroduce: ''
                                }
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    }

                }
            });

        },
        handleCustomOrgCreateOrg(row) {
            if (this.$storage.get('role').includes(1)) {
                var st = Number(this.$storage.get('030StTime'))
                var end = Number(this.$storage.get('030EdTime'))
                var nT = new Date().getTime();
                if (nT < st || nT > end) {
                    this.$message.error('当前客户没有权限')
                    return
                }
                if (end < (nT + 2592000000)) {
                    this.$message.warning('当前客户权限快过期')
                }
            }
            this.createKhCustomForm = {
                adminAccount: '',
                adminName: '',
                customId: '',
                orgName: '',
                parentOrgId: '',
                introduceType: 0,
                introduce: '',
                fwbIntroduce: '',
                gwIntroduce: ''
            }
            this.createKhCustomForm.customId = row.$$data.customId;
            this.createKhCustomForm.parentOrgId = row.$$data.orgId;

            console.log(row.$$data);
            this.createKhCustomShow = true;
        },
        onMenus({ node, command }) {
            console.log(node, command);
        },
        onNodeClick(e, data) {
            console.log(e, data)
        },
        expandChange() {
            this.toggleExpand(this.data, this.expandAll);
        },
        submitUpdateForm() {
            customOrgUpdate(this.UpdateObj).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("修改成功");
                    this.customOrgUpdateShow = false;
                    if (this.queryParams.orgId) {
                        this.changeOrg()
                    } else {
                        this.changeCustom()
                    }

                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        submitzdyForm() {
            customOrgCreate(this.createdObj).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("创建成功");
                    this.createCustomShow = false;
                    this.createdObj = {
                        orgName: '',
                        parentOrgId: '',
                        orgType: 0
                    }
                    if (this.queryParams.orgId) {
                        this.changeOrg()
                    } else {
                        this.changeCustom()
                    }

                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        handleCreateCustom(row, orgType) {
            if (this.$storage.get('role').includes(1)) {
                var st = Number(this.$storage.get('030StTime'))
                var end = Number(this.$storage.get('030EdTime'))
                var nT = new Date().getTime();
                if (nT < st || nT > end) {
                    this.$message.error('当前客户没有权限')
                    return
                }
                if (end < (nT + 2592000000)) {
                    this.$message.warning('当前客户权限快过期')
                }
            }
            this.createdObj.orgType = orgType;
            this.createdObj.parentOrgId = row.id;
            this.createdObj.orgName = '';
            console.log(row)
            // this.zdycustomName
            if (orgType == 5) {
                this.qzform.orgId = row.id
                this.qzform.bookIds = []
                customOrgBook({ orgId: row.$$data.orgId }).then(res => {
                    this.qzbookList = res.data.data;
                    // if(this.qzbookList.length == 0){
                    //     this.qzbookList = this.bookList;
                    // }
                })
                this.createQzShow = true;
            } else {
                this.createCustomShow = true;
            }

        },
        changeOrg() {
            this.dataList = []
            this.treeData = {}
            if (this.queryParams.orgId) {
                customOrgQueryTreeOrg({ orgId: this.queryParams.orgId }).then(res => {
                    console.log(res)
                    this.dataList.push(res.data.data);
                    this.treeData = (res.data.data[0]);
                    console.log(this.treeData)
                })
            }

        },
        ergodicList(childrenList) {
            if (childrenList.length != 0) {
                childrenList.forEach(e => {
                    if (e.orgType == 1 || e.orgType == 3) {

                    }
                    if (e.orgType == 2) {
                        e.style = { "color": "#fff", "background": "#108ffe" }
                    }
                    if (e.orgType == 5) {
                        e.style = { "color": "#fff", "background": "rgb(165 165 100)" }
                    }
                    if (e.orgType == 0 || e.orgType == 1) {
                        e.noDragging = true
                    }
                    if (e.childrenList.length != 0) {
                        this.ergodicList(e.childrenList)
                    }
                })
            }
        },
        changeCustom() {
            this.isShow = true;
            this.orgList = [];
            this.queryParams.orgId = '';
            this.treeData = {}
            if (this.queryParams.customId) {
                custominfo({ customId: this.queryParams.customId }).then(respon => {
                    this.bookList = respon.data.data.bookList;
                    var adminList = respon.data.data.adminList;
                    queryTreeCustomOrg({ customId: this.queryParams.customId }).then(res => {
                        console.log(res);
                        res.data.data.forEach(e => {
                            this.ergodicList(e.childrenList);
                        })
                        this.orgList = res.data.data;
                        this.dataList = res.data.data;
                        this.treeData = (res.data.data[0])
                        this.treeData.adminList = adminList;
                        console.log(this.treeData)
                    })
                })

            } else {
                this.treeData = {}
                this.isShow = false;

            }

        },
        readbookIds(row) {
            this.readBookList = [];
            this.bookList.forEach(e => {
                row.bookIds.forEach(j => {
                    if (e.id == j) {
                        this.readBookList.push(e)
                    }
                })
            })
            this.readBook = true;
        },
        getBookList() {
            // bookList().then(res => {
            //     this.allBookList = res.data.data;
            // });
        },
        tgMethod(e) {
            this.queryParams.customName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        /** 查询基础：基数数据列表 */
        getList() {
            this.loading = false;
            this.customList = []
            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.customType.includes('030');
                })
                res.data.data.sort(function (a, b) {
                    return a.customName.localeCompare(b.customName);
                })
                res.data.data.forEach(e => {
                    if (e.status == 0) {
                        this.customList.push(e)
                    }
                })
                res.data.data.forEach(e => {
                    if (e.status == 1) {
                        this.customList.push(e)
                    }
                })
                this.customList.forEach((e,i) => {
                    if (e.customName == '南京孜博汇信息科技有限公司') {
                        this.customList.splice(i,1)
                        this.customList.unshift(e)
                    }
                })
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            customOrgDel({ orgId: row.id }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    if (this.queryParams.orgId) {
                        this.changeOrg()
                    } else {
                        this.changeCustom()
                    }
                } else {
                    this.$message.error("删除失败");
                }
            })
        },

        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有基础：基数数据数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(function () {
                    return exportData(queryParams);
                })
                .then(response => {
                    this.download(response.msg);
                });
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                title: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
            // this.$refs['form'].resetFields()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.did);
            this.single = selection.length !== 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.$router.push({
                path: "/customerManageAdd",
            });
            // this.reset();
            // this.open = true;
            // this.form.px = 10;
            // this.title = "添加基础：基数数据";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            console.log(row);
            this.UpdateObj.orgId = row.id;
            this.UpdateObj.orgName = row.label;
            if (row.$$data.orgType != 2) {
                this.customOrgUpdateShow = true;
            } else {
                this.createKhCustomShow = true;
                console.log(this.createKhCustomForm);
                setTimeout(() => {
                    this.createKhCustomForm = row.$$data;
                    if (this.createKhCustomForm.introduceType == 1) {
                        this.createKhCustomForm.gwIntroduce = JSON.parse(JSON.stringify(this.createKhCustomForm.introduce));
                    } else {
                        this.createKhCustomForm.fwbIntroduce = JSON.parse(JSON.stringify(this.createKhCustomForm.introduce));
                    }
                    console.log(111);
                })

            }

        }
    }
};
</script>
<style scoped>
.editbox .el-button--text {
    padding: 0;
    height: auto;
}

.editbox {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: none;
    width: 100%;
    position: absolute;
    min-height: 100%;
    line-height: 100%;
    background-color: rgb(0 0 0 / 75%);
}

.zdyedit .sss {
    min-height: 30px;
    line-height: 30px;
}

.zdyedit {
    position: relative;
}

.zdyedit:hover .editbox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.custom /deep/ .el-checkbox__input {
    position: absolute;
    bottom: 0;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    /* height: 270px; */
    /* overflow-y: auto; */
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}
</style>