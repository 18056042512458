<template>
    <div class="app-container pt0Im">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>班级管理</a></el-breadcrumb-item>
                <el-breadcrumb-item>批量创建班级</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 160px);overflow: auto;">
            <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="学校" prop="schoolId">
                            <el-select style="width: 100%;" clearable @change="changeSchoolId" v-model="form.schoolId"
                                placeholder="请选择学校">
                                <el-option v-for="item in schoolList" :key="item.schoolId" :label="item.schoolName"
                                    :value="item.schoolId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="" label-width="0" prop="classList">
                            <el-table ref="classList" border :data="classList" style="width: 50%">
                                <el-table-column align="center" width="70" type="index" label="序号">
                                </el-table-column>
                                <el-table-column align="center" prop="name" label="年级">
                                </el-table-column>
                                <el-table-column align="center" prop="num" label="班级数">
                                    <template #default="scope">
                                        <el-input-number v-model="scope.row.num" :min="0" :max="100" :step="1" />
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>

        <div>
            <div class="disflex-row-center cusbtbtn" :style="$store.state.zdy.isActive ? 'left:64px;' : 'left:201px;'">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submitForm">保 存</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { formGroupForm } from "@/api/adminservice/form"
import { bookList } from "@/api/adminservice/public"
import { queryCustoms, queryTreeCustomOrg, createCustoms, custominfo, configCustom, customOrgBook } from "@/api/adminservice/custom"
import { qunQueryUser, qunCreate, qunUpdate, queryCustomQun, queryQunInfo } from "@/api/adminservice/qun"
import { schoolListAuth,schoolList, zhktQueryDict, schoolCreate, schoolUpdate, schoolInfo, classBatch } from "@/api/adminservice/classOrganizaMain"

export default {
    name: "demoAdd",
    components: {},
    data() {
        return {
            classIsmList: [],
            memberList: [],
            id: '',
            schoolId: '',
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 表单参数
            form: {
                schoolId: '',
                gradeList: [],
            },
            tableData: [{
                adminAccount: '',
                orgName: ''
            }],
            classList: [],
            // 表单校验
            rules: {
                schoolId: [
                    { required: true, message: "请选择学校", trigger: "change" },
                ],
            },
            schoolList: [],
            gradeClassIsmList: [],
            id: '',
            role: '',
        };
    },
    created() {
        this.role = this.$storage.get("role")
        this.getZhktQueryDict()
    },
    mounted() {
        this.getList()
    },
    computed: {
    },
    methods: {
        getZhktQueryDict() {
            zhktQueryDict({ code: 'grade_class_ism' }).then(res => {
                this.classIsmList = res.data.data;
            })
        },
        checkMemberValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            var result = true;
            for (var i = 0; i < this.memberList.length; i++) {
                var e = this.memberList[i];
                if (e.username == '' || e.memberName == '') {
                    result = false
                    break;
                }
            }
            if (result) {
                callback()
            } else {
                callback(new Error('请填写完整信息'))
            }
        },
        validateMemberUsername(value) {
            qunQueryUser({ mobile: value.username }).then(res => {
                const result = res.data.data
                if (!result) {
                    value.username = ''
                    value.memberName = ''
                    this.$message.error("请输入正确账号")
                } else {
                    if (value.memberName == '') {
                        value.memberName = res.data.data.nickName
                    }

                    value.userId = res.data.data.userId
                }
            })
        },
        changeSchoolId(schoolId) {
            console.log(schoolId);
            this.classList = [];
            this.gradeClassIsmList = []
            this.schoolList.forEach(e => {
                if (e.schoolId == schoolId) {
                    console.log(e);
                    this.gradeClassIsmList = e.gradeClassIsm.split(',')
                    if (this.gradeClassIsmList.indexOf('1') != -1) {
                        this.classList.push({ name: '一年级', num: 0, schoolType: 1 })
                        this.classList.push({ name: '二年级', num: 0, schoolType: 1 })
                        this.classList.push({ name: '三年级', num: 0, schoolType: 1 })
                        this.classList.push({ name: '四年级', num: 0, schoolType: 1 })
                        this.classList.push({ name: '五年级', num: 0, schoolType: 1 })
                        this.classList.push({ name: '六年级', num: 0, schoolType: 1 })
                    }
                    if (this.gradeClassIsmList.indexOf('2') != -1) {
                        this.classList.push({ name: '初一年级', num: 0, schoolType: 2 })
                        this.classList.push({ name: '初二年级', num: 0, schoolType: 2 })
                        this.classList.push({ name: '初三年级', num: 0, schoolType: 2 })
                    }
                    if (this.gradeClassIsmList.indexOf('3') != -1) {
                        this.classList.push({ name: '高一年级', num: 0, schoolType: 3 })
                        this.classList.push({ name: '高二年级', num: 0, schoolType: 3 })
                        this.classList.push({ name: '高三年级', num: 0, schoolType: 3 })
                    }
                }
            })
        },
        deleteMemberRow(index) {
            this.memberList.splice(index, 1)
        },
        onMemberAddItem() {
            this.memberList.push({
                username: '',
                memberName: ''
            })
        },
        onAddItem() {
            console.log(this.tableData)
            this.tableData.push({
                adminAccount: '',
                orgName: ''
            })
        },
        /** 查询基础：基数数据列表 */
        getList() {
            this.loading = false;
            // schoolList({ customId: this.$storage.get('customId') }).then(res => {
            //     this.schoolList = res.data.data;
            // });
            schoolListAuth({ customId: this.$storage.get('customId'), adminUserId: ((this.role.includes(3) || this.role.includes(2)) && !this.role.includes(1)) ? JSON.parse(this.$storage.get('userInfo')).userId : '' }).then(res => {
                this.schoolList = res.data.data;
            });
        },


        /** 提交按钮 */
        submitForm() {
            console.log(this.form)
            console.log(this.classList);

            console.log(this.form);
            this.$refs["form"].validate(valid => {
                if (valid) {
                    this.form.gradeList = [];
                    this.gradeClassIsmList.forEach(e => {
                        var classList = []
                        this.classList.forEach(j => {
                            console.log(1);
                            if (j.schoolType == e) {
                                console.log(2);

                                for (var i = 0; i < j.num; i++) {
                                    classList.push({
                                        className: j.name + '(' + (i + 1) + ')班',
                                        grade: j.name
                                    })
                                }

                            }
                        })
                        this.form.gradeList.push({ classList: classList, schoolType: e })

                    })
                    classBatch(this.form).then(res => {
                        if (res.data.code == 1) {
                            this.$message.success("新增成功");
                            this.replace('/classManagement', { isSx: 1 })
                            // this.$emit('isTg', true);
                        } else {
                            this.$message.error("操作失败")
                        }
                    })
                }
            });
        },

        // 取消按钮
        cancel() {
            this.replace('/classManagement')
            // this.$emit('isTg', true)
        },

    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}
</style>