<template>
    <div class="app-container" @click="visible = false;">
        <el-card class="mb15">
            <el-row>
                <el-col :span="20" class="mb0">
                    <el-button type="primary" @click="handleAdd">创建</el-button>
                    <el-button type="primary" @click="handleRelease">发布</el-button>
                    <el-button type="primary" @click="hadlePs">铺设点阵</el-button>
                    <el-button type="primary" @click="handleXzPs">设计配置</el-button>
                    <el-popover @show="handlePd" placement="bottom-start" :width="125" trigger="hover" :min-width="'auto'">
                        <div @click="handleXz('dywj')" class="pxbutton"
                            v-if="showObj && showObj.id && showObj.sjc > 1719763200000">
                            打印文件
                        </div>
                        <div @click="handleXz('yswj')" class="pxbutton"
                            v-if="showObj && showObj.id && showObj.dotType == 0 && showObj.sjc > 1719763200000">
                            印刷文件
                        </div>
                        <div @click="handleXz('ywj')" class="pxbutton"
                            v-if="showObj && showObj.id && showObj.sjc > 1719763200000">
                            源文件
                        </div>
                        <div @click="handleXz('hcwj')" class="pxbutton"
                            v-if="showObj && showObj.id && showObj.sjc > 1719763200000">
                            合成文件
                        </div>
                        <div @click="handleXz('kbdzwj')" class="pxbutton"
                            v-if="showObj && showObj.id && showObj.dotType == 0 && showObj.sjc > 1719763200000">
                            空白点阵文件
                        </div>
                        <template #reference>
                            <el-button type="primary">文件下载</el-button>
                        </template>
                    </el-popover>
                </el-col>
                <el-col :span="4" class="mr0 mb0">
                    <div class="pull-right">
                        <el-popover :visible="visible" popper-style="width: 320px !important;" placement="bottom"
                            :min-width="'auto'">
                            <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true"
                                label-width="68px" @submit.native.prevent="getList">
                                <el-row>
                                    <el-col>
                                        <div class="chosebtn">
                                            <div>
                                                <img @click="changeActive(1)" :class="active == 1 ? 'bor-lan' : ''"
                                                    src="@/assets/img/tr-card.png" alt="">
                                            </div>
                                            <div>
                                                <img @click="changeActive(2)" :class="active == 2 ? 'bor-lan' : ''"
                                                    src="@/assets/img/tr-table.png" alt="">
                                            </div>
                                        </div>
                                    </el-col>
                                </el-row>
                                <div class="txt-ct">
                                    <el-divider />
                                </div>
                                <el-row class="mt15">
                                    <el-col :span="24" class="mr0">
                                        <el-form-item label="功能类型" class="w-100">
                                            <el-select multiple clearable class="w-100"
                                                v-model="queryParams.functionTypeList" placeholder="请选择">
                                                <el-option v-for="item in functionTypeList" :key="item.value"
                                                    :label="item.name" :value="item.value" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="mr0">
                                        <el-form-item label="应用场景" class="w-100">
                                            <el-select multiple clearable class="w-100"
                                                v-model="queryParams.employSceneList" placeholder="请选择">
                                                <el-option v-for="item in employSceneList" :key="item.value"
                                                    :label="item.name" :value="item.value" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="mr0">
                                        <el-form-item label="本子归属" class="w-100">
                                            <el-select clearable v-model="queryParams.bookBelong" class="w-100"
                                                placeholder="请选择">
                                                <el-option v-for="item in customList" :key="item.customId"
                                                    :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                                    :value="item.customId" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="mr0">
                                        <el-form-item label="状态" class="w-100">
                                            <el-select clearable v-model="queryParams.status" class="w-100"
                                                placeholder="请选择">
                                                <el-option label="初始" :value="1" />
                                                <el-option label="发布" :value="2" />
                                                <el-option label="停用" :value="3" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="mr0 ">
                                        <el-form-item label="智能本" class="w-100">
                                            <el-input clearable v-model="queryParams.bookName" placeholder="请输入智能本名称"
                                                class="input-with-select">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <div style="margin:0 auto;">
                                        <el-button @click="getList" type="primary">查找</el-button>
                                    </div>
                                </el-row>
                            </el-form>
                            <template #reference>
                                <el-button type="primary" @click.stop="visible = true;">筛选条件</el-button>
                            </template>
                        </el-popover>
                    </div>
                </el-col>
                <!-- <el-col :span="isSuperAuth ? 2 : 2" class="mr0 mb0">
                    <el-form-item class="pull-right">
                        <el-icon :color="isSxShow ? '#409EFC' : ''" class="cursor" @click="isSxShow = !isSxShow"
                            type="primary" :size="20">
                            <Filter />
                        </el-icon>
                    </el-form-item>
                </el-col> -->
            </el-row>
        </el-card>
        <el-card style="position: relative;">
            <!-- <img @click="isSxShow = true;" v-if="!isSxShow" style="transform: translateY(-20px);" class="sxShowcss"
                src="../../assets/img/arrow-down.png" alt="">
            <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true" label-width="68px"
                @submit.native.prevent="getList">
                <el-row class="mt15" v-if="isFilter">
                    <el-col :span="6" class="">
                        <el-form-item label="功能类型">
                            <el-select multiple clearable @change="getList" v-model="queryParams.functionTypeList"
                                placeholder="请选择">
                                <el-option v-for="item in functionTypeList" :key="item.value" :label="item.name"
                                    :value="item.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="">
                        <el-form-item label="应用场景">
                            <el-select multiple clearable @change="getList" v-model="queryParams.employSceneList"
                                placeholder="请选择">
                                <el-option v-for="item in employSceneList" :key="item.value" :label="item.name"
                                    :value="item.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="">
                        <el-form-item label="本子归属">
                            <el-select clearable @change="getList" v-model="queryParams.bookBelong" placeholder="请选择">
                                <el-option v-for="item in customList" :key="item.customId"
                                    :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                    :value="item.customId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mr0 ">
                        <el-form-item class="pull-right">
                            <el-input v-model="queryParams.bookName" placeholder="请输入智能本名称" class="input-with-select">
                                <template #append>
                                    <el-button @click="getList" type="primary" icon="el-icon-search" />
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <div class="chosebtn pull-right">
                            <div>
                                <img @click="changeActive(1)" :class="active == 1 ? 'bor-lan' : ''"
                                    src="@/assets/img/tr-card.png" alt="">
                            </div>
                            <div>
                                <img @click="changeActive(2)" :class="active == 2 ? 'bor-lan' : ''"
                                    src="@/assets/img/tr-table.png" alt="">
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-form>
            <el-divider style="position: relative;" v-if="isSxShow">
                <img @click="isSxShow = false;" style="transform: translateY(-100%);" class="sxShowcss"
                    src="../../assets/img/arrow-up.png" alt="">
            </el-divider> -->

            <template v-if="active == 1">
                <el-row :style="'max-height:calc(100vh - ' + (isSxShow ? '255' : '250') + 'px);overflow-y: auto;'"
                    v-loading="loading">
                    <div style="display:flex;flex-wrap: wrap;">
                        <div @click="changeData(item)" v-for="(item, index) in dataList" :key="index"
                            style="margin: 10px 10px;position:relative;">
                            <el-card :body-style="{ padding: '0px' }" class="csbbb" style="cursor: pointer;">
                                <div v-if="showObj.id == item.id"
                                    style="position: absolute;left: 0;border: 1px solid #409EFF;right: 0;top: 0;bottom: 0;">
                                </div>
                                <img src="../../assets/img/yfb.png" alt="" v-if="item.isDeployed == 1"
                                    style="width: auto;height:35px;position:absolute;left:-3px;top:0px">
                                <div title="详情" @click="goDetail(item)" style="cursor: pointer;z-index:999;" class="eicon">
                                    <img src="../../assets/img/detail.png" style="width:25px;height:auto;">
                                </div>
                                <!-- <el-icon title="修改" @click="handleUpdate(item)" class="eicon"
                                    style="cursor: pointer;z-index:999;">
                                    <Edit />
                                </el-icon> -->
                                <div style="display: flex;">
                                    <div
                                        :style="`padding:10px 0px 0px 0px;width: 160px;height: 220px;display: flex;overflow: hidden;`">
                                        <img :style="`width:160px;margin: auto;}`"
                                            :src="imgUrl + '/book/' + item.id + '/img/cover.png_original?' + new Date().getTime()"
                                            alt="" class="image">
                                    </div>
                                </div>
                                <div
                                    style="display: flex;justify-content: space-between;padding:10px 5px;width: 150px;height: 20px;">
                                    <div :title="item.bookName" class="text-one" style="padding-right: 10px;">
                                        {{ item.bookName }}
                                    </div>
                                    <div style="white-space: nowrap;">
                                        {{ item.pageCount }}页
                                    </div>
                                </div>
                                <!-- <div style="display: flex;padding: 5px;" v-if="item.functionType">
                                    <img src="../../assets/sx.png" alt="" v-if="item.functionType.indexOf('1') != -1"
                                        style="width: 25px;height:25px;padding-right: 15px;">
                                    <img src="../../assets/gnj.png" alt="" v-if="item.functionType.indexOf('2') != -1"
                                        style="width: 25px;height:25px;padding-right: 15px;">
                                    <img src="../../assets/zyk.png" alt="" v-if="item.functionType.indexOf('3') != -1"
                                        style="width: 25px;height:25px;padding-right: 15px;">
                                    <img src="../../assets/sj.png" alt="" v-if="item.functionType.indexOf('4') != -1"
                                        style="width: 25px;height:25px;">
                                </div>
                                <div style="display: flex;padding: 5px;" v-if="!item.functionType">
                                    <img src="../../assets/sx.png" alt=""
                                        style="width: 25px;height:25px;padding-right: 15px;">
                                </div> -->
                            </el-card>
                        </div>
                    </div>
                    <el-empty style="margin: 0 auto;padding-top: 10vh;" v-if="dataList.length == 0" :image-size="200" />
                </el-row>
            </template>
            <template v-if="active == 2">
                <div class="bre9e">
                    <el-table @current-change="handleCurrentChange" header-cell-class-name="bgf2" v-loading="loading"
                        v-if="groupName == ''" show-overflow-tooltip class="sssstt"
                        :height="'calc(100vh - ' + (isSxShow ? '255' : '267') + 'px)'" :data="dataList"
                        highlight-current-row>
                        <!-- <el-table-column align="center" type="selection" width="55" /> -->
                        <!-- <el-table-column align="center" fixed="left" type="index" label="序号" width="60" /> -->
                        <el-table-column align="center" width="120" label="封面" prop="fm">
                            <template #default="scope">
                                <div style="position:relative;">
                                    <img src="../../assets/img/yfb.png" alt="" v-if="scope.row.isDeployed == 1"
                                        style="width: auto;height:25px;position:absolute;left: -3px;top: 0px;">
                                    <img style="width:100px;margin: auto;"
                                        :src="imgUrl + '/book/' + scope.row.id + '/img/cover.png_original?' + new Date().getTime()"
                                        alt="" class="image">
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column class="cs" align="center" label="本子名称" prop="bzmc">
                            <template #default="scope">
                                <div>
                                    <!-- <div v-if="scope.row.isDeployed == 1" style="color: red;">已发布</div> -->
                                    <div @click="goDetail(scope.row)" :title="scope.row.bookName" style="color: #409efe;"
                                        class="text-one cursor">
                                        {{ scope.row.bookName }}
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" width="100" label="页码尺寸" prop="ymcc">
                            <template #default="scope">
                                <div>
                                    <div>{{ scope.row.pageCount }}页</div>
                                    <div class="">
                                        {{ Math.round(scope.row.pageWidth) }} * {{ Math.round(scope.row.pageHeight) }}
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" width="100" label="功能类型" prop="functionTypeTxt">
                            <template #default="scope">
                                <div class="" v-for="item in scope.row.functionTypeTxtList">
                                    {{ item }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" width="100" label="应用场景" prop="employSceneTxt">
                            <template #default="scope">
                                <div class="" v-for="item in scope.row.employSceneTxtList">
                                    {{ item }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" width="150" label="点阵" prop="dotType">
                            <template #default="scope">
                                <div>
                                    <div v-if="scope.row.dotType || scope.row.dotType == 0" class="text-one">
                                        {{ scope.row.dotType == 0 ? '拓思德' : '腾千里' }}
                                    </div>
                                    <div v-if="scope.row.startPageAddress">
                                        {{ scope.row.startPageAddress }}
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" width="230" label="本子归属" prop="bookBelongTxt">
                            <template #default="scope">
                                <div>
                                    <div v-if="scope.row.bookBelongTxt" :title="scope.row.bookBelongTxt" class="text-one">
                                        {{ scope.row.bookBelongTxt }}
                                    </div>
                                    <div>{{ timestampToTime(scope.row.createTime) }}</div>
                                </div>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column align="center" width="160" label="点阵资源" prop="startPageAddress" /> -->
                        <!-- <el-table-column align="center" width="140" label="创建时间" prop="createTime" sortable>
                            <template #default="scope">
                                <div>{{ timestampToTime(scope.row.createTime) }}</div>
                            </template>
                        </el-table-column> -->
                        <!-- <el-table-column align="center" width="100" label="记录数" prop="pageCount" />
                        <el-table-column align="center" width="140" label="最后落笔时间" prop="createTime" sortable>
                            <template #default="scope">
                                <div>{{ timestampToTime(scope.row.createTime) }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" width="100" label="落笔用户" prop="pageCount" /> -->
                        <!-- <el-table-column align="center" fixed="right" width="150" label="编辑"
                            class-name="small-padding fixed-width">
                            <template #default="scope">
                                <el-button link icon="Edit" type="primary" @click="handleUpdate(scope.row)">修改
                                </el-button>
                                <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                    icon-color="#626AEF" title="确定删除该智慧本" @confirm="handleDelete(scope.row)">
                                    <template #reference>
                                        <el-button link icon="Delete" type="danger">删除
                                        </el-button>
                                    </template>
                                </el-popconfirm>
                            </template>
                        </el-table-column> -->
                        <el-table-column align="center" width="100" label="" fixed="right"
                            class-name="small-padding fixed-width">
                            <template #default="scope">
                                <div class="">
                                    <el-popover popper-style="min-width: 100px !important;width: 100px !important;"
                                        placement="bottom" trigger="hover" :min-width="'auto'">
                                        <div @click="handleUpdate(scope.row)" class="cursor txt-ct mb5">
                                            修改
                                        </div>
                                        <el-popconfirm v-if="isPlatform == 0" confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                            icon-color="#626AEF" title="确定删除该智慧本" @confirm="handleDelete(scope.row)">
                                            <template #reference>
                                                <div class="cursor txt-ct">
                                                    删除
                                                </div>
                                            </template>
                                        </el-popconfirm>
                                        <div class="txt-ct">
                                            <el-divider />
                                        </div>
                                        <div v-if="isPlatform == 0" @click="submitStop(scope.row)" class="cursor txt-ct mb5">
                                            {{ scope.row.isDeployed == 1 ? '停用' : '发布' }}
                                        </div>
                                        <div  v-if="isPlatform == 1 && (scope.row.isDeployed != 1)" @click="submitStop(scope.row)" class="cursor txt-ct mb5">
                                            {{ scope.row.isDeployed == 1 ? '停用' : '发布' }}
                                        </div>
                                        <el-upload @change="handlezzChange" :show-file-list="false" ref="coverImgupload"
                                            :headers="{ 'token': token }" :data="{ 'bookId': scope.row.id }"
                                            class="upload-demo txt-ct" :before-upload="beforeAvatarUpload"
                                            action="https://zbh.zbform.com/adminservice/bookManage/uploadCover">
                                            <div class="cursor txt-ct" style="width:100%;">
                                                更换封面
                                            </div>
                                        </el-upload>
                                        <template #reference>
                                            <el-button size="mini" type="primary">
                                                操作
                                            </el-button>
                                        </template>
                                    </el-popover>
                                </div>
                                <!-- <div style="display:flex;">
                                    <el-button size="mini" type="primary" @click="submitStop(scope.row)">{{
                                        scope.row.isDeployed
                                        == 1 ? '停用' : '发布' }}
                                    </el-button>
                                    <el-upload @change="handlezzChange" :show-file-list="false" style="margin: auto;"
                                        ref="coverImgupload" :headers="{ 'token': token }"
                                        :data="{ 'bookId': scope.row.id }" class="upload-demo"
                                        :before-upload="beforeAvatarUpload"
                                        action="https://zbh.zbform.com/adminservice/bookManage/uploadCover">
                                        <el-button size="mini" type="primary">更换封面
                                        </el-button>
                                    </el-upload>
                                </div> -->
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </template>
            <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
                :limit.sync="queryParams.pageSize" @pagination="getList" />
        </el-card>

        <el-dialog title="创建智能本" v-model="cjShow" width="60%">
            <el-form ref="reportForm" label-width="100px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="" label-width="0" required>
                            <div style="">
                                <el-upload :auto-upload="false" ref="zdyupload" v-model:file-list="form.file"
                                    class="upload-demo" @change="handleznbChange" action="" accept=".pdf">
                                    <el-button type="primary">上传pdf文件</el-button>
                                </el-upload>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="" label-width="0" required>
                            <div style="">
                                <el-upload :auto-upload="false" ref="coverImgupload" v-model:file-list="form.coverImg"
                                    class="upload-demo" @change="handlecoverImgbChange" :before-upload="beforeAvatarUpload"
                                    action="">
                                    <el-button type="primary">上传封面</el-button>
                                </el-upload>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="智能本名称" prop="bookName">
                            <el-input v-model="form.bookName" placeholder="最多64个字" maxlength="64" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-form-item label="扉页数" prop="headPage">
                        <el-input-number :min="0" v-model="form.headPage" placeholder="最多64个字" maxlength="64" />
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="尾页数" prop="tailPage">
                        <el-input-number :min="0" v-model="form.tailPage" placeholder="最多64个字" maxlength="64" />
                    </el-form-item>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center disnone" style="align-items: unset;">
                    <el-button type="primary" @click="submitcj">确 定</el-button>
                    <el-button class="ml15" @click="cjShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog title="智能本详情" v-model="isDetailShow" width="60%">
            <el-row class="detailFF">
                <el-col :span="6">
                    <img style="width:150px;margin: auto;" @click="lookBook(form)"
                        :src="imgUrl + '/book/' + form.id + '/img/cover.png_original?' + new Date().getTime()" alt=""
                        class="image cursor">
                </el-col>
                <el-col :span="18">
                    <el-form ref="form" label-width="100px">
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="智能本名称" prop="bookName">
                                    {{ form.bookName }}
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="本子尺寸" prop="ccsize">
                                    {{ form.pageWidth }} * {{ form.pageHeight }}
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="页码" prop="pageCount">
                                    {{ form.pageCount }}
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-form-item label="扉页数" prop="headPage">
                                {{ form.headPage }}
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-form-item label="尾页数" prop="tailPage">
                                {{ form.tailPage }}
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-form-item label="功能类型" prop="functionType">
                                {{ form.functionTypeTxt }}
                            </el-form-item>
                        </el-row>
                        <el-row v-if="form.employSceneTxt">
                            <el-form-item label="应用场景" prop="employSceneList">
                                {{ form.employSceneTxt }}
                            </el-form-item>
                        </el-row>
                        <el-row v-if="form.dotType || form.dotType == 0">
                            <el-form-item label="点阵类型" prop="dotType">
                                <div v-if="form.dotType == 0">
                                    拓思德
                                </div>
                                <div v-if="form.dotType == 1">
                                    腾千里
                                </div>
                            </el-form-item>
                        </el-row>
                        <el-row v-if="form.startPageAddress">
                            <el-form-item label="点阵地址" prop="startPageAddress">
                                <div>
                                    {{ form.startPageAddress }}
                                </div>
                            </el-form-item>
                        </el-row>
                        <el-row v-if="form.bookBelong">
                            <el-form-item label="本子归属" prop="bookBelong" style="width: 100%;">
                                <el-select style="width: 100%;" disabled clearable v-model="form.bookBelong"
                                    placeholder="请选择客户">
                                    <el-option v-for="item in customList" :key="item.customId"
                                        :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                        :value="item.customId" />
                                </el-select>
                            </el-form-item>
                        </el-row>
                    </el-form>
                </el-col>
            </el-row>
            <template #footer>
                <div class="disflex-row-center disnone" style="align-items: unset;">
                    <el-button type="primary" @click="handleUpdate(form)">去编辑</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog title="修改智能本" v-model="isUpShow" width="60%">
            <el-row>
                <el-col :span="6">
                    <img style="width:150px;margin: auto;" v-if="isShow"
                        :src="imgUrl + '/book/' + form.id + '/img/cover.png_original?' + new Date().getTime()" alt=""
                        class="image">
                    <div style="margin: auto;width: 88px;padding-top: 15px;">
                        <el-upload @change="handlezzChange" :show-file-list="false" style="margin: auto;"
                            ref="coverImgupload" :headers="{ 'token': token }" :data="{ 'bookId': form.id }"
                            class="upload-demo" :before-upload="beforeAvatarUpload"
                            action="https://zbh.zbform.com/adminservice/bookManage/uploadCover">
                            <el-button type="primary">更换封面</el-button>
                        </el-upload>
                    </div>

                </el-col>
                <el-col :span="18">
                    <el-form ref="form" :rules="rules" label-width="100px">
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="智能本名称" prop="bookName">
                                    <el-input v-model="form.bookName" placeholder="最多64个字" maxlength="64" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="本子尺寸" prop="ccsize">
                                    {{ form.pageWidth }} * {{ form.pageHeight }}
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="页码" prop="pageCount">
                                    {{ form.pageCount }}
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-form-item label="扉页数" prop="headPage">
                                <el-input-number :min="0" v-model="form.headPage" placeholder="最多64个字" maxlength="64" />
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-form-item label="尾页数" prop="tailPage">
                                <el-input-number :min="0" v-model="form.tailPage" placeholder="最多64个字" maxlength="64" />
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-form-item label="功能类型" prop="functionType">
                                <el-checkbox-group v-model="form.functionTypeList">
                                    <el-checkbox label="1" value="1" disabled>书写</el-checkbox>
                                    <el-checkbox label="2" value="2">功能键</el-checkbox>
                                    <el-checkbox label="3" value="3">资源库</el-checkbox>
                                    <el-checkbox label="4" value="4">数据</el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-form-item label="应用场景" prop="employSceneList">
                                <el-checkbox-group v-model="form.employSceneList">
                                    <el-checkbox label="1" value="1">云尚记事</el-checkbox>
                                    <el-checkbox label="2" value="2">云尚群笔记</el-checkbox>
                                    <el-checkbox label="3" value="3">智慧批阅</el-checkbox>
                                    <el-checkbox label="4" value="4">表单</el-checkbox>
                                    <el-checkbox label="5" value="5">ZBClass</el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                        </el-row>
                        <el-row v-if="form.dotType || form.dotType == 0">
                            <el-form-item label="点阵类型" prop="dotType">
                                <div v-if="form.dotType == 0">
                                    拓思德
                                </div>
                                <div v-if="form.dotType == 1">
                                    腾千里
                                </div>
                            </el-form-item>
                        </el-row>
                        <el-row v-if="form.startPageAddress">
                            <el-form-item label="点阵地址" prop="startPageAddress">
                                <div>
                                    {{ form.startPageAddress }}
                                </div>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-form-item label="本子归属" prop="bookBelong">
                                <el-select clearable v-model="form.bookBelong" placeholder="请选择客户">
                                    <el-option v-for="item in customList" :key="item.customId"
                                        :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                        :value="item.customId" />
                                </el-select>
                            </el-form-item>
                        </el-row>
                    </el-form>

                </el-col>
            </el-row>

            <template #footer>
                <div class="disflex-row-center disnone" style="align-items: unset;">
                    <el-button type="primary" @click="submitStop()">{{ form.isDeployed == 1 ? '停 用' : '发 布' }}</el-button>
                    <el-button type="primary" @click="handleDelete()">删 除</el-button>
                    <el-button type="primary" @click="submitUp">保存修改</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog title="铺设点阵" v-model="isPsdzShow" width="500px">
            <el-row>
                <el-col :span="24">
                    <el-form ref="form" label-width="100px">
                        <el-row>
                            <el-form-item label="腾千里点阵" prop="tqlDotId" class="w-100">
                                <el-select style="width: 100%;" @change="showObj.tsdDotId = ''" clearable v-model="showObj.tqlDotId"
                                    placeholder="请选择点阵">
                                    <el-option v-for="item in tqlList" :key="item.dotId" :label="(item.dotName+'(总共'+item.pageCount+'页，已用'+item.pageUsed+'页)')"
                                        :value="item.dotId" />
                                </el-select>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-form-item label="拓思德点阵" prop="tsdDotId" class="w-100">
                                <el-select style="width: 100%;" @change="showObj.tqlDotId = ''" clearable v-model="showObj.tsdDotId"
                                    placeholder="请选择点阵">
                                    <el-option v-for="item in tsdList" :key="item.dotId" :label="(item.dotName+'(总共'+item.pageCount+'页，已用'+item.pageUsed+'页)')"
                                        :value="item.dotId" />
                                </el-select>
                            </el-form-item>
                        </el-row>
                    </el-form>
                </el-col>
            </el-row>

            <template #footer>
                <div class="disflex-row-center disnone" style="align-items: unset;">
                    <el-button type="primary" @click="submitPsdz">确 定</el-button>
                    <el-button class="ml15" @click="isPsdzShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-image-viewer v-if="isOpen" :url-list="activeimgs" @close="isOpen = false" />

    </div>
</template>

<script>

import {
    exportData
} from "@/api/demo/data";
import { timestampToTime } from "@/utils/date";
import { queryCustoms } from "@/api/adminservice/custom";
import { formQuery, deleteForm, formRelease, formStop } from "@/api/adminservice/form"
import { download } from "@/api/adminservice/public"
import { queryScene, queryAuthMembers } from "@/api/adminservice/scene"
import { bookManageList, bookManageCreate, bookManageUpdate, bookManageDelete, bookManageRelease, bookDotList, bookManagePrintPook } from "@/api/adminservice/bookManage"
import { ElLoading } from 'element-plus'

import { queryAllRecords } from "@/api/adminservice/formRecord"
import { strokeQuery } from "@/api/adminservice/formRecord";

export default {
    name: "smartBookManage",
    components: {
    },
    data() {
        return {
            isPlatform:this.$storage.get('isPlatform'),
            visible: false,
            token: this.$storage.get("token"),
            isOpen: false,
            activeimgs: [],
            cjShow: false,
            isUpShow: false,
            isShow: false,
            isSxShow: true,
            isDetailShow: false,
            isCanvas: false,
            collapseList: [],
            showObj: {},
            groupName: '',
            active: 2,
            customList: [],
            sortName: '',
            functionTypeList: [
                { name: '书写', value: 1 },
                { name: '功能键', value: 2 },
                { name: '资源库', value: 3 },
                { name: '数据', value: 4 },
            ],
            employSceneList: [
                { name: '云尚记事', value: 1 },
                { name: '云尚群笔记', value: 2 },
                { name: '智慧批阅', value: 3 },
                { name: '表单', value: 4 },
                { name: 'ZBClass', value: 5 },
            ],
            sceneList: [],
            timestampToTime,
            isSuperAuth: this.$storage.get('isSuperAuth'),
            // 遮罩层
            loading: false,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            isFilter: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                functionTypeList: [],
                employSceneList: [],
                bookBelong: '',
                bookName: '',
                pageNum: 1,
                pageSize: 10,
                userId: '',
                fileName: '',
                sceneId: '',
                formId: '',
                finishName: '',
                customId: '',
                createTime: [],
                memberName: '',
                beginTime: '',
                endTime: '',
                isDelete: '',
                isFinish: '',
                status: ''
            },
            formList: [],
            loadingInstance: null,
            // 表单参数
            form: {
                file: [],
                coverImg: [],
                bookName: '',
                headPage: 0,
                tailPage: 0,
                employSceneList: []
            },
            // 表单校验
            rules: {
                bookName: [
                    { required: true, message: "", trigger: "blur" }
                ],
                functionTypeList: [
                    { required: true, message: "", trigger: "change" }
                ],
                employSceneList: [
                    { required: true, message: "", trigger: "change" }
                ],
                bookBelong: [
                    { required: true, message: "", trigger: "change" }
                ]
            },
            selectObj: [],
            authMembersList: [],
            oldList: [],
            role: '',
            imgUrl: this.$store.state.zdy.imgUrl,
            isProduct: false,
            tqlList: [],
            tsdList: [],
            isPsdzShow: false,
        };
    },
    created() {
        if (process.env.NODE_ENV === 'production') {
            this.isProduct = true;
            // this.imgUrl = 'http://test-oss-zbh.oss-cn-beijing.aliyuncs.com';
            // this.imgUrl = 'https://zbh-oss-formal.oss-cn-beijing.aliyuncs.com';
        } else {

        }
        this.role = this.$storage.get("role")
        this.getSceneList()
        this.getFormList();
    },
    watch: {

    },
    methods: {
        lookBook(item) {
            this.activeimgs = [];
            console.log(item);
            for (let i = 0; i < item.pageCount; i++) {
                var urls = `${this.imgUrl}/book/${item.id}/img/${i + 1}.png_original`
                this.activeimgs.push(urls)
            }
            this.isOpen = true;
        },
        changeData(item) {
            this.showObj = item;
            this.showObj.sjc = new Date(this.showObj.createTime).getTime()
        },
        handlezzChange(e, type) {
            console.log(e, type);
            if (e.response) {
                if (e.response.code == 1) {
                    this.isShow = false;
                    setTimeout(() => {
                        this.isShow = true;
                    })
                    this.getList()
                } else {
                    if (e.response.msg) {
                        this.$message.error(e.response.msg)
                    } else {
                        this.$message.error('更换失败')
                    }
                }
            }
            if (e.status == 'fail') {
                this.$message.error('更换失败')
            }
        },
        handlePd() {
            if (!this.showObj.id) {
                this.$message.error("请选择数据");
                return;
            }
            if (this.showObj.sjc < 1719763200000) {
                this.$message.error("老旧本子不可操作");
                return;
            }
        },
        handleXzPs() {
            if (!this.showObj.id) {
                this.$message.error("请选择数据");
                return;
            }
            if (this.showObj.sjc < 1719763200000) {
                this.$message.error("老旧本子不可操作");
                return;
            }
            let routeUrl = this.$router.resolve({
                path: '/configureDesignView',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { bookId: this.showObj.id }
            })
            window.open(routeUrl.href, '_blank')
        },
        hadlePs(type) {
            if (!this.showObj.id) {
                this.$message.error("请选择数据");
                return;
            }
            if (this.showObj.sjc < 1719763200000) {
                this.$message.error("老旧本子不可操作");
                return;
            }
            if (this.showObj.startPageAddress) {
                this.$message.error('已铺设点阵')
                return;
            }

            bookDotList({ dotType: 1 }).then(res => {
                this.tqlList = res.data.data;
                bookDotList({ dotType: 0 }).then(res => {
                    this.tsdList = res.data.data;
                    this.showObj.tqlDotId = '';
                    this.showObj.tsdDotId = '';
                    this.isPsdzShow = true;
                })
            })

        },
        // 发布
        handleRelease(row) {
            if (!this.showObj.id) {
                this.$message.error("请选择数据");
                return;
            }
            if (!this.showObj.startPageAddress) {
                this.$message.error('请先铺设点阵')
                return;
            }
            if (this.showObj.isDeployed == 1) {
                this.$message.error('该数据已发布')
                return;
            }
            bookManageRelease({ bookId: this.showObj.id }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("发布成功");
                    this.getList();
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        submitPsdz() {
            var dotId = ''
            if (!this.showObj.tsdDotId && !this.showObj.tqlDotId) {
                this.$message.error('请选择点阵资源')
                return;
            }
            bookManagePrintPook({ bookId: this.showObj.id, dotId: this.showObj.tsdDotId ? this.showObj.tsdDotId : this.showObj.tqlDotId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("铺设成功");
                    this.isPsdzShow = false;
                    setTimeout(() => {
                        this.getList();
                    });
                } else {
                    this.$message.error("操作失败")
                }
            })
        },
        submitStop(row) {
            if (row) {
                this.form = row;
            }
            console.log(row)
            if (!this.form.startPageAddress) {
                this.$message.error('请先铺设点阵')
                return;
            }
            bookManageRelease({ bookId: this.form.id }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("操作成功");
                    if (this.form.isDeployed == 1) {
                        this.form.isDeployed = 0
                    } else {
                        this.form.isDeployed = 1
                    }
                    this.getList();
                } else {
                    this.$message.error("操作失败")
                }
            })
        },
        submitUp() {
            this.showObj = {};
            console.log(this.form);
            if (this.form.bookName == '') {
                this.$message.error('请输入智能本名称')
                return
            }
            if (this.form.functionTypeList.length == 0) {
                this.$message.error('请选择功能类型')
                return
            }
            if (this.form.employSceneList.length == 0) {
                this.$message.error('请选择应用场景')
                return
            }
            if (!this.form.bookBelong) {
                this.$message.error('请选择本子归属')
                return
            }
            console.log(334);
            this.form.bookId = this.form.id
            this.form.functionType = this.form.functionTypeList.toString()
            this.form.employScene = this.form.employSceneList.toString()
            bookManageUpdate(this.form).then(res => {
                if (res.data.code == 1) {
                    this.isUpShow = false;
                    this.$message.success("修改成功");
                    this.getList();
                } else {
                    this.loadingInstance.close()
                    this.$message.error("操作失败")
                }
            })

        },
        submitcj() {
            if (this.form.file.length == 0) {
                this.$message.error('请上传pdf文件')
                return
            }
            if (this.form.coverImg.length == 0) {
                this.$message.error('请上传封面')
                return
            }
            if (this.form.bookName == '') {
                this.$message.error('请输入智能本名称')
                return
            }
            this.loadingInstance = ElLoading.service({
                lock: true,
                text: '提交中',
                background: 'rgba(0, 0, 0, 0.7)',
            })
            let formdata = new FormData();
            this.form.file.map((item) => {
                console.log(item)
                formdata.append('bookFile', item.raw)
            })
            this.form.coverImg.map((item) => {
                console.log(item)
                formdata.append('coverImg', item.raw)
            })
            formdata.append('bookName', this.form.bookName)
            formdata.append('headPage', Number(this.form.headPage))
            formdata.append('tailPage', Number(this.form.tailPage))
            console.log(formdata);
            bookManageCreate(formdata).then(res => {
                if (res.data.code == 1) {
                    this.loadingInstance.close()
                    this.cjShow = false;
                    this.$message.success("新增成功");
                    this.getList();
                } else {
                    this.loadingInstance.close()
                    this.$message.error("操作失败")
                }
            }).catch(err => {
                this.loadingInstance.close()
            });
        },
        handleCurrentChange(val) {
            console.log(val)
            this.showObj = val;
            this.showObj.sjc = new Date(this.showObj.createTime).getTime()
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.isDetailShow = false;
            this.form = row;
            this.form.employSceneList = []
            if (this.form.functionType) {
                this.form.functionTypeList = this.form.functionType.split(',')
            } else {
                this.form.functionTypeList = ['1']
            }
            this.form.functionTypeList = this.form.functionTypeList.filter(item => item);
            if (this.form.employScene) {
                this.form.employSceneList = this.form.employScene.split(',')
            } else {

            }

            console.log(this.form);
            setTimeout(() => {
                this.isUpShow = true;
                this.isShow = true;
            })

        },
        changeList() {
            // this.getList()
        },
        changeCustom(e) {
            this.queryParams.sceneId = '';
            this.queryParams.formId = '';
            queryScene({ customId: this.$storage.get('customId') }).then(res => {
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.sceneType != 1;
                })
                if (this.queryParams.customId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.customId == this.queryParams.customId
                    })
                }
                this.sceneList = res.data.data;
            });
            formQuery({ customId: this.$storage.get('customId') }).then(res => {
                if (this.queryParams.customId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.customId == this.queryParams.customId
                    })
                }
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.isDesign == 1
                })
                this.formList = res.data.data;
            });
            // this.getList()
        },
        changeScene(e) {
            this.queryParams.formId = '';
            formQuery({ customId: this.$storage.get('customId') }).then(res => {
                if (this.queryParams.customId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.customId == this.queryParams.customId
                    })
                }
                if (this.queryParams.sceneId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.sceneId == this.queryParams.sceneId
                    })
                }
                this.formList = res.data.data;
                // this.getList()
            });
        },
        setSort() {
            if (this.sortName != '') {
                this.sortName = ''
            }
        },
        setGroup() {
            if (this.groupName != '') {
                this.groupName = ''
            }
        },
        getFormList() {
            formQuery({ customId: this.$storage.get('customId') }).then(res => {
                this.formList = res.data.data;
            });
        },
        changeActive(num) {
            this.showObj = {}
            this.active = num
            this.showObj = {}
        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        getSceneList() {
            this.customList = []
            queryScene({ customId: this.$storage.get('customId') }).then(res => {
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.sceneType != 1;
                })
                this.sceneList = res.data.data;
            });
            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                res.data.data.sort(function (a, b) {
                    return a.customName.localeCompare(b.customName);
                })
                res.data.data.forEach(e => {
                    if (e.status == 0) {
                        this.customList.push(e)
                    }
                })
                res.data.data.forEach(e => {
                    if (e.status == 1) {
                        this.customList.push(e)
                    }
                })
                this.getList();
            });
        },
        /** 查询基础：基数数据列表 */
        getList(e) {
            this.visible = false;
            this.showObj = {}
            this.dataList = [];
            // console.log(e);
            if (e) {
                this.queryParams.pageNum = e.page;
                this.queryParams.pageSize = e.limit;
            }
            if (this.queryParams.createTime.length != 0) {
                this.queryParams.beginTime = this.queryParams.createTime[0]
                this.queryParams.endTime = this.queryParams.createTime[1]
            } else {
                this.queryParams.beginTime = ''
                this.queryParams.endTime = ''
            }
            this.groupName = '';
            this.sortName = '';
            if (!this.role.includes(0)) {
                this.queryParams.customId = this.$storage.get('customId');
            }
            console.log(1);
            bookManageList().then(res => {
                if (this.queryParams.functionTypeList.length != 0) {
                    res.data.data = res.data.data.filter(itemA => {
                        var jg = true;
                        for (var i = 0; i < this.queryParams.functionTypeList.length; i++) {
                            var e = this.queryParams.functionTypeList[i];
                            console.log(e.toString(), itemA.functionType);

                            if (itemA.functionType && itemA.functionType.indexOf(e) == -1) {
                                jg = false;
                                break;
                            }
                        }
                        return itemA.functionType && jg
                    })
                }
                if (this.queryParams.employSceneList.length != 0) {
                    res.data.data = res.data.data.filter(itemA => {
                        var jg = true;
                        for (var i = 0; i < this.queryParams.employSceneList.length; i++) {
                            var e = this.queryParams.employSceneList[i];
                            if (itemA.employScene && itemA.employScene.indexOf(e) == -1) {
                                jg = false;
                                break;
                            }
                        }
                        return itemA.employScene && jg
                    })
                }
                if (this.queryParams.bookBelong !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.bookBelong && itemA.bookBelong.toString().indexOf(this.queryParams.bookBelong) != -1
                    })
                }
                if (this.queryParams.bookName !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.bookName.toString().indexOf(this.queryParams.bookName) != -1
                    })
                }
                if (this.queryParams.status !== '') {
                    if (this.queryParams.status == 1) {
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.status == 0
                        })
                    }else if(this.queryParams.status == 2){
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.status == 2 && item.isDeployed == 1
                        })
                    }else if(this.queryParams.status == 3){
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.status == 2 && item.isDeployed == 0
                        })
                    }
                }
                res.data.data.forEach(e => {
                    var functionTypeTxtList = []
                    if (e.functionType) {
                        var functionTypeList = e.functionType.split(',')
                        this.functionTypeList.forEach(m => {
                            functionTypeList.forEach(j => {
                                if (m.value == j) {
                                    functionTypeTxtList.push(m.name)
                                }
                            })
                        })
                    }
                    e.functionTypeTxtList = functionTypeTxtList;
                    var employSceneTxtList = []
                    if (e.employScene) {
                        var employSceneList = e.employScene.split(',')
                        this.employSceneList.forEach(m => {
                            employSceneList.forEach(j => {
                                if (m.value == j) {
                                    employSceneTxtList.push(m.name)
                                }
                            })
                        })
                    }
                    e.employSceneTxtList = employSceneTxtList
                    e.bookBelongTxt = ''
                    this.customList.forEach(m => {
                        if (e.bookBelong && e.bookBelong == m.customId) {
                            e.bookBelongTxt = m.customName
                        }
                    })
                })
                this.dataList = res.data.data;
                this.dataList.sort(function (a, b) {
                    if (a.createTime > b.createTime) { return -1; }
                    if (a.createTime < b.createTime) { return 1; }
                    return 0;
                })
            })
        },
        handleXz(type) {
            if (type == 'dywj' || type == 'hcwj') {
                download(this.imgUrl + '/book/' + this.showObj.id + '/dot.pdf', this.showObj.bookName + '.pdf')
            }
            if (type == 'yswj') {
                download(this.imgUrl + '/book/' + this.showObj.id + '/print_dot.pdf', this.showObj.bookName + '.pdf')
            }
            if (type == 'ywj') {
                download(this.imgUrl + '/book/' + this.showObj.id + '/original.pdf', this.showObj.bookName + '.pdf')
            }
            if (type == 'kbdzwj') {
                download(this.imgUrl + '/book/' + this.showObj.id + '/empty_dot.pdf', this.showObj.bookName + '.pdf')
            }
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            if (row) {
                this.form = row;
            }
            bookManageDelete({ bookId: this.form.id }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.isUpShow = false;
                    this.getList();
                } else {
                    this.$message.error("删除失败");
                }
            })
        },
        goDetail(row) {
            this.form = row;
            this.form.employSceneList = []
            if (this.form.functionType) {
                this.form.functionTypeList = this.form.functionType.split(',')
            } else {
                this.form.functionTypeList = ['1']
            }
            this.form.functionTypeList = this.form.functionTypeList.filter(item => item);
            var functionTypeTxtList = []
            this.functionTypeList.forEach(e => {
                this.form.functionTypeList.forEach(j => {
                    if (e.value == j) {
                        functionTypeTxtList.push(e.name)
                    }
                })
            })
            this.form.functionTypeTxt = functionTypeTxtList.toString('、')
            if (this.form.employScene) {
                this.form.employSceneList = this.form.employScene.split(',')
            } else {

            }
            var employSceneTxtList = []
            this.employSceneList.forEach(e => {
                this.form.employSceneList.forEach(j => {
                    if (e.value == j) {
                        employSceneTxtList.push(e.name)
                    }
                })
            })
            this.form.employSceneTxt = employSceneTxtList.toString('、')

            console.log(this.form);
            setTimeout(() => {
                this.isDetailShow = true;
                this.isShow = true;
            })
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.form = {
                coverImg: [],
                file: [],
                bookName: '',
                headPage: 0,
                tailPage: 0,
            }
            this.cjShow = true;
        },
        handleznbChange(file, fileList) {
            console.log(fileList)
            console.log(fileList.length)

            this.form.file = []
            if (fileList[0]) {
                this.form.file.push(fileList[fileList.length - 1])
                this.form.bookName = fileList[fileList.length - 1].name.substring(0, fileList[fileList.length - 1].name.lastIndexOf("."));
            }
            // console.log(this.form.file)
        },
        handlecoverImgbChange(file, fileList) {
            console.log(fileList)
            console.log(fileList.length)

            this.form.coverImg = []
            if (fileList[0]) {
                this.form.coverImg.push(fileList[fileList.length - 1])
            }
            // console.log(this.form.file)
        },
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.w-90 {
    margin-bottom: 15px;
    margin-top: 10px;
}

.w-90 /deep/ .el-card.is-always-shadow {
    width: 90%;
    margin: 0 auto;
    cursor: pointer;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    /* position: relative; */
}

.csbbb:hover .eicon {
    display: block;
}

.eicon {
    display: none;
    position: absolute;
    top: 2px;
    right: 2px;
    /* background: #dbdbdb; */
}

.image {
    width: 100%;
    display: block;
}

.chosebtn {
    /* padding-top: 10px; */
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
}

.chosebtn div {
    margin-right: 10px;
}

.chosebtn img {
    padding: 5px;

    margin: 0 auto;
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.bor-lan {
    border: 1px solid #21ac74
}

.wbba {
    word-break: break-all;
}

.sssstt /deep/ .el-scrollbar__view {
    display: block !important;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}

.pxbutton {
    padding: 7px 15px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #e4e7ed;
    font-size: 13px;
}

/deep/ .pagination-container {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}

.detailFF /deep/ .el-input__suffix {
    display: none;
}

.detailFF /deep/ .el-select .el-input.is-disabled .el-input__wrapper {
    cursor: unset;
    background: transparent;
    border: 0;
    box-shadow: none;
    padding: 0;
}

.detailFF /deep/ .el-select .el-select__tags-text {
    color: #333;
}

.detailFF /deep/ .el-select .el-input.is-disabled .el-input__inner {
    color: #333;
    -webkit-text-fill-color: #333;
}

.detailFF /deep/ .el-input.is-disabled {
    cursor: unset;
}</style>