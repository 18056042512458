import request from "@/utils/request";

// 查询智能本详情
export const notebookinfo =(query) =>{
    return request({
        url: "/adminservice/note/book/bookinfo",
        method:"get",
        params: query
    })
}
// 查询查看群本
export const noteotherBooks =(query) =>{
    return request({
        url: "/adminservice/note/book/otherBooks",
        method:"get",
        params: query
    })
}
// 查询云本一页笔迹及插图
export const notepageData =(data) =>{
    return request({
        url: "/adminservice/note/book/pageData",
        method:"post",
        data: data
    })
}
// 查询智能本笔迹及插图
export const noteBookData =(query) =>{
    return request({
        url: "/adminservice/note/book/bookData",
        method:"get",
        params: query
    })
}
// 按条件查询查看群本
export const noteConditionBooks =(data) =>{
    return request({
        url: "/adminservice/note/book/conditionBooks",
        method:"post",
        data: data
    })
}
// 查询群云本列表
export const noteBookQunBookList =(query) =>{
    return request({
        url: "/adminservice/note/book/qunBookList",
        method:"get",
        params: query
    })
}
// 群云本成员笔迹概览
export const noteQunBookView =(data) =>{
    return request({
        url: "/adminservice/note/book/qunBookView",
        method:"post",
        data: data
    })
}