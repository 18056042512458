<template>
    <div class="app-container pt0Im pl0Im">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>学校维护</a></el-breadcrumb-item>
                <el-breadcrumb-item v-if="!schoolId">新增学校</el-breadcrumb-item>
                <el-breadcrumb-item v-if="schoolId">编辑学校</el-breadcrumb-item>

            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 180px);overflow: auto;">
            <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="客户" prop="customId">
                            <el-select :disabled="role.includes(1)" style="width: 100%;" clearable @change="changeCustom" v-model="form.customId"
                                placeholder="请选择客户">
                                <el-option v-for="item in customList" :key="item.customId"
                                    :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                    :value="item.customId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="年级班制" prop="gradeClassIsmList">
                            <el-checkbox-group v-model="form.gradeClassIsmList">
                                <el-checkbox v-for="item in classIsmList" :label="item.dictKey" :key="item.dictKey">{{
                                    item.dictValue }}</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="学校名称" prop="schoolName">
                            <el-input v-model="form.schoolName" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="学校简称" prop="shortName">
                            <el-input v-model="form.shortName" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="管理员" prop="adminList">
                            <el-table ref="adminList" border :data="adminList" style="width: 100%" max-height="400">
                                <el-table-column align="center" type="index" label="序号" width="70" />
                                <el-table-column width="200px" align="center" prop="username" label="管理员账号">
                                    <template #default="scope">
                                        <el-input @blur="validateAdminUsername(scope.row)" v-model="scope.row.username"
                                            placeholder="最多16个字" maxlength="16" />
                                    </template>
                                </el-table-column>
                                <el-table-column width="200px" align="center" prop="adminName" label="管理员姓名">
                                    <template #default="scope">
                                        <el-input v-model="scope.row.adminName" placeholder="最多16个字" maxlength="16" />
                                    </template>
                                </el-table-column>
                                <el-table-column width="200px" align="center" prop="adminType" label="类型">
                                    <template #default="scope">
                                        <el-radio-group v-model="scope.row.adminType">
                                            <el-radio :label="'2'">平台</el-radio>
                                            <el-radio :label="'3'">业务</el-radio>
                                        </el-radio-group>
                                    </template>
                                </el-table-column>
                                <el-table-column width="350px" align="center" prop="subjectList" label="学科">
                                    <template #default="scope">
                                        <el-select v-model="scope.row.subjectList" multiple placeholder="请选择学科"
                                            style="width: 100%;">
                                            <el-option v-for="item in subjectList" :key="item.dictKey"
                                                :label="item.dictValue" :value="item.dictKey" />
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <!-- <el-table-column width="450px" v-if="schoolId" align="center" prop="classIdList" label="班级">
                                    <template #default="scope">
                                        <el-select v-model="scope.row.classIdList" multiple placeholder="请选择班级"
                                            style="width: 100%;">
                                            <el-option v-for="item in classList" :key="item.classId" :label="item.className"
                                                :value="item.classId" />
                                        </el-select>
                                    </template>
                                </el-table-column> -->

                                <el-table-column align="center" fixed="right" label="操作" width="100">
                                    <template #default="scope">
                                        <el-button link type="primary" size="small"
                                            @click.prevent="deleteAdminRow(scope.$index)">
                                            删除
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div style="width: 100%;">
                                <el-button plain type="primary" class="mt10" style="width: 100%"
                                    @click="onAdminAddItem">增加</el-button>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- <el-row>
                    <el-col :span="12">
                        <el-form-item label="管理员账号" prop="adminUsername">
                            <el-input @blur="form.adminName = ''" v-model="form.adminUsername" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="管理员姓名" prop="adminName">
                            <el-input v-model="form.adminName" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row> -->
                <!-- <el-row>
                    <el-col>
                        <el-form-item label="群成员" prop="memberList">
                            <el-table ref="memberList" border :data="memberList" style="width: 600px" max-height="250">
                                <el-table-column align="center" prop="username" label="成员账号">
                                    <template #default="scope">
                                        <el-input @blur="validateMemberUsername(scope.row)" v-model="scope.row.username"
                                            placeholder="最多16个字" maxlength="16" />
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" prop="memberName" label="成员姓名">
                                    <template #default="scope">
                                        <el-input v-model="scope.row.memberName" placeholder="最多16个字" maxlength="16" />
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" fixed="right" label="操作" width="160">
                                    <template #default="scope">
                                        <el-button link type="primary" size="small"
                                            @click.prevent="deleteMemberRow(scope.$index)">
                                            删除
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div style="width: 100%;">
                                <el-button plain type="primary" class="mt10" style="width: 600px"
                                    @click="onMemberAddItem">增加</el-button>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row> -->
            </el-form>
        </el-card>

        <div>
            <div class="disflex-row-center cusbtbtn" :style="$store.state.zdy.isActive ? 'left:64px;' : 'left:201px;'">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submitForm">保 存</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { formGroupForm } from "@/api/adminservice/form"
import { bookList } from "@/api/adminservice/public"
import { queryCustoms, queryTreeCustomOrg, createCustoms, custominfo, configCustom, customOrgBook } from "@/api/adminservice/custom"
import { qunQueryUser, qunCreate, qunUpdate, queryCustomQun, queryQunInfo } from "@/api/adminservice/qun"
import { zhktQueryDict, schoolCreate, schoolUpdate, schoolInfo } from "@/api/adminservice/classOrganizaMain"

export default {
    name: "demoAdd",
    components: {},
    data() {
        return {
            adminList: [],
            classIsmList: [],
            memberList: [],
            formList: [],
            customId: '',
            groupData: {},
            id: '',
            schoolId: '',
            readBook: false,
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 表单参数
            form: {
                adminName: '',
                adminUserId: '',
                adminUsername: '',
                customId: '',
                gradeClassIsm: '',
                schoolName: '',
                shortName: '',
                gradeClassIsmList: [],
                gradeClassIsm: '',
            },
            tableData: [{
                adminAccount: '',
                orgName: ''
            }],
            // 表单校验
            rules: {
                customId: [
                    { required: true, message: "请选择客户", trigger: "change" },
                ],
                gradeClassIsmList: [
                    { required: true, message: "请选择年级班制", trigger: "change" },
                ],
                schoolName: [
                    { required: true, message: "请输入学校名称", trigger: "blur" },
                ],
                shortName: [
                    { required: true, message: "请输入学校简称", trigger: "blur" },
                ],
                // adminUsername: [
                //     { required: true, message: "请输入管理员账号", trigger: "blur" },
                //     { validator: this.validateUsername, trigger: "blur" }
                // ],
                // adminName: [
                //     { required: true, message: "请输入管理员姓名", trigger: "blur" },
                // ],
                adminList: [
                    { required: true, validator: this.checkAdminValidator }
                ],
            },
            bookList: [],
            customList: [],
            orgList: [],
            oldBookIds: [],
            oldFromIds: [],
            chooseBookIds: [],
            chooseBook: false,
            id: '',
            subjectList: [],
            role: '',
        };
    },
    created() {
        this.role = this.$storage.get("role")
        this.getZhktQueryDict()
    },
    mounted() {
        this.getList()

        if (this.$route.query.schoolId) {
            this.schoolId = this.$route.query.schoolId;
            this.getInfo()
        } else {
            if(this.$storage.get("role").includes(1)){
                this.form.customId = this.$storage.get("customId")
                this.changeCustom()
            }
        }
    },
    computed: {
    },
    methods: {
        checkAdminValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.adminList.length == 0) {
                this.onAdminAddItem()
                callback(new Error('请添加管理员'))
            }
            var result = true;
            for (var i = 0; i < this.adminList.length; i++) {
                var e = this.adminList[i];
                if (e.username == '' || e.adminName == '') {
                    result = false
                    break;
                }
            }
            if (result) {
                callback()
            } else {
                callback(new Error('请填写完整信息'))
            }
        },
        validateAdminUsername(value) {
            qunQueryUser({ mobile: value.username }).then(res => {
                const result = res.data.data
                if (!result) {
                    value.username = ''
                    value.adminName = ''
                    this.$message.error("请输入正确账号")
                } else {
                    if (value.adminName == '') {
                        value.adminName = res.data.data.nickName
                    }
                    value.userId = res.data.data.userId
                }
            })
        },
        deleteAdminRow(index) {
            this.adminList.splice(index, 1)
        },
        onAdminAddItem() {
            this.adminList.push({
                adminType: '2',
                adminName: '',
                subjects: '',
                username: '',
                subjectList: [],
                classIdList:[],
            })
        },
        getZhktQueryDict() {
            zhktQueryDict({ code: 'subject' }).then(res => {
                this.subjectList = res.data.data;
            })
            zhktQueryDict({ code: 'grade_class_ism' }).then(res => {
                this.classIsmList = res.data.data;
            })
        },
        checkMemberValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            var result = true;
            for (var i = 0; i < this.memberList.length; i++) {
                var e = this.memberList[i];
                if (e.username == '' || e.memberName == '') {
                    result = false
                    break;
                }
            }
            if (result) {
                callback()
            } else {
                callback(new Error('请填写完整信息'))
            }
        },
        getInfo() {
            schoolInfo({ schoolId: this.schoolId }).then(res => {
                this.form = res.data.data;
                this.form.gradeClassIsmList = this.form.gradeClassIsm.split(',')
                this.adminList = res.data.data.adminList;
                this.adminList.forEach(e => {
                    if (e.subjects) {
                        e.subjectList = e.subjects.split(',')
                    } else {
                        e.subjectList = []
                    }
                    if(e.classIds){
                        e.classIdList = e.classIds.split(',')
                    }else{
                        e.classIdList = []
                    }
                })
                this.classList = res.data.data.classList
            })
        },
        validateMemberUsername(value) {
            qunQueryUser({ mobile: value.username }).then(res => {
                const result = res.data.data
                if (!result) {
                    value.username = ''
                    value.memberName = ''
                    this.$message.error("请输入正确账号")
                } else {
                    if (value.memberName == '') {
                        value.memberName = res.data.data.nickName
                    }

                    value.userId = res.data.data.userId
                }
            })
        },
        validateUsername(rule, value, callback) {
            qunQueryUser({ mobile: value }).then(res => {
                const result = res.data.data
                if (!result) {
                    this.form.adminName = ''
                    this.form.adminUserId = ''
                    callback(new Error("该账号用户不存在"))
                } else {
                    if (this.form.adminName == '') {
                        this.form.adminName = res.data.data.nickName;
                    }
                    this.form.adminUserId = res.data.data.userId;
                    callback()
                }
            })
        },
        changeCustom() {
            this.formList = [];
            this.orgList = [];
            this.form.formIds = [];
            queryTreeCustomOrg({ customId: this.form.customId }).then(res => {
                this.orgList = res.data.data;
                res.data.data.forEach((e, i) => {
                    e.label = e.orgName
                    e.value = e.orgId
                    e.children = e.childrenList;
                    e.disabled = true
                    this.ergodicList(e.childrenList)
                })
                console.log(res.data.data)
                this.orgList = res.data.data;
            })
            formGroupForm({ customId: this.form.customId }).then(res => {
                this.formList = res.data.data;
                res.data.data.forEach(e => {
                    this.oldFromIds.forEach(j => {
                        if (e.formId == j) {
                            this.form.formIds.push(e.formId)
                        }
                    })
                })
            })
        },
        deleteMemberRow(index) {
            this.memberList.splice(index, 1)
        },
        onMemberAddItem() {
            this.memberList.push({
                username: '',
                memberName: ''
            })
        },
        onAddItem() {
            console.log(this.tableData)
            this.tableData.push({
                adminAccount: '',
                orgName: ''
            })
        },
        /** 查询基础：基数数据列表 */
        getList() {
            this.loading = false;

            // bookList().then(res => {
            //     this.bookList = res.data.data;
            // });
            this.customList = []
            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                res.data.data.sort(function (a, b) {
                    return a.customName.localeCompare(b.customName);
                })
                res.data.data.forEach(e => {
                    if (e.status == 0) {
                        this.customList.push(e)
                    }
                })
                res.data.data.forEach(e => {
                    if (e.status == 1) {
                        this.customList.push(e)
                    }
                })
            });
        },


        /** 提交按钮 */
        submitForm() {
            console.log(this.form)
            this.$refs["form"].validate(valid => {
                if (valid) {
                    console.log(this.form)
                    this.form.gradeClassIsm = this.form.gradeClassIsmList && this.form.gradeClassIsmList.join(",")
                    this.adminList.forEach(e => {
                        e.subjects = e.subjectList.toString()
                        e.classIds = e.classIdList.toString()
                    })
                    this.form.adminList = this.adminList;
                    if (this.$route.query.schoolId) {
                        schoolUpdate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                this.replace('/classOrganizaMain', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        });
                    } else {
                        schoolCreate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("新增成功");
                                this.replace('/classOrganizaMain', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            this.replace('/classOrganizaMain')
            // this.$emit('isTg', true)
        },
        ergodicList(childrenList) {
            if (childrenList.length != 0) {
                childrenList.forEach(e => {
                    e.label = e.orgName
                    e.value = e.orgId
                    console.log(this.findParentNode(e.orgId))
                    if (this.findParentNode(e.orgId).indexOf(2) == -1) {
                        e.disabled = true
                    }
                    if (e.orgType == 5) {
                        // debugger
                        e.disabled = true
                        console.log(e)
                    }
                    if (e.childrenList.length != 0) {
                        e.children = e.childrenList
                        this.ergodicList(e.childrenList)
                    }
                })
            }
        },
        // id 指的是当前点击的节点id;
        findParentNode(id) {
            // debugger
            // 初始化所需数据
            var firstParentObj = {}; // 记录直系父级的名称和id即接口要传的数据
            var parentIds = []; // 记录所有的父级ids
            var parentIdsQueue = []; // 记录排队的

            // 将树放到排队系列
            var parentIdsQueue = JSON.parse(JSON.stringify(this.orgList));

            // 开始遍历排队的树
            while (parentIdsQueue.length) {
                //抽取第一个排队的数据 
                let item = parentIdsQueue.shift();

                let { childrenList } = item;
                if (item.orgId === id) {
                    // 第一层就找到了
                    if (!item.parentArr) {
                        firstParentObj = {
                            id: item.orgId,
                            name: item.orgType
                        };
                        parentIds = [item.orgType];
                    } else {
                        // 获取当前节点的parentArr
                        let len = item.parentArr.length;
                        firstParentObj = item.parentArr[len - 1];
                        item.parentArr.forEach(a => {
                            parentIds.push(a.name);
                        });
                        parentIds.push(item.orgType);
                    }

                    // 结束遍历
                    parentIdsQueue = [];

                    break;
                } else if (childrenList && childrenList.length) {
                    let len = childrenList.length;
                    for (let i = len - 1; i >= 0; i--) {
                        // 新建一个数组用于记录它的父亲节点
                        childrenList[i].parentArr = [];

                        // 把它的历史父亲节点们先放入
                        if (item.parentArr) {
                            childrenList[i].parentArr = childrenList[i].parentArr.concat(
                                item.parentArr
                            );
                        }

                        // 再放入当前的父亲节点
                        childrenList[i].parentArr.push({
                            id: item.orgId,
                            name: item.orgType
                        });

                        // 加入到排队序列中
                        parentIdsQueue.unshift(childrenList[i]);
                    }

                }
            }
            return parentIds

        },
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}
</style>