<template>
    <div class="app-container">
        <div style="display: flex;justify-content: space-between;align-items: center;" class="pb10">
            <el-breadcrumb separator="/" class="">
                <el-breadcrumb-item @click="close()"><el-icon>
                        <HomeFilled class="cursor" />
                    </el-icon></el-breadcrumb-item>
                <el-breadcrumb-item v-if="!classRecordId">{{ activeObj.memberName }} - {{ activeObj.qunName }} - {{
                    activeObj.recordName }}</el-breadcrumb-item>
                <el-breadcrumb-item v-if="classRecordId">{{ activeObj.studentName }} - {{ bookData.fileName
                }}</el-breadcrumb-item>
                <el-breadcrumb-item>详情</el-breadcrumb-item>
            </el-breadcrumb>
            <!-- <div class="chosebtn">
                <div>
                    <img @click="changepageWidth()" src="@/assets/img/pageWidth.png" alt="">
                </div>
                <div>
                    <img @click="changepageHeight()" src="@/assets/img/pageHeight.png" alt="">
                </div>
                <div>
                    <img @click="changeActive(2)" :class="active == 2 ? 'bor-lan' : ''" src="@/assets/img/k-two.png" alt="">

                </div>
                <div>
                    <img @click="changeActive(3)" :class="active == 3 ? 'bor-lan' : ''" src="@/assets/img/k-six.png" alt="">
                </div>
            </div> -->
        </div>
        <div style="height: calc(100vh - 60px);display: flex;">
            <div class="left">
                <el-card style="height: calc(100vh - 60px);">
                    <div style="display: flex;" class="mb10">
                        <el-radio-group v-model="fmType" @change="changFmType">
                            <el-radio-button label="全部" value="全部" />
                            <el-radio-button label="仅显示笔迹页" value="仅显示笔迹页" />
                        </el-radio-group>
                    </div>
                    <div
                        style="display: flex;flex-wrap:wrap;justify-content: space-between;max-height: calc(100vh - 150px);overflow-y: auto;">
                        <div v-show="fmType == '仅显示笔迹页' ? (item.illustrationList.length != 0 || item.strokeList.length != 0) : true"
                            :id="'zmd' + index" @click="changeCurrent(index)" class="mb10"
                            v-for="(item, index) in dataList.allStrokeList" style="position: relative;">
                            <div v-if="isCanvas"
                                style="position: absolute;right: 0;top: 0;z-index: 999;background: rgb(56, 148, 255);color: #fff;font-size: 12px;padding: 5px 10px;border-radius: 15px;">
                                {{ getPageName(index + 1, bookData.pageCount,
                                    bookData.headPage,
                                    bookData.tailPage) }}</div>
                            <template v-if="!classRecordId">
                                <Canvasbox v-if="isCanvas && bookData.booktype != 1" :bookData="bookData"
                                    :imgList="item.illustrationList" :imwidth="150"
                                    :imheight="bookData.pageHeight / bookData.pageWidth * 150"
                                    :canvasid="userRecordId + '' + index"
                                    :picSrc="this.$store.state.zdy.imgUrl + '/book/' + bookData.id + '/img/' + (index + 1) + '.png_original'"
                                    :detail="item.strokeList"></Canvasbox>
                                <Canvasbox v-if="isCanvas && bookData.booktype == 1" :bookData="bookData"
                                    :imgList="item.illustrationList" :imwidth="150"
                                    :imheight="bookData.pageHeight / bookData.pageWidth * 150"
                                    :canvasid="userRecordId + '' + index"
                                    :picSrc="bookData.formUrl + '/' + (index + 1) + '.png'" :detail="item.strokeList">
                                </Canvasbox>
                            </template>
                            <template v-if="classRecordId">
                                <Canvasbox noxk noPbj v-if="isCanvas" :bookData="bookData" :imgList="[]" :imwidth="150"
                                    :imheight="bookData.pageHeight / bookData.pageWidth * 150"
                                    :canvasid="classRecordId + fileId + activeObj.studentId + '' + index"
                                    :picSrc="bookData.imgFileUrl + (index + 1) + '.png_original'" :detail="item.strokeList">
                                </Canvasbox>
                            </template>
                            <div :class="current == index ? 'bor-lan' : ''"
                                :style="'width: 150px;height: ' + bookData.pageHeight / bookData.pageWidth * 150 + 'px;top: 0;position: absolute;'">

                            </div>

                            <!-- <div class="pageNumbox" v-if="isCanvas">{{ getPageName(index + 1, bookData.pageCount,
                                bookData.headPage,
                                bookData.tailPage) }}</div> -->
                        </div>
                    </div>
                </el-card>
            </div>
            <div class="right" style="position: relative;">
                <div class="chosebtn" style="position: absolute;right: 0;top: -35px;">
                    <div>
                        <img @click="changepageWidth()" src="@/assets/img/pageWidth.png" alt="">
                    </div>
                    <div>
                        <img @click="changepageHeight()" src="@/assets/img/pageHeight.png" alt="">
                    </div>
                    <div>
                        <img @click="changeActive(2)" :class="active == 2 ? 'bor-lan' : ''" src="@/assets/img/k-two.png"
                            alt="">

                    </div>
                    <div>
                        <img @click="changeActive(3)" :class="active == 3 ? 'bor-lan' : ''" src="@/assets/img/k-six.png"
                            alt="">
                    </div>
                </div>
                <el-card style="height: 100%;">
                    <div id="bjcan" style="height: calc(100vh - 95px);overflow-y: auto;">
                        <template v-for="(item, index) in dataList.allStrokeList">
                            <div v-show="fmType == '仅显示笔迹页' ? (item.illustrationList.length != 0 || item.strokeList.length != 0) : true"
                                :class="active == 1 ? 'ac1' : active == 2 ? 'ac2' : 'swbox'" :id="'md' + index"
                                v-if="isShow" :style="'margin:7px;'" @click="changeIndex(index)" style="">
                                <template v-if="!classRecordId">
                                    <Canvasbox v-if="isCanvas && bookData.booktype != 1" :bookData="bookData"
                                        :imgList="JSON.parse(JSON.stringify(item.illustrationList))" :imwidth="zdyWidth"
                                        :imheight="bookData.pageHeight / bookData.pageWidth * zdyWidth"
                                        :canvasid="userRecordId + 'rg' + index"
                                        :picSrc="this.$store.state.zdy.imgUrl + '/book/' + bookData.id + '/img/' + (index + 1) + '.png_original'"
                                        :detail="item.strokeList"></Canvasbox>
                                    <Canvasbox v-if="isCanvas && bookData.booktype == 1" :bookData="bookData"
                                        :imgList="JSON.parse(JSON.stringify(item.illustrationList))" :imwidth="zdyWidth"
                                        :imheight="bookData.pageHeight / bookData.pageWidth * zdyWidth"
                                        :canvasid="userRecordId + 'rg' + index"
                                        :picSrc="bookData.formUrl + '/' + (index + 1) + '.png'" :detail="item.strokeList">
                                    </Canvasbox>
                                </template>
                                <template v-if="classRecordId">
                                    <Canvasbox noxk noPbj v-if="isCanvas" :bookData="bookData"
                                        :imgList="JSON.parse(JSON.stringify(item.illustrationList))" :imwidth="zdyWidth"
                                        :imheight="bookData.pageHeight / bookData.pageWidth * zdyWidth"
                                        :canvasid="classRecordId + fileId + activeObj.studentId + 'rg' + index"
                                        :picSrc="bookData.imgFileUrl + (index + 1) + '.png_original'"
                                        :detail="item.strokeList">
                                    </Canvasbox>
                                </template>
                                <div class="pageNumbox" v-if="isCanvas">{{ getPageName(index + 1, bookData.pageCount,
                                    bookData.headPage,
                                    bookData.tailPage) }}</div>
                            </div>
                        </template>
                        <el-empty style="width:100%;"
                            v-if="!activeObj.lastWritePage && !classRecordId && fmType == '仅显示笔迹页'"
                            description="暂无笔迹数据"></el-empty>
                        <el-empty style="width:100%;" v-if="!activeObj.writeCount && classRecordId && fmType == '仅显示笔迹页'"
                            description="暂无笔迹数据"></el-empty>
                    </div>
                    <!-- <div class="chosebtn">
                        <div>
                            <img @click="changepageWidth()" src="@/assets/img/pageWidth.png" alt="">
                        </div>
                        <div>
                            <img @click="changepageHeight()" src="@/assets/img/pageHeight.png" alt="">
                        </div>
                        <div>
                            <img @click="changeActive(2)" :class="active == 2 ? 'bor-lan' : ''" src="@/assets/img/k-two.png"
                                alt="">

                        </div>
                        <div>
                            <img @click="changeActive(3)" :class="active == 3 ? 'bor-lan' : ''" src="@/assets/img/k-six.png"
                                alt="">
                        </div>
                    </div> -->
                </el-card>
            </div>
            <div class="right-right" v-if="!classRecordId">
                <div
                    style="height: 100%;overflow-y: auto;border: 4px;border: 1px solid #e4e7ed;background: #fff;box-shadow: 0px 0px 12px rgba(0,0,0,0.12);">
                    <div @click="changePeople(item)" v-for="item in dataPeoList" :title="item.memberName"
                        class="text-one cursor cstxt fs-14"
                        :style="item.id == userRecordId ? 'color:#e27f11;text-align: left;' : 'text-align: left;'">
                        <el-icon>
                            <UserFilled />
                        </el-icon>
                        {{ item.memberName }}
                    </div>
                </div>
            </div>
            <div class="right-right" v-if="classRecordId">
                <div
                    style="height: 100%;overflow-y: auto;border: 4px;border: 1px solid #e4e7ed;background: #fff;box-shadow: 0px 0px 12px rgba(0,0,0,0.12);">
                    <div @click="changePeople(item)" v-for="item in dataPeoList" :title="item.studentName"
                        class="text-one cursor cstxt fs-14"
                        :style="item.studentId == activeObj.studentId ? 'color:#e27f11;text-align: left;' : 'text-align: left;'">
                        <el-icon>
                            <UserFilled />
                        </el-icon>
                        {{ item.studentName }}
                    </div>
                </div>
            </div>
        </div>
        <!-- <PerviewCanvas :currentNum="currentNum" :strokeList="exStrList" :bookData="formActive"
            :formDesignData="JSON.parse(JSON.stringify(formDesignList))" @canvasClose="canvasClLfPerview"
            v-if="isLfPerview">
        </PerviewCanvas> -->
    </div>
</template>

<script>
import { notepageData } from "@/api/adminservice/book";
import { timestampToTime } from "@/utils/date";
import { noteotherBooks, noteBookData, noteConditionBooks } from "@/api/adminservice/book";
import { recordDetails } from "@/api/adminservice/classOrganizaMain"

import echarts from "echarts";

export default {
    name: "taskDetail",
    components: {

    },
    props: {
        teacherBookId: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            fmType: '仅显示笔迹页',
            exStrList: [],
            active: 1,
            current: 0,
            isZjShow: false,
            isShow: false,
            timestampToTime,
            bookData: {},
            strokeList: [],
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            zdyWidth: 0,
            zdyHeight: 0,
            isStart: false,
            isTime: false,
            isNull: true,
            userRecordId: '',
            peopleIdList: [],
            isCanvas: false,
            dataPeoList: [],
            activeObj: {},
            qunRecordId: '',
            qunId: '',
            classRecordId: '',
            fileId: '',
            studentId: '',
            stuStrokeList: [],
        };
    },
    created() {
        console.log('fdsdfds')
    },
    mounted() {
        this.bookData = JSON.parse(this.$route.query.bookData)

        if (this.$route.query.classRecordId) {
            this.classRecordId = this.$route.query.classRecordId;
            this.fileId = this.$route.query.fileId;
            this.studentId = this.$route.query.studentId;
            this.activeObj.studentId = this.$route.query.studentId;
        } else {
            this.userRecordId = this.$route.query.userRecordId
            // this.peopleIdList = JSON.parse(this.$route.query.peopleIdList)
            this.qunRecordId = this.$route.query.qunRecordId
            this.qunId = this.$route.query.qunId
            this.getList();

        }

        console.log(this.bookData);
        // this.dataList.allStrokeList = []
        let arr = []
        let _this = this;

        // for (let index = 0; index < this.bookData.pageCount; index++) {
        //     this.dataList.allStrokeList.push({ illustrationList: [], strokeList: [] })
        // }
        this.getDetail();
        // document.querySelector("#bjcan").addEventListener("scroll", this.handleScroll);
    },
    methods: {
        close() {
            window.close()
        },
        changFmType() {
            this.isShow = false;
            setTimeout(() => {
                this.isShow = true;
                setTimeout(() => {
                    location.href = "#md" + this.current;
                    location.href = "#zmd" + this.current;
                })
            })
        },
        changePeople(item) {
            this.isCanvas = false;
            // this.bookData = item.book;
            console.log(item);
            this.activeObj = item;
            this.userRecordId = item.id;
            this.getDetail();
        },
        getList() {
            var noteBook = []
            var obj = {}
            this.dataPeoList = [];
            var sjobj = JSON.parse(this.$route.query.queryParams)
            noteConditionBooks(sjobj).then(res => {
                console.log(res);
                this.dataPeoList = res.data.data;
                this.dataPeoList.forEach(e => {
                    if (e.id == this.userRecordId) {
                        this.activeObj = e;
                    }
                })
            })
            // noteotherBooks({}).then(res => {
            //     res.data.data.forEach(e => {
            //         e.qunList.forEach(j => {
            //             j.qunBookList.forEach(m => {
            //                 m.bookList.forEach(x => {
            //                     x.orgId = e.orgId;
            //                     x.orgName = e.orgName;
            //                     x.qunId = j.qunId;
            //                     x.qunName = j.qunName;
            //                     noteBook.push(x);
            //                     var txt = x.resourceId;
            //                     obj[x.resourceId] = []
            //                 })
            //             })
            //         })
            //     })
            //     console.log(noteBook)
            //     noteBook.forEach(e => {
            //         obj[e.resourceId].push(e)
            //     })
            //     console.log(obj[this.bookData.id])
            //     // obj[this.bookData.id] = obj[this.bookData.id].filter(itemA => {
            //     //     if (itemA.id == this.userRecordId) {
            //     //         this.activeObj = itemA;
            //     //     }
            //     //     return this.peopleIdList.indexOf(itemA.id) !== -1;
            //     // })
            //     console.log(obj[this.bookData.id])
            //     this.dataPeoList = obj[this.bookData.id];
            // })

        },
        // 滚动函数事件
        handleScroll() {
            if (!this.isTime) {
                if (this.isNull) {
                    let scrollTop = document.querySelector("#bjcan").scrollTop; //获取监听指定区域滚动位置的值
                    console.log(scrollTop, Math.round(scrollTop / document.getElementById("md0").clientHeight), document.getElementById("md0").clientHeight)
                    if (Math.round(scrollTop / document.getElementById("md0").clientHeight) - 1 < 0) {
                        this.current = 0
                    } else {
                        this.current = Math.round(scrollTop / document.getElementById("md0").clientHeight)
                    }

                    location.href = "#zmd" + (this.current);
                    this.isNull = false;

                    setTimeout(() => {
                        this.isNull = true;
                    }, 500)
                }

            }
        },
        changepageWidth() {
            this.active = 1;
            this.isShow = false;
            setTimeout(() => {
                this.zdyWidth = (document.getElementById("bjcan").clientWidth - 100)
                this.isShow = true;
                setTimeout(() => {
                    location.href = "#md" + this.current;
                    location.href = "#zmd" + this.current;
                })
            });
        },
        changepageHeight() {
            this.active = 1;
            this.isShow = false;
            setTimeout(() => {
                this.zdyHeight = document.getElementById("bjcan").clientHeight - 50;
                this.zdyWidth = this.zdyHeight * this.bookData.pageWidth / this.bookData.pageHeight
                this.isShow = true;
                setTimeout(() => {
                    location.href = "#md" + this.current;
                    location.href = "#zmd" + this.current;
                })
            });
        },
        changeActive(num) {
            if (this.active != num || !this.isStart) {
                // this.current = 0;
                this.isShow = false;

                this.active = num

                setTimeout(() => {
                    console.log(document.getElementById("bjcan").clientWidth)
                    console.log(document.getElementById("bjcan").clientHeight)
                    this.zdyHeight = document.getElementById("bjcan").clientHeight - 50;
                    if (this.active == 3) {
                        this.zdyWidth = (document.getElementById("bjcan").clientWidth - num * 20) / num
                    } else if (this.active == 2) {
                        console.log(document.getElementById("bjcan").clientWidth);
                        // this.zdyHeight = document.getElementById("bjcan").clientHeight - 90;
                        // this.zdyWidth = this.zdyHeight * this.bookData.pageWidth / this.bookData.pageHeight
                        this.zdyWidth = (document.getElementById("bjcan").clientWidth * 0.8) / num
                        console.log(this.zdyWidth);

                    } else {
                        this.zdyWidth = this.zdyHeight * this.bookData.pageWidth / this.bookData.pageHeight
                    }
                    this.isShow = true;
                    setTimeout(() => {
                        location.href = "#md" + this.current;
                        location.href = "#zmd" + this.current;
                    })

                    console.log(this.zdyWidth)
                })
            }



        },
        changeIndex(index) {
            this.current = index;
            location.href = "#zmd" + this.current;
        },
        canvasClosess(e) {
            this.isShow = false;
        },
        // 取消按钮
        cancel() {
            // this.$emit('isTg', true)
        },
        changeCurrent(index) {
            this.current = index;
            this.isTime = true;
            setTimeout(() => {
                this.isTime = false;
            }, 1000)
            location.href = "#md" + index;
        },
        getDetail() {
            console.log(this.bookData);
            this.dataList.allStrokeList = []
            let arr = []
            let _this = this;

            for (let index = 0; index < this.bookData.pageCount; index++) {
                this.dataList.allStrokeList.push({ illustrationList: [], strokeList: [] })
            }
            if (this.classRecordId) {
                this.isCanvas = false;
                // this.dataPeoList = [];
                recordDetails({ recordId: this.classRecordId }).then(res => {
                    let arr = []
                    this.stuStrokeList = res.data.data.stuStrokeList;
                    this.stuStrokeList.forEach((e, i) => {
                        if (e.fileId == this.fileId) {
                            if (this.dataPeoList.length == 0) {
                                this.dataPeoList = e.stuStrokeList;
                            }
                        }
                        e.pageWidth = e.fileWidth * 80 / 3;
                        e.pageHeight = e.fileHeight * 80 / 3;
                        e.stuStrokeList.forEach((j, k) => {

                            j.linkList.forEach((m, n) => {
                                var _this = this;
                                console.log(e.fileId);
                                if (j.studentId == this.activeObj.studentId && e.fileId == this.fileId) {
                                    arr.push(new Promise(function (resolve, reject) {
                                        const xhr = new XMLHttpRequest();
                                        xhr.overrideMimeType("text/plain");
                                        xhr.open('GET', m.link, true);
                                        xhr.onreadystatechange = function () {
                                            if (xhr.readyState === 4 && xhr.status === 200) {
                                                const text = xhr.responseText;
                                                var res = JSON.parse(text);
                                                j.bookData = {
                                                    pages: e.pages,
                                                    pageWidth: e.pageWidth,
                                                    pageHeight: e.pageHeight
                                                }
                                                m.dotsList = res
                                                _this.dataList.allStrokeList[m.page - 1].strokeList = res;
                                                // _this.bsStrokeList.push({ dotsList: res, bookData: { fileId: e.fileId, pageWidth: e.fileWidth * 80 / 3, pageHeight: e.fileHeight * 80 / 3, page: j.page, imgFileUrl: e.imgFileUrl } })
                                                resolve(res);
                                                // 处理读取到的文本数据
                                            }
                                        };
                                        xhr.send(null);
                                    }))
                                    this.activeObj = j;
                                }

                            })
                        })
                    })
                    Promise.all(arr).then((result) => {
                        // console.log(this.bsStrokeList);
                        this.changeActive(this.active)
                        setTimeout(() => {
                            this.isStart = true;
                            this.isCanvas = true;
                            if (this.activeObj.writeCount) {
                                setTimeout(() => {
                                    this.changeCurrent(Number(this.activeObj.linkList[0].page) - 1)
                                    location.href = "#zmd" + (Number(this.activeObj.linkList[0].page) - 1);
                                })

                            } else {
                                this.changeCurrent(0)
                                location.href = "#zmd" + (0);
                            }
                        }, 1000)
                    })
                })

            } else {
                noteBookData({ userRecordId: _this.userRecordId }).then(res => {
                    res.data.data.forEach(e => {
                        this.dataList.allStrokeList[e.pageNum - 1].illustrationList = e.illustrationList;
                        this.dataList.allStrokeList[e.pageNum - 1].strokeList = e.strokeList;
                    })
                    this.isZjShow = true
                    this.changeActive(this.active)
                    setTimeout(() => {
                        this.isStart = true;
                        this.isCanvas = true;
                        if (this.activeObj.lastWritePage) {
                            setTimeout(() => {
                                this.changeCurrent(this.activeObj.lastWritePage - 1)
                                location.href = "#zmd" + (this.activeObj.lastWritePage - 1);
                            })

                        } else {
                            this.changeCurrent(0)
                            location.href = "#zmd" + (0);
                        }
                    }, 2000)
                })
            }

        },
        getPageName(currentpageNum, totalPages, headPages, tailPages) {
            var pageNum = null;
            var tailpageNum = totalPages - currentpageNum;

            if (tailPages > tailpageNum) {
                // 尾页
                if (tailPages - tailpageNum == 0) {
                    // 尾页最后一页
                    pageNum = "尾页-" + tailPages;
                } else {
                    pageNum = "尾页-" + (tailPages - tailpageNum);
                }
            } else if (headPages >= currentpageNum) {
                // 扉页
                pageNum = "扉页-" + currentpageNum
            } else {
                // 正常页码
                pageNum = "第" + (currentpageNum - headPages) + "页"
            }
            return pageNum;
        },
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-tabs--border-card {
    border: 0;
}

/deep/ .el-tabs__header {
    background-color: transparent !important;
}

/deep/ .el-tabs--border-card>.el-tabs__content {
    background: #fff !important;
}

/deep/ .el-tabs--border-card {
    background: transparent;
}

.left {
    width: 360px;
    height: 100%;
    margin-right: 15px;
}

.right-right {
    min-width: 133px;
    max-width: 202px;
    height: 100%;
    margin-left: 15px;
}

.right {
    flex: 1;
}

.swbox {
    margin-bottom: 10px;
    flex: 1;
}

.pageNumbox {
    text-align: center;
    padding: 10px 0 25px 0;
}

.bor-lan {
    border: 1px solid #409eff
}

.chosebtn {
    /* padding-top: 10px; */
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
    justify-content: flex-end;
}

.chosebtn img {
    height: 30px;
    object-fit: contain;
    margin-left: 15px;
}

#bjcan {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.ac1 {
    width: 100% !important;
}

.ac2 {
    width: 45%;
}

.cstxt {
    padding: 0 15px;
    line-height: 50px;
    border: 1px solid #e3e3e3;
    text-align: center;
}
</style>