<template>
    <div class="app-container pt0Im pl0Im">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>教师维护</a></el-breadcrumb-item>
                <el-breadcrumb-item v-if="!teacherId">新增教师</el-breadcrumb-item>
                <el-breadcrumb-item v-if="teacherId">教师详情</el-breadcrumb-item>

            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 160px);overflow: auto;">
            <el-descriptions class="detailFF" title="基本信息">
                <el-descriptions-item label="教师账号">{{ form.username }}</el-descriptions-item>
                <el-descriptions-item label="教师姓名">{{ form.teacherName }}</el-descriptions-item>
                <el-descriptions-item label="学科">{{ form.subjectTxt }}</el-descriptions-item>
                
                <el-descriptions-item label="教授班级">{{ form.classIdsname }}</el-descriptions-item>
                <el-descriptions-item label="性别">{{ form.gender == 1 ? '女' : '男' }}</el-descriptions-item>
                <el-descriptions-item label="所属学校">{{ form.schoolName }}</el-descriptions-item>
                <el-descriptions-item label="所属客户">{{ form.customName }}</el-descriptions-item>
            </el-descriptions>
        </el-card>

    </div>
</template>

<script>
import { bookList } from "@/api/adminservice/public"
import { queryCustoms, queryTreeCustomOrg, createCustoms, custominfo, configCustom, customOrgBook } from "@/api/adminservice/custom"
import { qunQueryUser, qunCreate, qunUpdate, queryCustomQun } from "@/api/adminservice/qun"
import { formCreate, formInfo, updateForm } from "@/api/adminservice/form"
import { queryScene } from "@/api/adminservice/scene"
import { ElLoading } from 'element-plus'
import { classList, zhktQueryDict, schoolCreate, schoolUpdate, schoolInfo, teacherQueryOrRegUser, teacherCreate, teacherUpdate, teacherDelete } from "@/api/adminservice/classOrganizaMain"

export default {
    name: "teacherMainDeatil",
    components: {},
    data() {
        return {
            fileList: [],
            teacherId: '',
            groupData: {},
            id: '',
            readBook: false,
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 表单参数
            form: {
                teacherNo: '',
                username: '',
                teacherName: '',
                subject: '',
                subjectList: [],
                schoolId: '',
                gender: '',
                classIdsList: [],
            },
            tableData: [{
                adminAccount: '',
                orgName: ''
            }],
            loadingInstance: null,
            // 表单校验
            rules: {
            },
            bookList: [],
            configObj: {
                bookIds: [],
                orgId: ''
            },
            customList: [],
            orgList: [],
            oldBookIds: [],
            sceneList: [],
            subjectList: [],
            classList: [],
        };
    },
    created() {
    },
    mounted() {
        if (this.$route.query.teacherId) {
            this.teacherId = this.$route.query.teacherId;
            this.form = JSON.parse(this.$route.query.teacherData)
            if (this.form.classIds) {
                this.form.classIdsList = this.form.classIds.split(',')
            }

            console.log(this.form);
            this.getZhktQueryDict();
        } else {
        }
    },
    computed: {
    },
    methods: {
        validateUsername(rule, value, callback) {
            if (!isMobile(this.form.username)) {
                callback(new Error("手机号码格式不正确"))
            }
            teacherQueryOrRegUser({ username: this.form.username, trueName: this.form.teacherName, gender: this.form.gender }).then(res => {
                const result = res.data.data
                if (!result) {
                    callback(new Error("请输入正确账号"))
                } else {
                    if (this.form.teacherName == '') {
                        this.form.teacherName = res.data.data.trueName
                    }
                    if (this.form.gender == '') {
                        this.form.gender = res.data.data.gender
                    }
                    callback();
                }
            })
        },
        getZhktQueryDict() {
            zhktQueryDict({ code: 'subject' }).then(res => {
                this.subjectList = res.data.data;
            })
            classList({ customId: this.$storage.get('customId'), schoolId: this.form.schoolId }).then(res => {
                this.classList = res.data.data;
                var classIdsnameList = []
                this.form.classIdsList.forEach(e=>{
                    this.classList.forEach(j=>{
                        if(e == j.classId){
                            classIdsnameList.push(j.className)
                        }
                    })
                })
                this.form.classIdsname = classIdsnameList.join('、')
            });
        },
        handleChange(file, fileList) {
            console.log(fileList)
            console.log(fileList.length)

            this.form.file = []
            if (fileList[0]) {
                this.form.file.push(fileList[fileList.length - 1])
                this.form.fileName = fileList[fileList.length - 1].name.substring(0, fileList[fileList.length - 1].name.lastIndexOf("."));
                this.form.formName = fileList[fileList.length - 1].name.substring(0, fileList[fileList.length - 1].name.lastIndexOf("."));
            }
            // console.log(this.form.file)
        },
        validateUsername(rule, value, callback) {
            qunQueryUser({ mobile: value }).then(res => {
                const result = res.data.data
                if (!result) {
                    callback(new Error("该账号用户不存在"))
                } else {
                    this.form.adminName = res.data.data.nickName;
                    this.form.adminUserId = res.data.data.userId;

                    callback()
                }
            })
        },
        changeCustom() {
            this.orgList = [];
            this.sceneList = [];
            this.form.sceneId = '';
            this.form.orgId = '';
            queryTreeCustomOrg({ customId: this.form.customId }).then(res => {
                this.orgList = res.data.data;
                res.data.data.forEach((e, i) => {
                    e.label = e.orgName
                    e.value = e.orgId
                    e.children = e.childrenList;
                    e.disabled = true
                    this.ergodicList(e.childrenList)
                })
                console.log(res.data.data)
                this.orgList = res.data.data;
            })
            if (this.form.customId) {
                queryScene({ customId: this.form.customId }).then(res => {
                    this.sceneList = res.data.data;
                    if (this.sceneList.length == 1) {
                        this.form.sceneId = this.sceneList[0].sceneId
                    }
                })
            }
        },
        submitConfigForm() {
            console.log(this.configObj)
            configCustom(this.configObj).then(res => {
                if (res.data.code == 1) {
                    this.tableData.forEach(e => {
                        if (e.orgId) {
                            if (e.orgId == this.configObj.orgId) {
                                e.bookIds = this.configObj.bookIds
                            }
                        }
                    })
                    this.$message.success("配置成功");
                    this.addPeopleOpen = false;
                    this.configCancel();
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        configCancel() {
            this.readBook = false;
            this.configObj.bookIds = [];
            this.configObj.orgId = '';
        },
        configurationRow(index, row) {
            if (row.adminAccount == '') {
                this.$message.error("请填写管理员账号")
                return;
            }
            if (row.orgName == '') {
                this.$message.error("请填写组织名称")
                return;
            }
            this.readBook = true;
            this.configObj.bookIds = row.bookIds;
            this.configObj.orgId = row.orgId;
            console.log(index, row)
        },
        deleteRow(index) {
            console.log(index)
            if (this.tableData.length == 1) {
                this.$message.error("不能全部删除")
            } else {
                this.tableData.splice(index, 1)
            }
        },
        onAddItem() {
            console.log(this.tableData)
            this.tableData.push({
                adminAccount: '',
                orgName: ''
            })
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginDate === '' || !this.form.beginDate) {
                callback(new Error('请选择开始时间'))
            } else if (this.form.endDate === '' || !this.form.endDate) {
                callback(new Error('请选择结束时间'))
            } else {
                callback()
            }
        },
        startDateConfirm(e) {
            if (e > this.form.endDate) {
                this.form.endDate = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e < this.form.beginDate) {
                this.form.beginDate = '';
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date())
        },
        enddisabledDate(current) {
            if (this.form.beginDate) {
                return current && current < this.form.beginDate
            } else {
                return current && current < Number(new Date())
            }
        },


        /** 提交按钮 */
        submitForm() {
            console.log(this.form)
            this.$refs["form"].validate(valid => {
                if (valid) {
                    console.log(this.form)
                    this.form.subject = this.form.subjectList.join(',')
                    this.form.classIds = this.form.classIdsList.join(',')
                    if (this.teacherId) {
                        teacherUpdate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                this.replace('/teacherMain', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        });
                    } else {
                        formCreate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("新增成功");
                                this.replace('/teacherMain', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            this.replace('/teacherMain')
            // this.$emit('isTg', true)
        },
        ergodicList(childrenList) {
            if (childrenList.length != 0) {

                childrenList.forEach(e => {
                    e.label = e.orgName
                    e.value = e.orgId
                    console.log(this.findParentNode(e.orgId))
                    if (this.findParentNode(e.orgId).indexOf(2) == -1) {
                        e.disabled = true
                    }
                    if (e.orgType == 5) {
                        // debugger
                        e.disabled = true
                        console.log(e)
                    }
                    if (e.childrenList.length != 0) {
                        e.children = e.childrenList

                        this.ergodicList(e.childrenList)
                    }
                })
            }
        },
        // id 指的是当前点击的节点id;
        findParentNode(id) {
            // debugger
            // 初始化所需数据
            var firstParentObj = {}; // 记录直系父级的名称和id即接口要传的数据
            var parentIds = []; // 记录所有的父级ids
            var parentIdsQueue = []; // 记录排队的

            // 将树放到排队系列
            var parentIdsQueue = JSON.parse(JSON.stringify(this.orgList));

            // 开始遍历排队的树
            while (parentIdsQueue.length) {
                //抽取第一个排队的数据 
                let item = parentIdsQueue.shift();

                let { childrenList } = item;
                if (item.orgId === id) {
                    // 第一层就找到了
                    if (!item.parentArr) {
                        firstParentObj = {
                            id: item.orgId,
                            name: item.orgType
                        };
                        parentIds = [item.orgType];
                    } else {
                        // 获取当前节点的parentArr
                        let len = item.parentArr.length;
                        firstParentObj = item.parentArr[len - 1];
                        item.parentArr.forEach(a => {
                            parentIds.push(a.name);
                        });
                        parentIds.push(item.orgType);
                    }

                    // 结束遍历
                    parentIdsQueue = [];

                    break;
                } else if (childrenList && childrenList.length) {
                    let len = childrenList.length;
                    for (let i = len - 1; i >= 0; i--) {
                        // 新建一个数组用于记录它的父亲节点
                        childrenList[i].parentArr = [];

                        // 把它的历史父亲节点们先放入
                        if (item.parentArr) {
                            childrenList[i].parentArr = childrenList[i].parentArr.concat(
                                item.parentArr
                            );
                        }

                        // 再放入当前的父亲节点
                        childrenList[i].parentArr.push({
                            id: item.orgId,
                            name: item.orgType
                        });

                        // 加入到排队序列中
                        parentIdsQueue.unshift(childrenList[i]);
                    }

                }
            }
            return parentIds

        },
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.detailFF /deep/ .el-input__suffix {
    display: none;
}

.detailFF /deep/ .el-select .el-input.is-disabled .el-input__wrapper {
    cursor: unset;
    background: transparent;
    border: 0;
    box-shadow: none;
    padding: 0;
}

.detailFF /deep/ .el-select .el-select__tags-text {
    color: #333;
}

.detailFF /deep/ .el-select .el-input.is-disabled .el-input__inner {
    color: #333;
    -webkit-text-fill-color: #333;
}

.detailFF /deep/ .el-input.is-disabled {
    cursor: unset;
}
</style>