<template>
  <div class="user-info-content" v-loading="pageLoading" element-loading-text="正在切换组织...">
    <el-card>
      <template #header>
        <div slot="header" class="clearfix">
          <span>组织信息</span>
        </div>
      </template>
      <el-form ref="groupForm" :model="groupForm" label-width="120px" label-position="right">
        <el-form-item label="头像">
          <el-upload :action="actionUrl" :headers="headers" :data="avatarForm" :show-file-list="false"
            :on-success="changeAvatarSuccess" class="avatar-uploader">
            <el-image id="avatar" :src="avatarUrl" class="avatar" />
          </el-upload>
        </el-form-item>
        <el-form-item label="组织名称" prop="groupName">
          <el-input class="input-width-30" disabled v-model="groupForm.groupName" />
        </el-form-item>
        <el-form-item label="管理员账号" prop="managerAccount">
          <el-input class="input-width-30" disabled v-model="groupForm.managerAccount" />
        </el-form-item>
        <el-form-item label="授权开始时间" prop="beginDate">
          <el-date-picker style="width: 100%;" v-model="groupForm.beginDate" type="date" disabled placeholder="请选择授权开始时间" />
        </el-form-item>
        <el-form-item label="授权结束时间" prop="endDate">
          <el-date-picker style="width: 100%;" v-model="groupForm.endDate" type="date" disabled placeholder="请选择授权结束时间" />
        </el-form-item>
        <!--      <el-form-item>
        <el-button class="form-btn"  type="primary" @click="updateGroupInfo">保存</el-button>
      </el-form-item>-->
      </el-form>
    </el-card>
  </div>
</template>

<script>
import global from "@/utils/global"
export default {
  name: "groupInfo",
  data() {
    return {
      pageLoading: false,
      groupForm: {
        groupId: "",
        groupName: "",
        managerAccount: "",
        beginDate: "",
        endDate: "",
      },
      groupAccount: {},
      avatarUrl: "",
      avatarForm: {
        groupId: ""
      },
      headers: {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("token"))
      },
      actionUrl: global.groupAvatarUrl,
      global: global
    }
  },
  mounted() {
    window.addEventListener("changeSaasGroup", (e) => {
      this.groupAccount = JSON.parse(this.$storage.get("lastGroupAccount"))
      this.pageLoading = true
      this.setGroupAccountInfo()
    })
  },
  created() {
    this.groupAccount = JSON.parse(this.$storage.get("lastGroupAccount"))
    this.setGroupAccountInfo()
  },
  methods: {
    setGroupAccountInfo() {
      this.groupForm.groupId = this.groupAccount.id
      this.groupForm.groupName = this.groupAccount.groupName
      this.groupForm.managerAccount = this.groupAccount.managerAccount
      this.groupForm.beginDate = this.groupAccount.beginDate
      this.groupForm.endDate = this.groupAccount.endDate
      this.avatarForm.groupId = this.groupAccount.id
      this.avatarUrl = this.global.fileUrl + "/group/head/" + this.avatarForm.groupId + ".png_original?" + new Date().getTime()
      this.pageLoading = false
    },
    updateGroupInfo() {

    },
    changeAvatarSuccess() {
      this.avatarUrl = this.global.fileUrl + "/group/head/" + this.avatarForm.groupId + ".png_original?" + new Date().getTime()
      this.$message.success("头像上传成功")
    },
  }
}
</script>

<style scoped>
.user-info-content {
  width: 100%;
  height: 96%;
  background-color: #f7f8f8;
  padding: 0px 0 0 0;
}

.input-width-10 {
  width: 10%;
}

.input-width-20 {
  width: 20%;
}

.input-width-30 {
  width: 30%;
}

.avatar-uploader .avatar {
  width: 128px;
  height: 128px;
  display: block;
}

.form-btn {
  margin-left: 7%;
}
</style>
