import request from "@/utils/request";

// 新增智能本信息
export const bookManageCreate =(data) =>{
    return request({
        url: "/adminservice/bookManage/create",
        method:"post",
        ContentType:'multipart/form-data',
        data: data
    })
}

// 查询智能本列表
export const bookManageList =(query) =>{
    return request({
        url: "/adminservice/bookManage/list",
        method:"get",
        params: query
    })
}

// 删除智能本
export const bookManageDelete =(query) =>{
    return request({
        url: "/adminservice/bookManage/delete",
        method:"get",
        params: query
    })
}

// 分页查询智能本列表
export const bookManagePage =(data) =>{
    return request({
        url: "/adminservice/bookManage/page",
        method:"post",
        data: data
    })
}

// 查询智能本及其多媒体功能键信息
export const bookManageInfo =(query) =>{
    return request({
        url: "/adminservice/bookManage/info",
        method:"get",
        params: query
    })
}

// 铺设智能本
export const bookManagePrintPook =(data) =>{
    return request({
        url: "/adminservice/bookManage/printPook",
        method:"post",
        data: data
    })
}

// 发布或取消发布智能本
export const bookManageRelease =(query) =>{
    return request({
        url: "/adminservice/bookManage/release",
        method:"get",
        params: query
    })
}

// 更新智能本信息
export const bookManageUpdate =(data) =>{
    return request({
        url: "/adminservice/bookManage/update",
        method:"post",
        data: data
    })
}

// 上传、替换智能本封面
export const bookManageUploadCover =(data) =>{
    return request({
        url: "/adminservice/bookManage/uploadCover",
        method:"post",
        data: data
    })
}

// 创建功能键
export const actionCreate =(data) =>{
    return request({
        url: "/adminservice/action/create",
        method:"post",
        data: data
    })
}

// 编辑功能键
export const actionEdit =(data) =>{
    return request({
        url: "/adminservice/action/edit",
        method:"post",
        data: data
    })
}

// 查询智能本功能键列表
export const actionList =(query) =>{
    return request({
        url: "/adminservice/action/list",
        method:"get",
        params: query
    })
}

// 创建智能本多媒体资源
export const mediaCreate =(data) =>{
    return request({
        url: "/adminservice/media/create",
        method:"post",
        data: data
    })
}

// 编辑智能本多媒体资源
export const mediaEdit =(data) =>{
    return request({
        url: "/adminservice/media/edit",
        method:"post",
        data: data
    })
}

// 查询智能本多媒体列表
export const mediaList =(query) =>{
    return request({
        url: "/adminservice/media/list",
        method:"get",
        params: query
    })
}

// 查询智能本多媒体资源列表
export const mediaListMediaResource =(query) =>{
    return request({
        url: "/adminservice/media/listMediaResource",
        method:"get",
        params: query
    })
}

// 上传智能本多媒体资源
export const mediaUpload =(data) =>{
    return request({
        url: "/adminservice/media/upload",
        method:"post",
        data: data
    })
}

// 查询点阵资源列表
export const bookDotList =(query) =>{
    return request({
        url: "/adminservice/bookDot/list",
        method:"get",
        params: query
    })
}

// 上传图集
export const mediaUploadAtlas =(data) =>{
    return request({
        url: "/adminservice/media/uploadAtlas",
        method:"post",
        data: data
    })
}

// 查询图集图片列表
export const mediaAtlasList =(query) =>{
    return request({
        url: "/adminservice/media/atlasList",
        method:"get",
        params: query
    })
}