<template>
    <div class="app-container" @click="visible = false;">
        <el-card class="mb15">
            <el-row>
                <el-col :span="20" class="mb0">
                    <el-button type="primary" @click="handleExport">导出Excel</el-button>
                </el-col>
                <el-col :span="4" class="mr0 mb0">
                    <div class="pull-right">
                        <el-popover :visible="visible" popper-style="width: 320px !important;" placement="bottom"
                            :min-width="'auto'">
                            <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true"
                                label-width="68px" @submit.native.prevent="getList">
                                <el-row>
                                    <el-col :span="24" class="mr0" v-if="!role.includes(1)">
                                        <el-form-item label="所属客户" class="w-100">
                                            <el-select @change="changeCustom" filterable clearable
                                                v-model="queryParams.customId" placeholder="请选择" class="w-100">
                                                <el-option v-for="item in customList" :key="item.customId"
                                                    :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                                    :value="item.customId" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="mr0">
                                        <el-form-item label="应用场景" class="w-100">
                                            <el-select @change="changeScene" filterable clearable class="w-100"
                                                v-model="queryParams.sceneId" placeholder="请选择">
                                                <el-option v-for="item in sceneList" :key="item.sceneId"
                                                    :label="item.sceneName" :value="item.sceneId" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="mr0">
                                        <el-form-item label="表单名称" class="w-100">
                                            <el-select filterable v-model="queryParams.formId" placeholder="请选择"
                                                class="w-100">
                                                <el-option v-for="item in formList" :key="item.formId"
                                                    :label="item.formName" :value="item.formId + ',' + item.formName" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="mr0">
                                        <el-form-item label="采集人员" class="w-100">
                                            <el-select multiple filterable clearable v-model="queryParams.memberNameList"
                                                class="w-100" placeholder="请选择">
                                                <el-option v-for="item in peopleList" :key="item.memberUserId"
                                                    :label="item.memberName + '   ' + item.memberAccount"
                                                    :value="item.memberName" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="zdp mr0">
                                        <el-form-item label="采集时间" class="w-100">
                                            <el-date-picker value-format="x" class="w-100" v-model="queryParams.createTime"
                                                type="datetimerange" range-separator="至" start-placeholder="开始时间"
                                                :default-time="[new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 59)]"
                                                end-placeholder="结束时间" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="">
                                        <el-form-item label="表单状态" class="w-100">
                                            <el-select filterable clearable v-model="queryParams.finishName" class="w-100"
                                                placeholder="请选择">
                                                <el-option v-for="item in isFinishList" :key="item.value" :label="item.name"
                                                    :value="item.name" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <div style="margin:0 auto;">
                                        <el-button @click="getList" type="primary">查找</el-button>
                                    </div>
                                </el-row>
                            </el-form>
                            <template #reference>
                                <el-button type="primary" @click.stop="visible = !visible;">筛选条件</el-button>
                            </template>
                        </el-popover>
                    </div>
                </el-col>
            </el-row>
        </el-card>
        <el-card>
            <!-- <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true" label-width="68px"
                @submit.native.prevent="handleQuery">
                <el-row class="">
                    <el-col :span="6" class="mr0" v-if="role.includes(0)">
                        <el-form-item label="所属客户">
                            <el-select @change="changeCustom" filterable clearable v-model="queryParams.customId"
                                placeholder="请选择">
                                <el-option v-for="item in customList" :key="item.customId"
                                    :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                    :value="item.customId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mr0">
                        <el-form-item label="应用场景">
                            <el-select @change="changeScene" filterable clearable v-model="queryParams.sceneId"
                                placeholder="请选择">
                                <el-option v-for="item in sceneList" :key="item.sceneId" :label="item.sceneName"
                                    :value="item.sceneId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="">
                        <el-form-item label="表单状态">
                            <el-select filterable clearable v-model="queryParams.finishName" placeholder="请选择">
                                <el-option v-for="item in isFinishList" :key="item.value" :label="item.name"
                                    :value="item.name" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mr0">
                        <el-form-item label="表单名称">
                            <el-select filterable v-model="queryParams.formId" placeholder="请选择">
                                <el-option v-for="item in formList" :key="item.formId" :label="item.formName"
                                    :value="item.formId + ',' + item.formName" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="">
                    <el-col :span="6" class="mr0">
                        <el-form-item label="采集人员">
                            <el-select filterable clearable v-model="queryParams.memberName" placeholder="请选择">
                                <el-option v-for="item in peopleList" :key="item.memberUserId"
                                    :label="item.memberName + '   ' + item.memberAccount" :value="item.memberName" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="zdp">
                        <el-form-item label="采集时间">
                            <el-date-picker style="width: 100%;" value-format="x" v-model="queryParams.createTime"
                                type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" class="">
                        <el-button style="width:100px" class="pull-right" type="primary" @click="getList">查找</el-button>
                    </el-col>
                </el-row>
            </el-form>
            <el-divider style="position: relative;" v-if="isSxShow">
                <img @click="isSxShow = false;" style="transform: translateY(-100%);" class="sxShowcss"
                    src="../../assets/img/arrow-up.png" alt="">
            </el-divider>
            <div class="" style="display: flex;flex-wrap: wrap;align-items: center;position: relative;">
                <img @click="isSxShow = true;" v-if="!isSxShow" style="left: 50%;top: -20px;" class="sxShowcss"
                    src="../../assets/img/arrow-down.png" alt="">
                <div :key="item" v-for="item in smTitList" class="pxbutton" @click="changeActive(item.value)"
                    :style="activeObj == item.value ? 'background: #21ac74;color:#fff;' : ''">
                    {{ item.name }}
                </div>
            </div> -->
            <!-- <div v-for="item in smTitList"> -->
            <div class="">
                <el-table header-cell-class-name="bgf2" v-show="activeObj == 'yes'" @current-change="handleCurrentChange"
                    class="sssstt" :height="'calc(100vh - ' + (isSxShow ? '250' : '305') + 'px)'" v-loading="loading"
                    :data="dataList" highlight-current-row>
                    <!-- <el-table-column align="center" type="selection" width="55" /> -->
                    <!-- <el-table-column v-if="dataList.length != 0" align="center" fixed type="index" label="序号" width="60" /> -->
                    <el-table-column align="center" :width="item.width" :label="item.label" :prop="item.prop"
                        v-for="item in elTitleList">
                        <template #default="scope" v-if="item.label == '表单状态'">
                            <!-- <div style="color: #c45656;" v-if="scope.row.finishName == '删除'">删除</div>
                            <div style="color: rgb(40 12 175);" v-if="scope.row.finishName == '填报'">填报</div> -->
                            <div style="color: #eea507;" v-if="scope.row.isIdentify == 1">识别</div>
                            <div style="color: #048d17;" v-if="scope.row.isIdentify == 2">确认</div>
                        </template>
                        <template #default="scope" v-else>
                            <div style="word-break: break-word;">
                                {{ scope.row[item.prop] }}
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- <el-table header-cell-class-name="bgf2" v-show="activeObj == item.value" v-else show-overflow-tooltip
                        @current-change="handleCurrentChange" class="sssstt" height="calc(100vh - 475px)"
                        v-loading="loading" :data="tbList[item.value]" highlight-current-row>
                        <el-table-column align="center" fixed prop="row" label="序号" width="60" />
                        <el-table-column align="center" :label="index + 1" :prop="index + 1" width="100"
                            v-for="(items, index) in item.bigNum" />
                        <el-table-column align="center" :width="item.width" :label="item.label" :prop="item.prop"
                            v-for="item in cusList">
                            <template #default="scope" v-if="item.label == '表单状态'">
                                <div style="color: #c45656;" v-if="scope.row.finishName == '删除'">删除</div>
                                <div style="color: rgb(40 12 175);" v-if="scope.row.finishName == '填报'">填报</div>
                                <div style="color: #eea507;" v-if="scope.row.finishName == '识别'">识别</div>
                                <div style="color: #048d17;" v-if="scope.row.finishName == '确认'">确认</div>
                            </template>
                        </el-table-column>
                    </el-table> -->
            </div>
            <!-- </div> -->

            <!-- <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
                :limit.sync="queryParams.pageSize" @pagination="getList" /> -->
        </el-card>
    </div>
</template>

<script>

import FileSaver from "file-saver";
// import XLSX from "xlsx/dist/xlsx.full.min";
import XLSX from "xlsx-js-style";

import table2excel from 'js-table2excel';
import {
    exportData
} from "@/api/demo/data";
import { timestampToTime } from "@/utils/date";
import { queryCustoms } from "@/api/adminservice/custom";
import { formQuery, deleteForm, formRelease, formStop } from "@/api/adminservice/form"
import { download } from "@/api/adminservice/public"
import { queryScene, queryAuthMembers, sceneInfo } from "@/api/adminservice/scene"
import { queryFormResult, queryAllRecords } from "@/api/adminservice/formRecord"

export default {
    name: "scenarioMain",
    components: {
    },
    data() {
        return {
            visible: false,
            isSxShow: true,
            role: '',
            customList: [],
            peopleList: [],
            isFinishList: [
                // { name: '填报', value: 0 },
                { name: '识别', value: 1 },
                { name: '确认', value: 2 },
                // { name: '删除', value: 3 },
            ],
            elTitleList: [

            ],
            cusList: [
                {
                    label: '归属客户',
                    prop: 'customName',
                    width: 150
                },
                {
                    label: '应用场景',
                    prop: 'sceneName',
                    width: 180
                },
                {
                    label: '表单状态',
                    prop: 'finishName',
                    width: 90
                },
                {
                    label: '表单名称',
                    prop: 'formName',
                    width: 180
                },
                {
                    label: '采集人员',
                    prop: 'memberName',
                    width: 100
                },
                {
                    label: '采集时间',
                    prop: 'createTime',
                    width: 150
                }
            ],
            statusList: [
                { name: '未铺设', value: 0 },
                { name: '铺设中', value: 1 },
                { name: '铺设成功', value: 2 },
                { name: '铺设失败', value: 3 },
                { name: '发布', value: 4 },
                { name: '停用', value: 5 },
            ],
            formList: [],
            sceneList: [],
            oldDataList: [],
            timestampToTime,
            activeObj: 'yes',
            isSuperAuth: this.$storage.get('isSuperAuth'),
            // 遮罩层
            loading: false,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                formName: '',
                screenName: '',
                status: '',
                memberNameList: [],
                sceneId: '',
                isDesign: '',
                customId: '',
                finishName: '',
                createTime: [],
                releaseTime: []
            },
            sonTableList: {},
            tbList: {},
            recordDataList: {},
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            selectObj: [],
            smTitList: [],
        };
    },
    created() {
        this.role = this.$storage.get("role")
        // this.getPeopleList();
        this.getSceneList()
        this.getFormList();
        if (this.$storage.get("role").includes(1)) {
            this.queryParams.customId = this.$storage.get("customId")
            this.changeCustom()
        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        pdCmm(identifyName, identifyNameList, i) {
            if (identifyNameList.includes(identifyName + i)) {
                i = i + 1;
                this.pdCmm(identifyName, identifyNameList, i)
            } else {
                identifyNameList.push(identifyName + i)
                return identifyName + i
            }
        },
        changeCustom() {
            this.peopleList = [];
            this.queryParams.memberNameList = [];
            this.queryParams.sceneId = '';
            this.queryParams.formId = '';
            queryScene({customId: this.queryParams.customId?this.queryParams.customId:this.$storage.get('customId')}).then(res => {
                if (this.queryParams.customId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.customId.toString().indexOf(this.queryParams.customId) != -1
                    })
                }
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.sceneType != 1;
                })
                this.sceneList = res.data.data;
                this.changeScene()
            });
        },
        changeScene() {
            this.peopleList = [];
            this.queryParams.memberNameList = [];
            this.queryParams.formId = '';
            if (this.queryParams.sceneId !== '') {
                sceneInfo({ sceneId: this.queryParams.sceneId }).then(res => {
                    this.peopleList = res.data.data.memberList;
                })
            }
            formQuery({ customId: this.queryParams.customId?this.queryParams.customId:this.$storage.get('customId') }).then(res => {
                if (this.queryParams.customId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.customId.toString().indexOf(this.queryParams.customId) != -1
                    })
                }
                if (this.queryParams.sceneId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.sceneId.toString().indexOf(this.queryParams.sceneId) != -1
                    })
                }
                this.formList = res.data.data;
            });
        },
        changeActive(e) {
            this.activeObj = e
        },
        getPeopleList() {
            queryAuthMembers().then(res => {
                this.peopleList = res.data.data;
            })
            // queryAllRecords({ customId: this.$storage.get('customId') }).then(res => {
            //     res.data.data.forEach(e => {
            //         if (this.peopleList.indexOf(e.memberName) != -1) {

            //         } else {
            //             this.peopleList.push(e.memberName)
            //         }
            //     })
            // })
        },
        handleExport() {
            console.log(this.dataList)
            console.log(this.elTitleList)
            console.log(this.smTitList);
            const wb = XLSX.utils.book_new()

            var timestamp = timestampToTime(Date.parse(new Date()));
            let jlObj = {}
            this.smTitList.forEach((e, i) => {
                if (jlObj.hasOwnProperty(e.name)) {
                    jlObj[e.name] = jlObj[e.name] + 1;
                } else {
                    jlObj[e.name] = 0
                }
                if (e.value == 'yes') {
                    this.dataList.forEach(e => {
                        this.elTitleList.forEach(j => {
                            if (!Reflect.has(e, j.prop)) {
                                e[j.prop] = ''
                            }
                        })
                    })
                    console.log(this.dataList)
                    var tableData = []
                    this.dataList.forEach((j, k) => {
                        tableData.push({})
                    })
                    this.elTitleList.forEach((m) => {
                        this.dataList.forEach((j, k) => {
                            for (let st in j) {
                                if (st == m.prop) {
                                    if (j[m.prop]) {
                                        tableData[k][m.label] = Number(j[m.prop]) ? Number(j[m.prop]) : j[m.prop]
                                    } else {
                                        tableData[k][m.label] = ''
                                    }
                                }
                            }
                        })
                    })
                    console.log(tableData)
                    const data = XLSX.utils.json_to_sheet(tableData)//此处tableData.value为表格的数据
                    console.log(data);
                    for (const cell in data) {
                        if (cell != '!ref') {
                            // console.log(XLSX.utils.decode_cell(cell));
                            if (XLSX.utils.decode_cell(cell).r == 0) {
                                data[cell]['s'] = {
                                    border: {
                                        bottom: { style: 'thin' },
                                        left: { style: 'thin' },
                                        right: { style: 'thin' },
                                        top: { style: 'thin' }
                                    },
                                    font: {
                                        bold: true
                                    },
                                    alignment: {
                                        vertical: 'center',
                                        horizontal: 'center'
                                    },
                                    fill: {
                                        fgColor: { rgb: 'eeece1' }
                                    }
                                }
                            } else {
                                data[cell]['s'] = {
                                    border: {
                                        bottom: { style: 'thin' },
                                        left: { style: 'thin' },
                                        right: { style: 'thin' },
                                        top: { style: 'thin' }
                                    },
                                }
                            }
                            // if(cell )

                        }
                        // console.log(data[cell]);
                    }
                    // console.log(data);
                    if (jlObj[e.name] == 0) {
                        XLSX.utils.book_append_sheet(wb, data, e.name.substring(0, 30))//test-data为自定义的sheet表名
                    } else {
                        XLSX.utils.book_append_sheet(wb, data, (e.name + jlObj[e.name]).substring(0, 30))//test-data为自定义的sheet表名
                    }
                } else {

                    // // const datas = tableDatas
                    // const column = [
                    //     {
                    //         title: '序号',
                    //         key: 'row',
                    //         type: 'text'
                    //     }
                    // ];
                    // for (var i = 1; i <= e.bigNum; i++) {
                    //     column.push({
                    //         title: i,
                    //         key: i,
                    //         type: 'text',
                    //     })
                    // }
                    // this.cusList.forEach(e => {
                    //     column.push({
                    //         title: e.label,
                    //         key: e.prop,
                    //         type: 'text',
                    //     })
                    // })
                    // var tableData = []
                    // this.tbList[e.value].forEach((j, k) => {
                    //     tableData.push({})
                    // })
                    // console.log(column)
                    // console.log(this.tbList[e.value])
                    // column.forEach((m) => {
                    //     this.tbList[e.value].forEach((j, k) => {
                    //         for (let st in j) {
                    //             if (st == m.key) {
                    //                 tableData[k][m.title + ' '] = j[m.key]
                    //             }
                    //         }

                    //     })

                    // })
                    // console.log(tableData)
                    // const data = XLSX.utils.json_to_sheet(tableData)//此处tableData.value为表格的数据
                    // if (jlObj[e.name] == 0) {
                    //     XLSX.utils.book_append_sheet(wb, data, e.name.substring(0, 30))//test-data为自定义的sheet表名
                    // } else {
                    //     XLSX.utils.book_append_sheet(wb, data, (e.name + jlObj[e.name]).substring(0, 30))//test-data为自定义的sheet表名
                    // }

                }
            })
            XLSX.writeFile(wb, timestamp + '表单数据查询.xlsx')//test.xlsx为自定义的文件名

        },
        getFormList() {
            formQuery({ customId: this.queryParams.customId?this.queryParams.customId:this.$storage.get('customId') }).then(res => {
                this.formList = res.data.data;
                // if (this.formList.length != 0) {
                //     this.queryParams.formId = this.formList[0].formId + ',' + this.formList[0].formName
                // } else {
                //     this.loading = false;
                // }
                // this.getList();
            });
        },
        handleCurrentChange(val) {
            console.log(val)
            this.selectObj = val;
        },
        handleSelectionChange(val) {
            console.log(val)
            this.selectList = val;
        },
        getSceneList() {
            this.customList = []

            queryScene({ customId: this.queryParams.customId ? this.queryParams.customId : this.$storage.get('customId') }).then(res => {
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.sceneType != 1;
                })
                this.sceneList = res.data.data;
            });
            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.customType.includes('010');
                })
                res.data.data.sort(function (a, b) {
                    return a.customName.localeCompare(b.customName);
                })
                setTimeout(() => {
                    res.data.data.forEach(e => {
                        if (e.status == 0) {
                            this.customList.push(e)
                        }
                    })
                    res.data.data.forEach(e => {
                        if (e.status == 1) {
                            this.customList.push(e)
                        }
                    })
                    this.customList.forEach((e, i) => {
                        if (e.customName == '南京孜博汇信息科技有限公司') {
                            this.customList.splice(i, 1)
                            this.customList.unshift(e)
                        }
                    })
                })

            });
        },
        /** 查询基础：基数数据列表 */
        getList() {
            if (!this.queryParams.formId) {
                this.$message.error('请选择表单')
                return
            }
            this.visible = false;
            this.dataList = [];
            this.sonTableList = {};
            this.tbList = {};
            this.recordDataList = {};
            console.log(this.queryParams.createTime);
            console.log(this.queryParams.releaseTime);

            this.loading = true;
            var form = this.queryParams.formId.split(',');
            this.smTitList = [{ name: form[1], value: 'yes' }];
            this.activeObj = 'yes';
            queryFormResult({ formId: form[0] }).then(res => {
                // this.oldDataList = JSON.parse(JSON.stringify(res.data.data))
                console.log(res)
                if (this.queryParams.screenName !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.formNo.indexOf(this.queryParams.screenName) != -1 || itemA.fileName.indexOf(this.queryParams.screenName) != -1 || itemA.formName.indexOf(this.queryParams.screenName) != -1
                    })
                }
                if (this.queryParams.status !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.status.toString().indexOf(this.queryParams.status) != -1
                    })
                }
                if (this.queryParams.memberNameList.length != 0) {
                    res.data.data = res.data.data.filter(itemA => {
                        return this.queryParams.memberNameList.includes(itemA.memberName)
                    })
                }
                if (this.queryParams.sceneId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.sceneId.toString().indexOf(this.queryParams.sceneId) != -1
                    })
                }
                if (this.queryParams.isDesign !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.isDesign.toString().indexOf(this.queryParams.isDesign) != -1
                    })
                }
                if (this.queryParams.customId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.customId.toString().indexOf(this.queryParams.customId) != -1
                    })
                }
                if (this.queryParams.createTime && this.queryParams.createTime.length != 0) {
                    console.log(1)
                    res.data.data = res.data.data.filter(itemA => {
                        let currTime = itemA.createTime
                        let res = ((currTime - this.queryParams.createTime[0]) > 0 && (currTime - this.queryParams.createTime[1]) < 0);
                        return res
                    })
                }
                if (this.queryParams.releaseTime && this.queryParams.releaseTime.length != 0) {
                    console.log(1)
                    res.data.data = res.data.data.filter(itemA => {
                        let currTime = itemA.releaseTime
                        let res = ((currTime - this.queryParams.releaseTime[0]) > 0 && (currTime - this.queryParams.releaseTime[1]) < 0);
                        return res
                    })
                }
                if (this.queryParams.finishName !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        if (this.queryParams.finishName == '识别') {
                            return itemA.isIdentify == 1
                        } else if (this.queryParams.finishName == '确认') {
                            return itemA.isIdentify == 2
                        }
                    })
                }
                var formList = [];
                this.elTitleList = [];
                var designIdList = [];
                var recordIdList = [];
                var isArray = { num: '' }
                var identifyNameList = ['表单状态', '采集人员', '采集时间', '归属客户', '应用场景', '表单名称']
                var numList = [];
                res.data.data.forEach(e => {
                    // console.log(e.identifyValue)
                    // if(e.designId == 9023214681){
                    //     console.log(e)
                    // }
                    // var num = Number(e.num)
                    if (e.isTable == 1) {
                        if (recordIdList.indexOf(e.recordId) == -1) {
                            recordIdList.push(e.recordId)
                        }
                        if (this.sonTableList[e.num]) {
                            this.sonTableList[e.num].push(e)
                        } else {
                            this.sonTableList[e.num] = [e]
                        }
                        if (this.tbList[e.num]) {
                        } else {
                            this.tbList[e.num] = [];
                        }
                    }
                    if (formList.indexOf(e.recordId) == -1) {
                        formList.push(e.recordId)
                    }
                    if (designIdList.indexOf(e.designId) == -1) {
                        designIdList.push(e.designId)
                        if (e.isTable == 1) {

                            if (identifyNameList.includes(e.identifyName)) {
                                if (numList.includes(e.num)) {
                                    this.elTitleList.push({
                                        label: e.identifyName,
                                        prop: e.num,
                                        width: 180
                                    })
                                } else {
                                    var identifyName = this.pdCmm(e.identifyName, identifyNameList, 1)
                                    this.elTitleList.push({
                                        label: identifyName,
                                        prop: e.num,
                                        width: 180
                                    })
                                    numList.push(e.num)
                                }
                                console.log(identifyNameList);
                            } else {
                                identifyNameList.push(e.identifyName)
                                this.elTitleList.push({
                                    label: e.identifyName,
                                    prop: e.num,
                                    width: 180
                                })
                                numList.push(e.num)
                            }
                        } else {
                            isArray = { num: '' }
                            if (identifyNameList.includes(e.identifyName)) {
                                var identifyName = this.pdCmm(e.identifyName, identifyNameList, 1)
                                this.elTitleList.push({
                                    label: identifyName,
                                    prop: e.designId,
                                    width: 180
                                })
                                console.log(identifyNameList);
                            } else {
                                identifyNameList.push(e.identifyName)
                                this.elTitleList.push({
                                    label: e.identifyName,
                                    prop: e.designId,
                                    width: 180
                                })
                            }

                        }

                    }
                    // this.elTitleList.push({
                    //     label:e.identifyName,
                    //     prop:e.designId
                    // })
                })
                this.elTitleList = this.uniqueBy(this.elTitleList, 'prop')
                this.elTitleList.unshift(
                    {
                        label: '表单状态',
                        prop: 'finishName',
                        width: 90
                    },
                    {
                        label: '采集人员',
                        prop: 'memberName',
                        width: 130
                    },
                    {
                        label: '采集时间',
                        prop: 'createTime',
                        width: 150
                    },
                )
                this.elTitleList.push(
                    {
                        label: '归属客户',
                        prop: 'customName',
                        width: 150
                    },
                    {
                        label: '应用场景',
                        prop: 'sceneName',
                        width: 180
                    },

                    {
                        label: '表单名称',
                        prop: 'formName',
                        width: 180
                    },
                )



                // console.log(this.dataList)
                console.log(this.sonTableList)
                // console.log(this.tbList);
                // console.log(recordIdList)
                recordIdList.forEach(e => {
                    this.recordDataList[e] = {}
                })
                var _this = this;
                Object.getOwnPropertyNames(this.sonTableList).forEach(function (key) {
                    for (let ts in _this.recordDataList) {
                        _this.recordDataList[ts][key] = []
                    }
                })
                console.log(this.recordDataList)
                var _this = this;
                var ptList = [];
                Object.getOwnPropertyNames(this.sonTableList).forEach(function (key) {

                    console.log(key, _this.sonTableList[key]);


                    var bigcolNum = 0;
                    _this.sonTableList[key].forEach((m, n) => {
                        // _this.recordDataList[m.recordId][m.num]
                        if (bigcolNum <= Number(m.colNum)) {
                            bigcolNum = Number(m.colNum)
                        }
                    })
                    _this.sonTableList[key].forEach((m, n) => {
                        if (m.tableType == '单选' || m.tableType == '多选') {
                        } else {
                            if (_this.recordDataList[m.recordId][m.num].length < m.rowNum) {
                                _this.recordDataList[m.recordId][m.num].push([])
                            }
                        }

                    })
                    _this.sonTableList[key].forEach((m, n) => {
                        if (m.tableType == '单选' || m.tableType == '多选') {
                            if (m.identifyValue == 'Y') {
                                _this.recordDataList[m.recordId][m.num].push(m.itemValue)
                            }
                        } else {
                            ptList.push(m.num)
                            console.log(_this.recordDataList[m.recordId][m.num]);
                            // console.log(_this.recordDataList)
                            // _this.recordDataList[m.recordId][Number(m.num)][m.rowNum - 1]['row'] = m.rowNum
                            try {
                                _this.recordDataList[m.recordId][m.num][m.rowNum - 1][Number(m.colNum) - 1] = m.identifyValue
                            } catch (error) {
                                console.log(error);
                            }
                            // _this.recordDataList[m.recordId][Number(m.num)][m.rowNum - 1]['tableType'] = m.tableType
                            // _this.recordDataList[m.recordId][Number(m.num)][m.rowNum - 1]['customName'] = m.customName
                            // _this.recordDataList[m.recordId][Number(m.num)][m.rowNum - 1]['sceneName'] = m.sceneName
                            // _this.recordDataList[m.recordId][Number(m.num)][m.rowNum - 1]['finishName'] = m.finishName
                            // _this.recordDataList[m.recordId][Number(m.num)][m.rowNum - 1]['formName'] = m.formName
                            // _this.recordDataList[m.recordId][Number(m.num)][m.rowNum - 1]['memberName'] = m.memberName
                            // _this.recordDataList[m.recordId][Number(m.num)][m.rowNum - 1]['createTime'] = timestampToTime(m.createTime)
                        }

                    })
                    _this.smTitList.push({ name: _this.sonTableList[key][0].identifyName, value: key, bigNum: bigcolNum })

                })
                for (let as in this.recordDataList) {
                    for (let ba in this.recordDataList[as]) {
                        if (ptList.indexOf(ba) != -1) {
                            var tsList = [];
                            console.log(this.recordDataList[as][ba]);
                            this.recordDataList[as][ba].forEach(e => {
                                tsList.push(e.join(','))
                            })
                            this.recordDataList[as][ba] = tsList.join(';')
                        } else {
                            console.log(this.recordDataList[as][ba]);
                            this.recordDataList[as][ba] = this.recordDataList[as][ba].join(',')
                        }
                    }
                }
                console.log(formList);

                formList.forEach(e => {
                    var obj = {
                    }
                    for (let as in this.recordDataList) {
                        if (e == as) {
                            for (let ba in this.recordDataList[as]) {
                                obj[ba] = this.recordDataList[as][ba]
                            }
                        }
                    }
                    res.data.data.forEach(m => {
                        if (m.num == '58277024552583959') {
                            console.log(1, m);
                        }
                        if (m.identifyName == '病情') {
                            console.log(2, m);
                        }
                        if (m.isTable == 0) {
                            if (e == m.recordId) {
                                // console.log('m', m)
                                // debugger
                                if (m.isIdentify == 1) {
                                    m.finishName = '识别'
                                } else if (m.isIdentify == 2) {
                                    m.finishName = '确认'
                                }
                                obj.isIdentify = m.isIdentify
                                obj.customName = m.customName
                                obj.sceneName = m.sceneName
                                obj.formName = m.formName
                                obj.memberName = m.memberName
                                obj.createTime = timestampToTime(m.createTime)
                                obj.finishName = m.finishName
                                obj.recordId = m.recordId
                                designIdList.forEach(j => {
                                    if (j == m.designId) {
                                        // console.log(m)
                                        obj[j] = m.identifyValue
                                    }
                                })
                            } else {

                            }
                        }


                    })
                    console.log(obj);
                    this.dataList.push(obj)
                })
                this.dataList.sort(function (a, b) {
                    if (a.createTime > b.createTime) { return -1; }
                    if (a.createTime < b.createTime) { return 1; }
                    return 0;
                })
                console.log(this.recordDataList)
                console.log(this.elTitleList);
                console.log(this.dataList);
                // console.log(this.tbList);
                this.loading = false;

                // this.dataList = res.data.data;
            });
        },
        uniqueBy(arr, key) {
            const seen = new Set();
            return arr.filter((item) => {
                const seenValue = seen.has(item[key]);
                seen.add(item[key]);
                return !seenValue;
            });
        },
        handleXz() {
            if (!this.selectObj.formId) {
                this.$message.error("请选择数据");
                return;
            }
            download(this.selectObj.formUrl + '/original.pdf', this.selectObj.formName)
        },
        hadlePs() {
            if (!this.selectObj.formId) {
                this.$message.error("请选择数据");
                return;
            }
            let routeUrl = this.$router.resolve({
                path: '/ZBDesigner',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { userId: JSON.parse(this.$storage.get('userInfo')).userId, formId: this.selectObj.formId }
            })
            window.open(routeUrl.href, '_blank')

            // var url = "http://192.168.101.116:8080/?userId=" + JSON.parse(this.$storage.get('userInfo')).userId + "&formId=" + this.selectObj.formId
            // window.open(url)
        },
        goDetail(row) {
            this.$router.push({
                path: "/formDetail",
                query: {
                    formId: row.formId,
                }
            });
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                title: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
            // this.$refs['form'].resetFields()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.$router.push({
                path: "/formMainAdd",
            });
            // this.reset();
            // this.open = true;
            // this.form.px = 10;
            // this.title = "添加基础：基数数据";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.$router.push({
                path: "/formMainAdd",
                query: {
                    formId: row.formId,
                }
            });
        }
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.sssstt /deep/ .el-scrollbar__view {
    display: block !important;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}

.pxbutton {
    padding: 7px 15px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #e4e7ed;
    font-size: 13px;
}
</style>