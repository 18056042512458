import request from "@/utils/request";

// 查询群组信息列表
export function queryAllGroup(data) {
    return request({
        url: '/businessservice/group/queryAllGroup',
        method: 'post',
        data: data
    });
}

// 查询所有教师及其组织信息
export function queryAllTeacherOrgInfo(query) {
    return request({
        url: '/businessservice/group/queryAllTeacherOrgInfo',
        method: 'get',
        params: query
    });
}

// 删除群组成员
export function deleteGroupMember(query) {
    return request({
        url: '/businessservice/group/deleteGroupMember',
        method: 'get',
        params: query
    });
}

// 删除群组管理员
export function deleteGroupManager(query) {
    return request({
        url: '/businessservice/group/deleteGroupManager',
        method: 'get',
        params: query
    });
}

// 创建群组
export function createGroup(data) {
    return request({
        url: '/businessservice/group/createGroup',
        method: 'post',
        data: data
    });
}

// 更新群组
export function updateGroup(data) {
    return request({
        url: '/businessservice/group/updateGroup',
        method: 'post',
        data: data
    });
}

// 查询基础：基数数据列表
export function listData(query) {
    return request({
        url: '/cms/data/list',
        method: 'get',
        params: query
    });
}

// 查询基础：基数数据详细
export function getData(did) {
    return request({
        url: '/cms/data/' + did,
        method: 'get'
    });
}

// 新增基础：基数数据
export function addData(data) {
    return request({
        url: '/cms/data',
        method: 'post',
        data: data
    });
}

// 修改基础：基数数据
export function updateData(data) {
    return request({
        url: '/cms/data',
        method: 'put',
        data: data
    });
}

// 删除基础：基数数据
export function delData(did) {
    return request({
        url: '/cms/data/' + did,
        method: 'delete'
    });
}

// 导出基础：基数数据
export function exportData(query) {
    return request({
        url: '/cms/data/export',
        method: 'get',
        params: query
    });
}