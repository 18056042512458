import request from "@/utils/request";

// 查询表单(不传客户标识查询全部)
export const formQuery =(query) =>{
    return request({
        url: "/adminservice/form/queryForm",
        method:"get",
        params: query
    })
}
// 创建表单
export const formCreate =(data) =>{
    return request({
        url: "/adminservice/form/createForm",
        method:"post",
        ContentType:'multipart/form-data',
        data:data
    })
}
// 删除表单
export const deleteForm =(query) =>{
    return request({
        url: "/adminservice/form/deleteForm",
        method:"get",
        params: query
    })
}
// 查询表单信息
export const formInfo =(query) =>{
    return request({
        url: "/adminservice/form/info",
        method:"get",
        params: query
    })
}
// 修改表单
export const updateForm =(data) =>{
    return request({
        url: "/adminservice/form/updateForm",
        method:"post",
        data:data
    })
}
// 发布表单
export const formRelease =(query) =>{
    return request({
        url: "/adminservice/form/release",
        method:"get",
        params: query
    })
}
// 查询表单信息
export const formStop =(query) =>{
    return request({
        url: "/adminservice/form/stop",
        method:"get",
        params: query
    })
}

// 铺设表单(拓思德)
export const print =(query) =>{
    return request({
        url: "/adminservice/form/print",
        method:"get",
        params: query
    })
}
// 铺设表单(腾千里)
export const printTql =(data) =>{
    return request({
        url: "/adminservice/form/printTql",
        method:"post",
        data:data
    })
}
// 查询客户群笔记表单
export const formGroupForm =(query) =>{
    return request({
        url: "/adminservice/form/queryGroupForm",
        method:"get",
        params: query
    })
}

// 查询明信卡片(不传客户标识查询全部)
export const formQueryCards =(query) =>{
    return request({
        url: "/adminservice/form/queryCards",
        method:"get",
        params: query
    })
}

// 查询点阵资源列表
export const customDotList =(query) =>{
    return request({
        url: "/adminservice/customDot/list",
        method:"get",
        params: query
    })
}

// 查询表单选择及输入设计项
export const formRecordDesign =(query) =>{
    return request({
        url: "/adminservice/formRecord/formDesign",
        method:"get",
        params: query
    })
}
// 保存选择或输入值
export const formRecordResult =(data) =>{
    return request({
        url: "/adminservice/formRecord/saveSelectResult",
        method:"post",
        data:data
    })
}
// 查询关联表列表
export const formRelateTable =(query) =>{
    return request({
        url: "/adminservice/form/relateTable",
        method:"get",
        params: query
    })
}
// 铺设腾千里空白表单
export const formPrintTqlBlank =(query) =>{
    return request({
        url: "/adminservice/form/printTqlBlank",
        method:"get",
        params: query
    })
}
// 替换封面
export const formReplaceCover =(data) =>{
    return request({
        url: "/adminservice/form/replaceCover",
        method:"post",
        ContentType:'multipart/form-data',
        data:data
    })
}