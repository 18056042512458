import request from "@/utils/request";

// 删除学校
export const  schoolDelete =(query) =>{
    return request({
        url: "/adminservice/zhkt/school/delete",
        method:"get",
        params: query
    })
}
// 查询学校信息
export const  schoolInfo =(query) =>{
    return request({
        url: "/adminservice/zhkt/school/info",
        method:"get",
        params: query
    })
}
// 查询学校列表(客户标识过滤)
export const  schoolList =(query) =>{
    return request({
        url: "/adminservice/zhkt/school/list",
        method:"get",
        params: query
    })
}
// 查询学校列表(客户标识,学校管理员过滤)
export const  schoolListAuth =(data) =>{
    return request({
        url: "/adminservice/zhkt/school/school/listAuth",
        method:"post",
        data: data
    })
}
// 查询用户权限筛选条件
export const  schoolUserAuthCondition =(data) =>{
    return request({
        url: "/adminservice/zhkt/school/userAuthCondition",
        method:"post",
        data: data
    })
}
// 创建学校
export const  schoolCreate =(data) =>{
    return request({
        url: "/adminservice/zhkt/school/create",
        method:"post",
        data: data
    })
}


// 更新学校信息
export const  schoolUpdate =(data) =>{
    return request({
        url: "/adminservice/zhkt/school/update",
        method:"post",
        data: data
    })
}

// 查询字典基础数据
export const  zhktQueryDict =(query) =>{
    return request({
        url: "/adminservice/zhkt/dict/queryDict",
        method:"get",
        params: query
    })
}

// 上传新闻资讯文件
export const  customOrgUpload =(data) =>{
    return request({
        url: "/adminservice/customOrg/uploadFile",
        method:"post",
        ContentType:'multipart/form-data',
        data: data
    })
}

// Excel批量导入学校教师
export const  teacherBatchImport =(data) =>{
    return request({
        url: "/adminservice/zhkt/teacher/batchImport",
        method:"post",
        ContentType:'multipart/form-data',
        data: data
    })
}

// 添加教师
export const teacherCreate =(data) =>{
    return request({
        url: "/adminservice/zhkt/teacher/create",
        method:"post",
        data: data
    })
}

// 添加教师
export const teacherUpdate =(data) =>{
    return request({
        url: "/adminservice/zhkt/teacher/update",
        method:"post",
        data: data
    })
}

// 删除教师
export const  teacherDelete =(query) =>{
    return request({
        url: "/adminservice/zhkt/teacher/delete",
        method:"get",
        params: query
    })
}

// 查询或注册用户
export const teacherQueryOrRegUser =(data) =>{
    return request({
        url: "/adminservice/zhkt/teacher/user/queryOrRegUser",
        method:"post",
        data: data
    })
}

// 查询老师列表
export const  teacherListCondition =(data) =>{
    return request({
        url: "/adminservice/zhkt/teacher/teacher/listCondition",
        method:"post",
        data: data
    })
}

// 分页查询教师列表
export const  teacherList =(data) =>{
    return request({
        url: "/adminservice/zhkt/teacher/list",
        method:"post",
        data: data
    })
}

// 批量创建班级
export const classBatch =(data) =>{
    return request({
        url: "/adminservice/zhkt/class/batch",
        method:"post",
        data: data
    })
}

// 删除班级
export const  classDelete =(query) =>{
    return request({
        url: "/adminservice/zhkt/class/delete",
        method:"get",
        params: query
    })
}

// 查询班级信息
export const  classInfo =(query) =>{
    return request({
        url: "/adminservice/zhkt/class/info",
        method:"get",
        params: query
    })
}

// 查询班级列表
export const classList =(data) =>{
    return request({
        url: "/adminservice/zhkt/class/list",
        method:"post",
        data: data
    })
}

// 修改班级信息
export const classUpdate =(data) =>{
    return request({
        url: "/adminservice/zhkt/class/update",
        method:"post",
        data: data
    })
}

// 配置学生座位
export const studentConfigSeat =(data) =>{
    return request({
        url: "/adminservice/zhkt/student/configSeat",
        method:"post",
        data: data
    })
}

// 配置学生标签
export const studentConfigTag =(data) =>{
    return request({
        url: "/adminservice/zhkt/student/configTag",
        method:"post",
        data: data
    })
}

// 查询学生列表
export const studentList =(data) =>{
    return request({
        url: "/adminservice/zhkt/student/list",
        method:"post",
        data: data
    })
}

// 删除学生
export const  studentDelete =(query) =>{
    return request({
        url: "/adminservice/zhkt/student/student/delete",
        method:"get",
        params: query
    })
}

// 下载学生标签点阵文件
export const  studentDownloadTag =(query) =>{
    return request({
        url: "/adminservice/zhkt/student/student/downloadTag",
        method:"get",
        params: query
    })
}

// 更新学生信息
export const studentUpdate =(data) =>{
    return request({
        url: "/adminservice/zhkt/student/student/update",
        method:"post",
        data: data
    })
}

// 添加或更新班级教师
export const classTeacherCreateOrUpdate =(data) =>{
    return request({
        url: "/adminservice/zhkt/classTeacher/classTeacher/createOrUpdate",
        method:"post",
        data: data
    })
}
// 配置班级组数排数
export const classGroupRow =(data) =>{
    return request({
        url: "/adminservice/zhkt/class/groupRow",
        method:"post",
        data: data
    })
}
// 删除班级教师
export const  classTeacherDelete =(query) =>{
    return request({
        url: "/adminservice/zhkt/classTeacher/classTeacher/delete",
        method:"get",
        params: query
    })
}

// 上传班级座位图
export const classUploadClassSeat =(data) =>{
    return request({
        url: "/adminservice/zhkt/class/uploadClassSeat",
        method:"post",
        ContentType:'multipart/form-data',
        data: data
    })
}
// 下载班级点阵标签
export const  classDownloadTag =(query) =>{
    return request({
        url: "/adminservice/zhkt/class/downloadTag",
        method:"get",
        params: query
    })
}
// 生成学生点阵标签
export const  studentCreateTag =(query) =>{
    return request({
        url: "/adminservice/zhkt/student/createTag",
        method:"get",
        params: query
    })
}
// 查询班级已配置学生标签号
export const  classDotNos =(query) =>{
    return request({
        url: "/adminservice/zhkt/class/class/dotNos",
        method:"get",
        params: query
    })
}
// 置空学生标签
export const  studentClearTag =(query) =>{
    return request({
        url: "/adminservice/zhkt/student/clearTag",
        method:"get",
        params: query
    })
}
// 单个配置学生标签
export const  studentSaveTag =(data) =>{
    return request({
        url: "/adminservice/zhkt/student/saveTag",
        method:"post",
        data: data
    })
}
// 清空班级学生标签
export const  studentCreateAllTag =(query) =>{
    return request({
        url: "/adminservice/zhkt/student/createAllTag",
        method:"get",
        params: query
    })
}
// 手动创建班级
export const  classCreate =(data) =>{
    return request({
        url: "/adminservice/zhkt/class/class/create",
        method:"post",
        data: data
    })
}

// 创建奖评
export const  awardCreate =(data) =>{
    return request({
        url: "/adminservice/zhkt/award/award/create",
        method:"post",
        data: data
    })
}
// 删除奖评
export const  awardDelete =(query) =>{
    return request({
        url: "/adminservice/zhkt/award/award/delete",
        method:"get",
        params: query
    })
}
// 查询奖评
export const  awardList =(data) =>{
    return request({
        url: "/adminservice/zhkt/award/award/list",
        method:"post",
        data: data
    })
}
// 更新奖评
export const  awardUpdate =(data) =>{
    return request({
        url: "/adminservice/zhkt/award/award/update",
        method:"post",
        data: data
    })
}
// 查询奖评详情
export const  awardInfo =(query) =>{
    return request({
        url: "/adminservice/zhkt/award/info",
        method:"get",
        params: query
    })
}
// 生成班级学生批阅标签纸
export const  classGenerateStuSticker =(query) =>{
    return request({
        url: "/adminservice/zhkt/class/generateStuSticker",
        method:"get",
        params: query
    })
}
// 下载班级学生批阅标签纸
export const  classDownStuSticker =(query) =>{
    return request({
        url: "/adminservice/zhkt/class/downStuSticker",
        method:"get",
        params: query
    })
}
// 删除课堂记录
export const  recordDelete =(query) =>{
    return request({
        url: "/adminservice/zhkt/record/delete",
        method:"get",
        params: query
    })
}
// 删除板书记录
export const  recordDeleteRecordBs =(query) =>{
    return request({
        url: "/adminservice/zhkt/record/deleteRecordBs",
        method:"get",
        params: query
    })
}
// 查询课堂记录详情
export const  recordDetails =(query) =>{
    return request({
        url: "/adminservice/zhkt/record/details",
        method:"get",
        params: query
    })
}
// 分页查询课堂记录
export const  recordPage =(data) =>{
    return request({
        url: "/adminservice/zhkt/record/page",
        method:"post",
        data: data
    })
}