<template>
  <div class="base-container">
    <el-card>
      <el-form class="" ref="queryForm" :inline="true" label-width="68px">
        <el-row class="mb0">
          <el-col :span="16">
            <el-form-item label="组:" prop="filterText">
              <el-tree-select v-model="zuForm.zuId" :data="zuList" :props="zuProp" check-strictly
                @node-click="handleNodeClick" />
            </el-form-item>
          </el-col>
          <el-col :span="8" class="mr0">
            <el-form-item class="pull-right">
              <el-button type="primary" icon="el-icon-search" size="mini" @click="listQun">查询</el-button>
              <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <el-row style="height: calc(100vh - 195px);" class="admin-group-content mt20" :gutter="20">
      <el-col :span="7" class="group-title-content content">
        <el-card>
          <template #header>
            <div class="tit-txt">群列表</div>
          </template>
          <div class="tabs-info">
            <div v-for="item in qunList"
              :class="item.id.includes(this.qunId) && this.qunId !== '' ? 'tab-item active-tab' : 'tab-item'"
              @click="selectQun(item)">
              {{ item.qunName }}
            </div>
            <!--空状态-->
            <div v-if="zuForm.zuId == ''">
              <el-empty :image-size="60" description="请选择对应的组查看笔迹" />
            </div>
            <!--空群状态-->
            <div v-if="zuForm.zuId != '' && qunList.length == 0">
              <el-empty :image-size="60" description="当前组下暂无群信息" />
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="17" class="group-info-content content">
        <el-card>
          <template #header>
            <div class="tit-txt">群成员智能本</div>
          </template>
          <div class="group-commom">
            <div class="tab-content">
              <div class="record-container">
                <div class="record-list" v-for="item in recordInfoList">
                  <div class="record-one-container" @click="openMemberBook(item)">
                    <div class="book-cover-img">
                      <img class="cover-img"
                        :src="global.fileUrl + '/book/' + item.resourceId + '/img/cover.png_thumbnail'">
                    </div>
                    <!--         {{item.recordName}}         -->
                    <div class="record-name-zhanw"></div>
                    <div class="record-name">
                      {{ item.memberName }}
                    </div>
                  </div>
                </div>

                <div v-if="recordInfoList.length == 0">
                  <el-empty :image-size="60" description="暂无成员智能本信息" />
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <!--笔迹查看-->
    <div class="stroke-cover" v-show="strokeCover">
      <!--笔迹展示-->
      <div class="record-book-bg">
        <div class="book-left-right">
          <div class="book-page-box-left" id="strokeLeft" :style="{ background: 'url(' + bookPageLeftImg + ')' }">
          </div>
        </div>
        <div class="book-left-right">
          <div class="book-page-box-right" id="strokeRight" :style="{ background: 'url(' + bookPageRightImg + ')' }">
          </div>
        </div>
        <!--上下页按钮-每次翻两页-->
        <div class="change-page">
          <div class="change-page-box">
            <div class="last-page"><img class="page-img" title="上一页" src="../../../assets/img/write/last-page.png"
                @click="lastPage"></div>
            <div class="goto-page"></div>
            <div class="next-page"><img class="page-img" title="下一页" src="../../../assets/img/write/next-page.png"
                @click="nextPage"></div>
          </div>
        </div>
        <!--退出笔迹查看-->
        <div class="exit-stroke" @click="closeBook">
          <img src="../../../assets/img/write/close.png" title="退出笔迹查看" />
        </div>
      </div>
    </div>


  </div>

</template>

<script>
import { zuList } from "@/api/admin/zu"
import { qunList } from "@/api/admin/qun";
import { qunRecordAndMember } from "@/api/admin/record"
import { bookInfo } from "@/api/admin/book"
import { memberStroke } from "@/api/admin/stroke"
import global from "@/utils/global"
import ZbhIcon from "@/components/zbhIcon";

export default {
  name: "writeInfo",
  data() {
    return {
      zuList: [],
      qunList: [],
      recordInfoList: [],
      book: {},
      userRecord: {},
      global: global,
      zuForm: {
        zuId: ""
      },
      qunId: "",
      zuProp: {
        value: 'id',
        label: 'zuName',
        children: 'children',
      },
      strokeCover: false,
      bookPageLeftImg: "",
      bookPageRightImg: "",
      strokeForm: {
        userRecordId: "",
        pageNum: 0
      },
      strokeList: []
    }
  },
  components: {
    ZbhIcon
  },
  mounted() {
    window.addEventListener("changeSaasGroup", (e) => {
      this.account = JSON.parse(this.$storage.get("lastGroupAccount"))
      this.listZu()
    })
  },
  created() {
    this.account = JSON.parse(this.$storage.get("lastGroupAccount"))
    this.listZu()
  },
  methods: {
    /*查询组*/
    listZu() {
      // debugger
      zuList(this.account.id).then(res => {
        if (res.data.code === 1) {
          this.zuList = res.data.data
          this.zuForm.zuId = ""
          this.qunList = []
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    /*切换组*/
    handleNodeClick(data) {
      this.zuForm.zuId = data.id
      this.listQun();
    },
    /*查询组下群信息*/
    listQun() {
      qunList(this.zuForm.zuId).then(res => {
        if (res.data.code === 1) {
          this.qunList = res.data.data
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    /*选择群*/
    selectQun(data) {
      this.qunId = data.id
      this.listQunRecordInfo()
    },
    /*查询群书写记录、成员书写记录及对应成员信息*/
    listQunRecordInfo() {
      // debugger
      qunRecordAndMember(this.qunId).then(res => {
        if (res.data.code === 1) {
          this.recordInfoList = res.data.data
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    /*打开成员智能本-默认打开最后书写页*/
    openMemberBook(record) {
      let lastWritePage = record.lastWritePage
      let resourceId = record.resourceId
      if (lastWritePage == null) {
        this.$message.info("当前成员智能本暂无笔迹")
        return;
      }
      this.strokeForm.userRecordId = record.memberRecordId
      //查询智能本信息
      bookInfo(resourceId).then(res => {
        if (res.data.code === 1) {
          this.book = res.data.data;
          this.userRecord = record;
          this.strokeForm.pageNum = lastWritePage + this.book.headPage
          /*内页获取*/
          this.bookInsetImg();
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    /*本子内页信息*/
    bookInsetImg() {
      if (this.strokeForm.pageNum === 0) {
        this.strokeForm.pageNum = this.userRecord.lastWritePage
      }
      let resourceId = this.userRecord.resourceId
      /*扉页*/
      let headPage = this.book.headPage
      let tailPage = this.book.tailPage
      let pageCount = this.book.pageCount
      this.bookPageLeftImg = global.fileUrl + "/book/" + resourceId + "/img/" + (this.strokeForm.pageNum) + ".png_original"
      this.bookPageRightImg = global.fileUrl + "/book/" + resourceId + "/img/" + (this.strokeForm.pageNum + 1) + ".png_original"
      this.queryMemberStroke()
      this.strokeCover = true;
    },
    queryMemberStroke() {
      memberStroke(this.strokeForm).then(res => {
        if (res.data.code === 1) {
          this.strokeList = res.data.data
          this.drawStroke()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    /*画笔迹*/
    drawStroke() {
      document.getElementById("strokeLeft").innerHTML = ''
      document.getElementById("strokeRight").innerHTML = ''
      /*笔迹*/
      let strokeListLeft = this.strokeList.strokeLeft;
      let strokeListRight = this.strokeList.strokeRight;
      /*承载笔迹div*/
      var divLeft = document.getElementById("strokeLeft")
      var divRight = document.getElementById("strokeRight")
      /*本子宽高*/
      let width = this.book.pageWidth
      let height = this.book.pageHeight
      let pageWidth = width * 80 / 3
      let pageHeight = height * 80 / 3
      /*SVG代码拼接*/
      /*左页笔迹*/
      let leftSvgData = ''
      leftSvgData = leftSvgData + "<svg width=\"" + divLeft.clientWidth + "\" height=\"" + divLeft.clientHeight +
        "\"   viewBox=\"0 0 " + pageWidth + " " + pageHeight + "\" style=\"margin-top: -2%\" xmlns=\"http://www.w3.org/2000/svg\"><g id=\"strokeGroup\" fill=\"none\">";
      for (var i in strokeListLeft) {
        var p = strokeListLeft[i].p
        leftSvgData = leftSvgData + "<path fill=\"none\" stroke-linecap=\"round\" stroke=\"" + strokeListLeft[i].c + "\" stroke-width=\"" + strokeListLeft[i].t + "\"  d=\"" + p + "\"></path>";
      }
      leftSvgData = leftSvgData + "</g></svg>";
      divLeft.innerHTML = leftSvgData
      /*右页笔记*/
      let rightSvgData = ''
      rightSvgData = rightSvgData + "<svg width=\"" + divLeft.clientWidth + "\" height=\"" + divLeft.clientHeight +
        "\"   viewBox=\"0 0 " + pageWidth + " " + pageHeight + "\" style=\"margin-top: -2%\" xmlns=\"http://www.w3.org/2000/svg\"><g id=\"strokeGroup\" fill=\"none\">";
      for (var i in strokeListRight) {
        var p = strokeListRight[i].p
        rightSvgData = rightSvgData + "<path fill=\"none\" stroke-linecap=\"round\" stroke=\"" + strokeListRight[i].c + "\" stroke-width=\"" + strokeListRight[i].t + "\"  d=\"" + p + "\"></path>";
      }
      rightSvgData = rightSvgData + "</g></svg>";
      divRight.innerHTML = rightSvgData
    },
    /*关闭智能本*/
    closeBook() {
      this.strokeForm.pageNum = 0
      this.strokeForm.userRecordId = ""
      this.strokeCover = false;
    },
    /*翻页*/
    lastPage() {
      if (this.strokeForm.pageNum === 1) {
        this.$message.info("当前页为首页")
        return
      }
      if (this.strokeForm.pageNum - 2 > 0) {
        this.strokeForm.pageNum = this.strokeForm.pageNum - 2
      } else {
        this.strokeForm.pageNum = 1
      }
      this.bookInsetImg()
    },
    nextPage() {
      this.strokeForm.pageNum = this.strokeForm.pageNum + 2
      this.bookInsetImg()
    },
    /** 搜索按钮操作 */
    handleQuery() {

    },
    /** 重置按钮操作 */
    resetQuery() {
      this.zuForm.zuId = '';
      this.qunList = [];
      this.recordInfoList = [];
      this.listZu();
    },
  }
}
</script>

<style scoped>
@import "@/styles/write.scss";
</style>
