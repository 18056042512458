<template>
    <div>
        <div tabindex="-1" class="el-image-viewer__wrapper" style="z-index: 2201;">
            <div class="el-image-viewer__mask"></div>
            <!-- CLOSE --><span @click="closedd" class="el-image-viewer__btn el-image-viewer__close"><i class="el-icon"><svg
                        viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor"
                            d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z">
                        </path>
                    </svg></i></span>
            <!-- CANVAS -->
            <div class="el-image-viewer__canvas">
                <video controls :src="isBfSrc" alt="" style="z-index: 9999;" >
                </video>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'playVideo',
    props: {
        isBfSrc:{
            type:String,
            default:'',
        }
    },
    data() {
        return {

        }
    },
    created() {
    },
    mounted() {
    },
    methods: {
        closedd() {
            this.$emit('playVideoClose', true)
        },
    }
}
</script>

<style scoped>
.bgbbb {
    /* background-color: #fff; */
    z-index: 999;
    position: absolute;
}

.op0 {
    opacity: 0;
}

.op1 {
    opacity: 1;
}
</style>