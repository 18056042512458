<template>
  <div id="login-box"
    style="background: linear-gradient(to bottom, #1fa5fd, #2aa7fc, #38abfb, #3eadfa, #43acf9, #48abf8, #4eabf7);">
    <div style="position: fixed;display: flex;align-items: center;left: 5vw;top: 4vw;">
      <img style="height: 40px;" src="../../assets/zbhlogo.png" alt="">
      <img style="height: 30px;margin-left: 30px;" src="../../assets/writePenZnPt.png" alt="">
    </div>
    <el-container>
      <!--左侧-->
      <!-- <el-aside></el-aside> -->
      <el-container>
        <!--顶部-->
        <!-- <el-header>
          <el-row justify="end" type="flex">
            <el-col :span="4">
              <el-link :underline="false" href="https://www.zbform.com" target="_blank">
                <el-icon>
                  <connection />
                </el-icon>&nbsp;孜博汇官网
              </el-link>
            </el-col>
          </el-row>
        </el-header> -->
        <!--登录框-->
        <el-main>
          <div id="login-form">
            <el-form ref="loginFormRef" :model="loginForm" :rules="loginRules">
              <div style="font-size: 18px !important;text-align: left;font-weight: 400;" class="mb30">
                密码登录&nbsp; <span style="color: #aeaeae;" class="cursor" @click="toCodeLogin">| &nbsp;验证码登录</span>
              </div>
              <el-form-item prop="username">
                <el-input size="large" type="text" v-model="loginForm.username" placeholder="请输入登录账号">
                  <template #prepend>
                    <el-icon :size="19" style="background: #2fb383;">
                      <User />
                    </el-icon>
                  </template>
                </el-input>
              </el-form-item>
              <div class="mb0">
                <el-form-item prop="password">
                  <el-input size="large" type="password" show-password v-model="loginForm.password" placeholder="请输入密码">
                    <template #prepend>
                      <el-icon :size="19">
                        <Lock />
                      </el-icon>
                    </template>
                  </el-input>
                </el-form-item>
              </div>
              <el-form-item class="mt5">
                <el-col class="tab-one" :span="24" style="text-align: right;"><a @click="toResetPwd">重置密码</a></el-col>
              </el-form-item>
              <el-form-item>
                <el-button style="border-radius: 20px;height:35px;font-size: 20px;box-shadow: 1px 2px 3px #9ba4ac;"
                  class="loginBtn" type="primary" :loading="loginBtnLoading" @click="subUserLogin">登录</el-button>
              </el-form-item>
            </el-form>
            <div class="other-tab">
              <el-row>
                <!-- <el-col class="tab-one" :span="8" style="text-align: left;"><a @click="toCodeLogin">验证码登录</a></el-col> -->
                <el-col class="tab-one" :span="24" style="text-align: right;"><a @click="toRes">没有账号？<span
                      style="color: #54d8a5;">立即注册</span></a></el-col>
              </el-row>
            </div>
          </div>
        </el-main>
        <!--底部-->
        <!-- <el-footer>
          <el-row justify="center" type="flex">
            <el-col :span="16">
              版权所有©南京孜博汇信息科技有限公司 | <a style="text-decoration:none" target="_blank"
                href="https://beian.miit.gov.cn">苏ICP备18041825号-1</a>
            </el-col>
          </el-row>
        </el-footer> -->
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { userLogin, isAuthGroup } from "@/api/user/login";
let validateUsername = (rule, value, callback) => {
  isAuthGroup(value).then(res => {
    const result = res.data.data
    if (!result) {
      callback(new Error("该账号暂无权限登录"))
    } else {
      callback()
    }
  })
}
export default ({
  name: "userLogin",
  data() {
    return {
      loginBtnLoading: false,
      /*登录数据绑定对象*/
      loginForm: {
        username: "",
        password: ""
      },
      /*登录校验规则*/
      loginRules: {
        username: [
          { required: true, message: "请输入登录账号", trigger: "blur" },
          { validator: validateUsername, trigger: "blur" }
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      }
    }
  },
  created() {
    if (this.$route.query.username) {
      this.loginForm.username = this.$route.query.username
    }
    this.$store.dispatch('tagsView/delAllViews')
    this.$store.dispatch('tagsView/addView', {
      path: '/indexs',
      name: '首页',
      fullPath: '/indexs',
      title: '首页',
      meta: { title: '首页' },
    })
    let that = this
    document.onkeydown = function (e) {
      e = window.event || e;
      if (e.code === "Enter" || e.code === "enter") {
        that.subUserLogin();
      }
    }
  },
  methods: {
    subUserLogin() {
      this.$storage.set("isPlatform", '0');
      this.$refs.loginFormRef.validate(valid => {
        if (!valid) return
        // let pwd = this.$encrypt(this.loginForm.password)
        let loginInfo = { username: this.loginForm.username, password: this.loginForm.password }
        this.loginBtnLoading = true
        userLogin(loginInfo).then(res => {
          if (res.data.code === 1) {
            const info = res.data.data
            // debugger
            if (info.customList && info.customList.length != 0) {
              this.$storage.set("custom", JSON.stringify(info.customList[0]));
              this.$storage.set("customList", JSON.stringify(info.customList));
            } else {
              this.$storage.set("custom", '');
              this.$storage.set("customList", '[]');
            }
            this.$storage.set("userInfo", JSON.stringify(info.userInfo));
            this.$storage.set("token", info.token);
            if (info.role.includes(0)) {
              this.$storage.set("role", '0');
            } else if (info.role.includes(7)) {
              this.$storage.set("role", '0');
              this.$storage.set("isPlatform", '1');
              // 7 是平台管理员
            } else {
              this.$storage.set("role", info.role);
            }

            if (info.role.includes(0)) {
              this.$storage.set("customId", '');
              this.loginBtnLoading = false
              this.$router.push("/home")
            } else if (info.role.includes(1)) {
              if (info.customList) {
                if (info.customList.length != 0) {
                  if (info.customList) {
                    this.$storage.set("customId", info.customList[0].customId);
                    if (info.customList[0].moduleList) {
                      info.customList[0].moduleList.forEach(e => {
                        if (e.moduleNo == '010') {
                          this.$storage.set("010StTime", e.authTime);
                          this.$storage.set("010EdTime", e.endTime);
                        } else if (e.moduleNo == '020') {
                          this.$storage.set("020StTime", e.authTime);
                          this.$storage.set("020EdTime", e.endTime);
                        } else if (e.moduleNo == '030') {
                          this.$storage.set("030StTime", e.authTime);
                          this.$storage.set("030EdTime", e.endTime);
                        } else if (e.moduleNo == '040') {
                          this.$storage.set("040StTime", e.authTime);
                          this.$storage.set("040EdTime", e.endTime);
                        } else if (e.moduleNo == '050') {
                          this.$storage.set("050StTime", e.authTime);
                          this.$storage.set("050EdTime", e.endTime);
                        } else if (e.moduleNo == '060') {
                          this.$storage.set("060StTime", e.authTime);
                          this.$storage.set("060EdTime", e.endTime);
                        }
                      })
                    }
                  } else {
                    this.$storage.set("customId", '');
                  }
                  this.loginBtnLoading = false
                  this.$router.push("/home")
                } else {
                  this.loginBtnLoading = false
                  this.$message.error("当前客户已停用")
                }
              }
            } else {
              if (info.customList && info.customList.length != 0) {
                this.$storage.set("customId", info.customList[0].customId);
              } else {
                this.$storage.set("customId", '');
              }
              this.loginBtnLoading = false
              this.$router.push("/home")
            }

          } else {
            this.loginBtnLoading = false
            this.$message.error(res.data.msg)
          }
        })
      })
      // this.$storage.set("token",'fdsfdsdfdsds');
      // this.$router.push("/home")
    },
    toRes() {
      window.location.href = "https://zhuce.zbform.com"
    },
    toCodeLogin() {
      console.log("验证码登录")
      this.$router.push({path:"/codeLogin", query: {
        username: this.loginForm.username,
      }})
    },
    toResetPwd() {
      this.$router.push("/resetPwd")
    },
  }
})
</script >
<!--样式-->
<style lang="scss" scoped>
@import url(../../styles/login.scss);

/*登录框*/
#login-box {
  width: 100%;
  height: 100%;

  .el-container {
    height: 100%;
    background: url(../../assets/login-lf-background.png) no-repeat fixed left;
    background-size: auto 100%;
  }

  .el-header {
    padding: 0 0;
    font-size: 18px;
    text-align: center;
    height: 80px !important;

    .el-link {
      color: #4c596e;
      text-decoration: none;
    }

    .el-link:hover {
      color: #2489f2;
      text-decoration: none;
    }
  }

  .el-footer {
    color: #4c596e;
    text-align: center;
    height: 40px !important;
    font-size: 14px;
  }

  .el-aside {
    width: 34%;
    background-image: url("../../assets/login_l_bg.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .el-main {
    padding: 0 0;
    position: relative;
  }

  .el-row {
    height: 100%;
    align-items: center;
  }

  .el-col {
    child-align: middle;
  }
}

.tab-one {
  cursor: pointer;
  font-size: 14px;
  color: #7f8997;
}

.tab-one>a:hover {
  color: #428bfa;
}
</style>

