<template>
    <div class="app-container pt0Im pl0Im">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>课堂记录</a></el-breadcrumb-item>
                <el-breadcrumb-item>记录详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div style="height: calc(100vh - 150px);overflow: auto;">
            <el-card>
                <el-descriptions class="detailFF" title="基本信息">
                    <el-descriptions-item width="33.33%" label="课程名称">{{ form.lessonName }}</el-descriptions-item>
                    <el-descriptions-item width="33.33%" label="授课教师">{{ form.teacherName }}</el-descriptions-item>
                    <el-descriptions-item width="33.33%" label="学科" class="detailFF" style="display:flex">
                        <el-select disabled v-model="form.subject" placeholder="请选择学科">
                            <el-option v-for="item in subjectList" :key="item.dictKey" :label="item.dictValue"
                                :value="item.dictKey" />
                        </el-select>
                    </el-descriptions-item>
                    <el-descriptions-item width="33.33%" label="授课班级" v-if="form.className">{{ form.className
                    }}</el-descriptions-item>
                    <el-descriptions-item width="33.33%" label="授课日期">{{ timestampToTime(form.createTime)
                    }}</el-descriptions-item>
                    <el-descriptions-item width="33.33%" label="归属学校" v-if="form.schoolName">{{ form.schoolName
                    }}</el-descriptions-item>
                </el-descriptions>
            </el-card>
            <el-row class="">
                <el-form style="width: 100%;" class="detailFF" ref="form" :model="form" :rules="rules" label-width="100px">
                    <el-row class="lesscustom mb0 mt15">
                        <el-col :span="24">
                            <el-card class="">
                                <template #header>
                                    <div class="card-header">
                                        课件资料
                                    </div>
                                </template>
                                <el-col :span="24">
                                    <div class="bre9e w-100">
                                        <el-table header-cell-class-name="bgf2" :data="allFileList" class="">
                                            <el-table-column align="center" width="60" label="序号"
                                                type="index"></el-table-column>
                                            <el-table-column align="center" label="文件名称" prop="name">
                                                <template #default="scope">
                                                    <div :title="scope.row.name" class="text-one">
                                                        {{ scope.row.name }}
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column align="center" width="150" label="路径">
                                                <template #default="scope">
                                                    <div>
                                                        {{ scope.row.fileType == 1 ? '板书教案' : '互动学案' }}
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column align="center" width="150" label="性质">
                                                <template #default="scope">
                                                    <div v-if="scope.row.fileBelowType == 1">
                                                        点阵文件
                                                    </div>
                                                    <div v-if="scope.row.fileBelowType == 2">
                                                        普通文件
                                                    </div>
                                                    <div v-if="scope.row.fileBelowType == 3">
                                                        PPT
                                                    </div>
                                                    <div v-if="scope.row.fileBelowType == 4">
                                                        学生学案
                                                    </div>
                                                    <div v-if="scope.row.fileBelowType == 5">
                                                        小组学案
                                                    </div>
                                                    <div v-if="scope.row.fileBelowType == 6">
                                                        试卷
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column align="center" fixed="right" width="260" label="操作"
                                                class-name="small-padding fixed-width">
                                                <template #default="scope">
                                                    <el-button @click="xz(scope.row)" size="mini" type="primary">
                                                        下载文件
                                                    </el-button>
                                                    <el-button @click="xzdot(scope.row)" size="mini" type="primary">
                                                        下载点阵文件
                                                    </el-button>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                </el-col>
                            </el-card>
                        </el-col>
                    </el-row>
                    <el-row class="lesscustom mb0 mt15" v-if="form.awardList && form.awardList.length != 0">
                        <el-col :span="24">
                            <el-card class="">
                                <template #header>
                                    <div class="card-header">
                                        学生奖评
                                    </div>
                                </template>
                                <el-col :span="24">
                                    <div class="bre9e w-100">
                                        <el-table :span-method="objectSpanMethod" header-cell-class-name="bgf2"
                                            :data="form.awardList" class="">
                                            <el-table-column align="center" width="60" label="序号"
                                                prop="index"></el-table-column>
                                            <el-table-column align="center" label="学生姓名" prop="name">
                                                <template #default="scope">
                                                    <div :title="scope.row.studentName" class="text-one">
                                                        {{ scope.row.studentName }}
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column align="center" width="" label="讲评类别">
                                                <template #default="scope">
                                                    <div :title="scope.row.awardContent" class="text-one">
                                                        {{ scope.row.awardContent }}
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column align="center" width="200" label="星级数">
                                                <template #default="scope">
                                                    <div>
                                                        <el-rate :max="scope.row.starNum" disabled
                                                            v-model="scope.row.stuStarNum" />
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column align="center" width="200" label="奖评时间">
                                                <template #default="scope">
                                                    <div>
                                                        {{ timestampToTime(scope.row.createTime) }}
                                                    </div>
                                                </template>
                                            </el-table-column>

                                        </el-table>
                                    </div>
                                </el-col>
                            </el-card>
                        </el-col>
                    </el-row>
                    <el-row class="lesscustom mb0 mt15" v-if="form.bsStrokeList && form.bsStrokeList.length != 0">
                        <el-col :span="24">
                            <el-card class="">
                                <template #header>
                                    <div class="card-header">
                                        板书记录
                                    </div>
                                </template>
                                <div style="display: flex;flex-wrap:wrap;">
                                    <template v-for="(cs) in bsStrokeList">
                                        <div v-for="(item, index) in cs" class="mr10 mb5 csbbb">
                                            <Canvasbox @click="openIsPerview(item, index, cs)" noPbj v-if="isCanvas"
                                                class="cursor" :bookData="item.bookData" :imgList="[]"
                                                :imwidth="item.bookData.pageWidth / item.bookData.pageHeight * 200"
                                                :imheight="200" :canvasid="'tkjljlxq' + item.bookData.fileId + index"
                                                :picSrc="item.bookData.fileId == '8' ? item.bookData.imgFileUrl : (item.bookData.imgFileUrl + '' + item.bookData.page + '.png_original')"
                                                :detail="item.dotsList">
                                            </Canvasbox>
                                            <el-popconfirm
                                                v-if="(role.includes(5) && form.username == JSON.parse(this.$storage.get('userInfo')).username) || role.includes(0)"
                                                confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                                icon-color="#626AEF" title="确定删除该记录" @confirm="handleDelete(item)">
                                                <template #reference>
                                                    <div class="start cursor"
                                                        style="position: absolute;right: 0px;top:0px;padding: 5px;background: #f1f1f1;">
                                                        <el-icon>
                                                            <Delete />
                                                        </el-icon>
                                                    </div>
                                                </template>
                                            </el-popconfirm>
                                        </div>
                                    </template>

                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                    <el-row class="lesscustom mb0 mt15" v-if="stuStrokeList && stuStrokeList.length != 0">
                        <el-col :span="24">
                            <el-card class="">
                                <template #header>
                                    <div class="card-header">
                                        学生笔记
                                    </div>
                                </template>
                                <div style="display: flex;flex-wrap:wrap;">
                                    <div @click="changXsbj(item)" v-for="(item, index) in stuStrokeList"
                                        v-show="item.allWriteCount != 0" class="mr10 mb5 cursor"
                                        style="display: flex;align-items: center;flex-direction: column;">
                                        <Canvasbox noPbj v-if="isCanvas" class="" :bookData="item" :imgList="[]"
                                            :imwidth="item.pageWidth / item.pageHeight * 200" :imheight="200"
                                            :canvasid="'xsjl' + index" :picSrc="item.coverUrl" :detail="[]">
                                        </Canvasbox>
                                        <div :title="item.fileName" class="mb10 mt5 text-one"
                                            :style="'width:' + (item.pageWidth / item.pageHeight * 200) + 'px;'">
                                            {{ item.fileName }}
                                        </div>
                                    </div>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                    <el-row class="lesscustom mb0 mt15" v-if="form.voteList && form.voteList.length != 0">
                        <el-col :span="24">
                            <el-card class="">
                                <template #header>
                                    <div class="card-header">
                                        投票反馈
                                    </div>
                                </template>
                                <el-col :span="24">
                                    <div class="bre9e w-100">
                                        <el-table header-cell-class-name="bgf2" :data="form.voteList" class="">
                                            <el-table-column align="center" width="60" label="序号"
                                                type="index"></el-table-column>
                                            <el-table-column align="center" label="投票标题" prop="cardName">
                                                <template #default="scope">
                                                    <div :title="scope.row.card.cardName" class="text-one">
                                                        {{ scope.row.card.cardName }}
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column align="center" label="正确率" width="200" prop="rightRate">
                                                <template #default="scope">
                                                    <div class="text-one cursor">
                                                        {{ scope.row.rightRate }}%
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column align="center" label="正确人数" width="200" prop="rightCount">
                                                <template #default="scope">
                                                    <div class="text-one cursor">
                                                        {{ scope.row.rightCount }}
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column align="center" label="错误人数" width="200" prop="errorCount">
                                                <template #default="scope">
                                                    <div class="text-one cursor">
                                                        {{ scope.row.errorCount }}
                                                    </div>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                </el-col>
                            </el-card>
                        </el-col>
                    </el-row>
                    <el-row class="lesscustom mb0 mt15" v-if="form.examList && form.examList.length != 0">
                        <el-col :span="24">
                            <el-card class="">
                                <template #header>
                                    <div class="card-header">
                                        客观检测
                                    </div>
                                </template>
                                <el-col :span="24">
                                    <div class="bre9e w-100">
                                        <el-table header-cell-class-name="bgf2" :data="form.examList" class="">
                                            <el-table-column align="center" width="60" label="序号"
                                                type="index"></el-table-column>
                                            <el-table-column align="center" label="测验名称" prop="recordName">
                                                <template #default="scope">
                                                    <div :title="scope.row.recordName" class="text-one">
                                                        {{ scope.row.recordName }}
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column align="center" label="题目数" width="100" prop="questionCount">
                                                <template #default="scope">
                                                    <div @click="openTms(scope.row)" class="text-one cursor"
                                                        style="color: #409efe;">
                                                        {{ scope.row.questionCount }}
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column align="center" label="学生成绩" width="100" prop="questionList">
                                                <template #default="scope">
                                                    <div @click="openXscj(scope.row)" class="text-one cursor"
                                                        style="color: #409efe;">
                                                        查看详情
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column align="center" label="平均分" width="100" prop="averageScore">
                                                <template #default="scope">
                                                    <div class="text-one">
                                                        {{ scope.row.averageScore }}
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column align="center" label="五星人数" width="100"
                                                prop="starFiveStuCount">
                                                <template #default="scope">
                                                    <div class="text-one">
                                                        {{ scope.row.starFiveStuCount }}
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column align="center" label="四星人数" width="100"
                                                prop="starFourStuCount">
                                                <template #default="scope">
                                                    <div class="text-one">
                                                        {{ scope.row.starFourStuCount }}
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column align="center" label="三星人数" width="100"
                                                prop="starThreeStuCount">
                                                <template #default="scope">
                                                    <div class="text-one">
                                                        {{ scope.row.starThreeStuCount }}
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column align="center" label="二星人数" width="100" prop="starTwoStuCount">
                                                <template #default="scope">
                                                    <div class="text-one">
                                                        {{ scope.row.starTwoStuCount }}
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column align="center" label="一星人数" width="100" prop="starOneStuCount">
                                                <template #default="scope">
                                                    <div class="text-one">
                                                        {{ scope.row.starOneStuCount }}
                                                    </div>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                </el-col>
                            </el-card>
                        </el-col>
                    </el-row>
                </el-form>
            </el-row>
            <el-row class="mt15">
                <el-form style="width: 100%;" class="detailFF" ref="form" :model="form" :rules="rules" label-width="100px">
                    <el-row class="mt15" v-if="form.bookList && form.bookList.length != 0">
                        <el-col :span="24" class="custom">
                            <el-card class="mr5">
                                <template #header>
                                    <div class="card-header">
                                        互动本
                                    </div>
                                </template>
                                <el-form-item label-width="0" label="" prop="bookIdsList">
                                    <div v-for="item in form.bookList" style="margin-right:10px;">
                                        <img :title="item.bookName" style="width: 100px;margin-top: 10px;height: 140px;"
                                            :src="this.$store.state.zdy.imgUrl+'/book/' + item.id + '/img/cover.png_original'"
                                            alt="" class="image">
                                        <div :title="item.bookName" style="width: 100px;" class="text-one mb10">{{
                                            item.bookName }}</div>
                                    </div>
                                </el-form-item>
                            </el-card>
                        </el-col>
                    </el-row>
                    <el-row class="mt15" v-if="form.answerCardList && form.answerCardList.length != 0">
                        <el-col :span="24">
                            <el-card class="mr5">
                                <template #header>
                                    <div class="card-header">
                                        客观测验
                                    </div>
                                </template>
                                <el-form-item label-width="0" label="" prop="answerCardIds">
                                    <div @click="editAnswerCard(item)" class="mr15 csbbb cursor"
                                        v-for="(item, index) in form.answerCardList">
                                        <!-- <el-icon @click="editAnswerCard(item)" :size="20" class="editeicon"
                                    style="cursor: pointer;z-index: 99;">
                                    <Edit />
                                </el-icon>
                                <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                    icon-color="#626AEF" title="确定删除该答题卡" @confirm="delcardId(item.cardId, index)">
                                    <template #reference>
                                        <el-icon :size="20" class="eicon" style="cursor: pointer;z-index: 99;color: red;">
                                            <Delete />
                                        </el-icon>
                                    </template>
                                </el-popconfirm> -->
                                        <img style="width: 180px;height:130px;" src="../../assets/login-background.jpg"
                                            class="image">
                                        <div class="text-one">{{ item.cardName }}</div>
                                    </div>
                                    <!-- <div style="height:163px">
                                <el-icon @click="openIsaddAnswer"
                                    style="width: 180px;height:130px;border:1px solid #e3e3e3;"
                                    class="avatar-uploader-icon cursor">
                                    <Plus />
                                </el-icon>
                            </div> -->

                                </el-form-item>
                            </el-card>
                        </el-col>
                    </el-row>
                    <el-row class="mt15" v-if="form.backCardList && form.backCardList.length != 0">
                        <el-col :span="24">
                            <el-card class="mr5">
                                <template #header>
                                    <div class="card-header">
                                        投票反馈题
                                    </div>
                                </template>
                                <el-form-item label-width="0" label="" prop="backCardIds">
                                    <div @click="editCard(item)" class="mr15 csbbb cursor"
                                        v-for="(item, index) in form.backCardList">
                                        <!-- <el-icon @click="editCard(item)" :size="20" class="editeicon"
                                    style="cursor: pointer;z-index: 99;">
                                    <Edit />
                                </el-icon>
                                <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                    icon-color="#626AEF" title="确定删除该答题卡" @confirm="delbackCardId(item.cardId, index)">
                                    <template #reference>
                                        <el-icon :size="20" class="eicon" style="cursor: pointer;z-index: 99;color: red;">
                                            <Delete />
                                        </el-icon>
                                    </template>
                                </el-popconfirm> -->
                                        <img style="width: 180px;height:130px;" src="../../assets/login-background.jpg"
                                            class="image">
                                        <div class="text-one">{{ item.cardName }}</div>
                                    </div>
                                    <!-- <div style="height:163px">
                                <el-icon @click="openIsaddCard" style="width: 180px;height:130px;border:1px solid #e3e3e3;"
                                    class="avatar-uploader-icon cursor">
                                    <Plus />
                                </el-icon>
                            </div> -->

                                </el-form-item>
                            </el-card>
                        </el-col>
                    </el-row>
                </el-form>
            </el-row>
        </div>

        <!-- <div style="width: 390px;height:844px" v-html="form.content">

        </div> -->
        <el-dialog :title="answerCardForm.cardId ? '查看答题卡' : '新增答题卡'" v-model="addAnswer" width="60%">
            <el-form ref="answerCardForm" :model="answerCardForm" :rules="addAnswerrules" label-width="120px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="标题" prop="cardName">
                            <el-input disabled v-model="answerCardForm.cardName" placeholder="最多32个字" maxlength="32" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="考试用时" prop="examTime">
                            <el-input-number disabled step-strictly v-model="answerCardForm.examTime" :min="0"
                                :max="100000" />
                            <span class="ml15" style="color:red">精确到分钟</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="强制交卷时间" prop="submitTime">
                            <el-input-number disabled step-strictly v-model="answerCardForm.submitTime" :min="0"
                                :max="100000" />
                            <span class="ml15" style="color:red">精确到分钟</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="分数段设置" prop="sectionList">
                            <div v-for="item in answerCardForm.sectionList"
                                style="display: flex;align-items: center;width:500px;">
                                <el-rate v-model="item.starNum" disabled show-score text-color="#ff9900"
                                    score-template="{value} 星" />
                                <el-input-number disabled class="ml15" size="small" style="width: 90px;" step-strictly
                                    v-model="item.minScore" :min="0" :max="10000" />
                                <span class="ml5 mr5">~</span>
                                <el-input-number disabled size="small" style="width: 90px;" step-strictly
                                    v-model="item.maxScore" :min="0" :max="10000" />
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="题目数" prop="questionNum">
                            <el-input-number disabled @change="changeQuestionNum" step-strictly
                                v-model="answerCardForm.questionNum" :min="1" :max="100" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="测试总分" prop="totalScore">
                            <el-input-number disabled @change="changeIsNum" step-strictly
                                v-model="answerCardForm.totalScore" :min="0" :max="10000" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="平均分数" prop="isAverageBol">
                            <el-switch disabled @change="changeIsNum" v-model="answerCardForm.isAverageBol" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label-width="0" label="" prop="answerList">
                            <el-table ref="answerList" border :data="answerCardForm.answerList" style="width: 100%"
                                max-height="30vh">
                                <el-table-column align="center" prop="answerNo" label="序号" width="60" />
                                <el-table-column align="center" prop="itemName" label="选项内容">
                                    <template #default="scope">
                                        <div>
                                            <el-checkbox-group disabled v-model="scope.row.answerContentList" size="small">
                                                <el-checkbox label="A" size="small" border />
                                                <el-checkbox label="B" size="small" border />
                                                <el-checkbox label="C" size="small" border />
                                                <el-checkbox label="D" size="small" border />
                                                <el-checkbox label="E" size="small" border />
                                            </el-checkbox-group>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" prop="answerScore" label="分值" width="120">
                                    <template #default="scope">
                                        <el-input-number disabled @change="changeAnswer"
                                            :disabled="answerCardForm.isAverageBol" size="small" style="width: 90px;"
                                            v-model="scope.row.answerScore" :min="0" :max="100" />
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <!-- <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="addAnswerSumbit">确 定</el-button>
                    <el-button @click="addAnswer = false">取 消</el-button>
                </div>
            </template> -->
        </el-dialog>
        <el-dialog :title="'学生笔记'" v-if="isXsbj" v-model="isXsbj" width="800px">
            <div style="display: flex;flex-wrap:wrap;width: 100%;max-height: calc(70vh);overflow-y: auto;" class="sdfsa">
                <el-card class="cursor" style="margin-bottom: 10px;" v-for="(item, index) in showStuBookObj.stuStrokeList"
                    :key="index" @click="goNewDetail(item)" :body-style="{ padding: '0px' }">
                    <div style="padding:14px 14px 0 14px;margin:0 auto;width: 130px;position: relative;">
                        <Canvasbox noPbj :bookData="showStuBookObj" :imwidth="130"
                            :imheight="showStuBookObj.pageHeight / showStuBookObj.pageWidth * 130"
                            :canvasid="item.studentId + 'xsbjxq' + index"
                            :picSrc="showStuBookObj.imgFileUrl + '' + (item.linkList.length != 0 ? item.linkList[0].page : 1) + '.png_original'"
                            :detail="item.linkList.length != 0 ? item.linkList[0].dotsList : []">
                        </Canvasbox>
                    </div>
                    <div style="padding: 5px 14px 14px 14px">
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <div class="text-one fs-14" :title="item.studentName" style="">{{
                                item.studentName }}</div>
                            <div class="fs-14">{{ item.writeCount }}页</div>
                        </div>
                    </div>
                </el-card>
                <el-empty style="width:100%;" v-if="showStuBookObj.stuStrokeList.length == 0" description="暂无数据"></el-empty>
            </div>
        </el-dialog>
        <el-dialog :title="addCardForm.cardId ? '查看反馈卡' : '新增反馈卡'" v-model="addCard" width="60%">
            <el-form ref="addCardForm" :model="addCardForm" :rules="addCardrules" label-width="120px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="类型" prop="cardType">
                            <el-radio-group disabled @change="changeCardType" v-model="addCardForm.cardType">
                                <el-radio :label="1">单选</el-radio>
                                <el-radio :label="2">多选</el-radio>
                                <el-radio :label="3">判断</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="标题" prop="cardName">
                            <el-input disabled v-model="addCardForm.cardName" placeholder="最多64个字" maxlength="64" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="内容描述" prop="cardDescription">
                            <el-input disabled type="textarea" v-model="addCardForm.cardDescription" placeholder="最多1000个字"
                                maxlength="1000" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="图片内容" prop="imgList">
                            <div v-for="(item, index) in addCardForm.imgList" class="csbbb">
                                <!-- <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                    icon-color="#626AEF" title="确定删除该图片" @confirm="delImage(index)">
                                    <template #reference>
                                        <el-icon :size="20" class="eicon" style="cursor: pointer;z-index: 99;color: red;">
                                            <Delete />
                                        </el-icon>
                                    </template>
                                </el-popconfirm> -->
                                <el-image fit="cover" :initial-index="index" :preview-src-list="addCardForm.imgList"
                                    style="width: 180px;height:130px;margin-bottom: 10px;object-fit: cover;" :src="item"
                                    alt="" class="image mr15" />
                            </div>
                            <div class="csbbb" v-if="addCardForm.imgList.length <= 5 && false" style="height:140px;">

                                <el-upload v-model:file-list="filesList" class="avatar-uploadertxx"
                                    action="https://zbh.zbform.com/adminservice/zhkt/backCard/upload"
                                    :show-file-list="false" :on-change="handleSCChange" :before-upload="beforeAvatarUpload"
                                    :headers="{ token }">
                                    <el-icon style="width: 180px;height:130px;" class="avatar-uploader-icon">
                                        <Plus />
                                    </el-icon>
                                </el-upload>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>


                <el-row>
                    <el-col>
                        <el-form-item label-width="0" label="" prop="optionList">
                            <el-table ref="optionList" border :data="addCardForm.optionList" style="width: 100%"
                                max-height="300px">
                                <el-table-column align="center" prop="optionAnswer" label="投票选项" width="100" />
                                <el-table-column align="center" prop="optionContent" label="选项内容">
                                    <template #default="scope">
                                        <div>
                                            <el-input disabled v-model="scope.row.optionContent" placeholder="最多1000个字"
                                                maxlength="1000" />
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" prop="isAnswer" label="正确答案" width="120">
                                    <template #default="scope">
                                        <el-radio-group disabled v-model="scope.row.isAnswer" size="small">
                                            <el-radio-button :label="true">Y</el-radio-button>
                                            <el-radio-button :label="false">N</el-radio-button>
                                        </el-radio-group>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <!-- <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="addCardSumbit">确 定</el-button>
                    <el-button @click="addCard = false">取 消</el-button>
                </div>
            </template> -->
        </el-dialog>
        <!-- <div>
            <div class="disflex-row-center cusbtbtn" :style="$store.state.zdy.isActive ? 'left:64px;' : 'left:201px;'">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submitForm">保 存</el-button>
            </div>
        </div> -->
        <el-dialog title="题目数" v-model="tmsShowObj.isShow" width="600px">
            <div class="bre9e">
                <el-table header-cell-class-name="bgf2" highlight-current-row class="sssstt" :height="`calc(70vh)`"
                    :data="tmsShowObj.sjObj.questionList">
                    <el-table-column align="center" label="题目" prop="answerNo">
                        <template #default="scope">
                            第{{ scope.row.answerNo }}题
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="答案" prop="answerContent">
                        <template #default="scope">
                            {{ scope.row.answerContent }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="正确率" prop="rightRatezql">
                        <template #default="scope">
                            {{ ((tmsShowObj.sjObj.obj[scope.row.answerNo].zqs / tmsShowObj.sjObj.obj[scope.row.answerNo].count).toFixed(2)) *100  }}%
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="正确人数" prop="rightCountzqrs">
                        <template #default="scope">
                            {{ tmsShowObj.sjObj.obj[scope.row.answerNo].zqs }}
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>
        <el-dialog title="学生成绩" v-if="xscjShowObj.isShow" v-model="xscjShowObj.isShow" width="80%">
            <div class="bre9e">
                <el-table header-cell-class-name="bgf2" highlight-current-row class="sssstt" :height="`calc(70vh)`"
                    :data="xscjShowObj.sjObj.studentExamList">
                    <el-table-column fixed="left" align="center" width="60" label="排名" type="index"></el-table-column>
                    <el-table-column fixed="left" align="center" label="考试姓名" prop="studentName">
                        <template #default="scope">
                            {{ scope.row.studentName }}
                        </template>
                    </el-table-column>
                    <el-table-column sortable fixed="left" align="center" label="总分" prop="totalScore">
                        <template #default="scope">
                            {{ scope.row.totalScore }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" v-for="(item, index) in xscjShowObj.sjObj.questionList"
                        :label="'题目' + (index + 1)" prop="rightRate">
                        <template #default="scope">
                            <span style="color: red;">{{ scope.row.answerList[index].isRight ? '' : '×' }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>
        <PerviewCanvas noPbj :currentNum="activeIndex" :strokeList="bsActObj" :bookData="bsActObj[activeIndex - 1].bookData"
            @canvasClose="canvasClPerview" v-if="isPerview">
        </PerviewCanvas>
    </div>
</template>

<script>
import { zhktQueryDict, recordDetails, recordDeleteRecordBs } from "@/api/adminservice/classOrganizaMain"
import { lessonCreate, lessonUpdate, userTeachers, lessonDeleteFile, lessonInfo, answerCardCreate, answerCardUpdate, answerCardDelete, backCardDelete, backCardCreate, backCardUpdate } from "@/api/adminservice/lesson";
import { download } from "@/api/adminservice/public";
import { timestampToTime } from "@/utils/date";

export default {
    name: "demoAdd",
    components: {
    },
    computed: {
    },
    data() {
        return {
            tmsShowObj: {
                isShow: false,
                sjObj: {}
            },
            xscjShowObj: {
                isShow: false,
                sjObj: {}
            },
            isCanvas: false,
            isXsbj: false,
            showStuBookObj: {},
            timestampToTime,
            isNoSet: 1,
            addAnswer: false,
            addCard: false,
            addCardForm: {
                cardType: 1,
                answer: '',
                cardDescription: '',
                cardName: '',
                imgList: [],
                optionList: [
                    {
                        optionAnswer: 'A',
                        optionContent: '',
                        optionNo: 1,
                        isAnswer: false,
                    }, {
                        optionAnswer: 'B',
                        optionContent: '',
                        optionNo: 2,
                        isAnswer: false,
                    }, {
                        optionAnswer: 'C',
                        optionContent: '',
                        optionNo: 3,
                        isAnswer: false,
                    }, {
                        optionAnswer: 'D',
                        optionContent: '',
                        optionNo: 4,
                        isAnswer: false,
                    }, {
                        optionAnswer: 'E',
                        optionContent: '',
                        optionNo: 5,
                        isAnswer: false,
                    }
                ]
            },
            answerCardForm: {
                cardName: '',
                examTime: 0,
                submitTime: 0,
                questionNum: 1,
                totalScore: 0,
                isAverageBol: true,
                sectionList: [
                    { maxScore: 0, minScore: 0, starNum: 5 },
                    { maxScore: 0, minScore: 0, starNum: 4 },
                    { maxScore: 0, minScore: 0, starNum: 3 },
                    { maxScore: 0, minScore: 0, starNum: 2 },
                    { maxScore: 0, minScore: 0, starNum: 1 },
                ],
                answerList: [
                    {
                        answerNo: 1,
                        answerContentList: [],
                        answerContent: '',
                        answerScore: ''
                    }
                ],
            },
            addAnswerrules: {
                cardName: [
                    { required: true, message: "请输入标题", trigger: "blur" },
                ],
                examTime: [
                    { required: true, message: "请输入考试用时", trigger: "blur" },
                ],
                submitTime: [
                    { required: true, message: "请输入强制交卷时间", trigger: "blur" },
                ],
                questionNum: [
                    { required: true, message: "请输入题目数", trigger: "blur" },
                ],
                totalScore: [
                    { required: true, message: "请输入测试总数", trigger: "blur" },
                ],
            },
            addCardrules: {
                cardName: [
                    { required: true, message: "请输入反馈卡名称", trigger: "blur" },
                ],
                cardType: [
                    { required: true, message: "请新增反馈卡类型", trigger: "change" },
                ]
            },
            fileName: '',
            recordId: '',
            natureList: [],
            allFileList: [],
            dzFileList: [],
            ptFileList: [],
            PPTFileList: [],
            xsxaFileList: [],
            xzxaFileList: [],
            sjFileList: [],
            periodList: [],
            gradeList: [],
            subjectList: [],
            lessonLevelList: [],
            userList: [],
            teacherList: [],
            groupList: [],
            token: this.$storage.get("token"),
            delAnswerList: [],
            delCardList: [],
            // 遮罩层
            loading: true,
            // 非单个禁用
            single: true,
            activeIndex: 0,
            // 表单参数
            form: {
                answerCardIds: '',
                backCardIds: '',
                fileUrls: '',
                subject: '',
                teacherId: this.$storage.get("userId"),
                answerCardList: [],
                backCardList: [],

                coverUrl: '',
                groupId: '',
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                lessonLevelKey: '',
                title: '',
                leadUser: '',
                teamUsers: '',
                teamuseList: [],
                guideUsers: '',
                guideUsersList: [],
                type: 0,
                lessonNature: '1'
            },
            // 表单校验
            rules: {
                lessonName: [
                    { required: true, message: "请输入课程名称", trigger: "blur" },
                ],
                teacherId: [
                    { required: true, message: "请选择授课教师", trigger: "change" },
                ],
                subject: [
                    { required: true, message: "请选择学科", trigger: "change" },
                ],
            },
            delFileList: [],
            isDirectory: false,
            directoryList: [],
            tableData: [],
            bsStrokeList: {},
            stuStrokeList: [],
            isCourse: false,
            isPerview: false,
            role: '',
            bsActObj: {}
        };
    },
    created() {
        this.role = this.$storage.get("role")
        this.getZhktQueryDict()
        this.getUserTeachers()
    },
    mounted() {
        if (this.$route.query.isNoSet) {
            this.isNoSet = 0;
        }
        if (this.$route.query.recordId) {
            this.recordId = this.$route.query.recordId
            console.log(this.form)
            this.getlessonInfo()
        } else {
            var userId = JSON.parse(this.$storage.get("userInfo")).userId
            this.form.leadUser = userId
        }
    },
    unmounted() {

    },
    computed: {
    },
    methods: {
        canvasClPerview(e) {
            console.log(this.activeIndex);
            this.isPerview = false;
        },
        openIsPerview(row, index, cs) {
            console.log(cs);
            var img = ''
            cs.forEach(e => {
                if (e.bookData.fileId == '8') {
                    img = e.bookData.imgFileUrl
                } else {
                    img = e.bookData.imgFileUrl + '' + e.bookData.page + '.png_original'
                }
                e.bookData.img = img;
            })

            console.log(row);
            this.bsActObj = cs;
            this.activeIndex = index + 1;
            // console.log(this.currentNum)
            // console.log(this.dataList[this.activeIndex]);
            // console.log(this.exStrList)
            this.isPerview = true;
        },
        handleDelete(row) {
            console.log(row);
            recordDeleteRecordBs({ fileUrl: row.link }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getlessonInfo();
                } else {
                    this.$message.error("删除失败");
                }
            })
        },
        goNewDetail(row) {
            console.log(this.showStuBookObj, row)
            var book = {
                pageHeight: this.showStuBookObj.pageHeight,
                pageWidth: this.showStuBookObj.pageWidth,
                pageCount: this.showStuBookObj.pages,
                headPage: this.showStuBookObj.headPage,
                tailPage: this.showStuBookObj.tailPage,
                imgFileUrl: this.showStuBookObj.imgFileUrl,
                fileName: this.showStuBookObj.fileName,
            }
            let routeUrl = this.$router.resolve({
                path: '/readBook',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { bookData: JSON.stringify(book), studentId: row.studentId, fileId: this.showStuBookObj.fileId, classRecordId: this.recordId }
            })
            window.open(routeUrl.href, '_blank')
        },
        openTms(row) {
            console.log(row);
            this.tmsShowObj.isShow = true;
            this.tmsShowObj.sjObj = row;
        },
        openXscj(row) {
            console.log(row);
            this.xscjShowObj.isShow = true;
            this.xscjShowObj.sjObj = row;
            this.xscjShowObj.sjObj.studentExamList.sort(function (a, b) {
                if (a.totalScore > b.totalScore) { return -1; }
                if (a.totalScore < b.totalScore) { return 1; }
                return 0;
            })
        },
        xz(item) {
            console.log(item);
            download(item.url + '?_=' + Date.now(), item.name)
        },
        objectSpanMethod(e) {
            if (e.columnIndex === 0 || e.columnIndex === 1) {
                if (e.row.col != 0) {
                    return {
                        rowspan: e.row.col,
                        colspan: 1,
                    }
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0,
                    }
                }
            }
        },
        xzdot(item) {
            if (item.printStatus == 0) {
                this.$message.error('待铺设中，请等待')
                this.getlessonInfo()
            }
            if (item.printStatus == 1) {
                this.$message.error('正在铺设中，请等待')
                this.getlessonInfo()
            }
            if (!item.dotFileUrl) {
                this.$message.error('当前文件无点阵文件')
                return
            }
            if (item.printStatus == 2 && item.dotFileUrl) {
                download(item.dotFileUrl + '?_=' + Date.now(), item.name)
            }
            if (item.printStatus == 3) {
                this.$message.error('铺设失败')
            }
            if (item.printStatus == 4) {
                this.$message.error('无需铺设文件')
            }
            console.log(item);
        },
        changeAnswer(e) {
            console.log(e);
        },
        changeCardType() {
            console.log(this.addCardForm.cardType);
            if (this.addCardForm.cardType == 3) {
                this.addCardForm.optionList = [
                    {
                        optionAnswer: '是',
                        optionContent: '',
                        optionNo: 1,
                        isAnswer: false,
                    }, {
                        optionAnswer: '否',
                        optionContent: '',
                        optionNo: 2,
                        isAnswer: false,
                    }, {
                        optionAnswer: '未知',
                        optionContent: '',
                        optionNo: 3,
                        isAnswer: false,
                    }
                ]
            } else {
                this.addCardForm.optionList = [
                    {
                        optionAnswer: 'A',
                        optionContent: '',
                        optionNo: 1,
                        isAnswer: false,
                    }, {
                        optionAnswer: 'B',
                        optionContent: '',
                        optionNo: 2,
                        isAnswer: false,
                    }, {
                        optionAnswer: 'C',
                        optionContent: '',
                        optionNo: 3,
                        isAnswer: false,
                    }, {
                        optionAnswer: 'D',
                        optionContent: '',
                        optionNo: 4,
                        isAnswer: false,
                    }, {
                        optionAnswer: 'E',
                        optionContent: '',
                        optionNo: 5,
                        isAnswer: false,
                    }
                ]
            }
        },
        handleSCChange(e, type) {
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    this.$message.success('上传成功')
                    this.addCardForm.imgList.push(e.response.data)
                } else {
                    if (e.response.msg) {
                        this.$message.error(e.response.msg)
                    } else {
                        this.$message.error('上传失败')
                    }
                }
            }
            if (e.status == 'fail') {
                this.$message.error('上传失败')
            }
        },
        editAnswerCard(item) {
            console.log(item);
            this.answerCardForm = item;
            if (this.answerCardForm.isAverage) {
                this.answerCardForm.isAverageBol = true;
            } else {
                this.answerCardForm.isAverageBol = false;
            }
            this.answerCardForm.answerList.forEach(e => {
                e.answerContentList = e.answerContent.split(',')
            })
            this.addAnswer = true;
        },
        changXsbj(item) {
            console.log(item);
            this.showStuBookObj = item;
            this.showStuBookObj.stuStrokeList.sort(function (a, b) {
                if (a.writeCount > b.writeCount) { return -1; }
                if (a.writeCount < b.writeCount) { return 1; }
                return 0;
            })
            this.isXsbj = true;
        },
        editCard(item) {
            console.log(item);
            this.addCardForm = item;
            var answerList = item.answer.split(',')
            this.addCardForm.optionList.forEach(e => {
                e.isAnswer = false;
                answerList.forEach(j => {
                    if (e.optionAnswer == j) {
                        e.isAnswer = true;
                    }
                })
            })
            this.addCard = true;

        },
        delcardId(cardId, index) {
            this.delAnswerList.push(cardId);
            this.form.answerCardList.splice(index, 1);
        },
        delbackCardId(cardId, index) {
            this.delCardList.push(cardId);
            this.form.backCardList.splice(index, 1);
        },
        delImage(index) {
            this.addCardForm.imgList.splice(index, 1);
        },
        openIsaddAnswer() {
            this.answerCardForm = {
                cardName: '',
                examTime: 0,
                submitTime: 0,
                questionNum: 0,
                totalScore: 0,
                isAverageBol: true,
                sectionList: [
                    { maxScore: 0, minScore: 0, starNum: 5 },
                    { maxScore: 0, minScore: 0, starNum: 4 },
                    { maxScore: 0, minScore: 0, starNum: 3 },
                    { maxScore: 0, minScore: 0, starNum: 2 },
                    { maxScore: 0, minScore: 0, starNum: 1 },
                ],
                answerList: [{
                    answerNo: 1,
                    answerContentList: [],
                    answerContent: '',
                    answerScore: ''
                }],
            };
            this.addAnswer = true;
        },
        openIsaddCard() {
            this.addCardForm = {
                cardType: 1,
                answer: '',
                cardDescription: '',
                cardName: '',
                imgList: [],
                optionList: [
                    {
                        optionAnswer: 'A',
                        optionContent: '',
                        optionNo: 1,
                        isAnswer: false,
                    }, {
                        optionAnswer: 'B',
                        optionContent: '',
                        optionNo: 2,
                        isAnswer: false,
                    }, {
                        optionAnswer: 'C',
                        optionContent: '',
                        optionNo: 3,
                        isAnswer: false,
                    }, {
                        optionAnswer: 'D',
                        optionContent: '',
                        optionNo: 4,
                        isAnswer: false,
                    }, {
                        optionAnswer: 'E',
                        optionContent: '',
                        optionNo: 5,
                        isAnswer: false,
                    }
                ]
            }
            this.addCard = true;
        },
        addAnswerSumbit() {
            console.log(this.answerCardForm);
            this.$refs["answerCardForm"].validate(valid => {
                if (valid) {
                    this.answerCardForm.answerList.forEach(e => {
                        e.answerContent = e.answerContentList.toString();
                    })
                    if (this.answerCardForm.isAverageBol) {
                        this.answerCardForm.isAverage = 1;
                    } else {
                        this.answerCardForm.isAverage = 0;
                    }
                    if (this.answerCardForm.cardId) {
                        answerCardUpdate(this.answerCardForm).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                this.addAnswer = false;
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    } else {
                        answerCardCreate(this.answerCardForm).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("创建成功");
                                this.form.answerCardList.push(res.data.data)
                                this.addAnswer = false;
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    }

                }
            });
        },
        addCardSumbit() {
            this.$refs["addCardForm"].validate(valid => {
                if (valid) {
                    var answer = []
                    this.addCardForm.optionList.forEach(e => {
                        if (e.isAnswer == true) {
                            answer.push(e.optionAnswer)
                        }
                    })
                    this.addCardForm.answer = answer.toString()
                    if (this.addCardForm.cardId) {
                        backCardUpdate(this.addCardForm).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                this.addCard = false;
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    } else {
                        backCardCreate(this.addCardForm).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("创建成功");
                                this.form.backCardList.push(res.data.data)
                                this.addCard = false;
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    }

                }
            });
        },
        changeQuestionNum() {
            console.log(this.answerCardForm.questionNum);
            if (!this.answerCardForm.questionNum) {
                this.answerCardForm.questionNum = 1;
                this.$forceUpdate()
            }
            if (this.answerCardForm.questionNum >= this.answerCardForm.answerList.length) {
                for (var i = 0; i < this.answerCardForm.questionNum; i++) {
                    if ((i + 1) > this.answerCardForm.answerList.length) {
                        console.log(i);
                        this.answerCardForm.answerList.push({
                            answerNo: i + 1,
                            answerContentList: [],
                            answerContent: '',
                            answerScore: ''
                        })
                    }
                }
            } else {
                for (var i = this.answerCardForm.answerList.length; i > 0; i--) {
                    if ((i) > this.answerCardForm.questionNum) {
                        console.log(i);
                        this.answerCardForm.answerList.splice(i - 1, 1)
                    }
                }
            }
            this.changeIsNum()
        },
        changeIsNum() {
            if (this.answerCardForm.isAverageBol) {
                this.answerCardForm.answerList.forEach(e => {
                    e.answerScore = (this.answerCardForm.totalScore / this.answerCardForm.questionNum).toFixed(2)
                    console.log((this.answerCardForm.totalScore / this.answerCardForm.questionNum).toFixed(2));
                })
            }
        },
        getUserTeachers() {
            userTeachers({ username: JSON.parse(this.$storage.get("userInfo")).username }).then(res => {
                this.teacherList = res.data.data;
            })
        },
        getZhktQueryDict() {
            zhktQueryDict({ code: 'subject' }).then(res => {
                this.subjectList = res.data.data;
            })
        },
        changeTitle(row) {
            console.log(row);
            this.form.title = row.className + '­—' + row.ptit + (row.title ? '­—' + row.title : '')
            this.isDirectory = false;
            this.isCourse = false;
        },
        handleRemove(file) {
            if (file.printStatus == 0) {
                this.$message.error('待铺设中，不能删除')
                this.getlessonInfo()
                return
            }
            if (file.printStatus == 1) {
                this.$message.error('正在铺设中，不能删除')
                this.getlessonInfo()
                return
            }
            console.log(file)
            if (file.response.data) {
                this.delFileList.push(file.response.data)
                this.submitForm()
            }
        },
        getlessonInfo() {
            recordDetails({ recordId: this.recordId }).then(res => {
                this.dzFileList = [];
                this.ptFileList = [];
                this.PPTFileList = [];
                this.xsxaFileList = [];
                this.xzxaFileList = [];
                this.sjFileList = [];
                var awardList = [];
                this.bsStrokeList = {};
                this.stuStrokeList = [];
                let arr = []

                res.data.data.bsStrokeList.forEach((e, i) => {
                    this.bsStrokeList[e.fileId] = [];

                    e.linkList.forEach((j, k) => {
                        var _this = this;
                        arr.push(new Promise(function (resolve, reject) {
                            const xhr = new XMLHttpRequest();
                            xhr.overrideMimeType("text/plain");
                            xhr.open('GET', j.link, true);
                            xhr.onreadystatechange = function () {
                                if (xhr.readyState === 4 && xhr.status === 200) {
                                    const text = xhr.responseText;
                                    console.log(JSON.parse(text));
                                    var res = JSON.parse(text);
                                    _this.bsStrokeList[e.fileId].push({ link: j.link, dotsList: res, bookData: { fileId: e.fileId, pageWidth: e.fileWidth * 80 / 3, pageHeight: e.fileHeight * 80 / 3, page: j.page, imgFileUrl: e.imgFileUrl } })
                                    resolve(res);
                                    // 处理读取到的文本数据
                                }
                            };
                            xhr.send(null);
                        }))
                    })
                })
                this.stuStrokeList = res.data.data.stuStrokeList;
                this.stuStrokeList.forEach((e, i) => {
                    e.pageWidth = e.fileWidth * 80 / 3;
                    e.pageHeight = e.fileHeight * 80 / 3;
                    e.allWriteCount = 0
                    e.stuStrokeList.forEach((j, k) => {
                        e.allWriteCount = e.allWriteCount + j.writeCount;
                        j.linkList.forEach((m, n) => {
                            var _this = this;
                            arr.push(new Promise(function (resolve, reject) {
                                const xhr = new XMLHttpRequest();
                                xhr.overrideMimeType("text/plain");
                                xhr.open('GET', m.link, true);
                                xhr.onreadystatechange = function () {
                                    if (xhr.readyState === 4 && xhr.status === 200) {
                                        const text = xhr.responseText;
                                        console.log(JSON.parse(text));
                                        var res = JSON.parse(text);
                                        j.bookData = {
                                            pages: e.pages,
                                            pageWidth: e.pageWidth,
                                            pageHeight: e.pageHeight
                                        }
                                        m.dotsList = res
                                        // _this.bsStrokeList.push({ dotsList: res, bookData: { fileId: e.fileId, pageWidth: e.fileWidth * 80 / 3, pageHeight: e.fileHeight * 80 / 3, page: j.page, imgFileUrl: e.imgFileUrl } })
                                        resolve(res);
                                        // 处理读取到的文本数据
                                    }
                                };
                                xhr.send(null);
                            }))
                        })
                    })
                    // e.linkList.forEach((j, k) => {
                    //     var _this = this;
                    //     arr.push(new Promise(function (resolve, reject) {
                    //         const xhr = new XMLHttpRequest();
                    //         xhr.overrideMimeType("text/plain");
                    //         xhr.open('GET', j.link, true);
                    //         xhr.onreadystatechange = function () {
                    //             if (xhr.readyState === 4 && xhr.status === 200) {
                    //                 const text = xhr.responseText;
                    //                 console.log(JSON.parse(text));
                    //                 var res = JSON.parse(text);
                    //                 _this.bsStrokeList.push({ dotsList: res, bookData: { fileId:e.fileId,pageWidth: e.fileWidth * 80 / 3, pageHeight: e.fileHeight * 80 / 3, page: j.page, imgFileUrl: e.imgFileUrl } })
                    //                 resolve(res);
                    //                 // 处理读取到的文本数据
                    //             }
                    //         };
                    //         xhr.send(null);
                    //     }))
                    // })
                })
                Promise.all(arr).then((result) => {
                    console.log(this.bsStrokeList);
                    // console.log(this.stuStrokeList);
                    this.isCanvas = true;

                })
                res.data.data.awardList.forEach((e, i) => {
                    if (e.awardList && e.awardList.length != 0) {
                        e.awardList.forEach((j, n) => {
                            if (n == 0) {
                                awardList.push({ index: i + 1, studentName: e.studentName, awardContent: j.awardContent, stuStarNum: j.stuStarNum, starNum: j.starNum, createTime: j.createTime, col: e.awardList.length })
                            } else {
                                awardList.push({ index: i + 1, studentName: e.studentName, awardContent: j.awardContent, stuStarNum: j.stuStarNum, starNum: j.starNum, createTime: j.createTime, col: 0 })
                            }
                        })
                    } else {
                        awardList.push({ index: i + 1, studentName: e.studentName, awardContent: '', stuStarNum: 0, starNum: 5, col: 1, createTime: '' })
                    }
                })
                this.form = res.data.data;
                this.form.awardList = awardList;
                var obj = {}
                this.form.examList.forEach(m => {
                    m.obj = {}
                    m.studentExamList.forEach(e => {
                        e.answerList.forEach(j => {
                            if (m.obj[j.answerNo]) {
                                m.obj[j.answerNo].count++;
                                if (j.isRight == 1) {
                                    m.obj[j.answerNo].zqs++;
                                }
                            } else {
                                m.obj[j.answerNo] = {
                                    count: 1,
                                    zqs: 0
                                }
                                if (j.isRight == 1) {
                                    m.obj[j.answerNo].zqs = 1;
                                }
                            }

                        })
                    })
                })
                console.log(this.form.examList);
                this.form.fileList.forEach(e => {
                    e.typeList.forEach(j => {
                        j.fileList.forEach(m => {
                            // this.dzFileList.push({
                            //     dotFileUrl: m.dotFileUrl,
                            //     printStatus: m.printStatus,
                            //     name: m.fileName,
                            //     fileId: m.fileId,
                            //     url: m.originalFileUrl,
                            //     imgFileUrl: m.imgFileUrl,
                            //     response: {
                            //         data: m.originalFileUrl
                            //     }
                            // })
                            this.allFileList.push({
                                fileType: e.fileType,
                                fileBelowType: j.fileBelowType,
                                dotFileUrl: m.dotFileUrl,
                                printStatus: m.printStatus,
                                name: m.fileName,
                                fileId: m.fileId,
                                url: m.originalFileUrl,
                                imgFileUrl: m.imgFileUrl,
                                response: {
                                    data: m.originalFileUrl
                                }
                            })
                        })
                    })
                })
                // this.form.gajaFiles.forEach(e => {
                //     this.jAFileList.push({
                //         name: e.fileName,
                //         fileId: e.fileId,
                //         url: e.fileUrl,
                //         response: {
                //             data: e.fileUrl
                //         }
                //     })
                // })

            })
        },
        handleXTChange(file) {
            console.log(file)
        },
        handleJAChange(file) {
            console.log(file)
            if (file.response && file.response.code == 1) {
                this.submitForm()
            }
        },
        handleXAChange(file) {
            console.log(file);
        },
        handleKjChange(file) {
            console.log(file)

        },
        beforePdfUpload(rawFile) {
            console.log(rawFile)
            this.fileName = rawFile.name;
            if (!(rawFile.type == 'application/pdf')) {
                this.$message.error('文件必须是.pdf格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            this.fileName = rawFile.name;
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        beforePPTUpload(rawFile) {
            console.log(rawFile)
            this.fileName = rawFile.name;
            if (!(rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
                this.$message.error('文件必须是.ppt,.pptx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        startDateConfirm(e) {
            if (e > this.form.endTime) {
                this.form.endTime = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e < this.form.beginTime) {
                this.form.beginTime = '';
            }
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginTime === '' || !this.form.beginTime) {
                callback(new Error('请选择开始日期'))
            } else if (this.form.endTime === '' || !this.form.endTime) {
                callback(new Error('请选择结束日期'))
            } else {
                callback()
            }
        },
        checkteamuseList(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.$refs.teamuseList.checkList.length == 0) {
                callback(new Error('请选择协备人员'))
            } else {
                callback()
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date()) - 86400000
        },
        enddisabledDate(current) {
            if (this.form.beginTime) {
                return current && current < this.form.beginTime
            } else {
                return current && current < Number(new Date()) - 86400000
            }
        },
        changePeriod() {
            this.form.gradeKey = '';
            this.form.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.form.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
        },
        changeGrade() {
            this.form.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.form.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.form.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.form.coverUrl = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        /** 提交按钮 */
        submitForm() {
            // this.form.teamuseList = [];
            // this.form.guideUsersList = [];
            // if (this.isMyLess != 1) {
            //     this.$refs.teamuseList.checkList.forEach(e => {
            //         this.form.teamuseList.push(e.userId)
            //     })
            //     this.$refs.guideUsersList.checkList.forEach(e => {
            //         this.form.guideUsersList.push(e.userId)
            //     })
            //     if (this.$refs.leadUser.checkList.length != 0) {
            //         this.form.leadUser = this.$refs.leadUser.checkList[0].userId;
            //     } else {
            //         this.form.leadUser = '';
            //     }
            // }


            // console.log(this.form)
            // console.log(this.jAFileList)
            // console.log(this.kJFileList)
            // console.log(this.xTFileList)
            console.log(this.allFileList);
            var fileList = [];
            this.form.fileUrls = ''
            this.allFileList.forEach((e) => {
                if (e.response.code == 1) {
                    fileList.push(e.response.data);
                }
            })

            // this.form.teamUsers = this.form.teamuseList.toString()
            // this.form.guideUsers = this.form.guideUsersList.toString()
            this.$refs["form"].validate(valid => {
                if (valid) {
                    var answerCardIds = [];
                    this.form.answerCardList.forEach(e => {
                        answerCardIds.push(e.cardId)
                    })
                    var backCardIds = [];
                    this.form.backCardList.forEach(e => {
                        backCardIds.push(e.cardId)
                    })
                    this.form.backCardIds = backCardIds.toString();
                    this.form.answerCardIds = answerCardIds.toString();
                    console.log(fileList)
                    //         var obj = JSON.parse(JSON.stringify(this.form));
                    this.form.fileUrls = fileList.toString()


                    if (this.recordId) {
                        lessonUpdate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.dzFileList = []
                                this.ptFileList = []
                                this.PPTFileList = []
                                this.xsxaFileList = []
                                this.xzxaFileList = []
                                this.sjFileList = []
                                this.delFileList.forEach(e => {
                                    lessonDeleteFile({ originalFileUrl: e }).then(respon => {
                                        this.getlessonInfo()
                                    })
                                })
                                this.delAnswerList.forEach(e => {
                                    answerCardDelete({ cardId: e })
                                })
                                this.delCardList.forEach(e => {
                                    backCardDelete({ cardId: e })
                                })
                                this.$message.success("操作成功");
                                this.delFileList = [];
                                this.getlessonInfo()
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    } else {
                        lessonCreate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.dzFileList = []
                                this.ptFileList = []
                                this.PPTFileList = []
                                this.xsxaFileList = []
                                this.xzxaFileList = []
                                this.sjFileList = []
                                this.delFileList.forEach(e => {
                                    lessonDeleteFile({ originalFileUrl: e });
                                })
                                this.delAnswerList.forEach(e => {
                                    answerCardDelete({ cardId: e })
                                })
                                this.delCardList.forEach(e => {
                                    backCardDelete({ cardId: e })
                                })
                                this.$message.success("操作成功");
                                this.replace('/classRecords', { isSx: 1 })

                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }
                        });
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            this.replace('/classRecords')
        },
    }
};
</script>
<style scoped>
.sdfsa .el-card {
    /* 让最后一个元素的右边距自动适应，从而实现左对齐的效果。 */
    margin-left: 10px;
    margin-right: 10px;
}

.sdfsa .el-card:last-child {
    /* 让最后一个元素的右边距自动适应，从而实现左对齐的效果。 */
    margin-right: auto;
}

.avatar-uploader .avatar {
    width: 270px;
    height: 152px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    width: 270px;
    height: 152px;
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 270px;
    height: 152px;
    text-align: center;
}


/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    /* height: calc(100vh - 500px);
    overflow-y: auto; */
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* padding: 0 15px; */
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.editeicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 40px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .editeicon {
    display: block;
}

.csbbb:hover .eicon {
    display: block;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #21ac74;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar-uploadertxx /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploadertxx .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.detailFF /deep/ .el-input__suffix {
    display: none;
}

.detailFF /deep/ .el-select .el-input.is-disabled .el-input__wrapper {
    cursor: unset;
    background: transparent;
    border: 0;
    box-shadow: none;
    padding: 0;
}

.detailFF /deep/ .el-select .el-select__tags-text {
    color: #333;
}

.detailFF /deep/ .el-select .el-input.is-disabled .el-input__inner {
    color: #333;
    -webkit-text-fill-color: #333;
}

.detailFF /deep/ .el-input.is-disabled {
    cursor: unset;
}

.lesscustom /deep/ .el-form-item {
    justify-content: space-between;
}

.lesscustom /deep/ .el-form-item__content {
    flex: none;
}

.lesscustom /deep/ .el-form-item__label {
    font-weight: bold;
    color: #21ac74;
}

.csbbb:hover .start {
    display: block;
}

.start {
    display: none;
}
</style>