<template>
    <div class="app-container pt0Im pl0Im">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>班级管理</a></el-breadcrumb-item>
                <el-breadcrumb-item>班级详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div style="height: calc(100vh - 150px);overflow: auto;">
            <el-card>
                <el-descriptions class="detailFF" title="基本信息">
                    <el-descriptions-item label="班级名称">{{ form.className }}<el-icon v-if="isNoSet" @click="openSetName"
                            class="ml5 cursor" style="transform: translateY(2px);color: #359af2;">
                            <Edit />
                        </el-icon></el-descriptions-item>
                    <el-descriptions-item label="年级">{{ form.grade }}</el-descriptions-item>
                    <el-descriptions-item v-if="form.teacherName && form.isVirtual != 1" label="班主任">{{ form.teacherName
                    }}</el-descriptions-item>
                    <el-descriptions-item v-if="form.genderTxt" label="性别">{{ form.genderTxt }}</el-descriptions-item>
                    <el-descriptions-item v-if="form.username && form.isVirtual != 1" label="班主任账号">
                        <el-tag size="small">{{ form.username }}</el-tag>
                    </el-descriptions-item>
                    <el-descriptions-item label="所属学校">{{ form.schoolName }}</el-descriptions-item>
                    <el-descriptions-item label="所属客户">{{ form.customName }}</el-descriptions-item>
                    <el-descriptions-item label="虚拟班级">{{ form.isVirtual == 1 ? '是' : '否' }}</el-descriptions-item>
                </el-descriptions>
            </el-card>
            <el-card class="mt15" v-if="form.isVirtual != 1">
                <el-row>
                    <el-col :span="24">
                        <div class="mb15" style="display: flex;justify-content: space-between;">
                            <div style="font-size: 14px;width: 70px;text-align: right;">教师列表</div>
                            <div class="disnone" style="display: flex;">
                                <el-button type="primary" size="small" @click="newaddTeacher" v-if="isNoSet">
                                    新增教师
                                </el-button>
                            </div>
                        </div>
                        <div class="bre9e w-100">
                            <el-table header-cell-class-name="bgf2" :data="form.teacherList" style="width: 100%">
                                <el-table-column align="center" type="index" width="60" prop="studentNo" label="序号">
                                </el-table-column>
                                <el-table-column align="center" width="" prop="teacherName" label="姓名">
                                    <template #default="scope">
                                        <div style="display: flex;align-items: center;">
                                            {{ scope.row.teacherName }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" width="140" prop="adminAccount" label="账号">
                                    <template #default="scope">
                                        <div>{{ scope.row.username.substr(0, 3) + ' ' +
                                            scope.row.username.substr(3, 4) + ' ' +
                                            scope.row.username.substr(7, 4) }}</div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" width="" prop="gender" label="性别">
                                    <template #default="scope">
                                        <div style="" v-if="scope.row.gender || scope.row.gender == 0">
                                            {{ scope.row.gender == 0 ? '男' : '女' }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" prop="subjectTxt" label="学科">
                                </el-table-column>
                                <el-table-column align="center" width="145" label="编辑" v-if="isNoSet">
                                    <template #default="scope">
                                        <el-button link icon="Edit" @click.prevent="editTeacherDetail(scope.row)"
                                            type="primary">
                                            修改
                                        </el-button>
                                        <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                            icon-color="#626AEF" title="确定删除该教师" @confirm="deleteTeacher(scope.row)">
                                            <template #reference>
                                                <el-button link icon="Delete" type="danger">
                                                    删除
                                                </el-button>
                                            </template>
                                        </el-popconfirm>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </el-col>
                </el-row>
            </el-card>
            <el-card class="mt15">
                <el-row>
                    <el-col :span="24">
                        <div class="mb15" style="display: flex;justify-content: space-between;">
                            <div style="font-size: 14px;width: 70px;text-align: right;">学生列表</div>
                            <div class="disnone" style="display: flex;">
                                <el-button class="ml15" type="primary" size="small" v-if="isNoSet" @click="scPybq">
                                    生成批阅标签
                                </el-button>
                                <el-button class="ml15" type="primary" size="small" v-if="isNoSet" @click="xzPybq">
                                    下载批阅标签
                                </el-button>
                                <el-dropdown size="small">
                                    <el-button type="primary" size="small" class="ml15">
                                        学生标签号<el-icon class="el-icon--right"><arrow-down /></el-icon>
                                    </el-button>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item @click="configStudentTag"
                                                v-if="isNoSet">生成标签号</el-dropdown-item>
                                            <el-dropdown-item @click="clearStudentTag"
                                                v-if="isNoSet">清空标签号</el-dropdown-item>
                                            <el-dropdown-item @click="handleExport">导出标签</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                                <el-dropdown class="ml15" size="small">
                                    <el-button type="primary" size="small">
                                        学生点阵标签<el-icon class="el-icon--right"><arrow-down /></el-icon>
                                    </el-button>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item @click="downloadClassTag">下载班级标签</el-dropdown-item>
                                            <el-dropdown-item @click="downloadbatchTemplate">批量下载学生标签</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                                <el-dropdown class="ml15" size="small" v-if="isNoSet">
                                    <el-button type="primary" size="small">
                                        导入学生<el-icon class="el-icon--right"><arrow-down /></el-icon>
                                    </el-button>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item @click="downloadTemplate">下载导入模板</el-dropdown-item>
                                            <el-dropdown-item><el-upload v-model:file-list="filesList" class="upload-demo disnone"
                                                    action="https://zbh.zbform.com/adminservice/zhkt/student/batchImport"
                                                    :on-change="handleChange" :headers="{ token }" :on-remove="handleRemove"
                                                    :data="{ classId: classId }" accept=".xlsx"
                                                    :before-upload="beforeUpload">导入学生</el-upload></el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                                <!-- <el-button type="primary" size="small" @click="clearStudentTag">
                                    清空标签号
                                </el-button>
                                <el-button type="primary" size="small" @click="configStudentTag">
                                    生成标签号
                                </el-button>
                                <el-button type="primary" size="small" @click="handleExport">
                                    导出标签
                                </el-button> -->
                                <el-button class="ml15" type="primary" size="small" v-if="isNoSet" @click="configSeats">
                                    配置座位
                                </el-button>
                                <!-- <el-button type="primary" size="small" @click="downloadClassTag">
                                    下载班级标签
                                </el-button>
                                <el-button type="primary" size="small" @click="downloadbatchTemplate">
                                    批量下载学生标签
                                </el-button> -->
                                <!-- <el-button type="primary" size="small" @click="downloadTemplate">
                                    下载导入模板
                                </el-button>
                                <el-upload v-model:file-list="filesList" class="upload-demo"
                                    action="https://zbh.zbform.com/adminservice/zhkt/student/batchImport"
                                    :on-change="handleChange" :headers="{ token }" :on-remove="handleRemove"
                                    :data="{ classId: classId }" accept=".xlsx" :before-upload="beforeUpload">
                                    <el-button class="ml15" type="primary" size="small">
                                        导入学生
                                    </el-button>
                                </el-upload> -->
                            </div>
                        </div>
                        <div class="bre9e w-100">
                            <el-table class="sssstt" header-cell-class-name="bgf2" :data="form.studentList"
                                style="width: 100%">
                                <el-table-column align="center" type="index" width="60" prop="studentNo" label="序号">
                                </el-table-column>
                                <el-table-column align="center" width="" prop="studentNo" label="学号">
                                    <template #default="scope">
                                        <div class="text-one" style="">
                                            {{ scope.row.studentNo }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" width="80" prop="dotNo" label="标签号">
                                    <template #default="scope">
                                        <el-button @click.prevent="editStudentDotNo(scope.row)" type="primary" size="small">
                                            {{ scope.row.dotNo }}
                                        </el-button>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" width="" prop="studentName" label="姓名">
                                    <template #default="scope">
                                        <div class="text-one">
                                            {{ scope.row.studentName }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" width="140" prop="studentPhone1" label="手机号1">
                                    <template #default="scope">
                                        <div v-if="scope.row.studentPhone1">{{ scope.row.studentPhone1.substr(0, 3) + ' ' +
                                            scope.row.studentPhone1.substr(3, 4) + ' ' +
                                            scope.row.studentPhone1.substr(7, 4) }}</div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" width="140" prop="studentPhone2" label="手机号2">
                                    <template #default="scope">
                                        <div v-if="scope.row.studentPhone2">{{ scope.row.studentPhone2.substr(0, 3) + ' ' +
                                            scope.row.studentPhone2.substr(3, 4) + ' ' +
                                            scope.row.studentPhone2.substr(7, 4) }}</div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" width="60" prop="gender" label="性别">
                                    <template #default="scope">
                                        <div style="" v-if="scope.row.gender || scope.row.gender == 0">
                                            {{ scope.row.gender == 0 ? '男' : '女' }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" width="160" prop="schoolName" label="所属学校">
                                    <template #default="scope">
                                        <div style="" class="text-one" :title="scope.row.schoolName">
                                            {{ scope.row.schoolName }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" width="160" prop="subjectName" label="所属学科">
                                    <template #default="scope">
                                        <div style="" class="text-one" :title="scope.row.subjectName">
                                            {{ scope.row.subjectName }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" width="145" label="编辑" v-if="isNoSet">
                                    <template #default="scope">
                                        <el-button link icon="Edit" @click.prevent="editStudentDetail(scope.row)"
                                            type="primary">
                                            修改
                                        </el-button>
                                        <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                            icon-color="#626AEF" title="确定删除该学生" @confirm="deleteStudent(scope.row)">
                                            <template #reference>
                                                <el-button link icon="Delete" type="danger">
                                                    删除
                                                </el-button>
                                            </template>
                                        </el-popconfirm>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" width="120" label="操作">
                                    <template #default="scope">
                                        <el-button v-if="scope.row.dotNo" @click.prevent="downloadStudentTags(scope.row)"
                                            type="primary" size="small">
                                            下载学生标签
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </el-col>
                </el-row>
            </el-card>
        </div>
        <el-dialog :title="this.studentForm.studentId ? '修改学生' : '新增学生'" v-model="isCreateStudent" width="700px">
            <el-form v-if="isCreateStudent" ref="studentForm" :model="studentForm" :rules="studentFormRules"
                label-width="100px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="学生姓名" prop="studentName">
                            <el-input v-model="studentForm.studentName" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="性别:" prop="gender">
                            <el-radio-group v-model="studentForm.gender">
                                <el-radio :label="0">男</el-radio>
                                <el-radio :label="1">女</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- <el-row>
                    <el-col :span="24">
                        <el-form-item label="标签号" prop="dotNo">
                            <el-input oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" v-model.number="studentForm.dotNo" placeholder="最多8个字" maxlength="8" />
                        </el-form-item>
                    </el-col>
                </el-row> -->
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="联系方式1" prop="studentPhone1">
                            <el-input v-model="studentForm.studentPhone1"
                                placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="联系方式2" prop="studentPhone2">
                            <el-input v-model="studentForm.studentPhone2"
                                placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="studentSubmitForm">
                        确定
                    </el-button>
                    <el-button @click="isCreateStudent = false">
                        取消
                    </el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="this.teacherForm.relationId ? '修改教师' : '新增教师'" v-model="isCreateTeacher" width="700px">
            <el-form v-if="isCreateTeacher" ref="teacherForm" :model="teacherForm" :rules="teacherFormRules"
                label-width="100px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="班级教师" prop="teacherId">
                            <el-select style="width: 100%;" clearable @change="changeLeadTeacher"
                                v-model="teacherForm.teacherId" placeholder="请选择教师">
                                <el-option v-for="item in teacherList" :key="item.teacherId" :label="item.teacherName"
                                    :value="item.teacherId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="学科" prop="subjectList">
                            <el-select v-model="teacherForm.subjectList" multiple placeholder="请选择学科" style="width: 100%;">
                                <el-option v-for="item in sksubjectList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="性别" prop="gender">
                            {{ teacherForm.gender == 0 ? '男' : teacherForm.gender == 1 ? '女' : '' }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="教师账号" prop="username">
                            {{ teacherForm.username }}
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="teacherSubmitForm">
                        确定
                    </el-button>
                    <el-button @click="isCreateTeacher = false">
                        取消
                    </el-button>
                </div>
            </template>
        </el-dialog>
        <div class="bfb">
            <el-dialog :title="'配置座位'" v-model="isSeats" width="100%" hight="100vh">
                <template #header="{ close, titleId, titleClass }">
                    <div class="my-header">
                        <el-button :type="isSeatEdit ? 'primary' : ''" @click="changeSeat">调整座位</el-button>
                        <el-button v-if="isSeatEdit" type="primary" @click="handleRanks">设置行列</el-button>
                        <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                            icon-color="#626AEF" title="确定清空座位" @confirm="handleClear">
                            <template #reference>
                                <el-button v-if="isSeatEdit" type="primary">清空座位 </el-button>
                            </template>
                        </el-popconfirm>
                        <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                            icon-color="#626AEF" title="确定自动按标签号排序" @confirm="handleSort">
                            <template #reference>
                                <el-button v-if="isSeatEdit" type="primary" @click="handleAdd">自动排座</el-button>
                            </template>
                        </el-popconfirm>
                        <div class="pct" :id="titleId" :class="titleClass">配置座位</div>
                    </div>
                </template>
                <div v-if="isSeats">
                    <el-row>
                        <el-col class="zdytable" :span="6">
                            <el-table @current-change="handleCurrentChange" ref="stuTable" highlight-current-row border
                                :data="studentList" style="width: 100%" height="calc(100vh - 140px)">
                                <el-table-column align="center" sortable width="80" prop="studentNo" label="学号">
                                    <template #default="scope">
                                        <div :title="scope.row.studentNo" class="text-one"
                                            :style="`padding:8px;${scope.row.seatCol && (selectObj?.studentId != scope.row.studentId) ? '' : ''}`">
                                            {{ scope.row.studentNo }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" sortable prop="studentName" label="姓名">
                                    <template #default="scope">
                                        <div :data-row="scope.row.seatRow ? scope.row.seatRow : ''"
                                            :data-studentid="scope.row.studentId"
                                            :data-col="scope.row.seatCol ? scope.row.seatCol : ''" :data-tg="1"
                                            :title="scope.row.studentName" class="text-one"
                                            :draggable="isSeatEdit ? true : false" @dragenter="dragName"
                                            @dragstart="dragStart" @dragend="dragend"
                                            :style="`padding:8px;${scope.row.seatCol && (selectObj?.studentId != scope.row.studentId) ? '' : ''}${(scope.row.seatCol && scope.row.seatCol <= colTol) && (scope.row.seatRow && scope.row.seatRow <= rowTol) ? 'color: #409EFE;' : ''}`">
                                            {{ scope.row.studentName }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" width="60px" prop="gender" label="性别">
                                    <template #default="scope">
                                        <div v-if="scope.row.gender || scope.row.gender == 0"
                                            :style="`padding:8px;${scope.row.seatCol && (selectObj?.studentId != scope.row.studentId) ? '' : ''}`">
                                            {{ scope.row.gender == 0 ? '男' : '女' }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" sortable width="105px" prop="zwh" label="座位号">
                                    <template #default="scope">
                                        <div :title="`第${scope.row.seatCol}组第${scope.row.seatRow}排`" class="text-one"
                                            v-if="scope.row.seatCol && scope.row.seatRow"
                                            :style="`padding:8px;${scope.row.seatCol && (selectObj?.studentId != scope.row.studentId) ? '' : ''}`">
                                            第{{ scope.row.seatCol }}组第{{ scope.row.seatRow }}排
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="18">
                            <div ref="seatRef" class="ml15"
                                style="height: calc(100vh - 145px);border: 1px solid rgb(239 236 236);overflow:auto;">
                                <div v-for="item in (rowTol)"
                                    :style="`display: flex;align-items: center;height: 60px;width:${100 * (colTol + 1)}px;`">
                                    <div :style="`width:100px;`">
                                        <div style="width: 80%;margin:0 auto;text-align:center;">排{{ (rowTol + 1) - item }}
                                        </div>
                                    </div>
                                    <div :style="`width:100px;height:100%`" v-for="items in colTol">
                                        <div :data-row="(rowTol + 1) - item" :data-col="items"
                                            @dblclick="dbZk((rowTol + 1) - item, items)"
                                            @click="setSeat((rowTol + 1) - item, items)"
                                            :draggable="isSeatEdit ? true : false" @dragenter="dragName"
                                            @dragstart="dragStart" @dragend="dragend"
                                            :class="selectObj?.seatCol == items && selectObj.seatRow == ((rowTol + 1) - item) ? 'bgacc1d9' : changeGender((rowTol + 1) - item, items) == 0 ? 'bg0798f0' : 'bgf24d52'"
                                            v-if="changeStudent((rowTol + 1) - item, items)"
                                            style="width: 80%;height:80%;margin:10% auto;border: 1px solid #e3e3e3;position: relative;cursor: pointer;background:#f5f5f5;text-align: center;">
                                            <div :data-row="(rowTol + 1) - item" :data-col="items"
                                                style="position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);width: 90%;">
                                                {{ changeStudent((rowTol + 1) - item, items) }}</div>
                                        </div>
                                        <div :data-row="(rowTol + 1) - item" :data-col="items"
                                            @dblclick="dbZk((rowTol + 1) - item, items)"
                                            @click="setSeat((rowTol + 1) - item, items)" v-else
                                            :draggable="isSeatEdit ? true : false" @dragenter="dragName"
                                            @dragstart="dragStart" @dragend="dragend"
                                            :class="selectObj?.seatCol == items && selectObj.seatRow == ((rowTol + 1) - item) ? 'bgacc1d9' : ''"
                                            style="width: 80%;height:80%;margin:10% auto;border: 1px solid #e3e3e3;position: relative;cursor: pointer;text-align: center;">
                                            <div :data-row="(rowTol + 1) - item" :data-col="items"
                                                style="position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);width: 90%;">
                                                {{ changeStudent((rowTol + 1) - item, items) }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    :style="`display: flex;align-items: center;height: 30px;width:${100 * (colTol + 1)}px;`">
                                    <div :style="`width:100px;`">
                                    </div>
                                    <div :style="`width:100px;height:100%`" v-for="items in colTol">
                                        <div style="text-align: center;margin-top:10%;">组{{ items }}</div>
                                    </div>
                                </div>
                                <div
                                    :style="`display: flex;align-items: center;height: 30px;width:${100 * (colTol + 1)}px;`">
                                    <div :style="`width:100px;`">
                                    </div>
                                    <div style="width: 100%;height: 100%;">
                                        <div
                                            style="width: 20%;text-align: center;margin: 0 auto;padding: 10px;background: rgb(226, 149, 17);color: #333;">
                                            讲台</div>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <template #footer>
                    <div class="disflex-row-center" v-if="isSeatEdit">
                        <el-button type="primary" @click="seatsSubmitForm">
                            确定
                        </el-button>
                        <el-button @click="isSeats = false">
                            取消
                        </el-button>
                    </div>
                </template>
            </el-dialog>
        </div>
        <div class="bjbq">
            <el-dialog :title="'班级标签'" v-model="isClassLabel" width="2000px">
                <div v-if="isClassLabel">
                    <el-row>
                        <el-col :span="24">
                            <div ref="bjbq"
                                :style="`width: ${891 * 2}px;height: ${630 * 2}px;border: 1px solid rgb(239 236 236);margin: 0 auto;font-size:25px;`">
                                <div v-for="item in (rowTol)"
                                    :style="`display: flex;align-items: center;height: ${100 / (rowTol + 1)}%;`">
                                    <div :style="`width:${100 / colTol}%;height:100%`" v-for="items in colTol">
                                        <div
                                            style="width: 100%;height:100%;border: 0.3px solid #00A0E9;position: relative;cursor: pointer;">
                                            <div style="position: absolute;left: 10%;top: 10%;width: 90%;color:#00A0E9">
                                                {{ changeStudent((rowTol + 1) - item, items) }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div :style="`display: flex;align-items: center;height: ${100 / (rowTol + 1)}%;`">
                                    <div style="width: 100%;height: 100%;">
                                        <div
                                            :style="`text-align: center;margin: 0 auto;color: #00A0E9;line-height:${(630 * 2) / (rowTol + 1)}px;`">
                                            {{ form.className }}学生标签
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-button @click="copyCodeImg">下载</el-button>
                </div>
            </el-dialog>
        </div>
        <el-dialog :title="'班级标签'" v-model="isEditDotNo" width="80%">
            <div style="display: flex;flex-wrap:wrap;max-height: 60vh;overflow-y: auto;">
                <div v-for="item in 130">
                    <el-button v-if="activeDotNoList.indexOf(item) != -1 && activeObj.dotNo != item" type="primary"
                        style="width:50px;margin: 5px;" :disabled="activeDotNoList.indexOf(item) != -1 ? true : false">{{
                            item }}</el-button>
                    <el-button v-else-if="activeObj.dotNo == item" type="danger" style="width:50px;margin: 5px;" disabled>{{
                        item }}</el-button>
                    <el-button @click="activeDotNo = item" v-else :type="activeDotNo == item ? 'success' : ''"
                        style="width:50px;margin: 5px;">{{ item }}</el-button>
                </div>
            </div>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button @click="blank">
                        置空
                    </el-button>
                    <el-button type="primary" @click="activeFotSubmitForm">
                        确定
                    </el-button>

                </div>
            </template>
        </el-dialog>
        <el-dialog :title="'设置行列'" v-model="isRanks" width="50%" draggable>
            <el-form label-width="120px">
                <el-form-item label="排数">
                    <el-input-number v-model="setrow" :min="1" :max="130" :step="1" />
                </el-form-item>
                <el-form-item label="组数">
                    <el-input-number v-model="setcol" :min="1" :max="80" :step="1" />
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitTank">
                        确定
                    </el-button>
                    <el-button @click="isRanks = false">
                        取消
                    </el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="'设置班级名称'" v-model="isSetName" draggable>
            <el-form label-width="0px">
                <el-form-item>
                    <el-input v-model="setName" placeholder="请输入班级名称" />
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitClassName">
                        确定
                    </el-button>
                    <el-button @click="isSetName = false">
                        取消
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>

import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import table2excel from 'js-table2excel';
import { timestampToTime } from "@/utils/date";
import { validatenull, isMobile } from '@/utils/validate'
import { formGroupForm } from "@/api/adminservice/form"
import { bookList } from "@/api/adminservice/public"
import { queryCustoms, queryTreeCustomOrg, createCustoms, custominfo, configCustom, customOrgBook } from "@/api/adminservice/custom"
import { qunQueryUser, qunCreate, qunUpdate, queryCustomQun, queryQunInfo } from "@/api/adminservice/qun"
import { studentSaveTag, studentClearTag, studentCreateAllTag, studentCreateTag, studentConfigTag, studentList, classTeacherCreateOrUpdate, classTeacherDelete, teacherList, zhktQueryDict, schoolCreate, schoolUpdate, teacherQueryOrRegUser, teacherDelete, classInfo, studentUpdate, studentDelete, studentConfigSeat, studentDownloadTag, classUploadClassSeat, classDownloadTag, classDotNos, classGroupRow, classUpdate, classGenerateStuSticker, classDownStuSticker } from "@/api/adminservice/classOrganizaMain"
import { download } from "@/api/adminservice/public";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { ElLoading } from 'element-plus';

function getBlob(url) {
    return new Promise(resolve => {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.responseType = "blob";
        xhr.onload = () => {
            console.log(xhr);
            if (xhr.status === 200) {
                resolve(xhr.response);
            }
        };
        xhr.send();
    });
}

export default {
    name: "demoAdd",
    components: {},
    data() {
        return {
            isNoSet: '',
            isSeatEdit: false,
            colTol: 8,
            rowTol: 10,
            setcol: 8,
            setrow: 10,
            isClassLabel: false,
            isCreateStudent: false,
            token: this.$storage.get("token"),
            classIsmList: [],
            memberList: [],
            formList: [],
            customId: '',
            groupData: {},
            isSeats: false,
            id: '',
            classId: '',
            readBook: false,
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            subjectList: [],
            sksubjectList: [],
            studentForm: {
                teacherNo: '',
                username: '',
                teacherName: '',
                subject: '',
                subjectList: [],
                classId: '',
                gender: ''
            },
            isSetName: false,
            setName: '',
            studentFormRules: {
                studentName: [
                    { required: true, message: "请输入教师姓名", trigger: "blur" },
                ],
                subjectList: [
                    { required: true, message: "请选择学科", trigger: "change" },
                ],
                gender: [
                    { required: true, message: "请选择性别", trigger: "change" },
                ],
                dotNo: [
                    { required: true, message: "请输入标签号", trigger: "blur" },
                ]
            },
            // 表单参数
            form: {
                adminName: '',
                adminUserId: '',
                adminUsername: '',
                customId: '',
                gradeClassIsm: '',
                schoolName: '',
                shortName: '',
            },
            tableData: [{
                adminAccount: '',
                orgName: ''
            }],
            // 表单校验
            rules: {
                // customId:[
                //     { required: true, message: "请选择客户", trigger: "change" },
                // ],
                // schoolName: [
                //     { required: true, message: "请输入学校名称", trigger: "blur" },
                // ],
                // shortName:[
                //     { required: true, message: "请输入学校简称", trigger: "blur" },
                // ],
                // adminUsername: [
                //     { required: true, message: "请输入管理员账号", trigger: "blur" },
                //     { validator: this.validateUsername, trigger: "blur" }
                // ],
                // adminName: [
                //     { required: true, message: "请输入管理员姓名", trigger: "blur" },
                // ],
            },
            bookList: [],
            customList: [],
            orgList: [],
            oldBookIds: [],
            oldFromIds: [],
            chooseBookIds: [],
            chooseBook: false,
            id: '',
            teacherList: [],
            teacherForm: {
                classId: '',
                subject: '',
                teacherId: '',
                subjectList: []
            },
            isCreateTeacher: false,
            teacherFormRules: {
                teacherId: [
                    { required: true, message: "请选择班级教师", trigger: "change" },
                ],
                subjectList: [
                    { required: true, message: "请选择学科", trigger: "change" },
                ],
            },
            selectObj: {},
            activeObj: {},
            studentList: [],
            oldStudentList: [],
            maxDotNo: 0,
            isEditDotNo: false,
            activeDotNoList: [],
            activeDotNo: '',
            dragObj: {},
            isRanks: false,
            loadingInstance: null,
        };
    },
    created() {
    },
    mounted() {
        if (this.$route.query.isNoSet) {
            this.isNoSet = this.$route.query.isNoSet;
        }
        this.getList()
        if (this.$route.query.classId) {
            this.classId = this.$route.query.classId;
            this.getInfo()
            this.getZhktQueryDict()
        } else {
            this.getZhktQueryDict()
        }
    },
    computed: {
    },
    methods: {
        openSetName() {
            this.isSetName = true;
            this.setName = this.form.className
        },
        changeSeat() {
            this.isSeatEdit = !this.isSeatEdit;
        },
        submitClassName() {
            var obj = JSON.parse(JSON.stringify(this.form))
            obj.className = this.setName
            classUpdate(obj).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("编辑成功");
                    this.isSetName = false;

                    this.getInfo();
                } else {
                    this.$message.error("操作失败")
                }
            });
        },
        submitTank() {
            classGroupRow({ classId: this.classId, groupNum: this.setcol, rowNum: this.setrow }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success('设置成功');
                    this.colTol = this.setcol;
                    this.rowTol = this.setrow;
                    this.isRanks = false;
                    setTimeout(() => {
                        this.$refs.seatRef.scrollTo(0, 1000000);
                    })
                    this.getInfo();
                } else {
                    this.$message.error('操作失败');
                }
            })

        },
        dragStart(cs) {
            this.oldStudentList = [];
            this.dragObj = null;
            console.log(cs);
            this.studentList.forEach((e, i) => {
                if (cs.target.dataset.studentid) {
                    if (cs.target.dataset.studentid == e.studentId) {
                        this.dragObj = e;
                        this.selectObj = e;
                    }
                } else {
                    if (e.seatCol == cs.target.dataset.col && e.seatRow == cs.target.dataset.row) {
                        console.log(e.studentName);
                        this.dragObj = e;
                        this.selectObj = e;
                    }
                }

            })
            console.log(this.dragObj);
            this.oldStudentList = JSON.parse(JSON.stringify(this.studentList))
        },
        dragName(cs) {
            console.log(this.dragObj.studentId, cs);
            if (this.dragObj.studentId && !cs.target.dataset.tg) {
                try {
                    console.log(this.oldStudentList);

                    this.oldStudentList.forEach((e, i) => {
                        // if (e.seatCol == cs.target.dataset.col && e.seatRow == cs.target.dataset.row) {
                        //     e.seatCol = null;
                        //     e.seatRow = null;
                        // }
                        console.log(this.dragObj.studentId == e.studentId);
                        if (this.dragObj.studentId == e.studentId) {
                            e.seatCol = Number(cs.target.dataset.col);
                            e.seatRow = Number(cs.target.dataset.row);
                            this.dragObj = e;
                        }
                    })
                    console.log(this.oldStudentList);
                } catch (error) {
                    console.log(error);
                }
            }
        },
        dragend(cs) {
            console.log(cs);
            try {
                this.oldStudentList.forEach((e, i) => {
                    if (e.studentId == this.dragObj.studentId) {

                    } else {
                        if (e.seatCol == this.dragObj.seatCol && e.seatRow == this.dragObj.seatRow && e.studentId != this.dragObj.studentId) {
                            e.seatCol = null;
                            e.seatRow = null;
                        }
                    }
                })
                console.log(this.oldStudentList);
                this.studentList = this.oldStudentList;
                if (this.dragObj.studentId) {
                    this.$refs['stuTable'].setCurrentRow(this.dragObj)
                } else {
                    this.$refs['stuTable'].setCurrentRow(this.selectObj)
                }
            } catch (error) {
                console.log(error);
            }
        },
        activeFotSubmitForm() {
            if (this.activeDotNo == '') {
                this.$message.error('请选择标签号')
                return
            }
            studentSaveTag({ studentId: this.activeObj.studentId, dotNo: this.activeDotNo }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success('设置成功');
                    this.isEditDotNo = false;
                    this.getInfo();
                } else {
                    this.$message.error('操作失败');
                }
            })
        },
        blank() {
            studentClearTag({ studentId: this.activeObj.studentId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success('置空成功');
                    this.isEditDotNo = false;
                    this.getInfo();
                } else {
                    this.$message.error('置空失败');
                }
            })
        },
        handleRanks() {
            this.setcol = this.colTol;
            this.setrow = this.rowTol;
            this.isRanks = true;
        },
        handleClear() {
            this.studentList.forEach(e => {
                e.seatCol = null;
                e.seatRow = null;
                e.x = null;
                e.y = null;
                e.width = null;
                e.height = null;
            })
        },
        handleSort() {
            this.studentList.forEach(e => {
                e.seatCol = null;
                e.seatRow = null;
            })
            this.studentList.sort(function (a, b) {
                if (a.dotNo > b.dotNo) { return 1; }
                if (a.dotNo < b.dotNo) { return -1; }
                return 0;
            })
            this.studentList.forEach((e, i) => {
                e.seatCol = Math.trunc(i / this.rowTol) + 1;
                e.seatRow = i % this.rowTol + 1;
            })
        },
        tableRowClassName: function (row, index) {
            console.log(row.row)
            if (row.row.tagCol) {
                // return 'hs-row';
            }
            return '';
        },
        copyCodeImg() {
            this.$forceUpdate();
            let pdf = new jsPDF('p', 'mm', 'a4');
            // let uuid = this.uuid()
            try {
                html2canvas(this.$refs.bjbq, {
                    backgroundColor: "#ffffff",
                    useCORS: true,
                    scale: 8,
                }).then(async canvas => {
                    let pageData = canvas.toDataURL('image/jpeg');
                    // 生成图片地址
                    var imgUrl = canvas.toDataURL('image/png');
                    // // // 创建一个a标签，用来下载图片
                    // var eleLink = document.createElement('a');
                    // // 转换后的图片地址
                    // eleLink.href = imgUrl;
                    // // 文件名设置
                    // eleLink.download = this.form.className;
                    // // 下载
                    // eleLink.click();
                    // console.log(imgUrl);
                    // console.log(this.dataUrltoBlob(imgUrl));
                    let formdata = new FormData();
                    formdata.append('file', this.dataUrltoBlob(imgUrl))
                    formdata.append('classId', this.classId);
                    classUploadClassSeat(formdata).then(res => {
                        this.loadingInstance.close()
                        this.getInfo()
                    }).catch(error => {
                        this.loadingInstance.close()
                        this.getInfo()
                    })
                })
            } catch (error) {
                this.getInfo()
                this.loadingInstance.close()
            }



            // classUploadClassSeat().then(res => {

            // })
        },
        dataUrltoBlob(dataUrl) {
            var arr = dataUrl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            // return new Blob([u8arr], { type: mime });
            return new File([new Blob([u8arr], { type: mime })], this.form.className + '.png', { type: mime, lastModified: Date.now() })
        },
        downloadbatchTemplate() {
            let arr = []
            for (var i = 0; i < this.form.studentList.length; i++) {
                var _this = this;
                arr.push(new Promise(function (resolve, reject) {
                    var studentName = _this.form.studentList[i].studentName
                    studentDownloadTag({ studentId: _this.form.studentList[i].studentId }).then(res => {
                        res.studentName = studentName
                        resolve(res)
                    })
                }))
            }
            Promise.all(arr).then((result) => {
                let zip = new JSZip();//初始化
                console.log(result)
                result.forEach((e, i) => {
                    console.log(e);
                    if (e.data.code == 1) {
                        zip.file(e.studentName + ".pdf", getBlob(e.data.data));
                        console.log(getBlob(e.data.data));
                    }
                })
                zip.generateAsync({ type: "blob" }).then(function (content) {
                    //使用blob下载zip
                    saveAs(content, _this.form.className + ".zip");
                    // //这里也可以写成使用File下载zip，把blob转换为File
                    // const filesZip = new File([content], "Sound.zip", { type: "application/zip" });
                    // saveAs(filesZip);
                });
            })
        },
        downloadStudentTags(row) {
            studentDownloadTag({ studentId: row.studentId }).then(res => {
                if (res.data.code == 1) {
                    download(res.data.data, row.studentName)
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        handleCurrentChange(val) {
            console.log(val)
            this.selectObj = val;
        },
        handleExport() {
            var timestamp = timestampToTime(Date.parse(new Date()));
            const column = [
                {
                    title: '学号',
                    key: 'studentNo',
                    type: 'text',
                },
                {
                    title: '标签号',
                    key: 'dotNo',
                    type: 'text',
                },
                {
                    title: '姓名',
                    key: 'studentName',
                    type: 'text',
                },
                {
                    title: '所属学校',
                    key: 'schoolName',
                    type: 'text',
                },
                {
                    title: '所属学科',
                    key: 'subjectName',
                    type: 'text',
                },
                // {
                //     title: '手机号1',
                //     key: 'studentPhone1',
                //     type: 'text',
                // },
                // {
                //     title: '手机号2',
                //     key: 'studentPhone2',
                //     type: 'text',
                // },
                // {
                //     title: '性别',
                //     key: 'genderTxt',
                //     type: 'text',
                // },
            ]
            const tableDatas = JSON.parse(JSON.stringify(this.form.studentList))//这里面填写你接口的数据
            const datas = tableDatas
            table2excel(column, datas, '导出标签号' + timestamp)
        },
        dbZk(row, col) {
            if (this.isSeatEdit) {
                console.log(row, col);
                this.studentList.forEach((e, i) => {
                    if (e.seatCol == col && e.seatRow == row) {
                        console.log(e.studentName);
                        e.seatCol = null;
                        e.seatRow = null;
                    }
                })
            }

        },
        setSeat(row, col) {
            if (this.selectObj.studentId && this.isSeatEdit) {
                try {
                    var actObj = []
                    var isSet = false;
                    this.studentList.forEach((e, i) => {
                        if (e.seatCol == col && e.seatRow == row) {
                            console.log(e.studentName);
                            e.seatCol = null;
                            e.seatRow = null;
                        }
                        if (this.selectObj.studentId == e.studentId) {
                            e.seatCol = col;
                            e.seatRow = row;
                            isSet = true;
                            console.log(col, row);
                            console.log(i, this.studentList.length);
                            // if ((i + 1) != this.studentList.length) {
                            //     actObj = this.studentList[i + 1]
                            // } else {
                            // }
                        }
                        e.isSet = isSet;
                        if (!e.seatCol && !e.seatRow) {
                            actObj.push(e)
                        }
                    })
                    var aaaObj = {};
                    for (var i = 0; i < actObj.length; i++) {
                        if (actObj[i].isSet) {
                            aaaObj = actObj[i];
                            break;
                        }
                    }
                    if (aaaObj.studentId) {
                        if (actObj.length != 0) {
                            this.selectObj = aaaObj;
                            this.$refs['stuTable'].setCurrentRow(aaaObj)
                        }
                    } else {
                        if (actObj.length != 0) {
                            this.selectObj = actObj[0];
                            this.$refs['stuTable'].setCurrentRow(actObj[0])
                        }
                    }

                } catch (error) {
                    console.log(error);
                }

            }
        },
        changeStudent(row, col) {
            for (var i = 0; i < this.studentList.length; i++) {
                var e = this.studentList[i]
                if (e.seatCol == col && e.seatRow == row) {
                    if (e.dotNo) {
                        return e.studentName + '(' + e.dotNo + ')';
                    } else {
                        return e.studentName;
                    }

                }
            }
        },
        changeGender(row, col) {
            for (var i = 0; i < this.studentList.length; i++) {
                var e = this.studentList[i]
                if (e.seatCol == col && e.seatRow == row) {
                    return e.gender;
                }
            }
        },
        scPybq(row) {
            classGenerateStuSticker({ classId: this.classId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success('操作成功')
                } else {
                    this.$message.error('操作失败')
                }
            })
        },
        xzPybq(row) {
            classDownStuSticker({ classId: this.classId }).then(res => {
                if (res.data.code == 1) {
                    download(res.data.data + '?' + new Date().getTime(), this.form.className + '学生批阅标签')
                } else {
                    if (res.data.msg) {
                        this.$message.error(res.data.msg)
                    } else {
                        this.$message.error('操作失败')
                    }
                }
            })
        },
        deleteTeacher(row) {
            classTeacherDelete({ relationId: row.relationId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success('删除成功')
                    this.getInfo()
                } else {
                    this.$message.error('删除失败')
                }
            })
        },
        editTeacherDetail(row) {
            console.log(row);
            this.sksubjectList = []
            this.sksubjectList = []
            this.teacherForm = row;
            this.teacherList.forEach(e => {
                if (e.teacherId == this.teacherForm.teacherId) {
                    if (e.subject) {
                        var subjectList = e.subject.split(',')
                    } else {
                        var subjectList = []
                    }
                    console.log(subjectList);
                    this.subjectList.forEach(m => {
                        subjectList.forEach(j => {
                            console.log(m.dictKey, j);
                            if (m.dictKey == j) {
                                this.sksubjectList.push(m)
                            }
                        })
                    })
                    console.log(e);
                    this.teacherForm.gender = e.gender;
                    this.teacherForm.username = e.username;
                }
            })
            this.isCreateTeacher = true;
        },
        changeLeadTeacher() {
            this.teacherForm.gender = null
            this.teacherForm.username = ''
            this.teacherForm.subject = ''
            this.sksubjectList = []
            console.log(this.teacherList);
            this.teacherList.forEach(e => {
                if (e.teacherId == this.teacherForm.teacherId) {
                    if (e.subject) {
                        var subjectList = e.subject.split(',')
                    } else {
                        var subjectList = []
                    }
                    console.log(subjectList);
                    this.subjectList.forEach(m => {
                        subjectList.forEach(j => {
                            console.log(m.dictKey, j);
                            if (m.dictKey == j) {
                                this.sksubjectList.push(m)
                            }
                        })
                    })
                    console.log(e);
                    this.teacherForm.gender = e.gender;
                    this.teacherForm.username = e.username;
                }
            })
            console.log(this.sksubjectList);

        },
        newaddTeacher() {
            this.teacherForm = {
                teacherNo: '',
                username: '',
                teacherName: '',
                subject: '',
                subjectList: [],
                schoolId: '',
                gender: ''
            }
            this.sksubjectList = [];
            this.isCreateTeacher = true;
        },
        deleteStudent(row) {
            studentDelete({ studentId: row.studentId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getInfo()
                } else {
                    this.$message.error("操作失败")
                }
            })
        },
        validateUsername(rule, value, callback) {
            if (!isMobile(this.studentForm.username)) {
                callback(new Error("手机号码格式不正确"))
            }
            teacherQueryOrRegUser({ username: this.studentForm.username, trueName: this.studentForm.teacherName, gender: this.studentForm.gender }).then(res => {
                const result = res.data.data
                if (!result) {
                    callback(new Error("请输入正确账号"))
                } else {
                    if (this.studentForm.teacherName == '') {
                        this.studentForm.teacherName = res.data.data.trueName
                    }
                    if (this.studentForm.gender == '') {
                        this.studentForm.gender = res.data.data.gender
                    }
                    callback();
                }
            })
        },
        studentSubmitForm() {
            this.studentForm.classId = this.classId;
            this.$refs["studentForm"].validate(valid => {
                if (valid) {
                    console.log(1);
                    studentUpdate(this.studentForm).then(res => {
                        if (res.data.code == 1) {
                            this.$message.success("新增成功");
                            this.isCreateStudent = false;
                            this.getInfo()
                        } else {
                            this.$message.error(res.data.msg ? res.data.msg : "操作失败")
                        }
                    })
                }
            });
        },
        teacherSubmitForm() {
            this.teacherForm.classId = this.classId;
            this.$refs["teacherForm"].validate(valid => {
                if (valid) {
                    console.log(1);
                    this.teacherForm.subject = this.teacherForm.subjectList.toString()
                    classTeacherCreateOrUpdate(this.teacherForm).then(res => {
                        if (res.data.code == 1) {
                            this.$message.success("新增成功");
                            this.isCreateTeacher = false;
                            this.getInfo()
                        } else {
                            this.$message.error("操作失败")
                        }
                    })

                }
            });
        },
        seatsSubmitForm() {
            console.log(this.studentList)
            var seatList = [];
            var tagList = [];
            var isTg = true;
            for (var i = 0; i < this.studentList.length; i++) {
                var e = this.studentList[i];
                if (e.seatCol) {
                    if (e.seatCol > this.colTol) {
                        isTg = false;
                        break;
                    }
                    if (e.seatRow > this.rowTol) {
                        isTg = false;
                        break;
                    }
                } else {
                    isTg = false;
                    break;
                }
            }
            if (isTg) {
                this.loadingInstance = ElLoading.service({
                    lock: true,
                    text: '配置中',
                    background: 'rgba(0, 0, 0, 0.7)',
                })
                this.studentList.forEach(e => {
                    seatList.push({ seatCol: e.seatCol, seatRow: e.seatRow, studentId: e.studentId })
                    if (e.seatCol) {
                        tagList.push({ studentId: e.studentId, tagCol: e.seatCol, tagRow: e.seatRow, width: 297 / this.colTol, height: 210 / (this.rowTol + 1), x: (e.seatCol - 1) * (297 / this.colTol), y: (this.rowTol - e.seatRow) * (210 / (this.rowTol + 1)) })
                    } else {
                        tagList.push({ studentId: e.studentId, tagCol: e.seatCol, tagRow: e.seatRow, width: 297 / this.colTol, height: 210 / (this.rowTol + 1), x: (e.seatCol - 1) * (297 / this.colTol), y: (this.rowTol - e.seatRow) * (210 / (this.rowTol + 1)) })
                    }
                })
                studentConfigTag({ tagList: tagList }).then(res => {
                    studentConfigSeat({ seatList: seatList }).then(res => {
                        if (res.data.code == 1) {
                            this.selectObj = {};
                            this.$message.success("配置成功");
                            this.isSeats = false;
                            setTimeout(() => {
                                this.copyCodeImg()
                            }, 3000)
                        } else {
                            this.loadingInstance.close()
                            this.$message.error("操作失败")
                        }
                    })
                })

            } else {
                this.$confirm("当前有学生没配置座位，是否跳过？", "确认提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    this.loadingInstance = ElLoading.service({
                        lock: true,
                        text: '配置中',
                        background: 'rgba(0, 0, 0, 0.7)',
                    })
                    this.studentList.forEach(e => {
                        seatList.push({ seatCol: e.seatCol, seatRow: e.seatRow, studentId: e.studentId })
                        if (e.seatCol) {
                            tagList.push({ studentId: e.studentId, tagCol: e.seatCol, tagRow: e.seatRow, width: 297 / this.colTol, height: 210 / (this.rowTol + 1), x: (e.seatCol - 1) * (297 / this.colTol), y: (this.rowTol - e.seatRow) * (210 / (this.rowTol + 1)) })
                        } else {
                            tagList.push({ studentId: e.studentId, tagCol: e.seatCol, tagRow: e.seatRow, width: 297 / this.colTol, height: 210 / (this.rowTol + 1), x: (e.seatCol - 1) * (297 / this.colTol), y: (this.rowTol - e.seatRow) * (210 / (this.rowTol + 1)) })
                        }
                    })
                    studentConfigTag({ tagList: tagList }).then(res => {

                    })
                    studentConfigSeat({ seatList: seatList }).then(res => {
                        if (res.data.code == 1) {
                            this.selectObj = {};
                            this.$message.success("配置成功");
                            this.isSeats = false;
                            setTimeout(() => {
                                this.copyCodeImg()
                            }, 3000)
                        } else {
                            this.loadingInstance.close()
                            this.$message.error("操作失败")
                        }
                    })
                }).then(response => {

                })
            }

        },
        editStudentDotNo(row) {
            if (this.isNoSet) {
                this.activeDotNo = '';
                this.activeObj = row;
                this.activeDotNoList = [];
                classDotNos({ classId: row.classId }).then(res => {
                    this.activeDotNoList = res.data.data;
                })
                this.isEditDotNo = true;
            }
            console.log(row);
        },
        editStudentDetail(row) {
            this.studentForm = JSON.parse(JSON.stringify(row));
            console.log(this.studentForm);
            this.isCreateStudent = true;
        },
        downloadTemplate() {
            download('https://zbh-oss-formal.oss-cn-beijing.aliyuncs.com/common/excel/%E5%AD%A6%E7%94%9F%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx', '导入学生模板.xlsx')
        },
        clearStudentTag() {
            // this.$message.error('暂未开放')
            studentCreateAllTag({ classId: this.classId }).then(res => {
                if (res.data.code == 1) {
                    this.getInfo()
                    this.$message.success('操作成功')
                } else {
                    if (res.data.msg) {
                        this.$message.error(res.data.msg)
                    } else {
                        this.$message.error('操作失败')
                    }
                }
            })
        },
        configStudentTag() {
            studentCreateTag({ classId: this.classId }).then(res => {
                if (res.data.code == 1) {
                    this.getInfo()
                    this.$message.success('操作成功')
                } else {
                    if (res.data.msg) {
                        this.$message.error(res.data.msg)
                    } else {
                        this.$message.error('操作失败')
                    }
                }
            })
        },
        configSeats() {
            this.studentList = JSON.parse(JSON.stringify(this.form.studentList));
            this.selectObj = {};
            this.isSeats = true;
            setTimeout(() => {
                console.log(this.$refs.seatRef);
                this.$refs.seatRef.scrollTo(0, 1000000);
            })
        },
        downloadClassTag() {
            classDownloadTag({ classId: this.classId }).then(res => {
                if (res.data.code == 1) {
                    download(res.data.data + '?' + new Date().getTime(), this.form.className + '学生标签')
                } else {
                    if (res.data.msg) {
                        this.$message.error(res.data.msg)
                    } else {
                        this.$message.error('操作失败')
                    }
                }

            })
        },
        configClassLabel() {
            this.studentList = JSON.parse(JSON.stringify(this.form.studentList));
            this.selectObj = {};
            this.isClassLabel = true;
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
        },
        handleChange(e, type) {
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    this.$message.success('导入成功')
                    this.getInfo()
                } else {
                    if (e.response.msg) {
                        this.$message.error(e.response.msg)
                    } else {
                        this.$message.error('导入失败')
                    }
                }
            }
            if (e.status == 'fail') {
                this.$message.error('导入失败')
            }
        },
        getZhktQueryDict() {
            zhktQueryDict({ code: 'grade_class_ism' }).then(res => {
                this.classIsmList = res.data.data;
            })
            zhktQueryDict({ code: 'subject' }).then(res => {
                this.subjectList = res.data.data;
            })
            teacherList({ customId: this.$storage.get('customId'), classId: this.classId, pageNum: 1, pageSize: 10000000, }).then(res => {
                this.teacherList = res.data.data.list;
            })
        },
        checkMemberValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            var result = true;
            for (var i = 0; i < this.memberList.length; i++) {
                var e = this.memberList[i];
                if (e.username == '' || e.memberName == '') {
                    result = false
                    break;
                }
            }
            if (result) {
                callback()
            } else {
                callback(new Error('请填写完整信息'))
            }
        },
        getInfo() {
            zhktQueryDict({ code: 'grade_class_ism' }).then(res => {
                this.classIsmList = res.data.data;
                zhktQueryDict({ code: 'subject' }).then(res => {
                    this.subjectList = res.data.data;
                    classInfo({ classId: this.classId }).then(res => {
                        this.colTol = res.data.data.groupNum;
                        this.rowTol = res.data.data.rowNum;
                        this.form = res.data.data;
                        this.form.teacherList.forEach(e => {
                            if (e.subject) {
                                e.subjectList = e.subject.split(',');
                            }else{
                                e.subjectList = []
                            }

                            var subjectTxt = [];
                            e.subjectList.forEach(j => {
                                this.subjectList.forEach(m => {
                                    if (j == m.dictKey) {
                                        subjectTxt.push(m.dictValue)
                                    }
                                })
                            })
                            e.subjectTxt = subjectTxt.toString('、')
                        })
                        this.form.studentList.forEach(e => {
                            e.studentNo = Number(e.studentNo)
                            if (!e.subjectName) {
                                e.subjectName = '';
                            }
                            if (e.dotNo >= this.maxDotNo) {
                                this.maxDotNo = e.dotNo + 1;
                            }
                            if (this.form.gender == 0) {
                                this.form.genderTxt = '男'
                            } else if (e.gender == 1) {
                                this.form.genderTxt = '女'
                            } else {
                                this.form.genderTxt = ''
                            }
                        })
                        this.form.studentList.sort(function (a, b) {
                            if (a.studentNo > b.studentNo) { return 1; }
                            if (a.studentNo < b.studentNo) { return -1; }
                            return 0;
                        })
                        // this.collapseList = arrts;
                        this.configClassLabel();
                    })
                })
            })
        },
        validateMemberUsername(value) {
            qunQueryUser({ mobile: value.username }).then(res => {
                const result = res.data.data
                if (!result) {
                    value.username = ''
                    value.memberName = ''
                    this.$message.error("请输入正确账号")
                } else {
                    if (value.memberName == '') {
                        value.memberName = res.data.data.nickName
                    }

                    value.userId = res.data.data.userId
                }
            })
        },
        // validateUsername(rule, value, callback) {
        //     qunQueryUser({ mobile: value }).then(res => {
        //         const result = res.data.data
        //         if (!result) {
        //             this.form.adminName = ''
        //             this.form.adminUserId = ''
        //             callback(new Error("该账号用户不存在"))
        //         } else {
        //             if (this.form.adminName == '') {
        //                 this.form.adminName = res.data.data.nickName;
        //             }
        //             this.form.adminUserId = res.data.data.userId;
        //             callback()
        //         }
        //     })
        // },
        changeCustom() {
            this.formList = [];
            this.orgList = [];
            this.form.formIds = [];
            queryTreeCustomOrg({ customId: this.form.customId }).then(res => {
                this.orgList = res.data.data;
                res.data.data.forEach((e, i) => {
                    e.label = e.orgName
                    e.value = e.orgId
                    e.children = e.childrenList;
                    e.disabled = true
                    this.ergodicList(e.childrenList)
                })
                console.log(res.data.data)
                this.orgList = res.data.data;
            })
            formGroupForm({ customId: this.form.customId }).then(res => {
                this.formList = res.data.data;
                res.data.data.forEach(e => {
                    this.oldFromIds.forEach(j => {
                        if (e.formId == j) {
                            this.form.formIds.push(e.formId)
                        }
                    })
                })
            })
        },
        deleteMemberRow(index) {
            this.memberList.splice(index, 1)
        },
        onMemberAddItem() {
            this.memberList.push({
                username: '',
                memberName: ''
            })
        },
        onAddItem() {
            console.log(this.tableData)
            this.tableData.push({
                adminAccount: '',
                orgName: ''
            })
        },
        /** 查询基础：基数数据列表 */
        getList() {
            this.loading = false;

            // bookList().then(res => {
            //     this.bookList = res.data.data;
            // });
            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                this.customList = res.data.data;
            });
        },
        /** 提交按钮 */
        submitForm() {
            console.log(this.form)
            this.$refs["form"].validate(valid => {
                if (valid) {
                    console.log(this.form)
                    if (this.$route.query.classId) {
                        schoolUpdate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                this.replace('/classManagement', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        });
                    } else {
                        schoolCreate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("新增成功");
                                this.replace('/classManagement', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }
                        });
                    }
                }
            });
        },
        // 取消按钮
        cancel() {
            this.replace('/classManagement')
            // this.$emit('isTg', true)
        },
        ergodicList(childrenList) {
            if (childrenList.length != 0) {
                childrenList.forEach(e => {
                    e.label = e.orgName
                    e.value = e.orgId
                    console.log(this.findParentNode(e.orgId))
                    if (this.findParentNode(e.orgId).indexOf(2) == -1) {
                        e.disabled = true
                    }
                    if (e.orgType == 5) {
                        // debugger
                        e.disabled = true
                        console.log(e)
                    }
                    if (e.childrenList.length != 0) {
                        e.children = e.childrenList
                        this.ergodicList(e.childrenList)
                    }
                })
            }
        },
        // id 指的是当前点击的节点id;
        findParentNode(id) {
            // debugger
            // 初始化所需数据
            var firstParentObj = {}; // 记录直系父级的名称和id即接口要传的数据
            var parentIds = []; // 记录所有的父级ids
            var parentIdsQueue = []; // 记录排队的

            // 将树放到排队系列
            var parentIdsQueue = JSON.parse(JSON.stringify(this.orgList));

            // 开始遍历排队的树
            while (parentIdsQueue.length) {
                //抽取第一个排队的数据 
                let item = parentIdsQueue.shift();

                let { childrenList } = item;
                if (item.orgId === id) {
                    // 第一层就找到了
                    if (!item.parentArr) {
                        firstParentObj = {
                            id: item.orgId,
                            name: item.orgType
                        };
                        parentIds = [item.orgType];
                    } else {
                        // 获取当前节点的parentArr
                        let len = item.parentArr.length;
                        firstParentObj = item.parentArr[len - 1];
                        item.parentArr.forEach(a => {
                            parentIds.push(a.name);
                        });
                        parentIds.push(item.orgType);
                    }

                    // 结束遍历
                    parentIdsQueue = [];

                    break;
                } else if (childrenList && childrenList.length) {
                    let len = childrenList.length;
                    for (let i = len - 1; i >= 0; i--) {
                        // 新建一个数组用于记录它的父亲节点
                        childrenList[i].parentArr = [];

                        // 把它的历史父亲节点们先放入
                        if (item.parentArr) {
                            childrenList[i].parentArr = childrenList[i].parentArr.concat(
                                item.parentArr
                            );
                        }

                        // 再放入当前的父亲节点
                        childrenList[i].parentArr.push({
                            id: item.orgId,
                            name: item.orgType
                        });

                        // 加入到排队序列中
                        parentIdsQueue.unshift(childrenList[i]);
                    }

                }
            }
            return parentIds

        },
    }
};
</script>
<style scoped>

.disnone /deep/ .el-upload-list {
    display: none;
}

.zdytable /deep/ .el-table td.el-table__cell {
    padding: 0;
}

.zdytable /deep/ .el-table td.el-table__cell div {
    padding: 0;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.bgacc1d9 {
    background: #acc1d9 !important;
}

.bg0798f0 {
    background: #e1f1f9 !important;
}

.bgf24d52 {
    background: #fed4d4 !important;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.el-table /deep/ .hs-row {
    background-color: #f5f5f5;
}

.csbbb:hover .eicon {
    display: block;
}

.detailFF /deep/ .el-input__suffix {
    display: none;
}

.detailFF /deep/ .el-select .el-input.is-disabled .el-input__wrapper {
    cursor: unset;
    background: transparent;
    border: 0;
    box-shadow: none;
    padding: 0;
}

.detailFF /deep/ .el-select .el-select__tags-text {
    color: #333;
}

.detailFF /deep/ .el-select .el-input.is-disabled .el-input__inner {
    color: #333;
    -webkit-text-fill-color: #333;
}

.detailFF /deep/ .el-input.is-disabled {
    cursor: unset;
}

.bfb /deep/ .el-dialog {
    height: 100% !important;
    margin-top: 0vh !important;
    margin-bottom: 0vh !important;
}

.bjbq /deep/ .el-overlay {
    top: -100vh;
}

.bjbq /deep/ .el-overlay-dialog {
    top: -100vh;
    bottom: 100vh;
}

.my-header {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    /* gap: 16px; */
    position: relative;
}

.pct {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.bfb /deep/ .el-dialog__footer {
    padding-top: 5px;
}
</style>